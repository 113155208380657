import React, { useEffect } from 'react';
import { matchPath } from 'react-router-dom';
import { ROUTES } from 'routes';
import { treeNavigationLocalStorageKey } from 'services/netrisk';
import { getRoute, useRouter } from 'services/router';
import { store, useStore } from 'stores/store';
import SportsbookCategoryTree from '../category/tree/SportsbookCategoryTree';
import { CategoryTypeDepth } from '../category/tree/types';

export function SportsbookTreeNavigator() {
    const [treeSelectedCategory] = useStore(store.sportsbook.treeSelectedCategory);
    const { location, navigateTo } = useRouter();

    useEffect(() => {
        const isNetRiskPage = matchPath(location.pathname, {
            path: '/sportsbook/net-risk',
            strict: true,
        });

        if (!treeSelectedCategory) return;
        const { id, depth } = treeSelectedCategory;

        if (isSamePath(id)) {
            return;
        }

        const isNetRiskOpen = localStorage.getItem(treeNavigationLocalStorageKey);

        if (isNetRiskPage) {
            depth === CategoryTypeDepth.League && navigateTo(`${getRoute(ROUTES.sportsbook.netRisk)}/${id}`);
            return;
        }

        if (depth === CategoryTypeDepth.League) {
            const type = isNetRiskOpen ? 'net-risk' : 'matches';
            navigateTo(`${getRoute(ROUTES.sportsbook.categories)}/${id}/${type}`);
        } else {
            navigateTo(`${getRoute(ROUTES.sportsbook.categories)}/${id}/settings`);
        }
    }, [treeSelectedCategory]);

    function isSamePath(id: number) {
        return (
            matchPath(location.pathname, { path: `${getRoute(ROUTES.sportsbook.netRisk)}/${id}` }) ||
            matchPath(location.pathname, { path: `${getRoute(ROUTES.sportsbook.categories)}/${id}` })
        );
    }

    return <SportsbookCategoryTree />;
}
