import styled from 'styled-components';

export default styled.div`
    height: 100%;

    .spinner-div {
        position: absolute;
        top: 50%;
        left: 50%;
    }
`;
