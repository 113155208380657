import debounce from 'lodash/debounce';
import { UiNumberInput } from '../UiNumberInput';
import type { UiNumberInputProps } from '../types';

interface Props extends Omit<UiNumberInputProps, 'type' | 'decimalSeparator' | 'formatter' | 'onChange' | 'onStep'> {
    debounceTimeout: number;
    onChange: (value: number) => void;
}

export function UiNumberInputDebounce({ debounceTimeout, onChange, ...rest }: Props) {
    const delayedChange = debounce((value: number) => {
        onChange(value);
    }, debounceTimeout);

    return <UiNumberInput {...rest} onChange={delayedChange} />;
}
