import { Col, Row, Spin } from 'antd';
import { useWarehouseStatusList } from '../../../../hooks/warehouse/status/useWarehouseStatusList';
import { WarehouseStatusListCard } from './card/WarehouseStatusListCard';

export function WarehouseStatusList() {
    const { processList, isLoading, isUpdating, changeWarehouseProcess } = useWarehouseStatusList();

    if (isLoading) {
        return <Spin size="large" />;
    }

    return (
        <Row gutter={[16, 16]}>
            {processList.map(process => (
                <Col key={process.id} span={24} lg={12} xl={8} xxl={6}>
                    <WarehouseStatusListCard
                        process={process}
                        isUpdating={isUpdating}
                        changeProcess={changeWarehouseProcess}
                    />
                </Col>
            ))}
        </Row>
    );
}
