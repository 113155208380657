import {
    CalculationSummary,
    CustomBalanceCorrection,
    CustomBalanceCorrectionSummary,
    PokerMaintenance,
    PokerUserBalanceCorrection,
    UploadSummary,
} from '../types/poker';
import { createStores } from './utils';

export const poker = createStores({
    manualCorrections: createStores({
        remoteCurrencyRateSummary: {} as UploadSummary,
        correctionUserSummary: {} as UploadSummary,
        calculationSummary: {} as CalculationSummary,
        historicalCorrections: [] as PokerUserBalanceCorrection[],
    }),
    customCorrections: createStores({
        activeCorrections: {} as CustomBalanceCorrection[],
        summaries: [] as CustomBalanceCorrectionSummary[],
    }),
    maintenances: { scheduled: [] as PokerMaintenance[], archived: [] as PokerMaintenance[] },
});
