import React, { useEffect } from 'react';
import { isTokenValid } from 'services/auth';
import { useKeycloak } from 'services/keycloak';
import { store, useStore } from 'stores/store';
import { logger } from 'services/logger';

interface Props {
    children: React.ReactNode;
}

export function Keycloak({ children }: Props) {
    const keycloak = useKeycloak();
    const [tokenCbAuth, setTokenCbAuth] = useStore(store.tokenCbAuth);
    const [tokenAuth, setTokenAuth] = useStore(store.tokenAuth);
    const [user] = useStore(store.boUser);
    const isAuthenticated = Boolean(tokenCbAuth && tokenAuth && user);

    useEffect(() => {
        keycloak.init().catch(error => logger.error('Keycloak', 'Keycloak', error));
    }, []);

    useEffect(() => {
        if (tokenCbAuth && !isTokenValid(tokenCbAuth)) {
            setTokenCbAuth(undefined);
            store.isAuthenticated.set(false);
        }
    }, [tokenCbAuth]);

    useEffect(() => {
        if (tokenAuth && !isTokenValid(tokenCbAuth)) {
            setTokenAuth(undefined);
            store.isAuthenticated.set(false);
        }
    }, [tokenAuth]);

    if (isAuthenticated) {
        return <>{children}</>;
    }
    return <></>;
}
