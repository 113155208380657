import { InputNumber } from 'antd';
import React, { useRef } from 'react';
import Wrapper from './styles';
import classNames from 'classnames';
import type { UiNumberInputProps } from './types';

export function UiNumberInput({
    value,
    onChange,
    customFormatter,
    isPreventUserSelection,
    isEmptyAllowed,
    hideArrows,
    ...props
}: UiNumberInputProps) {
    const inputRef = useRef<HTMLInputElement>(null);

    function parseValue(value: string | undefined) {
        if (value === '-0') {
            return parseFloat('-0.01');
        }

        if (isEmptyAllowed && (value === '' || value === undefined)) {
            return '';
        }

        return parseFloat(value?.replace(',', '.') as string);
    }

    function getCustomFormatter() {
        if (!customFormatter) {
            return { decimalSeparator: '.', parser: parseValue };
        }
        return {
            formatter: customFormatter,
        };
    }

    const format = getCustomFormatter();

    function inputWrapperHandler() {
        if (inputRef?.current) {
            inputRef.current.focus();
        }
    }

    if (isPreventUserSelection) {
        return (
            <Wrapper>
                <div onClick={inputWrapperHandler}>
                    <InputNumber
                        value={value}
                        keyboard={true}
                        ref={inputRef}
                        onChange={value => onChange && onChange(value as number)}
                        {...props}
                        {...format}
                        className={`input ${props.className}`}
                    />
                </div>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <div
                className={classNames({
                    'hide-arrows': hideArrows,
                })}
            >
                <InputNumber
                    value={value}
                    keyboard={true}
                    onChange={value => onChange && onChange(value as number)}
                    {...props}
                    {...format}
                />
            </div>
        </Wrapper>
    );
}
