import { Empty, Space } from 'antd';
import { ROUTES } from '../../../../../routes';
import { getWarehouseFormattedBigNumber } from '../../../../../services/warehouse-management';
import { WarehouseExtract } from '../../../../../types/warehouse-management';
import { WarehouseCardRow } from '../../../card/row/WarehouseCardRow';
import { WarehouseCardWrapper } from '../../../card/wrapper/WarehouseCardWrapper';
import { WarehouseErrorsAction } from '../../../errors/action/WarehouseErrorsAction';
import dayjs from 'services/dayjs';

interface Props {
    details: WarehouseExtract | undefined;
}

export function WarehouseExtractDetailsDetails({ details }: Props) {
    if (!details) {
        return <Empty />;
    }

    const { id, created, database, source_table, warehouse_table, exists_in_wh, live_row_count, wh_row_count } =
        details;

    return (
        <WarehouseCardWrapper
            title="Details"
            button={
                <WarehouseErrorsAction
                    name="See extract errors"
                    route={ROUTES.warehouse.extract.errors}
                    searchParam={warehouse_table}
                />
            }
        >
            <Space direction="vertical">
                <WarehouseCardRow title="Table mapping ID" component={id} />
                <WarehouseCardRow title="Created" component={dayjs(created).fromNow()} />
                <WarehouseCardRow title="Database" component={database} />
                <WarehouseCardRow title="Source table" component={source_table} />
                <WarehouseCardRow title="Warehouse table" component={warehouse_table} />
                <WarehouseCardRow title="Exists in WH" component={exists_in_wh ? 'Yes' : 'No'} />
                <WarehouseCardRow title="Live row count" component={getWarehouseFormattedBigNumber(live_row_count)} />
                <WarehouseCardRow title="WH row count" component={getWarehouseFormattedBigNumber(wh_row_count)} />
            </Space>
        </WarehouseCardWrapper>
    );
}
