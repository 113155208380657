export type ObjectValues<T> = T[keyof T];

export interface FilterSelectOption {
    id: number | string;
    name: string;
}

export interface CsvDataToExport {
    arrayHeader?: string[];
    customDelimiter?: string;
    arrayData: any[];
}

export interface Pagination {
    current: number;
    pageSize: number;
    total: number;
}

export const BOOLEAN_SELECTOR_OPTION = {
    ALL: 'All',
    YES: true,
    NO: false,
} as const;

export type BooleanSelectorOption = ObjectValues<typeof BOOLEAN_SELECTOR_OPTION>;
export type BooleanSelectorLabels = Partial<Record<keyof typeof BOOLEAN_SELECTOR_OPTION, string | undefined>>;

export const OPTIONS_LABEL_STYLE = {
    CAPITALIZED: 'CAPITALIZED',
    UPPERCASED: 'UPPERCASED',
    NORMALIZED: 'NORMALIZED',
};

export type OptionsLabelStyle = ObjectValues<typeof OPTIONS_LABEL_STYLE>;

export const FILE_SIZE_UNITS = {
    B: 'B',
    KB: 'KB',
    MB: 'MB',
    GB: 'GB',
    TB: 'TB',
} as const;

export type FileSizeUnits = ObjectValues<typeof FILE_SIZE_UNITS>;

export interface PaginatedResponse<T> {
    data: T[];
    total: number;
}

export type WithRequiredProperty<Type, Key extends keyof Type> = Type & {
    [Property in Key]-?: Type[Property];
};

export const OS = {
    WINDOWS: 'Windows',
    MACINTOSH: 'Macintosh',
} as const;

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export enum Device {
    DESKTOP = 'desktop',
    TABLET = 'tablet',
    MOBILE = 'mobile',
}
