import { Space, Switch, SwitchProps, Typography } from 'antd';
import classNames from 'classnames';
import Wrapper from './styles';

const { Text } = Typography;
interface Props extends SwitchProps {
    onChange: (value: boolean) => void;
    isChecked: boolean;
    name: string;
    description?: string;
    isDescriptionVertical?: boolean;
    isDescriptionSecondary?: boolean;
    isDisabled?: boolean;
    onLabelClick?: (value: boolean) => void;
    isStrong?: boolean;
    isPaddingDisabled?: boolean;
    isFullWidth?: boolean;
}

export function UiSwitch({
    onChange,
    isChecked,
    name,
    description,
    isDescriptionVertical = false,
    isDescriptionSecondary = false,
    isDisabled,
    onLabelClick,
    isStrong = true,
    isPaddingDisabled = false,
    isFullWidth = false,
    ...props
}: Props) {
    const labelAction = onLabelClick || onChange;

    return (
        <Wrapper isPaddingDisabled={isPaddingDisabled}>
            <Space className={classNames({ 'space-full-width': isFullWidth })}>
                <Space
                    size={isDescriptionVertical ? 0 : 'small'}
                    direction={isDescriptionVertical ? 'vertical' : 'horizontal'}
                    className="pointer"
                    onClick={() => labelAction(!isChecked)}
                >
                    <Text strong={isStrong}>{name}</Text>
                    {description && (
                        <Text className={classNames({ 'text-secondary': isDescriptionSecondary })}>{description}</Text>
                    )}
                </Space>
                <Switch checked={isChecked} onChange={value => onChange(value)} disabled={isDisabled} {...props} />
            </Space>
        </Wrapper>
    );
}
