import { logger } from '@sentry/utils';
import { notification } from 'antd';
import capitalize from 'lodash/capitalize';
import { useEffect, useState } from 'react';
import {
    getWarehouseDeliveryGeneralErrors,
    getWarehouseExtractErrors,
    updateWarehouseGeneralErrors,
} from '../../../microservices/warehouse-management';
import { WAREHOUSE_SEARCH_TABLE_NAME_PARAM } from '../../../services/warehouse-management';
import { GeneralErrorsFilter, WarehouseGeneralErrors } from '../../../types/warehouse-management';
import { useRouter } from '../../../services/router';
import { getString } from '../../../services/filter';
import { useTableFilter } from '../../table/useTableFilter';

const defaultFilter = { page: 0, limit: 100, etl_step: '', level: '', warehouse_table: '', message: '' };

export function useWarehouseGeneralErrors(isExtractErrors: boolean) {
    const { queryParams } = useRouter();
    const searchTableName = getString(WAREHOUSE_SEARCH_TABLE_NAME_PARAM, '', queryParams);

    const nameForNotification = isExtractErrors ? 'extract' : 'delivery general';

    const [generalErrors, setGeneralErrors] = useState<WarehouseGeneralErrors>();
    const { filterState, changeFilterState } = useTableFilter<GeneralErrorsFilter>({
        ...defaultFilter,
        warehouse_table: searchTableName ?? defaultFilter.warehouse_table,
    });
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        loadWarehouseGeneralErrors(filterState);
    }, [filterState]);

    async function loadWarehouseGeneralErrors(filter: GeneralErrorsFilter) {
        setIsLoading(true);

        try {
            const getWarehouseErrors = isExtractErrors ? getWarehouseExtractErrors : getWarehouseDeliveryGeneralErrors;
            const warehouseGeneralErrors = await getWarehouseErrors(filter);
            setGeneralErrors(warehouseGeneralErrors);
        } catch (error) {
            logger.log('useWarehouseGeneralErrors', 'loadWarehouseGeneralErrors', error);
            notification.error({ message: `Error loading ${nameForNotification} errors` });
        }

        setIsLoading(false);
    }

    async function handleWarehouseGeneralErrors(ids: number[]) {
        setIsUpdating(true);

        try {
            await updateWarehouseGeneralErrors({ id__in: ids });
            await loadWarehouseGeneralErrors(filterState);
            notification.success({ message: `${capitalize(nameForNotification)} errors handled successfully` });
        } catch (error) {
            logger.log('useWarehouseGeneralErrors', 'handleWarehouseGeneralErrors', error);
            notification.error({ message: `Error handling ${nameForNotification} errors` });
        }

        setIsUpdating(false);
    }

    return {
        generalErrors,
        filterState,
        searchTableName,
        changeFilterState,
        handleWarehouseGeneralErrors,
        isLoading,
        isUpdating,
    };
}
