import { Form } from 'antd';
import { UiButton } from '../../../ui/button/UiButton';

interface Props {
    name: string;
    isUpdating: boolean;
}

export function WarehouseCardAction({ name, isUpdating }: Props) {
    return (
        <Form.Item noStyle shouldUpdate>
            {({ isFieldsTouched, getFieldsError }) => (
                <UiButton
                    btnName={name}
                    size="middle"
                    className="green-btn"
                    htmlType="submit"
                    disabled={!isFieldsTouched() || !!getFieldsError().find(field => field.errors.length)}
                    loading={isUpdating}
                />
            )}
        </Form.Item>
    );
}
