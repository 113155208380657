import { getRoute } from 'services/router';
import { ROUTES } from 'routes';
import { RouteProps } from 'react-router-dom';
import { lazy } from 'react';
import omit from 'lodash/omit';
import { RouteWithFeature } from 'types/view';
import { OldBackofficePage } from './pages/old-backoffice/OldBackofficePage';
import { NewBackofficePage } from './pages/new-backoffice/NewBackofficePage';
import { WarehousePage } from './pages/warehouse/WarehousePage';
import { isFeatureAvailable } from 'services/features';

const BotPage = lazy(() => import('pages/bot/BotPage').then(module => ({ default: module.BotPage })));
const ChatPage = lazy(() => import('pages/chat/ChatPage').then(module => ({ default: module.ChatPage })));
const RetailPage = lazy(() => import('pages/retail/RetailPage').then(module => ({ default: module.RetailPage })));
const CmsPage = lazy(() => import('pages/cms/CmsPage').then(module => ({ default: module.CmsPage })));
const PaymentsPage = lazy(() =>
    import('pages/payments/PaymentsPage').then(module => ({ default: module.PaymentsPage })),
);
const SportsbookPage = lazy(() =>
    import('pages/sportsbook/SportsbookPage').then(module => ({ default: module.SportsbookPage })),
);

const RacebookPage = lazy(() =>
    import('pages/racebook/RacebookPage').then(module => ({ default: module.RacebookPage })),
);
const UsersPage = lazy(() => import('pages/users/UsersPage').then(module => ({ default: module.UsersPage })));

const MarketingPage = lazy(() =>
    import('pages/marketing/MarketingPage').then(module => ({ default: module.MarketingPage })),
);

const CasinoPage = lazy(() => import('pages/casino/CasinoPage').then(module => ({ default: module.CasinoPage })));
const MaintenancePage = lazy(() =>
    import('pages/maintain/MaintainPage').then(module => ({ default: module.MaintainPage })),
);

const PokerPage = lazy(() => import('pages/poker/PokerPage').then(module => ({ default: module.PokerPage })));

const SecurityPage = lazy(() =>
    import('pages/security/SecurityPage').then(module => ({ default: module.SecurityPage })),
);
const FeaturesPage = lazy(() =>
    import('pages/features/FeaturesPage').then(module => ({ default: module.FeaturesPage })),
);

const LivebetPage = lazy(() => import('pages/livebet/LivebetPage').then(module => ({ default: module.LivebetPage })));

const ReportingPage = lazy(() =>
    import('pages/reporting/ReportingPage').then(module => ({ default: module.ReportingPage })),
);

export function getRootRoutes(): RouteProps[] {
    const routes: RouteWithFeature[] = [
        {
            path: getRoute(ROUTES.sportsbook),
            component: SportsbookPage,
        },
        {
            path: getRoute(ROUTES.retail),
            component: RetailPage,
            feature: 'retail',
        },
        {
            path: getRoute(ROUTES.features),
            component: FeaturesPage,
        },
        {
            path: getRoute(ROUTES.users),
            component: UsersPage,
        },
        {
            path: getRoute(ROUTES.racebook),
            component: RacebookPage,
        },
        {
            path: getRoute(ROUTES.marketing),
            component: MarketingPage,
        },
        {
            path: getRoute(ROUTES.cms),
            component: CmsPage,
        },
        {
            path: getRoute(ROUTES.bot),
            component: BotPage,
        },
        {
            path: getRoute(ROUTES.chat),
            component: ChatPage,
        },
        {
            path: getRoute(ROUTES.marketing),
            component: MarketingPage,
        },
        {
            path: getRoute(ROUTES.payments),
            component: PaymentsPage,
        },
        {
            path: getRoute(ROUTES.maintain),
            component: MaintenancePage,
        },
        {
            path: getRoute(ROUTES.casino),
            component: CasinoPage,
        },
        {
            path: getRoute(ROUTES.poker),
            component: PokerPage,
        },
        {
            path: getRoute(ROUTES.warehouse),
            component: WarehousePage,
        },
        {
            path: `${getRoute(ROUTES.oldBackoffice)}/:url/:id?`,
            component: OldBackofficePage,
        },
        {
            path: `${getRoute(ROUTES.newBackoffice)}/:url/:id?`,
            component: NewBackofficePage,
        },
        {
            path: getRoute(ROUTES.security),
            component: SecurityPage,
        },
        {
            path: getRoute(ROUTES.livebet),
            component: LivebetPage,
        },
        {
            path: getRoute(ROUTES.reporting),
            component: ReportingPage,
        },
    ];
    return routes
        .filter(route => (route.feature ? isFeatureAvailable(route.feature) : true))
        .map(route => omit(route, 'feature') as RouteProps);
}
