import { createBrowserHistory } from 'history';
import { store, getStoreValue } from 'stores/store';
import { GetMedia } from './media';

export function isMobile() {
    return getStoreValue<GetMedia>(store.media).isPhone || getStoreValue<GetMedia>(store.media).isTablet;
}

export function isPhone() {
    return getStoreValue<GetMedia>(store.media).isPhone;
}

export function isDesktopSmall() {
    return getStoreValue<GetMedia>(store.media).isDesktopSmall;
}

export function initHistory() {
    const history = createBrowserHistory();

    history.listen((location, action) => {
        store.router.set({ location, action });
    });

    return history;
}

export function getHeightToFitInWindow(
    selector: string,
    offset?: number,
    height?: number,
    isTopStatic: boolean = false,
) {
    const element = document.querySelector<HTMLElement>(selector)?.getBoundingClientRect();
    const windowHeight = height || window.innerHeight;
    const top = !isTopStatic && element?.top ? element.top : 0;

    return windowHeight - top - (offset || 25);
}

export function getComponentHeight(selector: string) {
    const element = document.querySelector<Element>(selector);
    const elementTopMargin = element ? parseInt(getComputedStyle(element).getPropertyValue('margin-top')) : 0;
    const elementBottomMargin = element ? parseInt(getComputedStyle(element).getPropertyValue('margin-bottom')) : 0;
    const elementHeight = element ? element.getBoundingClientRect().height : 0;

    return elementHeight + elementTopMargin + elementBottomMargin;
}
