import round from 'lodash/round';
import capitalize from 'lodash/capitalize';
import { CashoutList, TicketList } from 'microservices/sportsbook-reports';
import { getCategoryUrl } from '../category';
import uniq from 'lodash/uniq';
import dayjs from 'services/dayjs';
import { OutcomeTradingPosition } from '@staycool/sports-types';
import { TicketDetailBoResponse, TicketOddsInfo, UniqueSelection } from 'microservices/bets/tickets';
import { Bookmaker } from 'types/auth-bo';
import { getDisplayGroupLimit, getUserAlias, getUserFullName } from 'services/user/user';
import { BetInfo, BetStatusEnum, Currency, TicketTypeEnum } from '../../microservices/bets/types';
import { getStoreValue, store } from 'stores/store';
import { getCancelReasons } from 'microservices/sports/cancellation-reason';
import { notification } from 'antd';
import { logger } from 'services/logger';
import { TicketStatus } from '@staycool/bets-types';
import { isFeatureAvailable } from 'services/features';
import { getDateFormat } from '../date';
import { OutcomeRiskRow } from '@staycool/sportsbook-risk-types/outcome-risk';
import { payoutRoundUp } from '@staycool/cb-round';

export const TICKET_LIST_HIDDEN_COLUMNS_KEY = 'ticketListHiddenColumns';

export function processTickets(tickets: TicketList[]) {
    return tickets.map(ticket => {
        const processedTicket = {} as ProcessedTicketProps;

        const ticketType = ticket.ticket_type as TicketTypeEnum;

        if ([TicketTypeEnum.combo, TicketTypeEnum.system, TicketTypeEnum.teaser].includes(ticketType)) {
            const capitalizedTicketType = capitalize(ticketType);
            processedTicket.matchName = capitalizedTicketType;
            if (ticketType === TicketTypeEnum.teaser) {
                processedTicket.marketType = `${capitalizedTicketType} ${ticket.outcome_ids.length}/${ticket.teaser_points}`;
            } else {
                processedTicket.marketType = `${capitalizedTicketType} ${ticket.match_ids.length}/${ticket.bets_count}`;
            }
            processedTicket.outcome = capitalizedTicketType;
        } else {
            processedTicket.matchName = ticket.match_names[0];
            processedTicket.marketType = getMarketDisplayName(
                ticket.market_types[0],
                ticket.home_teams,
                ticket.away_teams,
            );
            processedTicket.outcome = ticket.outcomes[0];
            processedTicket.outcomeComment = ticket.internal_comments[0];
        }
        const { groupLimitName, isLimitedInCategory } = getDisplayGroupLimit(
            ticket.risk_group_name,
            ticket.risk_limit_ratio_prematch,
            ticket.risk_limit_ratio_livebet,
            ticket.product,
            ticket.min_user_limit_ratio,
        );
        processedTicket.userRiskGroupName = groupLimitName;
        processedTicket.isLimitedInCategory = isLimitedInCategory;
        processedTicket.odds = getTicketOdds(ticket);
        processedTicket.userFullName = getUserFullName(ticket.user_id);
        processedTicket.userAlias = getUserAlias(ticket.user_id);
        processedTicket.userExternalId = getStoreValue(store.usersById)[ticket.user_id]?.externalId;
        processedTicket.stake = ticket.stake > 1 ? round(ticket.stake) : ticket.stake;
        processedTicket.matchUrl = getMatchUrl(ticket);
        processedTicket.displayTime = getDisplayTime(ticket.time);

        return { ...ticket, ...processedTicket } as ProcessedTicket;
    });
}

export function processCashoutTickets(tickets: CashoutList[]) {
    return tickets.map(ticket => {
        const processedTicket = {} as ProcessedTicketProps;

        const ticketType = ticket.ticket_type;

        if (ticketType === TicketTypeEnum.combo || ticketType === TicketTypeEnum.system) {
            const capitalizedTicketType = capitalize(ticketType);
            processedTicket.matchName = capitalizedTicketType;
            processedTicket.marketType = `${capitalizedTicketType} ${ticket.match_ids.length}/${ticket.bets_count}`;
            processedTicket.outcome = capitalizedTicketType;
        } else {
            processedTicket.matchName = ticket.match_names[0];
            processedTicket.marketType = getMarketDisplayName(
                ticket.market_types[0],
                ticket.home_teams,
                ticket.away_teams,
            );
            processedTicket.outcome = ticket.outcomes[0];
        }
        const { groupLimitName, isLimitedInCategory } = getDisplayGroupLimit(
            ticket.risk_group_name,
            ticket.risk_limit_ratio_prematch,
            ticket.risk_limit_ratio_livebet,
            ticket.product,
            ticket.min_user_limit_ratio,
        );
        processedTicket.userRiskGroupName = groupLimitName;
        processedTicket.isLimitedInCategory = isLimitedInCategory;
        processedTicket.odds = getTicketOdds(ticket);
        processedTicket.userFullName = getUserFullName(ticket.user_id);
        processedTicket.userAlias = getUserAlias(ticket.user_id);
        processedTicket.matchUrl = getMatchUrl(ticket);
        processedTicket.displayTime = getDisplayTime(ticket.time);

        return { ...ticket, ...processedTicket } as ProcessedCashoutTicket;
    });
}

function getMatchUrl(ticket: TicketList | CashoutList) {
    return `${getCategoryUrl(ticket.category_ids, ticket.match_ids)}${getNavigationUrl(
        ticket.market_ids,
        ticket.market_type_groups,
        ticket.outcome_ids,
    )}`;
}

function getMarketDisplayName(marketName: string, homeTeamNames: string[], awayTeamNames: string[]) {
    let result = marketName;
    if (marketName.toLowerCase().includes('handicap')) {
        const marketNameSplit = marketName.split(' ');
        let lineStr = marketNameSplit.pop();
        if (lineStr) {
            const line = parseFloat(lineStr);
            if (parseFloat(lineStr) > 0) {
                lineStr = `${Math.abs(line)} - 0`;
            } else {
                lineStr = `0 - ${Math.abs(line)}`;
            }
            marketNameSplit.push(lineStr);
            result = marketNameSplit.join(' ');
        }
    }
    if (marketName.toLowerCase().includes('[home]') || marketName.toLowerCase().includes('[away]')) {
        if (homeTeamNames[0]) {
            result = result.replace(/\[(H|h)ome\]/g, homeTeamNames[0]);
        }
        if (awayTeamNames[0]) {
            result = result.replace(/\[(A|a)way\]/g, awayTeamNames[0]);
        }
    }
    return result;
}

export function getDisplayTime(time: string) {
    const format = dayjs(time).isSameOrAfter(dayjs().startOf('day')) ? 'HH:mm:ss' : getDateFormat('seconds');
    return dayjs(time).format(format);
}

function getTicketOdds(ticket: TicketList | CashoutList) {
    return ticket.ticket_type === TicketTypeEnum.system ? capitalize(ticket.ticket_type) : ticket.first_bet_odds;
}

function getNavigationUrl(market_ids: number[], market_type_groups: string[], outcome_ids: number[]) {
    let navigationTarget = '';
    if (!market_ids || !market_type_groups || !outcome_ids) {
        return navigationTarget;
    }

    if (market_ids.length === 1 && market_type_groups.length === 1 && outcome_ids.length === 1) {
        navigationTarget += `?${market_type_groups[0]}=true&target=${outcome_ids[0]}&focus=true&inPlayStatus=All`;
    }

    return navigationTarget;
}

export function getTicketTradingPosition(ticket: ProcessedCashoutTicket | ProcessedTicket) {
    const ticketTradingPositions = ticket.trading_positions || [];
    const tradingPositions = ticketTradingPositions.map(({ trading_position: pos }) => pos).filter(pos => pos);
    if (!tradingPositions.length) {
        return null;
    }
    const uniquePositions = uniq(tradingPositions);
    return uniquePositions.length > 1 ? 'Mix' : uniquePositions[0];
}

export function getBetInfo(
    selection: UniqueSelection,
    ticketData: TicketDetailBoResponse,
    bookmakers: Bookmaker[],
    riskByOutcomeIds?: Record<number, OutcomeRiskRow>,
) {
    const { ticketOddsInfo, ticket } = ticketData;
    const oddsInfo = ticketOddsInfo.find(({ outcome_id }) => outcome_id === selection.outcome_id) as TicketOddsInfo;
    return {
        view_type: selection.view_type,
        match_id: selection.match_id,
        match_name: selection.match_name,
        match_start: selection.match_start,
        sport_name: selection.sport_name,
        home_team: selection.home_team,
        away_team: selection.away_team,
        team_names: selection.team_names,
        region_name: selection.region_name,
        league_name: selection.league_name,
        league_id: selection.category_id,
        market_name: selection.market_name,
        market_type_id: selection.market_type_id,
        market_line: selection.line,
        outcome_name: selection.outcome_name,
        outcome_id: selection.outcome_id,
        status: selection.status,
        trading_position: oddsInfo?.trading_position,
        our_betting_time_odds: oddsInfo?.our_betting_time_odds,
        our_closing_odds: oddsInfo?.our_closing_odds,
        pinnacle_betting_time_odds: oddsInfo?.pinnacle_betting_time_odds,
        pinnacle_closing_odds: oddsInfo?.pinnacle_closing_odds,
        riskBc: riskByOutcomeIds?.[selection.outcome_id]?.risk_bc || 0,
        bookmakers: selection?.responsible_bookmakers?.map(bookmakerId =>
            bookmakers.find(({ id }) => id === bookmakerId),
        ) as Bookmaker[],
        internal_comment: selection.internal_comment,
        cancel_reason: selection.audits?.[0]?.reason,
        product: selection.product,
        cashout_at_live: selection.cashout_at_live,
        cashout_status: ticket.cashout_status,
        cashout_odds: selection.cashout_odds,
        odds: selection.odds,
        odds_format: selection.odds_format,
        banker_outcome_ids: ticket.banker_outcome_ids,
        market_sequence: selection.market_sequence,
        market_type_group: selection.market_group_type,
        resulted_outcome_processed: selection.resulted_outcome_processed,
    };
}

export const payoutRound = (value: number) => {
    const { FEATURE_ENABLED_PAYOUT_ROUND_UP } = getStoreValue(store.environment);
    if (FEATURE_ENABLED_PAYOUT_ROUND_UP === 'true') {
        return payoutRoundUp(value);
    }
    return round(value, 2);
};

export function calculateTicketTotalWinnings(ticket: TicketDetailBoResponse) {
    let initialPotentialWin = 0;
    let pendingPotentialWin = 0;
    let totalWin = 0;

    ticket.bets.forEach(bet => {
        if (bet.is_ma && bet.status === 'MANUAL_ACCEPTANCE_DENIED') {
            return;
        }

        let initialBetTotalOdds = 1;
        let currentBetTotalOdds = 1;

        ticket.uniqueSelections.forEach(selection => {
            if (bet.outcome_ids.includes(selection.outcome_id)) {
                initialBetTotalOdds *= parseFloat(selection.odds);
                const isCancelled = ['CANCELLED', 'PUSHED', 'MANUALLY_CANCELLED'].includes(selection.status);
                currentBetTotalOdds *= isCancelled ? 1 : parseFloat(selection.odds);
            }
        });
        if (onDemandTicketTypes.includes(ticket.ticket.ticket_type)) {
            initialBetTotalOdds = round(initialBetTotalOdds, 3);
            currentBetTotalOdds = round(currentBetTotalOdds, 3);
        }

        initialPotentialWin = initialPotentialWin + payoutRound(initialBetTotalOdds * (bet.stake || 0));
        switch (bet.status) {
            case BetStatusEnum.PENDING:
                pendingPotentialWin = pendingPotentialWin + payoutRound(currentBetTotalOdds * (bet.stake || 0));
                break;
            case BetStatusEnum.WON:
            case BetStatusEnum.PUSHED:
            case BetStatusEnum.CANCELLED:
                totalWin = totalWin + payoutRound(currentBetTotalOdds * (bet.stake || 0));
                break;
        }
    });
    return { initialPotentialWin, pendingPotentialWin, totalWin };
}

export function getTranslatedName(name: string) {
    const isOddsFormatAmerican = getStoreValue(store.sportsbook.isOddsFormatAmerican);
    if (!isOddsFormatAmerican) return name;
    let formattedName = name;
    if (name.toLocaleLowerCase().includes('combo')) {
        formattedName = formattedName.replace('Combo', 'Parlay').replace('combo', 'parlay').replace('COMBO', 'PARLAY');
    }
    //Other replacements depending on 'isOddsFormatAmerican' can be implemented here.
    return formattedName;
}

interface ProcessedTicketProps {
    matchName: string;
    marketType: string;
    outcome: string;
    outcomeComment?: string;
    userRiskGroupName: string;
    isLimitedInCategory: boolean;
    odds: string | number;
    userFullName: string;
    userAlias: string;
    userExternalId?: string;
    stake: number;
    stake_uc: number;
    max_stake_uc: number | null;
    matchUrl: string;
    displayTime: string;
    freeze_status?: string;
    ma_status?: string;
    teaserPoints?: number;
}

export type ProcessedTicket = TicketList & ProcessedTicketProps;
export type ProcessedCashoutTicket = CashoutList & ProcessedTicketProps;

export enum TicketsListType {
    ALL_TICKETS = 'ALL_TICKETS',
    CASHOUT_TICKETS = 'CASHOUT_TICKETS',
    FROZEN_TICKETS = 'FROZEN_TICKETS',
}

export enum TicketFreezeSelectionType {
    ALL_TICKETS = 'ALL_TICKETS',
    FREEZABLE_TICKETS = 'FREEZABLE_TICKETS',
    UNFREEZABLE_TICKETS = 'UNFREEZABLE_TICKETS',
}

export interface TicketFreezeSelectionOption {
    listType: TicketFreezeSelectionType;
    btnName: string;
    frozen?: boolean;
    statuses?: TicketStatus[];
}

export const outcomeTradingPositions: OutcomeTradingPosition[] = ['PR', 'Real', '100%', null];

export const systemStakeNames = [
    'SINGLE',
    'DOUBLE',
    'TREBLE',
    'FOURFOLD',
    'FIVEFOLD',
    'SIXFOLD',
    'SEVENFOLD',
    'EIGHTFOLD',
    'NINEFOLD',
    'TENFOLD',
];

export const onDemandTicketTypes = [TicketTypeEnum.teaser, TicketTypeEnum.parlayCard] as string[];

export const getTicketCancellationReasons = async () => {
    try {
        const ticketCancelReasons = await getCancelReasons('ticket');
        const boUsers = getStoreValue(store.boUsers);
        const user = getStoreValue(store.boUser);
        const isAdmin = boUsers.find(u => u.id === user.id)?.groups?.includes('Sportsbook Department - Admin');
        const cancelReasons = isAdmin
            ? ticketCancelReasons
            : ticketCancelReasons.filter(c => c.id !== 'bet-cancel-integrity');
        return cancelReasons;
    } catch (err) {
        logger.log('TicketService', 'getTicketCancellationReasons', err);
        notification.error({ message: `Failed to get cancellation reasons: ${err.message}` });
        return [];
    }
};

export const showEuroConversion = (currency: Currency) => {
    return isFeatureAvailable('euroConversion') && currency !== 'EUR';
};

export const TICKET_LIST_BACKGROUND_COLORS = 'ticket-list-background-colors';

export type TicketListBackgroundColorSettings = Record<'success' | 'warning' | 'danger', { from: number; to?: number }>;
export const TicketListBackgroundColorSettingsDefaults: TicketListBackgroundColorSettings = {
    success: { from: 500, to: 1000 },
    warning: { from: 1000, to: 3000 },
    danger: { from: 3000 },
};

export const formatFirstKickOffTime = (firstKickOffTime: dayjs.Dayjs) => {
    return getStoreValue(store.sportsbook.isOddsFormatAmerican)
        ? dayjs(firstKickOffTime).format('MM/DD/YYYY h:mm A')
        : dayjs(firstKickOffTime).format('DD.MM HH:mm');
};

type ResultedOutcomeProcessed = UniqueSelection['resulted_outcome_processed'] | BetInfo['resulted_outcome_processed'];
export const checkProcessedDateForResettlement = (resultedOutcomeProcessed: ResultedOutcomeProcessed) => {
    if (!resultedOutcomeProcessed) {
        return false;
    }
    return new Date(resultedOutcomeProcessed) < dayjs().subtract(2, 'months').toDate();
};

export function formatTicketType(ticket: TicketDetailBoResponse) {
    const { extra, ticket_type, parlay_card_name } = ticket.ticket;
    const isRetailTicket = extra.retail;
    const isBetBuilderTicket = ticket.uniqueSelections.some(t => t.market_group_type === 'Bet Builder');
    const typeFirstPart = isRetailTicket && isBetBuilderTicket ? 'SGP' : getTranslatedName(ticket_type)?.toUpperCase();
    const typeSecondPart =
        ticket_type === TicketTypeEnum.parlayCard && parlay_card_name
            ? `- ${ticket.ticket.parlay_card_name} `
            : isBetBuilderTicket
            ? 'BetBuilder'
            : ' ';
    return typeFirstPart + ' ' + typeSecondPart;
}
