import { Col, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useWarehouseRegularValidationErrors } from '../../../../hooks/warehouse/errors/useWarehouseRegularValidationErrors';
import { showWarehouseDate } from '../../../../services/warehouse-management';
import {
    WarehouseRegularValidationError,
    WarehouseRegularValidationErrors,
} from '../../../../types/warehouse-management';
import { WarehouseDebounceInput } from '../../debounce/input/WarehouseDebounceInput';
import { WarehouseErrorsTable } from '../table/WarehouseErrorsTable';

export function WarehouseErrorsRegularValidation() {
    const { regularValidationErrors, filterState, changeFilterState, isLoading } =
        useWarehouseRegularValidationErrors();

    const regularValidationErrorsTableColumns: ColumnsType<WarehouseRegularValidationError> = [
        {
            title: 'Schema',
            dataIndex: 'schema',
            key: 'schema',
            width: 100,
        },
        {
            title: 'Table name',
            dataIndex: 'table_name',
            key: 'table_name',
            width: 500,
        },
        {
            title: 'Validation name',
            dataIndex: 'validation_name',
            key: 'validation_name',
        },
        {
            title: 'Period start',
            dataIndex: 'period_start',
            key: 'period_start',
            render: (_, { period_start }) => showWarehouseDate(period_start),
            width: 320,
        },
        {
            title: 'Period end',
            dataIndex: 'period_end',
            key: 'period_end',
            render: (_, { period_end }) => showWarehouseDate(period_end),
            width: 320,
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            width: 100,
        },
    ];

    return (
        <WarehouseErrorsTable<WarehouseRegularValidationErrors, WarehouseRegularValidationError>
            rowKey={({ period_start, table_name, validation_name }) =>
                `${period_start}_${table_name}_${validation_name}`
            }
            renderHeader={() => (
                <Row gutter={[16, 16]}>
                    <Col>
                        <WarehouseDebounceInput
                            placeholder="Search schema"
                            isLoading={isLoading}
                            onChange={schema => changeFilterState({ page: 0, schema })}
                        />
                    </Col>
                    <Col>
                        <WarehouseDebounceInput
                            placeholder="Search table name"
                            isLoading={isLoading}
                            onChange={table_name => changeFilterState({ page: 0, table_name })}
                        />
                    </Col>
                    <Col>
                        <WarehouseDebounceInput
                            placeholder="Search validation name"
                            isLoading={isLoading}
                            onChange={validation_name => changeFilterState({ page: 0, validation_name })}
                        />
                    </Col>
                </Row>
            )}
            expandable={{ expandedRowRender: ({ row }) => <pre>{JSON.stringify(row, null, 2)}</pre> }}
            tableColumns={regularValidationErrorsTableColumns}
            errors={regularValidationErrors}
            filterState={filterState}
            changeFilterState={changeFilterState}
            isLoading={isLoading}
        />
    );
}
