import { TeamPlayer } from '@staycool/sports-types/dist/features/players/types';
import { notification } from 'antd';
import { getSportsUrl, httpGet, httpPost, httpPut, parseAPIErrorMessage } from 'services/api';
import { logger } from 'services/logger';
import { Team } from './teams';
import { Dayjs } from 'services/dayjs';

export function getFilteredPlayers(filter) {
    const url = getSportsUrl('players/search/');
    return httpPost<Player[]>(url, filter);
}

export function createPlayer(player: Partial<Player>) {
    const url = getSportsUrl('players/');
    return httpPost<Player>(url, player);
}

export function removePlayerFromTeam(playerId: number, teamId: number) {
    const url = getSportsUrl('players/remove-from-team');
    return httpPost(url, { playerId, teamId });
}

export function addPlayerToTeam(playerId: number, teamId: number) {
    const url = getSportsUrl('players/add-to-team');
    return httpPost(url, { playerId, teamId });
}

export function getPlayerDetails(playerId: number) {
    const url = getSportsUrl(`players/player-detail/${playerId}`);
    return httpGet<PlayerDetails>(url);
}

export async function getTeamPlayers(teamIds: number[]) {
    try {
        const url = getSportsUrl(`players/by-team?teamIds=${teamIds}`);
        return await httpGet<TeamPlayer[]>(url);
    } catch (e) {
        logger.log('SportsPlayersMicroservice', 'getTeamPlayers', 'getTeamPlayers', e);
        notification.error({
            message: 'Error while requesting team players',
            description: parseAPIErrorMessage(e),
        });
    }
}

export function getTeamPlayersWithTeamNames(teamIds: number[]) {
    const url = getSportsUrl(`players/by-team-with-team-name?teamIds=${teamIds}`);
    return httpGet<TeamPlayerWithTeamName[]>(url);
}

export function updatePlayer(playerId: string, playerToUpdate: Partial<Player>) {
    const url = getSportsUrl(`players/${playerId}`);
    return httpPut(url, playerToUpdate);
}

export interface Player {
    active?: boolean;
    birth_date?: string | Dayjs | Date;
    created?: string;
    id: number;
    name: string;
    region_id?: number;
    region_name?: string;
    sport_id?: number;
    sport_name?: string;
    tags: string[];
}

export interface PlayerDetails {
    active: boolean;
    birth_date: string;
    created: string;
    id: number;
    name: string;
    region: number;
    sport: number;
    teams: Team[];
    translations: any[];
    tags: string[];
}

export interface PlayerFilter {
    category: number;
    excludeExisting: boolean;
    limit?: number;
    playerName?: string;
    region?: number;
    sport?: number;
}

export interface TeamPlayerWithTeamName {
    id: number;
    name: string;
    active: boolean;
    team_name: string;
    tags: string[];
}

export interface MarketPlayerRow {
    id: number;
    name: string;
    active: boolean;
    team_name?: string;
    existing: boolean;
    tags: string[];
}
