import { Empty, Form, Space, Typography } from 'antd';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';
import pickBy from 'lodash/pickBy';
import { useEffect, useState } from 'react';
import { WarehouseDeliveryBookmark, WarehouseDeliveryBookmarkRequest } from '../../../../../types/warehouse-management';
import { UiNumberInput } from '../../../../ui/number-input/UiNumberInput';
import { WAREHOUSE_DETAILS_FORM_CONFIGURATION } from '../../../../../services/warehouse-management';
import { WarehouseCardWrapper } from '../../../card/wrapper/WarehouseCardWrapper';
import { WarehouseCardAction } from '../../../card/action/WarehouseCardAction';

const { Text } = Typography;

interface Props {
    bookmarks: WarehouseDeliveryBookmark[];
    onBookmarksChange: (bookmarks: WarehouseDeliveryBookmarkRequest[]) => Promise<void>;
}

type EditBookmarksForm = Record<number, number>;

export function WarehouseDeliveryDetailsBookmarks({ bookmarks, onBookmarksChange }: Props) {
    const [form] = Form.useForm<EditBookmarksForm>();
    const [initialValues, setInitialValues] = useState<EditBookmarksForm>(getInitialValues());
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        setInitialValues(getInitialValues());
    }, [bookmarks]);

    useEffect(() => form.resetFields(), [initialValues]);

    function getInitialValues() {
        return mapValues(keyBy(bookmarks, 'id'), 'bookmark');
    }

    async function handleFinish(bookmarks: EditBookmarksForm) {
        setIsUpdating(true);

        const changedBookmarks = pickBy(bookmarks, (value, key) => initialValues[key] !== value);
        await onBookmarksChange(
            Object.entries(changedBookmarks).map(([id, bookmark]) => ({ id: Number(id), bookmark })),
        );

        setIsUpdating(false);
    }

    if (!bookmarks.length) {
        return <Empty />;
    }

    return (
        <Form
            form={form}
            name="delivery-bookmarks"
            initialValues={initialValues}
            onFinish={handleFinish}
            labelCol={{ span: 17 }}
            wrapperCol={{ offset: 1, span: 6 }}
            {...WAREHOUSE_DETAILS_FORM_CONFIGURATION}
        >
            <WarehouseCardWrapper
                title="Delivery bookmarks"
                button={<WarehouseCardAction name="Save" isUpdating={isUpdating} />}
            >
                <Space direction="vertical" size="middle">
                    <Space direction="vertical">
                        {bookmarks.map(({ id, dependency }) => (
                            <Form.Item
                                key={id}
                                label={<Text ellipsis={{ tooltip: dependency }}>{dependency}</Text>}
                                name={id}
                                rules={[{ required: true, message: '' }]}
                            >
                                <UiNumberInput size="small" />
                            </Form.Item>
                        ))}
                    </Space>
                </Space>
            </WarehouseCardWrapper>
        </Form>
    );
}
