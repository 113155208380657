import styled from 'styled-components';

export default styled.div`
    margin-left: auto;
    .user-settings-btn {
        top: 1rem;
        right: ${({ isMobile, isCategoryTreeOpen }) => (isMobile || !isCategoryTreeOpen ? '0' : 'auto')};
        left: ${({ isMobile, isCategoryTreeOpen }) => (isMobile || !isCategoryTreeOpen ? 'auto' : '-4em')};
        z-index: 1;
        height: 2em;
        color: #fff;
        font-size: 1.2em;
        line-height: 0.5em;
    }
`;
