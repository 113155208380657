import { notification } from 'antd';
import { httpPost, parseAPIErrorMessage } from 'services/api';
import { RefFeedBetStatus } from 'services/reference-feed';
import { getRefFeedUrl } from './reference-feed';

export async function getCoreRefFeedOdds(outcomeIds: number[]) {
    try {
        const url = getRefFeedUrl('odds/by-outcome-ids');
        return await httpPost<DatabaseRefFeedOdds[]>(url, { outcomeIds });
    } catch (e) {
        notification.error({
            message: `Failed to request Reference Feed odds for outcome ids ${outcomeIds}`,
            description: parseAPIErrorMessage(e),
        });
        return [];
    }
}

// eslint-disable-next-line rulesdir/no-extraneous-functions
export async function sendRefFeedOddsToCore(marketId: number) {
    // disabled because bookmakers dont use ref feed as odds providers currently
    /*     if (!isFeatureAvailable('referenceFeed')) {
        return;
    }
    try {
        const url = getRefFeedUrl(`odds/send/${marketId}`);
        await httpPost(url);
    } catch (e) {
        notification.error({
            message: 'Failed to send market Reference Feed odds to core',
            description: parseAPIErrorMessage(e),
        });
    } */
}

export interface DatabaseRefFeedOdds {
    /** Reference Feed bet id */
    id: number;
    name: string;
    provider_id: number;
    value: number;
    status: RefFeedBetStatus;

    core_outcome_id: number;
    core_market_id: number;
    core_match_id: number;

    bo_user_id: number;
    created_at?: Date;
    updated_at?: Date;
}
