import { FormInstance } from 'antd';
import React, { useState } from 'react';
import { ColumnType } from 'antd/lib/table';
import { FormItemProps } from 'antd/lib/form/FormItem';

export interface EditableTableColumn<T> extends ColumnType<T> {
    editable?: boolean;
    inputNode?: React.ReactNode;
    formItemProps?: FormItemProps;
}

export function useTableEditableFormRow<T extends {}>(
    form: FormInstance<Partial<T>>,
    field: string | undefined = 'id',
) {
    const [editingId, setEditingId] = useState<string | number | undefined>();

    function isEditing(record: T) {
        return record[field] === editingId;
    }

    function handleEdit(record: any) {
        form.setFieldsValue(record);
        setEditingId(record.id);
    }

    function handleCancel() {
        setEditingId(undefined);
        form.resetFields();
    }

    function getMergedColumns(columns: EditableTableColumn<T>[]) {
        return columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: (record: T) => ({
                    inputNode: col.inputNode,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(record),
                    formItemProps: col.formItemProps,
                }),
            } as ColumnType<T>;
        });
    }

    return { isEditing, handleEdit, handleCancel, getMergedColumns };
}
