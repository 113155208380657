import { color } from '../style/variables';
import { dateFormat } from './date';
import { WarehouseProcessStatus, WAREHOUSE_PROCESS_STATUS } from '../types/warehouse-management';
import { FormProps, ModalFuncProps, TableProps } from 'antd';
import { ROOT_CONTAINER, getPrettyEnumValue } from './util';

export const WAREHOUSE_TIMEZONE = 'UTC';
export const WAREHOUSE_SEARCH_TABLE_NAME_PARAM = 'searchTableName';

export const backgroundTagColorByProcessStatus: Record<WarehouseProcessStatus, string | undefined> = {
    [WAREHOUSE_PROCESS_STATUS.HEALTHY]: color.green500,
    [WAREHOUSE_PROCESS_STATUS.REQUIRES_ATTENTION]: color.gold500,
    [WAREHOUSE_PROCESS_STATUS.UNHEALTHY]: color.red500,
    [WAREHOUSE_PROCESS_STATUS.UNKNOWN]: undefined,
};

export const WAREHOUSE_DETAILS_FORM_CONFIGURATION: FormProps = {
    size: 'middle',
    className: 'form-details',
    requiredMark: false,
};

export const WAREHOUSE_EXTRACT_DETAILS_FORM_CONFIGURATION: FormProps = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
};

export const WAREHOUSE_DETAILS_TABLE_CONFIGURATION: TableProps<any> = {
    className: 'table-warehouse',
    pagination: false,
    scroll: { y: undefined },
    size: 'small',
};

export const WAREHOUSE_MODAL_CONFIGURATION: ModalFuncProps = {
    centered: true,
    getContainer: ROOT_CONTAINER,
};

export function getWarehousePrettyDuration(duration: number | null, hasSuffix: boolean = true) {
    if (duration === null) {
        return '-';
    }

    return `${duration.toFixed(2)}${hasSuffix ? ' s' : ''}`;
}

export function showWarehouseDate(date: string | null) {
    return dateFormat(date, 'millisecondsISO', WAREHOUSE_TIMEZONE);
}

export function getWarehouseDateForUpdate(date: string) {
    return `${dateFormat(date, 'millisecondsForUpdate')}+00:00`;
}

export function getWarehouseGeneralErrorsOptions(values: string[] | undefined) {
    if (!values) {
        return [];
    }

    return [{ label: 'All', value: '' }, ...values.map(value => ({ label: getPrettyEnumValue(value), value }))];
}

export function getWarehouseFormattedBigNumber(value: number | null) {
    if (!value) {
        return value;
    }

    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}
