import { DatabaseOdds } from '@staycool/odds-types';
import { Market } from '@staycool/sports-types';
import { NetRiskFilter } from '@staycool/sportsbook-risk-types/netrisk';
import { OutcomeRiskRow } from '@staycool/sportsbook-risk-types/outcome-risk';
import keyBy from 'lodash/keyBy';
import pickBy from 'lodash/pickBy';
import round from 'lodash/round';
import { OutcomeTradingPosition } from 'microservices/bets/types';
import { getOutcomeRisk, getOutcomesRiskByOutcomeId } from '../microservices/sportsbook-risk';
import { shouldDisplayMarketLine } from './market';

export interface NetriskFilter {
    userLimitFrom: string | null;
    userLimitTo: string | null;
    stakeLimitMin: number | null;
    stakeLimitMax: number | null;
    userCountryCodes: string[];
    userGroupIds: number[];
    timeFrom: string | null;
    timeTo: string | null;
    betTypes: number[];
    ticketType: string;
    marketIds?: number[];
    matchIds?: number[];
    kickOffTimeFrom?: string | null;
    kickOffTimeTo?: string | null;
    specific?: boolean;
}

export type NetriskAlarm = Pick<Market.GetOpenMatchMarketsWithExtras, 'market_type_id' | 'market_type_group_id'>;

export function displayFixtureName(market: Market.GetOpenMatchMarketsWithExtras) {
    let marketName = market.market_name;

    if (shouldDisplayMarketLine(market)) {
        const marketLine = market.market_line;
        let displayLine = String(marketLine);
        const isHandicapMarket = market.market_type_name.toLowerCase().includes('handicap');
        if (isHandicapMarket) {
            if (marketLine > 0) {
                displayLine = `${Math.abs(marketLine)} - 0`;
            } else {
                displayLine = `0 - ${Math.abs(marketLine)}`;
            }
        }
        marketName = `(${market.market_name}) ${displayLine}`;
    }

    return market.match_name ? `${market.match_name} (${marketName})` : marketName;
}

export function displayNetRisk(
    { outcome_id }: Market.GetOpenMatchMarketsWithExtrasOutcome,
    riskByOutcomeId: Record<number, OutcomeRiskRow>,
) {
    const netRisk = riskByOutcomeId[outcome_id]?.risk_bc;
    return netRisk ? Math.ceil(netRisk) : '';
}

export function getAlarmPercentage(
    { outcome_id }: Market.GetOpenMatchMarketsWithExtrasOutcome,
    riskByOutcomeId: Record<number, OutcomeRiskRow>,
) {
    const riskReached = riskByOutcomeId[outcome_id]?.risk_reached || 0;
    return riskReached > 0 ? 0 : Math.round(Math.abs(riskReached) * 100);
}

export function displayAlarmPercentage(
    outcome: Market.GetOpenMatchMarketsWithExtrasOutcome,
    riskByOutcomeId: Record<number, OutcomeRiskRow>,
) {
    return `${getAlarmPercentage(outcome, riskByOutcomeId)}%`;
}

export function getBetsCount(
    { outcome_id }: Market.GetOpenMatchMarketsWithExtrasOutcome,
    riskByOutcomeId: Record<number, OutcomeRiskRow>,
) {
    return riskByOutcomeId[outcome_id]?.bets_count || 0;
}

export function getTotalBetsCount(
    outcomes: Market.GetOpenMatchMarketsWithExtrasOutcome[],
    riskByOutcomeId: Record<number, OutcomeRiskRow>,
) {
    return outcomes.reduce((count, outcome) => {
        return count + getBetsCount(outcome, riskByOutcomeId);
    }, 0);
}

export function displayOutcomeColumnName(name: string) {
    return name
        ? name
              .replace(/Draw/g, 'X')
              .replace(/\[Home]/g, '1')
              .replace(/\[Away]/g, '2')
              .replace(/\[Player 1]/g, '1')
              .replace(/\[Player 2]/g, '2')
        : name;
}

export function getAlarmClass(
    outcome: Market.GetOpenMatchMarketsWithExtrasOutcome,
    riskByOutcomeId: Record<number, OutcomeRiskRow>,
) {
    const alarm = getAlarmPercentage(outcome, riskByOutcomeId);
    if (!alarm) {
        return '';
    }
    if (alarm > 99) {
        return 'alarm-flashing';
    }
    if (alarm > 80) {
        return 'alarm-red';
    }
    if (alarm > 60) {
        return 'alarm-light-red';
    }
    return '';
}

export function sortHtFtNewNetrisk(market: Market.GetOpenMatchMarketsWithExtras) {
    const resultKeysOrder = [
        '[Home]/[Home]',
        '[Home]/Draw',
        '[Home]/[Away]',
        'Draw/[Home]',
        'Draw/Draw',
        'Draw/[Away]',
        '[Away]/[Home]',
        '[Away]/Draw',
        '[Away]/[Away]',
    ];
    const ordered = resultKeysOrder.map(x => market.outcomes.find(o => o.outcome_result_key === x));
    return { ...market, outcomes: ordered } as Market.GetOpenMatchMarketsWithExtras;
}

export function getOutcomeOddsClass({ position }: { position: OutcomeTradingPosition | 'Mix' }) {
    switch (position) {
        case 'PR':
            return 'odds-pr';
        case '100%':
            return 'odds-100';
        case 'Real':
            return 'odds-real';
        case 'Mix':
            return 'odds-mix';
        default:
            return '';
    }
}

export function getTeamName(outcomeName: string, market: Market.GetOpenMatchMarketsWithExtras) {
    const { home_team_name, away_team_name, match_name } = market;
    switch (outcomeName) {
        case '[Home]' || '[Player 1]':
            return home_team_name || match_name.split('-')[0].trim();
        case '[Away]' || '[Player 2]':
            return away_team_name || match_name.split('-')[1].trim();
        default:
            return outcomeName;
    }
}

export function calculateMarginKeyForMarket(
    market: Market.GetOpenMatchMarketsWithExtras,
    oddsById: Record<number, DatabaseOdds>,
) {
    const marginKey = (market.outcomes as Market.GetOpenMatchMarketsWithExtrasOutcome[]).reduce(
        (marginKey, outcome) => {
            if (['DISABLED', 'RESULTED'].includes(outcome.outcome_status) || !oddsById[outcome.outcome_id]?.value) {
                return marginKey;
            }
            const odds = oddsById[outcome.outcome_id].value as number;
            return marginKey + 1 / odds;
        },
        0,
    );
    return round(marginKey * 100, 2);
}

export function formatFiltersForSportsbookRisk(filters: Partial<NetriskFilter> & { categoryId: number }) {
    const { userLimitFrom, userLimitTo, userGroupIds, specific, ticketType, ...rest } = filters;

    const transformedFilters = {
        limitRatioFrom: userLimitFrom || undefined,
        limitRatioTo: userLimitTo || undefined,
        riskGroupIds: userGroupIds || undefined,
        ticketType: { isCashOut: 'isCashout' }[ticketType || ''] || ticketType,
        ...rest,
    };

    const formattedFilters = {};

    for (const [key, value] of Object.entries(transformedFilters)) {
        if (value !== null && value !== '' && !(Array.isArray(value) && value.length === 0)) {
            formattedFilters[key] = value;
        }
    }

    return formattedFilters;
}

export const treeNavigationLocalStorageKey = 'is-net-risk-open';

export const getOutcomesRiskDataBasedOnFilters = async (
    filters: Partial<NetriskFilter> & { categoryId: number },
): Promise<Record<number, OutcomeRiskRow> | undefined> => {
    const cleanFilters: NetRiskFilter = pickBy(formatFiltersForSportsbookRisk(filters));
    const { matchIds, marketIds, categoryId, ...nokFilters } = cleanFilters;
    if (!Object.keys(nokFilters).length) {
        const outcomeRiskRows = await getOutcomeRisk({ marketIds });
        return keyBy(outcomeRiskRows, or => or.outcome_id);
    }
    return getOutcomesRiskByOutcomeId(filters);
};
