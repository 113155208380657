import { getStoreValue } from '../../stores/utils';
import { store } from '../../stores/store';
import { useRouter } from '../../services/router';
import { newRoutes } from '../../routes';
import Wrapper from '../old-backoffice/styles';

export function NewBackofficePage() {
    const { NEW_BACKOFFICE_URL } = getStoreValue(store.environment);
    const { params } = useRouter();

    const page = newRoutes[params.url];
    const id = params.id;

    if (!page) {
        return <div className="root">Page not found</div>;
    }

    const path = id ? `${page}/${id}` : page;
    const url = `${NEW_BACKOFFICE_URL}/${path}?disableMenu=true`;
    return (
        <Wrapper>
            <div className="iframe-container">
                <iframe title="new-backoffice" src={url} width="100%" height="100%" />
            </div>
        </Wrapper>
    );
}
