import { Col, Row, Space, Typography } from 'antd';
import { ReactNode } from 'react';

const { Title } = Typography;

interface Props {
    children: ReactNode;
    title: string;
    button?: ReactNode;
}

export function WarehouseCardWrapper({ children, title, button }: Props) {
    return (
        <Space direction="vertical" size="middle">
            <Row justify="space-between" align="middle">
                <Col>
                    <Title level={5} className="no-margin">
                        {title}
                    </Title>
                </Col>
                {button && <Col>{button}</Col>}
            </Row>
            {children}
        </Space>
    );
}
