import { useEffect } from 'react';
import { getProxyUrl, getServiceUrl } from 'services/api';
import { isDevelopment } from 'services/util';
import io from 'socket.io-client';
import { getStoreValue, store } from 'stores/store';
import { fromEvent } from 'rxjs';
import { logger } from 'services/logger';
import { getToken } from 'services/auth';
import { useKeycloak } from 'services/keycloak';
import pull from 'lodash/pull';

export const getUrl = url => getServiceUrl('feeder', url);

export const socket: any = io(isDevelopment() ? getProxyUrl() : window.location.host, {
    transports: ['websocket'],
    path: getUrl('socket.io/'),
    closeOnBeforeunload: false,
    reconnectionDelayMax: 10000,
});

export const onConnect = fromEvent(socket, 'connect');
export const onDisconnect = fromEvent(socket, 'disconnect');
export const onError = fromEvent(socket, 'error');
export const onAuthenticate = fromEvent(socket, 'authenticate');
export const onAuthenticated = fromEvent(socket, 'authenticated');
export const onAuthenticateRenew = fromEvent(socket, 'authenticate-renew');
export const onAuthenticateFailed = fromEvent(socket, 'authenticate-failed');

export function createSocketSubscribeTopics(topic) {
    const topicSubscribe = `${topic}-subscribe`;
    const topicUnsubscribe = `${topic}-unsubscribe`;
    return { topicSubscribe, topicUnsubscribe };
}

export function useSocketSubscribeUnsubscribe(
    topic,
    { params, watchParams = [] as any[], guardFunction = () => true, resubscribeOnReconnect = true },
) {
    const { topicSubscribe, topicUnsubscribe } = createSocketSubscribeTopics(topic);

    useEffect(() => {
        if (guardFunction()) {
            socket.emit(topicSubscribe, params);
            let unRecord: () => void | undefined;
            if (resubscribeOnReconnect) {
                unRecord = recordSubscription(topicSubscribe, params);
            }
            return () => {
                unRecord?.();
                socket.emit(topicUnsubscribe, params);
            };
        }
    }, [...watchParams]);
}

export const manualSocketSubscribeUnsubscribe = (topic: string, params: any) => {
    const { topicSubscribe, topicUnsubscribe } = createSocketSubscribeTopics(topic);
    socket.emit(topicSubscribe, params);
    const unRecord = recordSubscription(topicSubscribe, params);
    return () => {
        unRecord();
        socket.emit(topicUnsubscribe, params);
    };
};

export function useSocketTopicEvents(topic, callback, watchParams: any[] = []) {
    useEffect(() => {
        const event = fromEvent(socket, topic);
        const subscription = event.subscribe(callback);
        return () => subscription.unsubscribe();
    }, watchParams);
}

const { topicSubscribe: topicPublic, topicUnsubscribe: unsubscribePublic } = createSocketSubscribeTopics('public');
const { topicSubscribe: topicPrivate, topicUnsubscribe: unsubscribePrivate } = createSocketSubscribeTopics('private');

const webSocketSubscriptions: { topicSubscribe: string; params: any }[] = [];

const recordSubscription = (
    topicSubscribe: string = topicPublic,
    params: {
        keepAlive: boolean;
        service: string;
        channel: string;
    },
) => {
    const subscription = { topicSubscribe: topicSubscribe, params };
    webSocketSubscriptions.push(subscription);
    return function unRecordSubscription() {
        pull(webSocketSubscriptions, subscription);
    };
};

/**
 * Automatically join the channel / room - and receive updates to handler
 * @param serviceName - the microservice that is publishing the messages, might be lowercase
 * @param channel - the socket.io room or the first argument in `to` , `in` or `publicEmit`
 * @param eventName - the socket.io event first argument of `emit` or second argument of `publicEmit`
 * @param handlerFn - the function will be called when the
 * @param extraDeps - useEffect dependencies added to the array besides channel name, service Name
 */
export function usePublicSubscribeListen(
    serviceName: string,
    channel: string,
    eventName: string,
    handlerFn: (message: any) => void,
    extraDeps: React.DependencyList = [],
) {
    useEffect(() => {
        const params = createPublicSubscribeParams(serviceName, channel);
        const event = fromEvent(socket, eventName);
        const subscription = event.subscribe(handlerFn);
        socket.emit(topicPublic, params);
        const unRecord = recordSubscription(topicPublic, params);
        return () => {
            unRecord();
            socket.emit(unsubscribePublic, params);
            subscription.unsubscribe();
        };
    }, [channel, serviceName, ...extraDeps]);
}

export function usePrivateSubscribeListen(
    serviceName: string,
    channel: string,
    eventName: string,
    handlerFn: (message: any) => void,
    extraDeps: React.DependencyList = [],
) {
    useEffect(() => {
        const params = createPublicSubscribeParams(serviceName, channel);
        const event = fromEvent(socket, eventName);
        const subscription = event.subscribe(handlerFn);
        socket.emit(topicPrivate, params);
        return () => {
            socket.emit(unsubscribePrivate, params);
            subscription.unsubscribe();
        };
    }, [channel, serviceName, ...extraDeps]);
}

export function usePublicSubscribeUnsubscribe(serviceName: string, channel: string) {
    const params = createPublicSubscribeParams(serviceName, channel);
    return useSocketSubscribeUnsubscribe('public', { params, watchParams: [channel] });
}

export function publicSubscribeEmit(serviceName: string, channel: string) {
    const params = createPublicSubscribeParams(serviceName, channel);
    socket.emit(topicPublic, params);
}

export function publicUnSubscribeEmit(serviceName: string, channel: string) {
    const params = createPublicSubscribeParams(serviceName, channel);
    socket.emit(unsubscribePublic, params);
}

export function createPublicSubscribeParams(serviceName: string, channel: string) {
    return { service: serviceName, channel, keepAlive: true };
}

export function socketAuthenticate() {
    const token = getToken();

    if (token) {
        socket.emit('authenticate', token);
    }
}

export function socketLogout() {
    socket.emit('authenticate-logout');
}

function reSubscribe() {
    socket.emit('resubscribe');
    webSocketSubscriptions.forEach(({ topicSubscribe, params }) => {
        socket.emit(topicSubscribe, params);
    });
}

onConnect.subscribe(() => {
    logger.dev('FeederMicroservice', 'onConnect');
    store.sportsbook.isFeederConnected.set(true);
});

onDisconnect.subscribe(() => {
    logger.dev('FeederMicroservice', 'onDisconnect');
    store.sportsbook.isFeederConnected.set(false);
    store.isAuthenticated.set(false);
});

onError.subscribe(() => {
    logger.dev('FeederMicroservice', 'onError');
});

onAuthenticate.subscribe(() => {
    logger.dev('FeederMicroservice', 'onAuthenticate');
    socketAuthenticate();
});

onAuthenticated.subscribe(() => {
    logger.dev('FeederMicroservice', 'onAuthenticated');
    if (!getStoreValue(store.isAuthenticated)) {
        reSubscribe();
    }
    store.isAuthenticated.set(true);
});

onAuthenticateFailed.subscribe(() => {
    logger.dev('FeederMicroservice', 'onAuthenticateFailed');
});

onAuthenticateRenew.subscribe(async () => {
    logger.dev('FeederMicroservice', 'onAuthenticateRenew');
    const { updateTokens } = useKeycloak();
    await updateTokens();
});
