import React from 'react';
import { SystemAlarm } from './alarm/SystemAlarm';
import { SystemKeyboardShortcuts } from './keyboard-shortcuts/SystemKeyboardShortcuts';
import { SystemLoadStoreData } from './load-store-data/SystemLoadStoreData';
import { SystemMediaQueryHooks } from './media-query-hooks/SystemMediaQueryHooks';
import { SystemTabElection } from './tab-election/SystemTabElection';
import { isFeatureAvailable } from 'services/features';
import { ROLES, useKeycloak } from '../../services/keycloak';
import { SystemManualAcceptanceAggregate } from './manual-acceptance-aggregate/SystemManualAcceptanceAggregate';

export function System() {
    const keycloak = useKeycloak();
    const shouldUseSystemAlarm =
        isFeatureAvailable('alarms') && keycloak.hasAccessForPermissions([ROLES.SPORTSBOOK_RISK.READ]);

    return (
        <>
            <SystemLoadStoreData />
            <SystemKeyboardShortcuts />
            <SystemMediaQueryHooks />
            {isFeatureAvailable('manualAcceptanceAggregate') && <SystemManualAcceptanceAggregate />}
            {shouldUseSystemAlarm && <SystemAlarm />}
            <SystemTabElection />
        </>
    );
}
