import { Col, Row, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useWarehouseGeneralErrors } from '../../../../hooks/warehouse/errors/useWarehouseGeneralErrors';
import { getWarehouseGeneralErrorsOptions } from '../../../../services/warehouse-management';
import { WarehouseGeneralError, WarehouseGeneralErrors } from '../../../../types/warehouse-management';
import { UiButton } from '../../../ui/button/UiButton';
import { UiRadio } from '../../../ui/radio/UiRadio';
import UiSelector from '../../../ui/selector/UiSelector';
import { WarehouseDebounceInput } from '../../debounce/input/WarehouseDebounceInput';
import { WarehouseErrorsTable } from '../table/WarehouseErrorsTable';
import dayjs from 'services/dayjs';

const { Text } = Typography;

interface Props {
    isExtractErrors?: boolean;
}

export function WarehouseErrorsGeneral({ isExtractErrors = false }: Props) {
    const {
        generalErrors,
        filterState,
        searchTableName,
        changeFilterState,
        handleWarehouseGeneralErrors,
        isLoading,
        isUpdating,
    } = useWarehouseGeneralErrors(isExtractErrors);
    const { etl_step, level } = filterState;

    const generalErrorsTableColumns: ColumnsType<WarehouseGeneralError> = [
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
            render: (_, { created }) => <Text className="no-wrap-text">{dayjs(created).fromNow()}</Text>,
            width: 140,
        },
        {
            title: 'ETL step',
            dataIndex: 'etl_step',
            key: 'etl_step',
            width: '15%',
        },
        {
            title: 'Level',
            dataIndex: 'level',
            key: 'level',
            width: 85,
        },
        {
            title: 'Warehouse table',
            dataIndex: 'warehouse_table',
            key: 'warehouse_table',
            ellipsis: true,
            width: '20%',
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
        },
        {
            title: 'Actions',
            dataIndex: 'handled',
            key: 'handled',
            render: (_, { id }) => (
                <UiButton
                    btnName="Handle"
                    type="link"
                    disabled={isUpdating}
                    onClick={() => handleWarehouseGeneralErrors([id])}
                />
            ),
            align: 'center',
            width: 80,
        },
    ];

    return (
        <WarehouseErrorsTable<WarehouseGeneralErrors, WarehouseGeneralError>
            renderHeader={() => (
                <Row gutter={[16, 16]} justify="space-between">
                    <Col flex="auto">
                        <Row gutter={[16, 16]}>
                            <Col>
                                <WarehouseDebounceInput
                                    placeholder="Search warehouse table name"
                                    defaultValue={searchTableName ?? undefined}
                                    isLoading={isLoading}
                                    onChange={warehouse_table => changeFilterState({ page: 0, warehouse_table })}
                                />
                            </Col>
                            <Col>
                                <WarehouseDebounceInput
                                    placeholder="Search message"
                                    isLoading={isLoading}
                                    onChange={message => changeFilterState({ page: 0, message })}
                                />
                            </Col>
                            <Col>
                                <Space>
                                    <Text>ETL step:</Text>
                                    <UiSelector
                                        options={getWarehouseGeneralErrorsOptions(generalErrors?.etl_steps)}
                                        disabled={isLoading}
                                        value={etl_step}
                                        onChange={etl_step => changeFilterState({ page: 0, etl_step })}
                                        className="header-select"
                                        allowClear={false}
                                        showSearch={false}
                                        showArrow
                                        size="middle"
                                        popupMatchSelectWidth={false}
                                    />
                                </Space>
                            </Col>
                            <Col>
                                <Space>
                                    <Text>Level:</Text>
                                    <UiRadio
                                        options={getWarehouseGeneralErrorsOptions(generalErrors?.levels)}
                                        disabled={isLoading}
                                        value={level}
                                        onChange={event => changeFilterState({ page: 0, level: event.target.value })}
                                        size="middle"
                                        buttonStyle="solid"
                                    />
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                    {!!generalErrors?.data.length && (
                        <Col>
                            <UiButton
                                btnName="Handle all visible errors"
                                size="middle"
                                type="default"
                                disabled={isLoading || isUpdating}
                                onClick={() => handleWarehouseGeneralErrors(generalErrors.data.map(error => error.id))}
                            />
                        </Col>
                    )}
                </Row>
            )}
            tableColumns={generalErrorsTableColumns}
            errors={generalErrors}
            filterState={filterState}
            changeFilterState={changeFilterState}
            isLoading={isLoading}
        />
    );
}
