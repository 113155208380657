import {
    ApplicationSheet,
    BannerObject,
    BannerSetObject,
    BetshopData,
    Cms,
    CmsCreate,
    CmsData,
    CmsErrorCode,
    CmsRetailAction,
    CmsRetailActionData,
    CmsSettingType,
    CmsSettings,
    CmsSettingsCountryMultipleImageValueType,
    CmsType,
    CmsUpdate,
    CmsValidatorInput,
    ErrorResponse,
    ExistingBetshopValidator,
    HotCard,
    HotCardType,
    MEDIA_CMS_TABLE_COLUMNS_ARRAY,
    MaintenanceImageObject,
    MediaCms,
    MediaCmsTableColumns,
    MediaCmsUpload,
    OddsSheet,
    RegistrationImages,
    RulesPage,
} from 'types/cms';
import { createCmsSetting, deleteCmsSetting, getCmsSettings, updateCmsSetting } from 'microservices/cms/settings';
import { message, notification } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { downloadOddsSheet, ReportFileType } from 'microservices/retail-proxy';
import { OddsBoardContent, OddsBoardTemplate } from 'microservices/digital-board';
import { logger } from './logger';
import difference from 'lodash/difference';
import intersectionWith from 'lodash/intersectionWith';
import isEqual from 'lodash/isEqual';
import {
    ArticleCreationAndUpdateRequest,
    ArticleTemplateResponse,
    HtmlVariable,
    VariableContentType,
    VariableVariant,
} from 'types/article';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import isNumber from 'lodash/isNumber';
import { GetMedia } from './media';
import { RcFile } from 'antd/lib/upload';
import { isSmallerThanSize, transformFileSize } from 'services/util';
import { getStoreValue, store } from 'stores/store';
import { FILE_SIZE_UNITS } from 'types/util';
import { dateFormat } from 'services/date';
import { BoUser } from 'types/auth-bo';
import { MatchPromotionTypes, Promotion } from 'types/match-promotion';
import omit from 'lodash/omit';

export const IMAGE_BASE_URL = 'https://coolbet-cms.imgix.net/';

export async function getCmsParsedSettings(id?: string, keys?: string[]) {
    const data = await getCmsSettings(keys);
    const parsedCms = parseSetting(data);
    return id ? parsedCms.find(cms => cms.id === id) : parsedCms;
}

export function parseSetting(result: CmsSettings[]) {
    const groupedSettings: { [key: string]: Cms } = {};
    const cmsTypeMap = {
        banner: CmsType.Banner,
        'hot card': CmsType.HotCard,
        'rules page': CmsType.RulesPage,
        'odds sheet': CmsType.OddsSheet,
        'banner set': CmsType.BannerSet,
        'application sheet': CmsType.ApplicationSheet,
        'registration images': CmsType.RegistrationImages,
        'maintenance image': CmsType.MaintenanceImage,
    };
    result.forEach(setting => {
        if ((Object.values(CmsSettingType) as string[]).includes(setting.type)) {
            const parsedValue = JSON.parse(setting.value);
            const cmsType = getCmsType(parsedValue.type, parsedValue.position);
            const betshopId = setting.key.replace(cmsType + '-', '');

            if (groupedSettings[parsedValue.id]) {
                const count = groupedSettings[parsedValue.id]?.used_on || 0;
                const usedOn = betshopId === parsedValue.id ? count : count + 1;
                groupedSettings[parsedValue.id] = {
                    ...groupedSettings[parsedValue.id],
                    used_on: usedOn,
                    betshops: [...(groupedSettings[parsedValue.id]?.betshops || []), betshopId],
                };
            } else {
                const {
                    id,
                    name,
                    type,
                    banners,
                    position,
                    hotCards,
                    rulesPage,
                    oddsSheet,
                    applicationSheet,
                    registrationImages,
                    maintenanceImage,
                } = parsedValue;
                groupedSettings[parsedValue.id] = {
                    id,
                    name,
                    type: cmsTypeMap[type.toLowerCase()],
                    used_on: id === betshopId ? 0 : 1,
                    updatedAt: setting.updatedAt,
                    updatedBy: setting.updatedBy,
                    banners,
                    betshops: [betshopId],
                    position,
                    hotCards,
                    rulesPage,
                    oddsSheet,
                    applicationSheet,
                    registrationImages,
                    maintenanceImage,
                };
            }
        }
    });

    return Object.keys(groupedSettings).map(key => {
        return groupedSettings[key];
    });
}

export function betshopKey(type: CmsType, betshopId: number | string, position?: string) {
    return `${getCmsType(type, position)}-${betshopId}`;
}

export function checkIfArticleIsNew(articleId: string) {
    return /^\d+$/.test(articleId);
}

export async function checkExistingBetshops({ type, newBetshops, betshops, position }: ExistingBetshopValidator) {
    if (!newBetshops.length) {
        return [];
    }
    const keys = newBetshops.map(betshopId => betshopKey(type, betshopId, position));
    const cmsSetting = (await getCmsParsedSettings(undefined, keys)) as Cms[];
    if (!cmsSetting.length) {
        return [];
    }
    const availableBetshops: string[] = [];
    cmsSetting.forEach(setting => {
        const presentBetshop = intersectionWith(newBetshops, setting.betshops as string[], isEqual);
        availableBetshops.push(...presentBetshop);
        return setting;
    });

    const existingBetshops: { id: string; name: string }[] = [];
    availableBetshops.forEach(betshopId => {
        const betshop = betshops.find(betshop => betshop.id === betshopId);
        if (betshop || betshopId === 'web') {
            existingBetshops.push({ id: betshopId, name: betshop?.name || 'Web display' });
        }
    });
    return existingBetshops;
}

export function validateUserInput(data: CmsData, type: CmsType, banners?: BannerObject[] | BannerSetObject[]) {
    const { name, position } = data;
    if (!name.trim()) {
        const message = `Name is required`;
        notification.error({ message });
        return false;
    }

    if (type === CmsType.Banner && !position) {
        notification.error({ message: 'Position is required' });
        return false;
    }
    if (banners && !banners.length) {
        notification.error({ message: 'Banner is required to save content' });
        return false;
    }

    if (position === 'Top' && banners && banners.length > 1) {
        notification.error({ message: 'Only one banner can be added when position is Top' });
        return false;
    }
    return true;
}

export async function cmsRetailAction({
    betshopsIds,
    type,
    data,
    name,
    action = 'create',
    position,
    cmsId,
}: CmsRetailAction) {
    const id = action === 'create' ? uuidv4() : null;
    if (!betshopsIds.length && type !== CmsType.OddsSheet) {
        return;
    }
    await Promise.all(
        [...betshopsIds, ...[!betshopsIds.length && type === CmsType.OddsSheet ? id : undefined]]
            .filter(Boolean)
            .map(async betshopId => {
                const formattedData = formatData(betshopId as number, cmsId || id, type, name, data, position);
                const res =
                    action === 'create'
                        ? await createCmsSetting(formattedData)
                        : await updateCmsSetting(formattedData.key, formattedData);
                return res;
            }),
    );
}

function formatData(betshopId: number, id, type: CmsType, name, data: CmsRetailActionData, position?: string) {
    const betshopData: BetshopData = {
        name,
        type,
        id,
        position,
    };

    if (type === CmsType.BannerSet) betshopData.banners = data as BannerSetObject[];
    if (type === CmsType.Banner) betshopData.banners = data as BannerObject[];
    if (type === CmsType.HotCard) betshopData.hotCards = data as HotCard[];
    if (type === CmsType.RulesPage) betshopData.rulesPage = data as RulesPage;
    if (type === CmsType.OddsSheet) betshopData.oddsSheet = data as OddsSheet;
    if (type === CmsType.ApplicationSheet) betshopData.applicationSheet = data as ApplicationSheet;
    if (type === CmsType.RegistrationImages) betshopData.registrationImages = data as RegistrationImages;
    if (type === CmsType.MaintenanceImage) betshopData.maintenanceImage = data as MaintenanceImageObject;

    return {
        key: betshopKey(type, betshopId, position),
        value: JSON.stringify(betshopData),
        type: getCmsType(type, position),
    };
}

export function getCmsType(type: CmsType, position?: string) {
    const typeMap = {
        [CmsType.Banner]: `retail-${position?.toLowerCase()}-banner`,
        [CmsType.HotCard]: 'retail-hot-event-cards',
        [CmsType.RulesPage]: 'retail-rules-page',
        [CmsType.OddsSheet]: 'retail-odds-sheet',
        [CmsType.BannerSet]: 'retail-banner-set',
        [CmsType.ApplicationSheet]: 'retail-application-sheet',
        [CmsType.RegistrationImages]: 'retail-registration-images',
        [CmsType.MaintenanceImage]: 'retail-maintenance-image',
    };

    return typeMap[type];
}

export async function downloadOddsSheetReport(name: string, oddsSheet: OddsSheet, type: ReportFileType) {
    const data = {
        matchIds: oddsSheet.matchIds as number[],
        logo: oddsSheet.image?.url,
        pageSize: oddsSheet.pageSize,
        header: oddsSheet.headerText,
        footer: oddsSheet.footerText,
        name: name,
        categoryIds: (oddsSheet.categories?.map(category => category?.id) as number[]) || [oddsSheet?.['category']?.id],
    };

    await downloadOddsSheet(data, type);
}

function validateFields(oddsView: OddsBoardContent): boolean {
    const { marketTypeIds = [], title, leagueName, template } = oddsView;

    if (!title && !leagueName) {
        return false;
    }

    return (
        Boolean(marketTypeIds.length) ||
        template === OddsBoardTemplate.Triple ||
        template === OddsBoardTemplate.SingleColumnPortrait
    );
}

export function validateOddsView(oddsView: OddsBoardContent, matchIds: number[]): boolean {
    const isValidFields = validateFields(oddsView);
    if (!isValidFields) {
        notification.warning({ message: 'Please fill all fields to create Odds View' });
        return false;
    }
    if (oddsView.futuresEnabled) {
        if (matchIds.length > 1) {
            notification.warning({ message: 'Future matches can only be one' });
            return false;
        }
        if (!oddsView.marketTypeIds?.length) {
            notification.warning({ message: 'Please choose at least one future market' });
            return false;
        }
    }
    return true;
}

export async function createCmsforBetshops({
    name,
    selectedBetshops,
    position,
    betshops,
    banners,
    data,
    type,
    skipValidation,
    existingBetshops = [],
}: CmsCreate) {
    const id: string = uuidv4();
    const cmsBetshopToCreate: string[] = [id];

    const result = await validateCmsData({
        name,
        newBetshops: selectedBetshops,
        position,
        type,
        banners,
        betshops,
        validate: skipValidation,
    });

    if (!result.success) {
        return result;
    }

    if (existingBetshops.length) {
        cmsBetshopToCreate.push(...selectedBetshops.filter(bs => !existingBetshops.includes(bs)));
    } else {
        cmsBetshopToCreate.push(...selectedBetshops);
    }
    try {
        await Promise.all([
            cmsRetailAction({ ...data, betshopsIds: cmsBetshopToCreate, action: 'create', position, cmsId: id }),
            cmsRetailAction({ ...data, betshopsIds: existingBetshops, action: 'update', cmsId: id, position }),
        ]);
        return { success: true };
    } catch (e) {
        logger.log('CmsService', 'createCmsforBetshops', e);
        notification.error({
            message: `Error while creating ${type} for betshop`,
        });
    }
    return { success: false };
}

export async function updateCmsForBetshops({
    cmsBetshops,
    selectedBetshops,
    name,
    position,
    betshops,
    banners,
    data,
    cms,
    type,
    skipValidation,
    existingBetshops = [],
}: CmsUpdate) {
    const betShopsRemoved = difference(cmsBetshops, selectedBetshops);
    let newlyAddedBetshop = difference(selectedBetshops, cmsBetshops);
    const presentBetshop = intersectionWith(cmsBetshops, selectedBetshops, isEqual);

    const result = await validateCmsData({
        name,
        newBetshops: newlyAddedBetshop,
        position,
        type,
        banners,
        betshops,
        validate: skipValidation,
    });

    if (!result.success) {
        return result;
    }

    if (existingBetshops.length) {
        newlyAddedBetshop = newlyAddedBetshop.filter(bs => !existingBetshops.includes(bs));
    }

    try {
        await Promise.all([
            deleteCmsForBetshops(betShopsRemoved, type, position),
            cmsRetailAction({ ...data, betshopsIds: newlyAddedBetshop, action: 'create', cmsId: cms?.id, position }),
            cmsRetailAction({
                ...data,
                betshopsIds: [...presentBetshop, ...existingBetshops],
                action: 'update',
                cmsId: cms?.id,
                position,
            }),
        ]);
        notification.success({
            message: `${type} update successfull`,
        });
        return { success: true };
    } catch (e) {
        logger.log('CmsService', 'updateCmsForBetshops', e);
        notification.error({
            message: `Error while saving ${type} for betshop`,
        });
    }
    return { success: false };
}

async function validateCmsData({ name, newBetshops, position, type, banners, betshops, validate }: CmsValidatorInput) {
    if (validate) {
        return { success: true };
    }
    if (!validateUserInput({ name, position }, type, banners)) {
        return { success: false };
    }

    const existingBetshops = await checkExistingBetshops({ type, newBetshops, betshops, position });
    if (existingBetshops.length) {
        return { success: false, betshops: existingBetshops };
    }

    return { success: true };
}

async function deleteCmsForBetshops(betshops: string[], type: CmsType, position?: string) {
    try {
        if (!betshops.length) {
            return;
        }
        await deleteCmsSetting(betshops.map(betshopId => betshopKey(type, betshopId, position)));
    } catch (e) {
        logger.log('CmsService', 'deleteCmsForBetshops', e);
        notification.error({
            message: `Error while deleting banner for betshops`,
        });
    }
}

export function getCmsRouteFromType(type: CmsType) {
    return type.split(' ').join('-').toLowerCase();
}

// -----Articles------

export function compileArticle(template: ArticleTemplateResponse, themeId: number, htmlVariables: HtmlVariable[]) {
    try {
        const theme = template?.themes.find(theme => theme.id === themeId);
        const templateHtml = template.html;
        const templateCss = template.css;
        if (!template?.html) {
            throw new Error(`ERROR: Missing html body in template`);
        }
        const compiledHtml = compileArticleHtml(templateHtml, htmlVariables);
        const compiledCss = compileArticleCss(templateCss, theme?.css_values || {});
        return `${compiledCss}
                ${compiledHtml}`;
    } catch (error) {
        logger.error('CmsService', 'compileArticle', error);
    }
}

function compileArticleHtml(htmlString: string, htmlVariables: HtmlVariable[]) {
    const document = new DOMParser().parseFromString(htmlString, 'text/html');
    const htmlVariablesByKey = keyBy(htmlVariables, 'key');
    composeHtmlNodes([document.body] as unknown as NodeListOf<Node>, htmlVariablesByKey);
    return document.body.innerHTML;
}

function composeHtmlNodes(htmlNodes: NodeListOf<Node>, htmlVariablesByKey: Record<string, HtmlVariable>) {
    htmlNodes.forEach(node => {
        replaceTemplateInElementAttributes(node, htmlVariablesByKey);
        if (node.nodeType === Node.TEXT_NODE) {
            if (!node.textContent) {
                return;
            }
            const updatedTemplateValue = updateTemplateContent(
                node.textContent,
                htmlVariablesByKey,
                undefined,
                undefined,
                node,
            );
            if (updatedTemplateValue) {
                node.textContent = updatedTemplateValue as string;
            }
        }
        if (node.childNodes) {
            composeHtmlNodes(node.childNodes, htmlVariablesByKey);
        }
    });
}
function replaceTemplateInElementAttributes(node: Node, htmlVariablesByKey: Record<string, HtmlVariable>) {
    if (!(node instanceof Element)) {
        return;
    }
    const attributes = node.attributes;
    for (const attribute of attributes) {
        const updatedTemplateValue = updateTemplateContent(attribute.value, htmlVariablesByKey, node, attribute);
        if (updatedTemplateValue) {
            attribute.value = updatedTemplateValue as string;
        }
    }
}
function updateTemplateContent(
    content: string,
    htmlVariablesByKey: Record<string, HtmlVariable>,
    element?: Element,
    attribute?: Attr,
    node?: Node,
) {
    const templateVariables = findKeysInTemplate(content);
    const matchedVariable = htmlVariablesByKey[templateVariables[0]];
    if (!templateVariables.length || !matchedVariable) {
        return;
    }
    const isAttributeContentExisting = matchedVariable.variants.find(variant => variant.content);
    if (matchedVariable.key === templateVariables[0] && !isAttributeContentExisting) {
        return attribute && element && element.removeAttributeNode(attribute);
    }
    const defaultVariant = matchedVariable.variants.find(variant => variant.type === VariableVariant.DEFAULT);
    if (!defaultVariant) {
        return;
    }
    const newContent = content.replace(
        '$'.concat(`{${matchedVariable.key}}`),
        defaultVariant.content || templateVariables[0],
    );
    if (matchedVariable.type === VariableContentType.WYSIWYG || matchedVariable.type === VariableContentType.CODE) {
        if (!node || !node.parentNode) {
            return;
        }
        const container = document.createElement('div');
        container.innerHTML = newContent;
        while (container.firstChild) {
            node.parentNode.insertBefore(container.firstChild, node);
        }
        node.parentNode.removeChild(node);
        return;
    }
    return newContent;
}

function compileArticleCss(cssString: string, cssValues: Record<string, string>) {
    if (!cssString) {
        return '';
    }
    const cssKeys = findKeysInTemplate(cssString);
    for (const key of cssKeys) {
        if (!cssValues[key]) {
            throw new Error(`ERROR: Missing key ${key} in cssValues`);
        }
        cssString = cssString.replace('${' + key + '}', cssValues[key]);
    }
    return cssString;
}

export function findKeysInTemplate(template: string): string[] {
    const regex = /\${(.*?)}/g;
    const matches: string[] = [];

    let match: RegExpExecArray | null;
    while ((match = regex.exec(template)) !== null) {
        matches.push(match[1]);
    }
    return matches;
}

export function addCompiledArticleToTranslations(
    articleRequest: ArticleCreationAndUpdateRequest,
    template: ArticleTemplateResponse,
) {
    for (const translationIndex in articleRequest.translations) {
        const compiledArticle = compileArticle(
            template,
            articleRequest.theme_id,
            articleRequest.translations[translationIndex].html_variables,
        );
        if (compiledArticle) {
            articleRequest.translations[translationIndex].body = compiledArticle;
        }
    }
    return articleRequest;
}

export function truncateString(str: string, maxLength: number): string {
    if (str.length > maxLength) {
        return `${str.substring(0, maxLength)}...`;
    }
    return str;
}

export const CMS_GENERAL_SETTINGS_MODAL_FORM = 'CMS_GENERAL_SETTINGS_MODAL_FORM';
export const IMAGE_GENERAL_SETTINGS_NUMBER_OF_CELLS = { span: 12, md: 8, lg: 6, xl: 4, xxl: 3 };

export function getGeneralSettingsImagesLimit(media: GetMedia) {
    const COLS_IN_ROW = 24;
    const { isLaptop, isDesktop, isDesktopSmall } = media;

    if (isDesktop && !isDesktopSmall) {
        return COLS_IN_ROW / IMAGE_GENERAL_SETTINGS_NUMBER_OF_CELLS.xxl - 1;
    }

    if (isLaptop || isDesktop) {
        return COLS_IN_ROW / IMAGE_GENERAL_SETTINGS_NUMBER_OF_CELLS.xl - 1;
    }

    return 3;
}

export function updateCmsGeneralSettingsCountryMultipleImage(
    selectedImage: CmsSettingsCountryMultipleImageValueType,
    images: CmsSettingsCountryMultipleImageValueType[],
    imageIndexToBeReplaced: number | undefined,
) {
    return isNumber(imageIndexToBeReplaced)
        ? images.map((image, index) => (index === imageIndexToBeReplaced ? selectedImage : image))
        : [...images, selectedImage];
}

export function getCleanTranslation(translationObject: Record<string, string>, isSlug = false): Record<string, string> {
    return mapValues(translationObject, value => (isSlug ? value.replaceAll(' ', '-').replace(/-+/g, '-') : value));
}

export function getErrorCode(error: ErrorResponse): CmsErrorCode | undefined {
    if (error?.code) {
        return Number(error.code.split('-').pop()) as CmsErrorCode;
    }
}

export function isValidCmsMedia(file?: RcFile) {
    if (!file) {
        message.error(`Could not upload media`);

        return false;
    }

    if (!['image/jpeg', 'image/png'].includes(file.type)) {
        message.error(`Could not upload, ${file.name} is't JPG/PNG file`);

        return false;
    }

    const { MAX_IMAGE_UPLOAD_SIZE = 0.7 } = getStoreValue(store.environment);

    if (!isSmallerThanSize(file, MAX_IMAGE_UPLOAD_SIZE)) {
        message.error(`Could not upload ${file.name}, it's more than ${MAX_IMAGE_UPLOAD_SIZE} MB`);

        return false;
    }

    return true;
}

export function preparePayloadMediaCms(payload: MediaCmsUpload) {
    const { bucket, category, department, height, hidden, image, folder_id, tags, title, width } = payload;

    const media = new FormData();
    media.append('files', image);
    media.append('bucket', bucket);
    media.append('category', category);
    media.append('department', department);
    media.append('height', height.toString());
    media.append('title', title);
    media.append('width', width.toString());

    if (hidden) {
        media.append('hidden', hidden.toString());
    }

    if (folder_id) {
        media.append('folder_id', folder_id.toString());
    }

    if (tags?.length) {
        tags.forEach(tag => media.append('tags[]', tag));
    }

    return media;
}

export function getCmsMediaUrl({ url, is_old_media: isOldUrl }: Partial<MediaCms>) {
    const { IMAGES_HOST } = getStoreValue(store.environment);

    if (isOldUrl) {
        return url as string;
    }

    return IMAGES_HOST + url;
}

export function getMediaCmsSize({ width, height, size }: MediaCms) {
    const dimensions = width && height ? `${width}x${height}px` : undefined;

    return { dimensions, size: transformFileSize(size, FILE_SIZE_UNITS.KB) };
}

export function getMediaCmsDateAndUser(boUserByChangedBy: Record<string, BoUser>, userId: string | null, date: string) {
    const userName =
        userId && boUserByChangedBy[userId]
            ? `${boUserByChangedBy[userId].first_name} ${boUserByChangedBy[userId].last_name}`
            : userId;

    return { date: dateFormat(date, 'days'), userName };
}

export function getMediaCmsAllTableColumnsValue(value: boolean) {
    return MEDIA_CMS_TABLE_COLUMNS_ARRAY.reduce((acc, item) => {
        acc[item] = value;

        return acc;
    }, {} as Record<MediaCmsTableColumns, boolean>);
}

export const MEDIA_CMS_TABLE_COLUMNS_DEFAULT_ORDER = MEDIA_CMS_TABLE_COLUMNS_ARRAY.reduce((acc, item, index) => {
    acc[item] = index;

    return acc;
}, {} as Record<MediaCmsTableColumns, number>);

export const kioskCmsRoutes = [
    {
        name: 'Recommendations',
        path: 'sport.recommendations',
        hotCardType: HotCardType.RouteHotCard,
    },
    {
        name: 'Live',
        path: 'sport.live',
        hotCardType: HotCardType.RouteHotCard,
    },
    {
        name: 'Horse Racing',
        path: 'horse-racing',
        hotCardType: HotCardType.RouteHotCard,
    },
    {
        name: 'Parlay Cards',
        path: 'sport.parlay-cards',
        hotCardType: HotCardType.RouteHotCard,
    },
    {
        name: 'Contests',
        path: 'sport.contests',
        hotCardType: HotCardType.RouteHotCard,
    },
];

export interface KioskRouteType {
    name: string;
    path: string;
    hotCardType: HotCardType;
}

export function prepareMatchPromotionToUpload(promotion: Promotion | undefined, payload: Promotion): Promotion {
    const { market_type_id, outcome_id, market_id, link, ...restValues } = payload;
    const result = omit(promotion, 'link', 'market_id', 'market_type_id', 'outcome_id') as Promotion;

    if (payload.type === MatchPromotionTypes.CTA) {
        result.link = link;
        result.market_id = null;
        result.market_type_id = null;
        result.outcome_id = null;
    } else {
        result.link = null;
        result.market_id = market_id;
        result.market_type_id = market_type_id;
        result.outcome_id = outcome_id;
    }

    return { ...result, ...restValues };
}

export function getPaymentsImageUrl(url: string) {
    return url.startsWith('http') ? url : `${IMAGE_BASE_URL}/${url}`;
}
