import { logger } from '../../../services/logger';
import { useEffect, useState } from 'react';
import { getWarehouseActivityHistory } from '../../../microservices/warehouse-management';
import { WAREHOUSE_ACTIVITY_STATUS, WarehouseActivityHistory } from '../../../types/warehouse-management';
import { notification } from 'antd';
import { useTableFilter } from '../../table/useTableFilter';

interface TableFilter {
    page: number;
    limit: number;
    search: string;
    durationCutoff: number | null;
    isSuccessStatusExcluded: boolean;
}

const defaultTableState = {
    page: 1,
    limit: 2000,
    search: '',
    durationCutoff: null,
    isSuccessStatusExcluded: false,
};

export function useWarehouseStatusDetails(detailsId: number) {
    const [activityHistoryList, setActivityHistoryList] = useState<WarehouseActivityHistory[]>([]);
    const { filterState, changeFilterState } = useTableFilter<TableFilter>(defaultTableState);
    const [isLoading, setIsLoading] = useState(true);

    const filteredActivityHistoryList = activityHistoryList.filter(activityHistory => {
        if (filterState.durationCutoff && activityHistory.duration < filterState.durationCutoff) {
            return false;
        }

        if (filterState.isSuccessStatusExcluded && activityHistory.status === WAREHOUSE_ACTIVITY_STATUS.SUCCESS) {
            return false;
        }

        return activityHistory.table_name.toLowerCase().includes(filterState.search.toLowerCase());
    });

    useEffect(() => {
        loadWarehouseActivityHistory();
    }, []);

    async function loadWarehouseActivityHistory() {
        setIsLoading(true);

        try {
            const warehouseActivityHistoryList = await getWarehouseActivityHistory(detailsId);
            setActivityHistoryList(warehouseActivityHistoryList);
        } catch (error) {
            logger.log('useWarehouseStatusDetails', 'loadWarehouseActivityHistory', error);
            notification.error({ message: 'Error loading activity history list' });
        }

        setIsLoading(false);
    }

    return { filteredActivityHistoryList, filterState, changeFilterState, isLoading };
}
