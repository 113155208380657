import { Card, Col, Row, Spin, Typography } from 'antd';
import { useWarehouseDeliveryDetails } from '../../../../hooks/warehouse/delivery/useWarehouseDeliveryDetails';
import { useRouter } from '../../../../services/router';
import { WarehouseDeliveryDetailsBookmarks } from './bookmarks/WarehouseDeliveryDetailsBookmarks';
import { WarehouseDeliveryDetailsDetails } from './details/WarehouseDeliveryDetailsDetails';
import { WarehouseDeliveryDetailsProcessSettings } from './process-settings/WarehouseDeliveryDetailsProcessSettings';
import { WarehouseDeliveryDetailsRegularValidationSettings } from './regular-validation-settings/WarehouseDeliveryDetailsRegularValidationSettings';
import { WarehouseDeliveryDetailsValidationSettings } from './validation-settings/WarehouseDeliveryDetailsValidationSettings';

const { Title } = Typography;

export function WarehouseDeliveryDetails() {
    const { params } = useRouter();
    const { detailsId } = params;

    const {
        deliveryDetails,
        deliveryBookmarks,
        deliveryValidationSettings,
        deliveryRegularValidationSettings,
        changeWarehouseDeliveryProcessSettings,
        changeWarehouseDeliveryBookmarks,
        changeWarehouseDeliveryValidationSettings,
        addWarehouseDeliveryRegularValidationSettings,
        changeWarehouseDeliveryRegularValidationSettings,
        isLoading,
    } = useWarehouseDeliveryDetails(detailsId);

    if (isLoading) {
        return <Spin size="large" />;
    }

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Title level={4} className="no-margin">
                    {deliveryDetails?.table_name}
                </Title>
            </Col>
            <Col span={24} xl={8}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Card bordered={false}>
                            <WarehouseDeliveryDetailsDetails details={deliveryDetails} />
                        </Card>
                    </Col>
                    <Col span={24}>
                        <Card bordered={false}>
                            <WarehouseDeliveryDetailsProcessSettings
                                details={deliveryDetails}
                                onProcessSettingsChange={changeWarehouseDeliveryProcessSettings}
                            />
                        </Card>
                    </Col>
                    <Col span={24}>
                        <Card bordered={false}>
                            <WarehouseDeliveryDetailsBookmarks
                                bookmarks={deliveryBookmarks}
                                onBookmarksChange={changeWarehouseDeliveryBookmarks}
                            />
                        </Card>
                    </Col>
                </Row>
            </Col>
            <Col span={24} xl={16}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Card bordered={false}>
                            <WarehouseDeliveryDetailsValidationSettings
                                validationSettings={deliveryValidationSettings}
                                onValidationSettingsChange={changeWarehouseDeliveryValidationSettings}
                            />
                        </Card>
                    </Col>
                    <Col span={24}>
                        <Card bordered={false}>
                            <WarehouseDeliveryDetailsRegularValidationSettings
                                regularValidationSettings={deliveryRegularValidationSettings}
                                onRegularValidationSettingsDuplicate={addWarehouseDeliveryRegularValidationSettings}
                                onRegularValidationSettingsChange={changeWarehouseDeliveryRegularValidationSettings}
                            />
                        </Card>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
