import { Col, Row, Space, Typography } from 'antd';
import { ROUTES } from '../../../../../../../routes';
import { getRoute } from '../../../../../../../services/router';
import { getWarehousePrettyDuration, showWarehouseDate } from '../../../../../../../services/warehouse-management';
import { WarehouseActivity } from '../../../../../../../types/warehouse-management';
import { WarehouseStatusIcon } from '../../../../icon/WarehouseStatusIcon';
import Wrapper from './styles';

const { Text } = Typography;

interface Props {
    process: WarehouseActivity;
}

export function WarehouseStatusListCardActivitiesProcess({ process }: Props) {
    const { id, status, duration, end_time } = process;

    return (
        <Wrapper to={`${getRoute(ROUTES.warehouse.status)}/${id}`} target="_blank">
            <Row justify="space-between">
                <Col>
                    <Space>
                        <WarehouseStatusIcon status={status} />
                        <Text>{getWarehousePrettyDuration(duration)}</Text>
                    </Space>
                </Col>
                <Col>
                    <Text>{showWarehouseDate(end_time)}</Text>
                </Col>
            </Row>
        </Wrapper>
    );
}
