import { ReactNode } from 'react';
import { useTabsWithNavigation } from '../../../../hooks/navigation/useTabsWithNavigation';
import { WarehouseErrorsGeneral } from '../../errors/general/WarehouseErrorsGeneral';
import { WarehouseErrorsRegularValidation } from '../../errors/regular-validation/WarehouseErrorsRegularValidation';
import { WarehouseErrorsValidation } from '../../errors/validation/WarehouseErrorsValidation';
import { UiCard } from '../../../ui/card/UiCard';

export function WarehouseDeliveryErrors() {
    const deliveryErrorsTabs = [
        { key: 'general', tab: 'General' },
        { key: 'validation', tab: 'Validation' },
        { key: 'regularValidation', tab: 'Regular validation' },
    ];

    const deliveryErrorsTabsContent: Record<string, ReactNode> = {
        general: <WarehouseErrorsGeneral />,
        validation: <WarehouseErrorsValidation />,
        regularValidation: <WarehouseErrorsRegularValidation />,
    };

    const { activeTab, changeActiveTab } = useTabsWithNavigation({
        tabs: deliveryErrorsTabs.map(item => item.key),
        tabQueryParamName: 'tab',
    });

    return (
        <UiCard
            className="secondary-card"
            tabList={deliveryErrorsTabs}
            activeTabKey={activeTab}
            onTabChange={changeActiveTab}
            tabProps={{ size: 'middle' }}
        >
            {deliveryErrorsTabsContent[activeTab]}
        </UiCard>
    );
}
