import { useEffect } from 'react';
import { store, useStore } from 'stores/store';
import { getBoUsers } from 'microservices/auth-bo';
import { logger } from 'services/logger';
import keyBy from 'lodash/keyBy';
import { BoUser } from 'types/auth-bo';

export function SystemLoadStoreData() {
    const [user] = useStore(store.boUser);

    useEffect(() => {
        loadData();
    }, []);

    async function loadData() {
        try {
            const boUsers = await getBoUsers();
            store.boUsers.set(boUsers);
            store.boUsersById.set({ ...keyBy(boUsers, 'id'), ...keyBy(boUsers, 'keycloak_id') });
            store.sportsbook.bookmakers.set(getBookmakers(boUsers));
        } catch (error) {
            logger.error('SystemLoadStoreData', 'loadData', error);
        }
    }

    function getBookmakers(users: BoUser[]) {
        return users
            .filter(({ groups, enabled }) => groups?.some(group => group.startsWith('Sportsbook')) && enabled)
            .map(({ id, first_name, last_name }) => ({ id, name: `${first_name} ${last_name}` }))
            .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
            .sort(({ id }) => (id === user.id ? -1 : 1));
    }
    return null;
}
