import { CancellationReasonVisibleFor } from '@staycool/sports-types/cancellation-reason';
import { getSportsUrl, httpGet } from 'services/api';

export async function getCancelReasons(objectToCancel: CancellationReasonVisibleFor) {
    const url = getSportsUrl('cancellation-reason');
    return httpGet<CancelReason[]>(url, { visible_for: objectToCancel });
}

export interface CancelReason {
    id: string;
    name: string;
}
