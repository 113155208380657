import { PaginationType } from 'components/user/timespan-selector/types';
import { getServiceUrl, httpDelete, httpGet, httpPatch, httpPost } from 'services/api';
import { KeycloakUserRepresentation, BoUser, PaginatedCustomKeycloakLogs, RelevantGroup } from 'types/auth-bo';

export const getUrl = url => getServiceUrl('auth-bo', url);

export async function renewToken() {
    const url = getUrl('renew_token/');
    return httpPost(url);
}

export function getBoUsers() {
    const url = getUrl('user/all-short');
    return httpGet<BoUser[]>(url);
}

export function getCustomKeycloakUser(id: string) {
    const url = getUrl(`user/custom-keycloak-users/${id}`);
    return httpGet<KeycloakUserRepresentation>(url);
}

export function getCustomKeycloakUsers() {
    const url = getUrl('user/custom-keycloak-users');
    return httpGet<KeycloakUserRepresentation[]>(url);
}

export function getCustomRelevantGroups() {
    const url = getUrl('user/custom-relevant-groups');
    return httpGet<RelevantGroup[]>(url);
}

export async function createCustomKeycloakUser(newUser: KeycloakUserRepresentation) {
    const url = getUrl('user/custom-keycloak-users');
    await httpPost(url, newUser);
}

export async function updateCustomKeycloakUser(id: string, newUser: KeycloakUserRepresentation) {
    const url = getUrl(`user/custom-keycloak-users/${id}`);
    await httpPatch(url, newUser);
}

export async function updateEnableStatusCustomKeycloakUser(id: string, enabled: boolean) {
    const url = getUrl(`user/custom-keycloak-users/${id}/enable`);
    await httpPatch(url, { enabled });
}

export async function sendEmailUpdatePasswordCustomKeycloakUser(id: string) {
    const url = getUrl(`user/custom-keycloak-users/${id}/send-email-update-password`);
    await httpPost(url);
}

export async function deleteCustomKeycloakUser(id: string) {
    const url = getUrl(`user/custom-keycloak-users/${id}`);
    await httpDelete(url);
}

export function getCustomKeycloakLogs(pagination: PaginationType) {
    const { offset, pageSize, order, orderBy, from, to } = pagination;
    const url = getUrl('user/custom-keycloak-logs');
    return httpGet<PaginatedCustomKeycloakLogs>(url, { offset, pageSize, order, orderBy, from, to });
}

export interface Bookmaker {
    id: number;
    name: string;
}
