import { mediaQuery } from 'services/media';
import { createGlobalStyle } from 'styled-components';
import { color, sizes } from 'style/variables';
import hexToRgba from 'hex-to-rgba';

export const GlobalStyles = createGlobalStyle`
    html, body {
        overscroll-behavior-x: none;
        margin: 0;
        background: ${color.gray50};
        color: ${color.gray900}
    }

    [class="ant-btn-primary"] {
        background: ${color.blue500};
    }

    .ant-btn {
        box-shadow: none;
        border-radius: 0.25rem;

        &:disabled {
            color: ${hexToRgba(color.white, 0.3)} !important;
            cursor: not-allowed;
            text-shadow: none;
            box-shadow: none;

            &:not(.ant-btn-link):not(.ant-btn-text) {
                background: ${hexToRgba(color.white, 0.08)};
                border-color: ${color.gray400};
            }
        }
    }


    .ant-btn-ghost {
        background: transparent;
        border-color: ${color.gray400};
    }

    .ant-btn-default,
    .ant-btn-dashed {
        background: ${color.gray100};
        border-color: ${color.gray400};
    }

    .ant-btn-link {
        color: ${color.blue500};

        &:hover {
            color: ${color.blue600};
        }
    }

    .ant-btn-text:not(:disabled):hover,
    .ant-btn-text:not(:disabled):active {
        color: ${color.gray900};
    }

    .ant-btn-text:not(:disabled):hover {
        background-color: ${hexToRgba(color.white, 0.03)};
    }

    .ant-btn-text:not(:disabled):active {
        background-color: ${hexToRgba(color.white, 0.04)};
    }

    a:hover {
        color: ${color.blue600};
    }

    .ant-tooltip {
        & .ant-tooltip-inner,
        & .ant-tooltip-arrow::before,
        & .ant-tooltip-arrow::after {
            background-color: ${color.gray50};
        }

        &.no-max-width {
            max-width: none;
        }
    }

    .ant-menu-submenu-popup {
        box-shadow: 0 0.75rem 0.75rem ${hexToRgba(color.gray100, 0.6)};

        & > .ant-menu {
            background: ${color.gray100} !important;

            & .ant-menu-title-content {
                transition: color 0.1s;
            }
        }

        & .ant-menu-item {
            border-radius: 0 !important;
            margin: 0 !important;
            width: 100% !important;

            &:hover {
                background-color: transparent !important;
                color: ${color.blue500} !important;
            }
        }
    }

    .ant-notification-notice {
        border-radius: 0.25rem !important;
        background: ${color.gray100} !important;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .48), 0 6px 16px 0 rgba(0, 0, 0, .32), 0 9px 28px 8px rgba(0, 0, 0, .2);

        &-message, &-description, &-close {
            color: ${color.gray800} !important;
        }
    }

    .ant-message .ant-message-notice .ant-message-notice-content {
        background: ${color.gray100};
        color: ${color.gray800};
    }

    .ant-drawer .ant-drawer-content {
        background: ${color.gray100};
    }

    .ant-typography {
        margin: 0;
        font-size: inherit;
        font-family: inherit;
    }

    h1, h2, h3, h4, h5 {
        &.ant-typography {
            color: ${color.gray900};
        }
    }

    .ant-select-lg, .ant-input-lg {
        font-size: 1rem;
    }

    .ant-select-dropdown {
        padding: 0;

        .ant-select-item {
            border-radius: 0;
        }

        .rc-virtual-list-scrollbar {
            width: 0.8em !important;
        }

        .rc-virtual-list-scrollbar-thumb {
            background-color: ${color.blue700} !important;
        }
    }

    .popover-input {
        margin-bottom: 0;
    }

    .ant-layout-header {
        padding: 0;
    }

    .align-center {
        text-align: center;
    }

    .align-end {
        display: flex;
        align-items: end;
    }

    .align-left {
        text-align: left;
    }

    .align-right {
        text-align: right;
    }

    .ant-layout {
        .ant-layout-sider {
            background: inherit;
        }

        .ant-layout-sider-zero-width-trigger {
            display: none;
        }

        .ant-layout-content {
            padding: 1.25rem 1.25rem 0;

            .ant-layout-content {
                @media ${mediaQuery.isPhone} {
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }

    .ant-badge .ant-badge-count {
        color: ${color.gray900};
    }

    .translation-flag-img {
        width: 3em;
    }

    .ant-table-thead > tr > th {
        border: none;
        background: ${color.gray200} !important;

        &.ant-table-cell {
            font-weight: 500;
        }
    }

    .ant-table-cell {
        padding: 0.2em;
    }

    .ant-input {
        width: 100%;
    }

    .ant-input-number {
        width: 100%;
    }

    .ant-picker {
        width: 100%;
    }

    .ant-select {
        width: 100%;
    }

    .ant-space-vertical {
        width: 100%;
    }

    .ant-menu {
        font-weight: bold;
    }

    .ant-tag {
        margin-right: 0;
        font-weight: 400;
        img {
            vertical-align: middle;
        }
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background: rgba(76, 156, 230, 0.36);
    }

    .ant-card {
        background: ${color.gray200};

        &.ant-card-bordered {
            border: 0.1em solid ${color.gray400};
        }
    }

    .ant-comment {
        background-color: transparent;
    }

    .ant-select-selection-overflow-item-suffix {
        flex-grow: 1;
    }

    .ant-select-selection-search {
        z-index: 1;
        min-width: 100%;
    }

    .ant-upload {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: #DDDDE0;
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: 'tnum', "tnum";
        outline: 0;

        &.ant-upload-drag {
            position: relative;
            width: 100%;
            height: 100%;
            text-align: center;
            background: ${hexToRgba(color.white, 0.04)};
            border: 1px dashed #58596A;
            border-radius: 0.25em;
            cursor: pointer;
            transition: border-color 0.3s;

            & .ant-upload-text {
                margin: 0 0 4px;
                color: ${hexToRgba(color.white, 0.85)};
                font-size: 16px;
            }

            & .ant-upload-hint {
                color: ${hexToRgba(color.white, 0.45)};
                font-size: 14px;
                margin: 0;
            }

            & .ant-upload-drag-icon {
                margin-bottom: 20px;

                & .anticon {
                    color: #2c75af;
                    font-size: 48px;
                }
            }
        }
    }

    .ant-table-cell .ant-btn-link {
        padding: 0 0.25rem;
    }
    
    .ant-divider {
        &.margin-1 {
            margin: 1rem 0;
        }
    }

    .sportbook-mapping-table-container .ant-table-container {
        max-height: 75vh;
        overflow: auto;
    }

    .sportbook-mapping-table-container .ant-table-body {
        max-height: 100% !important;
    }


    @media only screen and (min-width: 992px) {
        ::-webkit-scrollbar-thumb {
            background-color: ${color.blue700};
        }
    }

    .pointer {
        cursor: pointer;
    }

    .not-allowed {
        cursor: not-allowed;
    }

    .active-switch {
        opacity: 1;
    }

    .block {
        display: block;
    }

    .hide {
        display: none;
    }

    .hidden {
        visibility: hidden;
    }

    .primary {
        color: ${color.blue500};
    }

    .success, .ant-typography.ant-typography-success {
        color: ${color.green500}
    }

    .warning, .ant-typography.ant-typography-warning {
        color: ${color.gold700}
    }

    .danger, .ant-typography.ant-typography-danger {
        color: ${color.red500}
    }

    .ant-modal {
        .ant-modal-header {
            margin-top: -0.25rem;
            margin-bottom: 1rem;
        }

        .ant-modal-confirm-title, .ant-modal-confirm-content {
            color: ${color.gray900} !important;
        }

        .ant-modal-content {
            background-color: ${color.gray100};
        }

        .ant-btn-default {
            background: transparent;
            color: ${color.gray900};
            border-color: ${color.gray400};
        }
    }

    .ant-notification-notice {
        &.alarm {
            background: ${color.red600};
            color: ${color.gray50};

            .ant-notification-notice-message {
                color: ${color.gray50};
            }

            .ant-notification-notice-close {
                color: ${color.gray50};
            }

            &.critical {
                background: ${color.red500};
            }
        }
    }

    .grey-btn {
        background-color: ${color.gray500};
        border: none;

        &:not(:disabled) {
            &:focus, &:hover {
                background-color: ${color.gray500};
                color: #ffffff;
            }
        }
    }

    .white-btn {
        color: #000000;
        background-color: ${color.gray1000};
        border: none;

        &:not(:disabled) {
            &:focus, &:hover {
                background-color: ${color.gray1000};
                color: #000000;
            }
        }
    }

    .cyan-btn {
        background-color: ${color.cyan500};
        border-color: ${color.cyan500};

        &:not(:disabled) {
            &:focus, &:hover {
                background-color: ${color.cyan500};
                color: #ffffff;
            }
        }
    }

    .yellow-btn {
        background-color: ${color.gold500};
        border: ${color.gold500};
    }

    .yellow-btn:not(:disabled):hover {
        background-color: ${color.gold700};
        border: ${color.gold700};
    }

    .red-btn, .red-btn:focus {
        background-color: ${color.red500};
        border-color: ${color.red500};
        color: #ffffff;
    }

    .red-btn:not(:disabled):hover {
        background-color: ${color.red300};
        border-color: ${color.red300};
        color: #ffffff;
    }

    .red-btn[disabled] {
        opacity: 0.4;
        border: none;
    }

    .green-btn, .green-btn:not(:disabled):focus {
        background-color: ${color.green500};
        border-color: ${color.green500};
        color: #ffffff;
    }

    .green-btn:not(:disabled):hover {
        background-color: ${color.green300};
        border-color: ${color.green300};
        color: #ffffff;
    }

    .green-btn[disabled] {
        background-color: #779979;
        border: none;
    }

    .available-btn {
        color: ${color.green500};
        border-color: ${color.green500};
    }

    .available-btn:not(:disabled):hover {
        border-color: ${color.green300};
        color: ${color.green300};
    }

    .red-text {
        color: ${color.red500};
    }

    .green-text {
        color: ${color.green500};
    }

    .yellow-text {
        color: ${color.gold700};
    }

    .blue-text {
        color: ${color.blue700};
    }

    .secondary-text {
        color: ${color.gray500};
    }

    .ant-table-pagination.ant-pagination {
        margin-top: 16px;
        margin-bottom: 0;
    }

    .disabled-table-row {
        background-color: ${color.gray50};
        opacity: 0.6;
        cursor: not-allowed;
    }

    .no-style-link {
        color: inherit;
        text-decoration: inherit;
    }

    .ticket-button .ant-btn-primary[disabled] {
        background-color: ${color.red600};
        color: #ffffff;
    }

    .list-item.ant-list-item:hover {
        box-shadow: 0 0 0.1em ${color.blue500};
        cursor: pointer;
        color: ${color.blue500};
    }

    .select-language__image {
        vertical-align: middle;
        width: 2em;
    }

    .bordered-tag {
        background-color: transparent;
        border: 1px solid ${color.gray400};
    }

    .btn-success {
        color: ${color.gray1000};
        background-color: ${color.green500}
    }

    .one-row-table {
        table {
            border: 1px solid ${color.gray400};
            border-radius: 4px;

            .ant-table-tbody > tr {

                &:hover td {
                    background-color: ${color.gray100};
                }

                &:last-child {
                    td {
                        &:first-child {
                            border-bottom-left-radius: 4px;
                        }

                        &:last-child {
                            border-bottom-right-radius: 4px;
                        }
                    }
                }

                td {
                    background-color: ${color.gray100};
                }
            }

            .ant-table-cell {
                border-bottom: none;
                text-align: center;
                border-right: 1px solid ${color.gray500};
            }
        }
    }

    .overlay-wo-padding .ant-dropdown-menu-item {
        padding: 0;
    }

    .number-input-addon {
        border-right-width: 0;

        &:hover,
        &.ant-input-number-focused,
        &.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless),
        &.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless) {
            border-right-width: 1px;
        }

        &.ant-input-number-group-wrapper {
            width: 100%;
        }

        & .ant-input-number-group-addon {
            min-width: 60px;
            background-color: transparent;
        }
    }

    @media ${mediaQuery.isDesktopSmall} {
        .ant-menu {
            font-size: 0.73rem;
        }
    }

    .go-top-btn {
        right: 49%;

        &-body {
            opacity: 0.8;
            background-color: ${color.gray100};
            border-color: ${color.gray100};
        }
    }

    .default-gray-background {
        background-color: ${color.gray100};
    }

    .border-none {
        border: none;
    }

    .full-width {
        width: 100%;
    }

    .container {
        background-color: ${color.gray100};
        padding: ${sizes.padding};
        border-radius: ${sizes.borderRadius};
    }

    .no-margin {
        margin: 0 !important;
    }

    .no-padding {
        padding: 0 !important;
    }

    .color-gray-800 {
        color: ${color.gray800};
    }

    .background-transparent {
        background-color: transparent;
    }

    .whitespace-pre {
        white-space: pre;
    }

    .full-height {
        height: 100%
    }

    .normal-weight {
        font-weight: normal;
    }

    .ant-table-wrapper .ant-table-thead > tr {
        & > th,
        & > td {
            border: none;
            background: ${color.gray200};

            &.ant-table-cell {
                font-weight: 500;
            }
        }
    }

    .selected-dropdown-item {
        background-color: ${color.blue300};
        pointer-events: none;
    }

    .empty-description {
        color: ${hexToRgba(color.white, 0.25)};
    }

    .updated-form-field {
        --highlight-color: ${color.gold500};

        & .ant-form-item-label label {
            color: var(--highlight-color);
        }

        & .ant-input, .ant-picker, .ant-input-number, .ant-select-selector, .ant-input-textarea-affix-wrapper {
            border-color: var(--highlight-color) !important;
        }

        & .ant-checkbox-wrapper {
            span {
                color: var(--highlight-color);
                border-color: var(--highlight-color);
            }
        }
    }

    .card-footer-divider {
        margin: 1rem 0 0.25rem -1.5rem;
        width: calc(100% + 3rem);
    }

    .mb-05 {
        margin-bottom: 0.5rem;
    }

    .ant-table, .ant-collapse {
        & .ant-checkbox .ant-checkbox-input {
            width: 100%;
            height: 100%;
        }
    }

    .centered-spin {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80vh;
    }

    .dashed-tag {
        background: transparent;
        border-style: dashed;
    }

    #version {
        position: fixed;
        right: 0;
        bottom: 0;
        font-size: .75rem;
        padding: 3px;
    }
`;
