import React, { useEffect, useState } from 'react';
import { Menu, notification } from 'antd';
import clx from 'classnames';
import { StarFilled, StarOutlined } from '@ant-design/icons';
import { CategoryByIdType, CategoryType, CategoryTypeDepth } from '../types';

import {
    getCategoryWithRelatives,
    getChildCategoriesByParents,
    getFavourites,
    toggleCategoryFavourite,
} from 'microservices/sports/category';
import Wrapper from './styles';
import { SportsbookCategoryTreeMenuItem } from './item/SportsbookCategoryTreeMenuItem';
import { isMobile } from 'services/browser';
import { store, useStore } from 'stores/store';
import uniq from 'lodash/uniq';
import { Link, matchPath } from 'react-router-dom';
import { getRoute, useRouter } from '../../../../../services/router';
import { ROUTES } from '../../../../../routes';
import { treeNavigationLocalStorageKey } from '../../../../../services/netrisk';
import { logger } from 'services/logger';

export default function SportsbookCategoryTreeMenu() {
    const { location, queryParams } = useRouter();
    const [treeSelectedCategory, setTreeSelectedCategory] = useStore(store.sportsbook.treeSelectedCategory);
    const [currentCategoryId, setCurrentCategoryId] = useState<number>();
    const [openCategories, setOpenCategories] = useState<number[]>([]);
    const [isDisplayNotActiveEnabled] = useStore(store.sportsbook.isDisplayNotActiveEnabled);
    const [favourites, setFavourites] = useStore(store.sportsbook.favouriteCategories);
    const [sports, setSports] = useState<CategoryType[]>([]);
    const [regions, setRegions] = useState<{ [id: number]: CategoryType[] }>({});
    const [leagues, setLeagues] = useState<{ [id: number]: CategoryType[] }>({});

    async function init() {
        try {
            if (!favourites.length) {
                setFavourites(await getFavourites());
            }
            if (treeSelectedCategory) {
                const { id } = treeSelectedCategory;
                handleCategorySearch(id);
                setCurrentCategoryId(id);
            } else {
                setSports(await getChildCategoriesByParents([1]));
            }
        } catch (error) {
            logger.error('SportsbookCategoryTreeMenu', 'init', error);
            notification.error({ message: 'Something went wrong with the category tree.' });
        }
    }

    useEffect(() => {
        const categoryId = treeSelectedCategory?.id;
        if (!currentCategoryId || !categoryId) {
            init();
            return;
        }
        if (categoryId !== currentCategoryId) {
            setCurrentCategoryId(categoryId);
        }
    }, [treeSelectedCategory]);

    async function handleCategorySearch(categoryId: number) {
        if (categoryId === currentCategoryId) {
            return;
        }
        const categoryWithRelatives = await getCategoryWithRelatives(categoryId);
        if (categoryWithRelatives) {
            setMenuFromCurrent(categoryWithRelatives);
            scrollToMenuItem(categoryWithRelatives.currentCategory.name);
        }
    }

    function setMenuFromCurrent(currentCategories: CategoryByIdType) {
        const { currentCategory, sports, regions: currentRegions, leagues: currentLeagues } = currentCategories;
        const { id, depth, parent_category_id, grand_parent_id } = currentCategory;
        const regionParentId =
            depth === CategoryTypeDepth.Sport
                ? id
                : depth === CategoryTypeDepth.Region
                ? parent_category_id
                : grand_parent_id;
        const leagueParentId =
            depth === CategoryTypeDepth.Sport ? null : depth === CategoryTypeDepth.Region ? id : parent_category_id;
        const addOpenCategories = [id] as number[];

        setSports(sports);
        if (regionParentId) {
            setRegions({ ...regions, [regionParentId]: currentRegions });
            addOpenCategories.push(regionParentId);
        }
        if (leagueParentId) {
            setLeagues({ ...leagues, [leagueParentId]: currentLeagues });
            addOpenCategories.push(leagueParentId);
        }
        setOpenCategories(uniq([...openCategories, ...addOpenCategories]));
    }

    async function handleMenuClick(id: number, navigateDepth: number, isExpandOnly: boolean = false) {
        const isOpen = openCategories.includes(id);
        const isLoaded =
            (navigateDepth === CategoryTypeDepth.Sport && Object.keys(regions)?.includes(String(id))) ||
            (navigateDepth === CategoryTypeDepth.Region && Object.keys(leagues)?.includes(String(id)));
        if (!isOpen) {
            if (!isLoaded) {
                const categoriesData = await getChildCategoriesByParents([id]);

                const depth =
                    navigateDepth === CategoryTypeDepth.Sport ? CategoryTypeDepth.Region : CategoryTypeDepth.League;

                if (depth === CategoryTypeDepth.Region && !regions?.[id]) {
                    setRegions({ ...regions, [id]: categoriesData });
                } else if (depth === CategoryTypeDepth.League && !leagues?.[id]) {
                    setLeagues({ ...leagues, [id]: categoriesData });
                }
            }
            setOpenCategories([...openCategories, id]);
        } else {
            setOpenCategories(openCategories.filter(_id => _id !== id));
        }

        if (!isExpandOnly) {
            setTreeSelectedCategory({ id, depth: navigateDepth });
        }
    }

    async function toggleFavourite(
        event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
        category: CategoryType,
        parentId?: number,
    ) {
        event.stopPropagation();
        const { id, is_favourite } = category;
        const isFavourited = !!is_favourite;
        await toggleCategoryFavourite(id, isFavourited);

        if (parentId) {
            setLeagues({ ...leagues, [parentId]: await getChildCategoriesByParents([parentId]) });
        }

        if (!isFavourited) {
            setFavourites([...favourites, { ...category, is_favourite: true }]);
        } else {
            setFavourites(favourites.filter(favourite => favourite.id !== id));
        }
    }

    function scrollToMenuItem(categoryName: string) {
        const leagueMenuItem = document.querySelector(`.sports-tree-menu [title="${categoryName}"]`);
        if (leagueMenuItem !== null && queryParams.updateCategoryTree === 'true') {
            leagueMenuItem.scrollIntoView();
        }
    }

    async function navigateFavouritesMenuItem(id: number) {
        handleCategorySearch(id);
        setTreeSelectedCategory({ id, depth: CategoryTypeDepth.League });
    }

    function filterNotActive({ active }: { active: boolean }) {
        if (isDisplayNotActiveEnabled) {
            return true;
        }

        return active;
    }

    function checkActiveCategoriesAvailability(
        category: CategoryType[] | { [id: number]: CategoryType[] },
        parentId?: number,
    ) {
        if (
            !(
                (category && !parentId && (category as CategoryType[]).filter(filterNotActive).length > 0) ||
                (category &&
                    parentId &&
                    (category as { [id: number]: CategoryType[] })[parentId] &&
                    (category as { [id: number]: CategoryType[] })[parentId].filter(filterNotActive).length > 0)
            )
        ) {
            return <div className="title">No categories available</div>;
        }
    }

    function getLeagueRoute(id: number) {
        const isNetRiskPage = matchPath(location.pathname, {
            path: '/sportsbook/net-risk',
            strict: true,
        });
        if (isNetRiskPage) {
            return `${getRoute(ROUTES.sportsbook.netRisk)}/${id}`;
        }

        const isNewNetRiskPage = matchPath(location.pathname, {
            path: '/sportsbook/net-risk',
            strict: true,
        });
        if (isNewNetRiskPage) {
            return `${getRoute(ROUTES.sportsbook.netRisk)}/${id}`;
        }

        const isNetRiskOpen = localStorage.getItem(treeNavigationLocalStorageKey);
        const type = isNetRiskOpen ? 'net-risk' : 'matches';
        return `${getRoute(ROUTES.sportsbook.categories)}/${id}/${type}`;
    }

    return (
        <Wrapper isMobile={isMobile()}>
            <Menu
                className="menu-favourites"
                mode="inline"
                triggerSubMenuAction="click"
                inlineIndent={20}
                defaultOpenKeys={['favouritesSubMenu']}
                selectedKeys={[`${currentCategoryId}`]}
            >
                <Menu.SubMenu
                    key="favouritesSubMenu"
                    data-testid="tree-menu-favourite"
                    title="Favourites"
                    icon={<StarFilled />}
                >
                    {favourites?.map(league => (
                        <Menu.Item
                            className="sub-menu-favourites"
                            key={league.id}
                            title={league.name}
                            onClick={() => navigateFavouritesMenuItem(league.id)}
                        >
                            <Link to={getLeagueRoute(league.id)}>{league.name}</Link>
                            <span
                                className={clx('favourite-button', { active: league.is_favourite })}
                                onClick={event => toggleFavourite(event, league)}
                            >
                                {league.is_favourite ? <StarFilled /> : <StarOutlined />}
                            </span>
                        </Menu.Item>
                    ))}
                </Menu.SubMenu>
            </Menu>
            <ul className="sports-tree-menu tree-menu">
                {sports?.filter(filterNotActive)?.map(sport => (
                    <SportsbookCategoryTreeMenuItem
                        key={sport.id}
                        onMenuClick={handleMenuClick}
                        currentCategoryId={currentCategoryId}
                        isOpen={openCategories.includes(sport.id) && !!regions[sport.id]}
                        dataTest="menu-item-sport"
                        category={sport}
                        scrollToMenuItem={scrollToMenuItem}
                    >
                        <ul className="sub-menu sub-menu-region tree-menu">
                            {regions[sport.id]?.filter(filterNotActive)?.map(region => (
                                <SportsbookCategoryTreeMenuItem
                                    key={region.id}
                                    onMenuClick={handleMenuClick}
                                    currentCategoryId={currentCategoryId}
                                    isOpen={openCategories.includes(region.id) && !!leagues[region.id]}
                                    dataTest="menu-item-region"
                                    category={region}
                                    scrollToMenuItem={scrollToMenuItem}
                                >
                                    <ul className="sub-menu sub-menu-league tree-menu">
                                        {leagues[region.id]?.filter(filterNotActive)?.map(league => (
                                            <SportsbookCategoryTreeMenuItem
                                                key={league.id}
                                                onMenuClick={() =>
                                                    setTreeSelectedCategory({
                                                        id: league.id,
                                                        depth: CategoryTypeDepth.League,
                                                    })
                                                }
                                                currentCategoryId={currentCategoryId}
                                                isOpen
                                                onFavourite={_ => toggleFavourite(_, league, region.id)}
                                                dataTest="menu-item-league"
                                                category={league}
                                                scrollToMenuItem={scrollToMenuItem}
                                            />
                                        ))}
                                        {checkActiveCategoriesAvailability(leagues, region.id)}
                                    </ul>
                                </SportsbookCategoryTreeMenuItem>
                            ))}
                            {checkActiveCategoriesAvailability(regions, sport.id)}
                        </ul>
                    </SportsbookCategoryTreeMenuItem>
                ))}
                {checkActiveCategoriesAvailability(sports)}
            </ul>
        </Wrapper>
    );
}
