import { Input, InputProps } from 'antd';
import debounce from 'lodash/debounce';
import { ChangeEvent } from 'react';

interface Props extends Omit<InputProps, 'onChange'> {
    debounceTimeout: number;
    onChange: (value: string) => void;
}

export function UiInputDebounce({ debounceTimeout, onChange, ...rest }: Props) {
    const delayedChange = debounce((event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value.trim());
    }, debounceTimeout);

    return <Input {...rest} allowClear onChange={delayedChange} />;
}
