import { Form, Input, Space, Switch } from 'antd';
import { useState } from 'react';
import { EditableTableColumn, useTableEditableFormRow } from '../../../../../hooks/table/useTableEditableFormRow';
import { sorter } from '../../../../../services/sorter';
import {
    getWarehouseDateForUpdate,
    showWarehouseDate,
    WAREHOUSE_DETAILS_TABLE_CONFIGURATION,
} from '../../../../../services/warehouse-management';
import { WarehouseDeliveryRegularValidationSettings } from '../../../../../types/warehouse-management';
import { UiButton } from '../../../../ui/button/UiButton';
import { UiEditableTableFormCell } from '../../../../ui/editable-table-form-cell/UiEditableTableFormCell';
import { UiTable } from '../../../../ui/table/UiTable';
import { WarehouseCardWrapper } from '../../../card/wrapper/WarehouseCardWrapper';
import { WarehouseDeliveryDetailsTableAction } from '../table-action/WarehouseDeliveryDetailsTableAction';

interface Props {
    regularValidationSettings: WarehouseDeliveryRegularValidationSettings[];
    onRegularValidationSettingsDuplicate: (settings: WarehouseDeliveryRegularValidationSettings) => Promise<void>;
    onRegularValidationSettingsChange: (
        id: number,
        settings: Partial<WarehouseDeliveryRegularValidationSettings>,
    ) => Promise<void>;
}

export function WarehouseDeliveryDetailsRegularValidationSettings({
    regularValidationSettings,
    onRegularValidationSettingsDuplicate,
    onRegularValidationSettingsChange,
}: Props) {
    const editRegularValidationSettings = regularValidationSettings.map(settings => ({
        ...settings,
        next_validation: showWarehouseDate(settings.next_validation),
    }));
    const [isUpdating, setIsUpdating] = useState(false);

    const [form] = Form.useForm<Partial<WarehouseDeliveryRegularValidationSettings>>();
    const { getMergedColumns, isEditing, handleEdit, handleCancel } =
        useTableEditableFormRow<WarehouseDeliveryRegularValidationSettings>(form);

    const regularValidationSettingsTableColumns: EditableTableColumn<WarehouseDeliveryRegularValidationSettings>[] = [
        {
            title: 'Validation name',
            dataIndex: 'validation_name',
            key: 'validation_name',
            sorter: (a, b) => sorter(a.validation_name, b.validation_name),
        },
        {
            title: 'Period',
            dataIndex: 'period',
            key: 'period',
            sorter: (a, b) => sorter(a.period, b.period),
            editable: true,
            inputNode: <Input size="small" autoFocus />,
            formItemProps: {
                rules: [{ required: true, message: '' }],
            },
            width: 140,
        },
        {
            title: 'Interval',
            dataIndex: 'validation_interval',
            key: 'validation_interval',
            sorter: (a, b) => sorter(a.validation_interval, b.validation_interval),
            editable: true,
            inputNode: <Input size="small" />,
            formItemProps: {
                rules: [{ required: true, message: '' }],
            },
            width: 120,
        },
        {
            title: 'Next validation',
            dataIndex: 'next_validation',
            key: 'next_validation',
            sorter: (a, b) => sorter(a.next_validation, b.next_validation),
            editable: true,
            inputNode: <Input size="small" />,
            formItemProps: {
                rules: [{ required: true, message: '' }],
            },
            width: 225,
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            sorter: (a, b) => sorter(a.enabled, b.enabled),
            render: (_, { id, enabled }) => (
                <Switch
                    checked={enabled}
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    onChange={() => handleRegularValidationSettingsChange(id, { enabled: !enabled })}
                />
            ),
            width: 90,
        },
        {
            title: 'Actions',
            render: (settings: WarehouseDeliveryRegularValidationSettings) => (
                <Space>
                    <WarehouseDeliveryDetailsTableAction
                        isEditingMode={isEditing(settings)}
                        isUpdating={isUpdating}
                        onSaveButtonClick={() => handleRegularValidationSettingsChange(settings.id)}
                        onCancelButtonClick={handleCancel}
                        onEditButtonClick={() => handleEdit(settings)}
                    />
                    {!isEditing(settings) && (
                        <UiButton
                            type="link"
                            size="small"
                            btnName="Duplicate"
                            disabled={isUpdating}
                            onClick={() => handleRegularValidationSettingsDuplicate(settings)}
                        />
                    )}
                </Space>
            ),
            width: 140,
        },
    ];

    async function handleRegularValidationSettingsDuplicate(settings: WarehouseDeliveryRegularValidationSettings) {
        setIsUpdating(true);

        await onRegularValidationSettingsDuplicate(settings);

        setIsUpdating(false);
    }

    async function handleRegularValidationSettingsChange(
        id: number,
        settings?: Partial<WarehouseDeliveryRegularValidationSettings>,
    ) {
        setIsUpdating(true);

        const values = await form.validateFields();
        const payload = settings
            ? settings
            : {
                  ...values,
                  next_validation: values.next_validation && getWarehouseDateForUpdate(values.next_validation),
              };
        await onRegularValidationSettingsChange(id, payload);
        handleCancel();

        setIsUpdating(false);
    }

    return (
        <Form form={form} component={false}>
            <WarehouseCardWrapper title="Delivery regular validation settings">
                <UiTable
                    rowKey="id"
                    components={{
                        body: {
                            cell: props => <UiEditableTableFormCell {...props} />,
                        },
                    }}
                    columns={getMergedColumns(regularValidationSettingsTableColumns)}
                    dataSource={editRegularValidationSettings}
                    {...WAREHOUSE_DETAILS_TABLE_CONFIGURATION}
                />
            </WarehouseCardWrapper>
        </Form>
    );
}
