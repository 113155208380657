import { Col, Row, Space, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import classNames from 'classnames';
import { useFilterWithNavigation } from '../../../../hooks/navigation/useFilterWithNavigation';
import { useWarehouseExtractList } from '../../../../hooks/warehouse/extract/useWarehouseExtractList';
import { ROUTES } from '../../../../routes';
import { getRoute } from '../../../../services/router';
import { sorter } from '../../../../services/sorter';
import { getWarehouseFormattedBigNumber } from '../../../../services/warehouse-management';
import { WarehouseExtract } from '../../../../types/warehouse-management';
import { UiRadio } from '../../../ui/radio/UiRadio';
import { UiTable } from '../../../ui/table/UiTable';
import { WarehouseCheckSign } from '../../check-sign/WarehouseCheckSign';
import { WarehouseDebounceInput } from '../../debounce/input/WarehouseDebounceInput';
import { BOOLEAN_SELECTOR_OPTION, BooleanSelectorOption } from '../../../../types/util';
import { filterBooleanSelectorOption, filterInput, getBooleanSelectorOptions } from '../../../../services/util';
import dayjs from 'services/dayjs';

const { Text, Title } = Typography;

interface TableFilter {
    page: number;
    limit: number;
    databaseSearch: string;
    sourceTableSearch: string;
    warehouseTableSearch: string;
    copyToWhStatus: BooleanSelectorOption;
    existsToWhStatus: BooleanSelectorOption;
    disabledStatus: BooleanSelectorOption;
}

export function WarehouseExtractList() {
    const { extractList, isLoading } = useWarehouseExtractList();
    const { filterWithNavigation, changeFilterWithNavigation } = useFilterWithNavigation<TableFilter>({
        page: 1,
        limit: 2000,
        databaseSearch: '',
        sourceTableSearch: '',
        warehouseTableSearch: '',
        copyToWhStatus: BOOLEAN_SELECTOR_OPTION.ALL,
        existsToWhStatus: BOOLEAN_SELECTOR_OPTION.ALL,
        disabledStatus: BOOLEAN_SELECTOR_OPTION.ALL,
    });

    const booleanSelectorOptions = getBooleanSelectorOptions();

    const filteredExtractList = extractList.filter(extract => {
        if (!filterBooleanSelectorOption(filterWithNavigation.copyToWhStatus, extract.copy_to_wh)) {
            return false;
        }

        if (!filterBooleanSelectorOption(filterWithNavigation.existsToWhStatus, extract.exists_in_wh)) {
            return false;
        }

        if (!filterBooleanSelectorOption(filterWithNavigation.disabledStatus, extract.disabled)) {
            return false;
        }

        if (!filterInput(filterWithNavigation.warehouseTableSearch, extract.warehouse_table)) {
            return false;
        }

        if (!filterInput(filterWithNavigation.sourceTableSearch, extract.source_table)) {
            return false;
        }

        return filterInput(filterWithNavigation.databaseSearch, extract.database);
    });

    const extractTableColumns: ColumnsType<WarehouseExtract> = [
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
            render: (_, { created }) => <Text className="no-wrap-text">{dayjs(created).fromNow()}</Text>,
            sorter: (a, b) => sorter(a.created, b.created),
            defaultSortOrder: 'descend',
            width: 140,
        },
        {
            title: 'Database',
            dataIndex: 'database',
            key: 'database',
            sorter: (a, b) => sorter(a.database, b.database),
            ellipsis: true,
            width: '15%',
        },
        {
            title: 'Source table',
            dataIndex: 'source_table',
            key: 'source_table',
            sorter: (a, b) => sorter(a.source_table, b.source_table),
            ellipsis: true,
        },
        {
            title: 'Warehouse table',
            dataIndex: 'warehouse_table',
            key: 'warehouse_table',
            sorter: (a, b) => sorter(a.warehouse_table, b.warehouse_table),
            ellipsis: true,
        },
        {
            title: 'Live row count',
            dataIndex: 'live_row_count',
            key: 'live_row_count',
            render: (_, { live_row_count }) => getWarehouseFormattedBigNumber(live_row_count),
            sorter: (a, b) => sorter(a.live_row_count, b.live_row_count),
            align: 'right',
            width: '9%',
        },
        {
            title: 'Warehouse row count',
            dataIndex: 'wh_row_count',
            key: 'wh_row_count',
            render: (_, { wh_row_count }) => getWarehouseFormattedBigNumber(wh_row_count),
            sorter: (a, b) => sorter(a.wh_row_count, b.wh_row_count),
            align: 'right',
            width: '10%',
        },
        {
            title: 'Copy to WH',
            dataIndex: 'copy_to_wh',
            key: 'copy_to_wh',
            render: (_, { copy_to_wh }) => <WarehouseCheckSign value={copy_to_wh} />,
            sorter: (a, b) => sorter(a.copy_to_wh, b.copy_to_wh),
            width: '9%',
        },
        {
            title: 'Exists in WH',
            dataIndex: 'exists_in_wh',
            key: 'exists_in_wh',
            render: (_, { exists_in_wh }) => <WarehouseCheckSign value={exists_in_wh} />,
            sorter: (a, b) => sorter(a.exists_in_wh, b.exists_in_wh),
            width: '9%',
        },
        {
            title: 'Disabled',
            dataIndex: 'disabled',
            key: 'disabled',
            render: (_, { disabled }) => <WarehouseCheckSign value={disabled} />,
            sorter: (a, b) => sorter(a.disabled, b.disabled),
            width: '9%',
        },
    ];

    return (
        <UiTable
            rowKey="id"
            renderHeader={() => (
                <Row gutter={[16, 16]} align="middle">
                    <Col>
                        <Title level={5} className="no-margin">
                            {`Rows: ${filteredExtractList.length}`}
                        </Title>
                    </Col>
                    <Col>
                        <WarehouseDebounceInput
                            placeholder="Search database name"
                            isLoading={isLoading}
                            defaultValue={filterWithNavigation.databaseSearch}
                            onChange={databaseSearch => changeFilterWithNavigation('databaseSearch', databaseSearch)}
                        />
                    </Col>
                    <Col>
                        <WarehouseDebounceInput
                            placeholder="Search source table name"
                            isLoading={isLoading}
                            defaultValue={filterWithNavigation.sourceTableSearch}
                            onChange={sourceTableSearch =>
                                changeFilterWithNavigation('sourceTableSearch', sourceTableSearch)
                            }
                        />
                    </Col>
                    <Col>
                        <WarehouseDebounceInput
                            placeholder="Search warehouse table name"
                            isLoading={isLoading}
                            defaultValue={filterWithNavigation.warehouseTableSearch}
                            onChange={warehouseTableSearch =>
                                changeFilterWithNavigation('warehouseTableSearch', warehouseTableSearch)
                            }
                        />
                    </Col>
                    <Col>
                        <Space>
                            <Text>Copy to WH:</Text>
                            <UiRadio
                                options={booleanSelectorOptions}
                                disabled={isLoading}
                                value={filterWithNavigation.copyToWhStatus}
                                onChange={event => changeFilterWithNavigation('copyToWhStatus', event.target.value)}
                                size="middle"
                                buttonStyle="solid"
                            />
                        </Space>
                    </Col>
                    <Col>
                        <Space>
                            <Text>Exists in WH:</Text>
                            <UiRadio
                                options={booleanSelectorOptions}
                                disabled={isLoading}
                                value={filterWithNavigation.existsToWhStatus}
                                onChange={event => changeFilterWithNavigation('existsToWhStatus', event.target.value)}
                                size="middle"
                                buttonStyle="solid"
                            />
                        </Space>
                    </Col>
                    <Col>
                        <Space>
                            <Text>Disabled:</Text>
                            <UiRadio
                                options={booleanSelectorOptions}
                                disabled={isLoading}
                                value={filterWithNavigation.disabledStatus}
                                onChange={event => changeFilterWithNavigation('disabledStatus', event.target.value)}
                                size="middle"
                                buttonStyle="solid"
                            />
                        </Space>
                    </Col>
                </Row>
            )}
            className="table-warehouse table-warehouse-bordered table-warehouse-hovered"
            columns={extractTableColumns}
            dataSource={filteredExtractList}
            components={{
                body: {
                    row: props => (
                        <Tooltip title="View details">
                            <tr {...props} />
                        </Tooltip>
                    ),
                },
            }}
            rowClassName={({ copy_to_wh, disabled }) =>
                classNames(
                    'pointer',
                    'background-hover',
                    { 'background-green': copy_to_wh && !disabled },
                    { 'background-red': copy_to_wh && disabled },
                )
            }
            onRow={({ id }) => {
                return { onClick: () => window.open(`${getRoute(ROUTES.warehouse.extract)}/${id}`, '_blank') };
            }}
            pagination={false}
            loading={isLoading}
            scroll={{ y: undefined }}
            size="small"
        />
    );
}
