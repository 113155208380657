import { Form } from 'antd';
import { useState } from 'react';
import { EditableTableColumn, useTableEditableFormRow } from '../../../../../hooks/table/useTableEditableFormRow';
import { sorter } from '../../../../../services/sorter';
import { WAREHOUSE_DETAILS_TABLE_CONFIGURATION } from '../../../../../services/warehouse-management';
import { WarehouseDeliveryValidationSettings } from '../../../../../types/warehouse-management';
import { UiEditableTableFormCell } from '../../../../ui/editable-table-form-cell/UiEditableTableFormCell';
import { UiNumberInput } from '../../../../ui/number-input/UiNumberInput';
import { UiTable } from '../../../../ui/table/UiTable';
import { WarehouseCardWrapper } from '../../../card/wrapper/WarehouseCardWrapper';
import { WarehouseDeliveryDetailsTableAction } from '../table-action/WarehouseDeliveryDetailsTableAction';

interface Props {
    validationSettings: WarehouseDeliveryValidationSettings[];
    onValidationSettingsChange: (id: number, settings: Partial<WarehouseDeliveryValidationSettings>) => Promise<void>;
}

export function WarehouseDeliveryDetailsValidationSettings({ validationSettings, onValidationSettingsChange }: Props) {
    const [isUpdating, setIsUpdating] = useState(false);

    const [form] = Form.useForm<Partial<WarehouseDeliveryValidationSettings>>();
    const { getMergedColumns, isEditing, handleEdit, handleCancel } =
        useTableEditableFormRow<WarehouseDeliveryValidationSettings>(form);

    const validationSettingsTableColumns: EditableTableColumn<WarehouseDeliveryValidationSettings>[] = [
        {
            title: 'Dependency',
            dataIndex: 'dependency',
            key: 'dependency',
            sorter: (a, b) => sorter(a.dependency, b.dependency),
        },
        {
            title: 'Period',
            dataIndex: 'period',
            key: 'period',
            sorter: (a, b) => sorter(a.period, b.period),
            editable: true,
            inputNode: <UiNumberInput size="small" autoFocus />,
            formItemProps: {
                rules: [{ required: true, message: '' }],
            },
            width: 180,
        },
        {
            title: 'Bookmark',
            dataIndex: 'bookmark',
            key: 'bookmark',
            sorter: (a, b) => sorter(a.bookmark, b.bookmark),
            editable: true,
            inputNode: <UiNumberInput size="small" />,
            formItemProps: {
                rules: [{ required: true, message: '' }],
            },
            width: 240,
        },
        {
            title: 'Actions',
            render: (_, settings: WarehouseDeliveryValidationSettings) => (
                <WarehouseDeliveryDetailsTableAction
                    isEditingMode={isEditing(settings)}
                    isUpdating={isUpdating}
                    onSaveButtonClick={() => handleSaveButtonClick(settings.id)}
                    onCancelButtonClick={handleCancel}
                    onEditButtonClick={() => handleEdit(settings)}
                />
            ),
            width: 140,
        },
    ];

    async function handleSaveButtonClick(id: number) {
        setIsUpdating(true);

        const values = await form.validateFields();
        await onValidationSettingsChange(id, values);
        handleCancel();

        setIsUpdating(false);
    }

    return (
        <Form form={form} component={false}>
            <WarehouseCardWrapper title="Delivery validation settings">
                <UiTable
                    rowKey="id"
                    components={{
                        body: {
                            cell: props => <UiEditableTableFormCell {...props} />,
                        },
                    }}
                    columns={getMergedColumns(validationSettingsTableColumns)}
                    dataSource={validationSettings}
                    {...WAREHOUSE_DETAILS_TABLE_CONFIGURATION}
                />
            </WarehouseCardWrapper>
        </Form>
    );
}
