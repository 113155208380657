import { Modal, Switch, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { sorter } from '../../../../../services/sorter';
import {
    WAREHOUSE_DETAILS_TABLE_CONFIGURATION,
    WAREHOUSE_MODAL_CONFIGURATION,
} from '../../../../../services/warehouse-management';
import { WarehouseExtractColumn } from '../../../../../types/warehouse-management';
import { UiTable } from '../../../../ui/table/UiTable';
import { WarehouseCardWrapper } from '../../../card/wrapper/WarehouseCardWrapper';
import dayjs from 'services/dayjs';

const { Text } = Typography;

interface Props {
    columnList: WarehouseExtractColumn[];
    onColumnChange: (id: number, column: Partial<WarehouseExtractColumn>) => Promise<void>;
}

export function WarehouseExtractDetailsColumns({ columnList, onColumnChange }: Props) {
    const columnListTableColumns: ColumnsType<WarehouseExtractColumn> = [
        {
            title: 'Column name',
            dataIndex: 'column_name',
            key: 'column_name',
            sorter: (a, b) => sorter(a.column_name, b.column_name),
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            sorter: (a, b) => sorter(a.type, b.type),
            width: 200,
        },
        {
            title: 'Length',
            dataIndex: 'character_maximum_length',
            key: 'character_maximum_length',
            sorter: (a, b) => sorter(a.character_maximum_length, b.character_maximum_length),
            width: 90,
        },
        {
            title: 'Precision',
            dataIndex: 'numeric_precision',
            key: 'numeric_precision',
            sorter: (a, b) => sorter(a.numeric_precision, b.numeric_precision),
            width: 90,
        },
        {
            title: 'Scale',
            dataIndex: 'numeric_scale',
            key: 'numeric_scale',
            sorter: (a, b) => sorter(a.numeric_scale, b.numeric_scale),
            width: 80,
        },
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
            render: (_, { created }) => <Text className="no-wrap-text">{dayjs(created).fromNow()}</Text>,
            sorter: (a, b) => sorter(a.created, b.created),
            width: 130,
        },
        {
            title: 'Primary key',
            dataIndex: 'primary_key',
            key: 'primary_key',
            render: (_, { id, primary_key }) => (
                <Switch
                    checked={primary_key}
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    onChange={() => handleColumnsConfirm(id, { primary_key: !primary_key })}
                />
            ),
            sorter: (a, b) => sorter(a.primary_key, b.primary_key),
            defaultSortOrder: 'descend',
            width: 120,
        },
        {
            title: 'Copy to WH',
            dataIndex: 'copy_to_wh',
            key: 'copy_to_wh',
            render: (_, { id, copy_to_wh }) => (
                <Switch
                    checked={copy_to_wh}
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    onChange={() => handleColumnsConfirm(id, { copy_to_wh: !copy_to_wh })}
                />
            ),
            sorter: (a, b) => sorter(a.copy_to_wh, b.copy_to_wh),
            width: 120,
        },
        {
            title: 'Disabled',
            dataIndex: 'disabled',
            key: 'disabled',
            render: (_, { id, disabled }) => (
                <Switch
                    checked={disabled}
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    onChange={() => handleColumnsConfirm(id, { disabled: !disabled })}
                />
            ),
            sorter: (a, b) => sorter(a.disabled, b.disabled),
            width: 120,
        },
    ];

    async function handleColumnsConfirm(id: number, column: Partial<WarehouseExtractColumn>) {
        Modal.confirm({
            ...WAREHOUSE_MODAL_CONFIGURATION,
            title: 'Are you sure you want to update column?',
            onOk: async () => {
                await onColumnChange(id, column);
            },
        });
    }

    return (
        <WarehouseCardWrapper title="Columns">
            <UiTable
                rowKey="id"
                columns={columnListTableColumns}
                dataSource={columnList}
                {...WAREHOUSE_DETAILS_TABLE_CONFIGURATION}
            />
        </WarehouseCardWrapper>
    );
}
