import {
    DatabaseMarket,
    DatabaseMarketOddsSource,
    DatabaseMarketType,
    DatabaseOutcome,
    Market,
    MarketStatus,
    MatchStatus,
    OutcomeStatus,
} from '@staycool/sports-types';
import {
    CreateMarketRequest,
    MarketStatusResponse,
    MarketWithOutcomes,
    MatchMarket,
    MatchMarketBetCountByStatus,
} from '@staycool/sports-types/dist/features/market/types';
import { message, notification } from 'antd';
import { getSportsUrl, httpDelete, httpGet, httpPatch, httpPost, parseAPIErrorMessage } from 'services/api';
import { logger } from 'services/logger';

export function cancelMarket(marketId: number, cancelReason: string = 'bet-cancel-generic') {
    const url = getSportsUrl(`market/${marketId}/cancel`);
    return httpPost<DatabaseMarket>(url, { cancel_reason_id: cancelReason });
}

export function getMarketsBetCount(matchId: number) {
    const url = getSportsUrl(`market/resulted/${matchId}`);
    return httpGet<MatchMarketBetCountByStatus>(url);
}

export async function getMatchMarkets(
    matchId: number,
    shouldExcludeBetBuilderMarkets = false,
    shouldExcludeResultedMarket = false,
) {
    try {
        const url = getSportsUrl(`market/by-match/${matchId}`);
        return await httpPost<Record<number, MatchMarket[]>>(url, {
            shouldExcludeBetBuilderMarkets,
            shouldExcludeResultedMarket,
        });
    } catch (e) {
        notification.error({ message: 'Failed to get match markets', description: parseAPIErrorMessage(e) });
    }
}

export async function updateMarket(id: number, updateFields: any) {
    const url = getSportsUrl(`market/update/${id}`);
    await httpPatch(url, updateFields);
}

export async function updateMarketRetailAvailable(id: number, isAvailable: boolean) {
    const url = getSportsUrl(`market/retail-available/${id}`);
    await httpPatch(url, { isAvailable });
}

export async function updateMarketsMulti(ids: number[], updateFields: Partial<DatabaseMarket>) {
    const url = getSportsUrl('market/update-multi');
    await httpPatch(url, { ids, update: updateFields });
}

export function updateOddsProvider(marketId: number, provider: string) {
    const url = getSportsUrl('market/odds-source');
    return httpPost<DatabaseMarketOddsSource>(url, { provider, marketId });
}

export function createMarkets(markets: CreateMarketRequest[]) {
    const url = getSportsUrl('market/manual');
    return httpPost<(CreatedMarket | FailedMarket)[]>(url, markets);
}

export async function duplicateLine(market: DuplicateLineMarket) {
    const url = getSportsUrl('market/duplicate-line/');
    return await httpPost(url, market);
}

export async function deleteMarket(marketId: number) {
    const url = getSportsUrl(`market/${marketId}`);
    return httpDelete<Record<string, any>>(url);
}

export async function getMarketBydId(marketId: number) {
    try {
        const url = getSportsUrl(`market/bo/${marketId}`);
        const market = await httpGet<Record<number, MatchMarket[]>>(url);
        if (market) {
            return Object.values(market)?.[0]?.[0];
        }
    } catch (e) {
        logger.log('SportsMarketMicroservice', 'getMarketBydId', e);
        notification.error({ message: 'Error while requesting market', description: parseAPIErrorMessage(e) });
    }
}

export async function uncancelMarket(marketId: number, marketName: string) {
    try {
        const url = getSportsUrl(`market/${marketId}/uncancel`);
        await httpPost(url);
        message.success(`${marketName} uncanceled`);
    } catch (error) {
        notification.error({ message: `Failed to uncancel market  ${marketName}!` });
        logger.log('SportsMarketMicroservice', 'uncancelMarket', error);
    }
}

export async function getMarketsListWithOutcomesAndExtras(
    categoryId: number,
    matchStatuses?: MatchStatus[],
    marketStatuses?: MarketStatus[],
    marketIds?: number[],
) {
    const url = getSportsUrl('market/bo/open-matches-markets');
    return httpGet<Market.GetOpenMatchMarketsWithExtras[]>(url, {
        categoryId,
        matchStatuses,
        marketStatuses,
        marketIds,
    });
}

export async function suspendMarkets(marketIds: number[]) {
    const url = getSportsUrl(`market/suspend-markets/`);
    await httpPost(url, { marketIds });
}

export async function openSuspendedMarkets(marketIds: number[]) {
    const url = getSportsUrl('market/open-all-suspended-markets-by-ids');
    await httpPatch(url, { marketIds });
}

export async function getMainMarketByMatchId(matchId: number) {
    try {
        const url = getSportsUrl(`market/asb-main-market-by-match-id/${matchId}`);
        return await httpGet<MarketWithOutcomes>(url);
    } catch (e) {
        notification.error({ message: 'Failed to get main market by match id', description: parseAPIErrorMessage(e) });
    }
}

export async function getAsbMarketsByMatchIdFormulaNames(matchId: number, formulaNames: string[]) {
    try {
        const url = getSportsUrl(`market/asb-markets-by-match-id-formula-names`);
        return await httpGet<MarketWithOutcomes[]>(url, { matchId, formulaNames });
    } catch (e) {
        notification.error({
            message: 'Failed to get ASB markets by match id and formula names',
            description: parseAPIErrorMessage(e),
        });
    }
}

export async function getSameLineMarket(params) {
    const url = getSportsUrl('market/same-line');
    return await httpGet<MarketWithOutcomes>(url, params);
}

export async function getByMarketIds(marketIds: number[]) {
    const url = getSportsUrl('market/bo/markets-by-ids');
    return await httpPost<MarketWithOutcomes[]>(url, { marketIds });
}

export async function disableMarkets(marketIds: number[]) {
    const url = getSportsUrl('market/disable-markets');
    return await httpPost(url, { marketIds });
}

export async function getMarketStatusHistory(
    marketId: number,
    createdBefore: string = new Date().toISOString(),
    createdAfter?: string,
) {
    try {
        const afterDate = createdAfter ? `&createdAfter=${createdAfter}` : '';
        const url = getSportsUrl(`market/status-history/${marketId}?createdBefore=${createdBefore}${afterDate}`);
        return await httpGet<MarketStatusResponse[]>(url);
    } catch (error) {
        notification.error({
            message: 'Failed to get market status history',
            description: parseAPIErrorMessage(error),
        });
        return [];
    }
}

interface DuplicateLineMarket {
    marketId: number;
    newLine: number;
    newStatus: MarketStatus;
    skipDisable: boolean;
    force: boolean;
}

export interface CreatedMarket extends DatabaseMarket {
    betgenius_market_id: number | null;
    outcomes: MatchMarketOutcomes[];
}

export interface FailedMarket {
    data: CreateMarketRequest;
    error: string;
}

export interface MatchMarketOutcomes extends DatabaseOutcome {
    betgenius_id: number | null;
    odds: Record<string, any>;
}

export interface MarketType extends DatabaseMarketType {
    formula: string;
    bg_market_type_id: number;
}

export interface Outcome {
    outcomeId: number;
    outcomeStatus: OutcomeStatus;
}

export interface MarketStatusUpdate {
    id: number;
    category_id: number;
    market_type_group_id: number;
    market_type_group_name: string;
    market_type_id: number;
    match_id: number;
    status: MarketStatus;
    isDeleted?: boolean;
}
