import { ClockCircleOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { Clock } from 'components/clock/Clock';
import { SportsbookAlarmsIcon } from 'components/sportsbook/alarms/icon/SportsbookAlarmsIcon';
import { SportsbookResultRequestIcon } from 'components/sportsbook/result-request/icon/SportsbookResultRequestIcon';
import React from 'react';
import { store, useStore } from 'stores/store';
import Wrapper from './styles';
import { isFeatureAvailable } from 'services/features';
import { ROLES, useKeycloak } from '../../../services/keycloak';
import { SportsbookManualAcceptanceAggregateIcon } from '../../sportsbook/manual-acceptance-aggregate/icon/SportsbookManualAcceptanceAggregateIcon';
import { SportsbookMissingResultsV2Icon } from '../../sportsbook/missing-results-v-2/icon/SportsbookMissingResultsV2Icon';

export function HeaderStatus() {
    const [isFeederConnected] = useStore(store.sportsbook.isFeederConnected);
    const [isOddsFormatAmerican] = useStore(store.sportsbook.isOddsFormatAmerican);
    const keycloak = useKeycloak();
    const shouldDisplayAlarmsIcon =
        isFeatureAvailable('alarms') && keycloak.hasAccessForPermissions([ROLES.SPORTSBOOK_RISK.READ]);

    return (
        <Wrapper>
            <Space size="middle">
                <Space size={6} align="center" direction="horizontal" className="time-container">
                    <ClockCircleOutlined className="icon-clock" />
                    <Clock
                        options={{
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: isOddsFormatAmerican,
                        }}
                    />
                </Space>
                {isFeatureAvailable('resultRequest') && <SportsbookResultRequestIcon />}
                {isFeatureAvailable('manualAcceptanceAggregate') && <SportsbookManualAcceptanceAggregateIcon />}
                {shouldDisplayAlarmsIcon && <SportsbookAlarmsIcon />}
                {isFeatureAvailable('missingResultsV2') && <SportsbookMissingResultsV2Icon />}
                <img
                    className="status-icon"
                    src={`/assets/images/live/live-${isFeederConnected ? 'on' : 'off'}.svg`}
                    alt="feeder-status"
                />
            </Space>
        </Wrapper>
    );
}
