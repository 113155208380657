import { logger } from '../../../services/logger';
import { useEffect, useState } from 'react';
import { notification } from 'antd';
import {
    WarehouseDeliveryBookmark,
    WarehouseDeliveryBookmarkRequest,
    WarehouseDeliveryDetails,
    WarehouseDeliveryRegularValidationSettings,
    WarehouseDeliverySettings,
    WarehouseDeliveryValidationSettings,
} from '../../../types/warehouse-management';
import {
    createWarehouseDeliveryRegularValidationSettings,
    getWarehouseDeliveryBookmarks,
    getWarehouseDeliveryDetails,
    getWarehouseDeliveryRegularValidationSettings,
    getWarehouseDeliveryValidationSettings,
    updateWarehouseDeliveryBookmark,
    updateWarehouseDeliveryProcessSettings,
    updateWarehouseDeliveryRegularValidationSettings,
    updateWarehouseDeliveryValidationSettings,
} from '../../../microservices/warehouse-management';
import keyBy from 'lodash/keyBy';

export function useWarehouseDeliveryDetails(detailsId: number) {
    const [deliveryDetails, setDeliveryDetails] = useState<WarehouseDeliveryDetails>();
    const [deliveryBookmarks, setDeliveryBookmarks] = useState<WarehouseDeliveryBookmark[]>([]);
    const [deliveryValidationSettings, setDeliveryValidationSettings] = useState<WarehouseDeliveryValidationSettings[]>(
        [],
    );
    const [deliveryRegularValidationSettings, setDeliveryRegularValidationSettings] = useState<
        WarehouseDeliveryRegularValidationSettings[]
    >([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        loadWarehouseDeliveryDetailsData();
    }, []);

    async function loadWarehouseDeliveryDetailsData() {
        setIsLoading(true);

        await Promise.all([
            loadWarehouseDeliveryDetails(),
            loadWarehouseDeliveryBookmarks(),
            loadWarehouseDeliveryValidationSettings(),
            loadWarehouseDeliveryRegularValidationSettings(),
        ]);

        setIsLoading(false);
    }

    async function loadWarehouseDeliveryDetails() {
        try {
            const warehouseDeliveryDetails = await getWarehouseDeliveryDetails(detailsId);
            setDeliveryDetails(warehouseDeliveryDetails);
        } catch (error) {
            logger.log('useWarehouseDeliveryDetails', 'loadWarehouseDeliveryDetails', error);
            notification.error({ message: 'Error loading delivery details' });
        }
    }

    async function changeWarehouseDeliveryProcessSettings(id: number, settings: Partial<WarehouseDeliverySettings>) {
        if (!deliveryDetails) {
            return;
        }

        try {
            const updatedWarehouseDeliveryProcessSettings = await updateWarehouseDeliveryProcessSettings(id, settings);
            setDeliveryDetails({
                ...deliveryDetails,
                settings: deliveryDetails.settings.map(deliveryDetailsSettings =>
                    deliveryDetailsSettings.id === updatedWarehouseDeliveryProcessSettings.id
                        ? { ...deliveryDetailsSettings, enabled: updatedWarehouseDeliveryProcessSettings.enabled }
                        : deliveryDetailsSettings,
                ),
            });
            notification.success({ message: 'Delivery process settings updated successfully' });
        } catch (error) {
            logger.log('useWarehouseExtractDetails', 'changeWarehouseDeliveryProcessSettings', error);
            notification.error({ message: 'Error updating delivery process settings' });
        }
    }

    async function loadWarehouseDeliveryBookmarks() {
        try {
            const warehouseDeliveryBookmarks = await getWarehouseDeliveryBookmarks(detailsId);
            setDeliveryBookmarks(warehouseDeliveryBookmarks);
        } catch (error) {
            logger.log('useWarehouseDeliveryDetails', 'loadWarehouseDeliveryBookmarks', error);
            notification.error({ message: 'Error loading delivery bookmarks' });
        }
    }

    async function changeWarehouseDeliveryBookmarks(bookmarks: WarehouseDeliveryBookmarkRequest[]) {
        try {
            const updatedWarehouseDeliveryBookmarks = await Promise.all(
                bookmarks.map(bookmark => updateWarehouseDeliveryBookmark(bookmark)),
            );
            const updatedWarehouseDeliveryBookmarksObject = keyBy(updatedWarehouseDeliveryBookmarks, 'id');
            setDeliveryBookmarks(
                deliveryBookmarks.map(deliveryBookmark =>
                    updatedWarehouseDeliveryBookmarksObject[deliveryBookmark.id]
                        ? updatedWarehouseDeliveryBookmarksObject[deliveryBookmark.id]
                        : deliveryBookmark,
                ),
            );
            notification.success({ message: 'Delivery bookmarks updated successfully' });
        } catch (error) {
            logger.log('useWarehouseExtractDetails', 'changeWarehouseDeliveryBookmarks', error);
            notification.error({ message: 'Error updating delivery bookmarks' });
        }
    }

    async function loadWarehouseDeliveryValidationSettings() {
        try {
            const warehouseDeliveryValidationSettings = await getWarehouseDeliveryValidationSettings(detailsId);
            setDeliveryValidationSettings(warehouseDeliveryValidationSettings);
        } catch (error) {
            logger.log('useWarehouseDeliveryDetails', 'loadWarehouseDeliveryValidationSettings', error);
            notification.error({ message: 'Error loading delivery validation settings' });
        }
    }

    async function changeWarehouseDeliveryValidationSettings(
        id: number,
        settings: Partial<WarehouseDeliveryValidationSettings>,
    ) {
        try {
            const updatedWarehouseDeliveryValidationSettings = await updateWarehouseDeliveryValidationSettings(
                id,
                settings,
            );
            setDeliveryValidationSettings(
                deliveryValidationSettings.map(deliveryValidationSettingsItem =>
                    deliveryValidationSettingsItem.id === updatedWarehouseDeliveryValidationSettings.id
                        ? updatedWarehouseDeliveryValidationSettings
                        : deliveryValidationSettingsItem,
                ),
            );
            notification.success({ message: 'Delivery validation settings updated successfully' });
        } catch (error) {
            logger.log('useWarehouseExtractDetails', 'changeWarehouseDeliveryValidationSettings', error);
            notification.error({ message: 'Error updating delivery validation settings' });
        }
    }

    async function loadWarehouseDeliveryRegularValidationSettings() {
        try {
            const warehouseDeliveryValidationSettings = await getWarehouseDeliveryRegularValidationSettings(detailsId);
            setDeliveryRegularValidationSettings(warehouseDeliveryValidationSettings);
        } catch (error) {
            logger.log('useWarehouseDeliveryDetails', 'loadWarehouseDeliveryRegularValidationSettings', error);
            notification.error({ message: 'Error loading extract bookmarks' });
        }
    }

    async function addWarehouseDeliveryRegularValidationSettings(settings: WarehouseDeliveryRegularValidationSettings) {
        if (!deliveryDetails) {
            return;
        }

        const { schema, table_name } = deliveryDetails;
        try {
            const createdWarehouseDeliveryValidationSettings = await createWarehouseDeliveryRegularValidationSettings({
                ...settings,
                schema,
                table_name,
            });
            setDeliveryRegularValidationSettings([
                ...deliveryRegularValidationSettings,
                createdWarehouseDeliveryValidationSettings,
            ]);
            notification.success({ message: 'Delivery regular validation settings created successfully' });
        } catch (error) {
            logger.log('useWarehouseExtractDetails', 'addWarehouseDeliveryRegularValidationSettings', error);
            notification.error({ message: 'Error creating delivery regular validation settings' });
        }
    }

    async function changeWarehouseDeliveryRegularValidationSettings(
        id: number,
        settings: Partial<WarehouseDeliveryRegularValidationSettings>,
    ) {
        try {
            const updatedWarehouseDeliveryRegularValidationSettings =
                await updateWarehouseDeliveryRegularValidationSettings(id, settings);
            setDeliveryRegularValidationSettings(
                deliveryRegularValidationSettings.map(deliveryRegularValidationSettingsItem =>
                    deliveryRegularValidationSettingsItem.id === updatedWarehouseDeliveryRegularValidationSettings.id
                        ? updatedWarehouseDeliveryRegularValidationSettings
                        : deliveryRegularValidationSettingsItem,
                ),
            );
            notification.success({ message: 'Delivery regular validation settings updated successfully' });
        } catch (error) {
            logger.log('useWarehouseExtractDetails', 'changeWarehouseDeliveryRegularValidationSettings', error);
            notification.error({ message: 'Error updating delivery regular validation settings' });
        }
    }

    return {
        deliveryDetails,
        deliveryBookmarks,
        deliveryValidationSettings,
        deliveryRegularValidationSettings,
        changeWarehouseDeliveryProcessSettings,
        changeWarehouseDeliveryBookmarks,
        changeWarehouseDeliveryValidationSettings,
        addWarehouseDeliveryRegularValidationSettings,
        changeWarehouseDeliveryRegularValidationSettings,
        isLoading,
    };
}
