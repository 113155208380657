import { Badge } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from 'routes';
import { getRoute } from 'services/router';
import { getStoreValue, store, useStore } from 'stores/store';
import useSound from 'use-sound';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { logger } from '../../../../services/logger';

export function SportsbookManualAcceptanceAggregateIcon() {
    const [pendingRequestsCount] = useStore(store.sportsbook.aggregatedTicketAcceptances.pendingRequestsCount);
    const [previousCount, setPreviousCount] = useState(-10);
    const [isNotificationEnabled] = useStore(store.isNotificationEnabled);
    const [soundVolume] = useLocalStorage('settings-ma-sound-volume', 50);
    const volume = soundVolume / 100;
    const [play] = useSound('/assets/sounds/manualacceptance.mp3', { volume, interrupt: true });

    useEffect(() => {
        const tabElection = getStoreValue(store.tabElection) as any;
        const debugInfo = {
            pendingRequestsCount,
            isLeader: tabElection?.isLeader,
            isNotificationEnabled,
            didMACountIncrease: didMACountIncrease(),
        };
        logger.debug('SportsbookManualAcceptanceAggregateIcon', 'SportsbookManualAcceptanceAggregateIcon', {
            debugInfo,
        });
        if (pendingRequestsCount && tabElection?.isLeader && isNotificationEnabled && didMACountIncrease()) {
            logger.debug(
                'SportsbookManualAcceptanceAggregateIcon',
                'SportsbookManualAcceptanceAggregateIcon',
                'play MA sound',
            );
            play();
        }
    }, [pendingRequestsCount]);

    function didMACountIncrease() {
        const hasMACountIncreased = pendingRequestsCount - previousCount > 0;
        setPreviousCount(pendingRequestsCount);
        return hasMACountIncreased;
    }

    return (
        <Link to={getRoute(ROUTES.sportsbook.manualAcceptanceAggregate)}>
            <Badge count={pendingRequestsCount} className="pointer">
                <img className="status-icon" src="/assets/images/m-icon.svg" alt="manual-acceptance" />
            </Badge>
        </Link>
    );
}
