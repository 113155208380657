import { breakpoints } from 'services/media';
import { color, sizes } from 'style/variables';
import styled from 'styled-components';

export default styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;

    .header {
        display: flex;
        gap: 0.5rem;

        .header-additional-content {
            flex: 1;
        }

        .toggle-columns-container {
            flex: 0;
        }
    }

    .toggle-columns-popover-btn {
        float: right;
    }

    .ui-table .ant-table-body {
        tr > td {
            border-bottom: none;
        }

        overflow: auto !important;
        @media (min-width: ${breakpoints.desktopMin + 1}px) {
            ::-webkit-scrollbar {
                width: 0.8em;
                height: 0.8em;
            }

            ::-webkit-scrollbar-thumb {
                border-radius: ${sizes.borderRadius};
            }
        }
    }

    .separated-row-table tbody {
        tr {
            &.odd {
                & + .ant-table-expanded-row > td,
                & > td {
                    background-color: ${color.gray100};
                }
            }

            &.even {
                & + .ant-table-expanded-row > td,
                & > td {
                    background-color: ${color.gray200};
                }
            }
        }
    }

    & .ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected > td {
        background: ${color.blue200};
    }

    .divider {
        margin: 0;
    }

    && tbody > tr:not(.ant-table-row-selected):hover > td {
        background: ${color.gray300};
    }

    & .ant-table-wrapper .ant-table .ant-table-tbody > tr.ant-table-placeholder {
        &,
        & > td:hover {
            background: ${color.gray100};
        }

        & > .ant-table-cell {
            border-bottom: none;

            & .ant-table-expanded-row-fixed {
                width: auto !important;
            }
        }
    }
`;
