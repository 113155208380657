import { SystemBetType } from '@staycool/bets-types/bet-placing-handlers-bets';
import { CustomerResultingRequestWithDetails } from '@staycool/bets-types/dist/_features/customer-requests/types';
import {
    DatabaseAggregatedManualAcceptance,
    ManualAcceptanceSelection,
    TicketSummary,
} from '@staycool/bets-types/manual-acceptance';
import { notification } from 'antd';
import differenceBy from 'lodash/differenceBy';
import isNumber from 'lodash/isNumber';
import mapValues from 'lodash/mapValues';
import remove from 'lodash/remove';
import round from 'lodash/round';
import { ProductTypeEnum } from 'microservices/bets/types';
import { logger } from '../logger';
import { getStoreValue, store } from 'stores/store';
import dayjs from 'services/dayjs';
import { sendMAResponsibility } from 'microservices/sports/responsible-bookmaker';
import { parseAPIErrorMessage } from 'services/api';
import { getDisplayGroupLimit } from 'services/user/user';
import { getManualAcceptances } from '../../microservices/bets/manual-acceptance-aggregate';
import { DatabaseAggregatedVoidTicket } from '@staycool/bets-types';

export const responsibleBookmakerChannelSettings = {
    service: 'sports',
    channel: 'responsible-bookmaker',
    keepAlive: true,
};

export const MAAggregateTicketsChannelSettings = {
    service: 'bets',
    channel: 'manual-acceptance-aggregate-update',
    keepAlive: false,
};

export const resultingRequestsChannelSettings = {
    service: 'bets',
    channel: 'resulting-request',
    keepAlive: false,
};

export const MA_BOOKMAKER_CHANNEL = 'manualAcceptanceBookmakerChannel';
export const MA_BOOKMAKER_KEY = 'maResponsibleBookmakersVersion2';

export async function fetchManualAcceptances() {
    const manualAcceptanceFilter = getStoreValue(store.sportsbook.aggregatedTicketAcceptances.filter);

    try {
        const manualAcceptancesList = getStoreValue(store.sportsbook.aggregatedTicketAcceptances.manualAcceptancesList);
        const { manual_acceptance_aggregates: manualAcceptances } = await getManualAcceptances(manualAcceptanceFilter);
        const newManualAcceptances =
            differenceBy(manualAcceptances, manualAcceptancesList, 'ticket_id') || manualAcceptances;

        const currentMaIds = manualAcceptances.map(ma => ma.ticket_id);

        remove(manualAcceptancesList, manualAcceptance => !currentMaIds.includes(manualAcceptance.ticket_id));

        const processedNewManualAcceptances = newManualAcceptances.map(manualAcceptance => {
            const { groupLimitName, isLimitedInCategory } = getDisplayGroupLimit(
                '',
                manualAcceptance.extra.risk_limit_ratio_prematch,
                manualAcceptance.extra.risk_limit_ratio_livebet,
                manualAcceptance.extra.product as ProductTypeEnum,
                manualAcceptance.min_user_limit_ratio,
            );
            return {
                ...manualAcceptance,
                isLimitedInCategory,
                userRiskGroupName: groupLimitName,
                tryAccept: manualAcceptance.stake_requested,
            } as ProcessedManualAcceptanceAggregate;
        });

        store.sportsbook.aggregatedTicketAcceptances.manualAcceptancesList.set([
            ...manualAcceptancesList,
            ...processedNewManualAcceptances,
        ]);
    } catch (err) {
        const message = parseAPIErrorMessage(err);
        logger.error(
            'ManualAcceptanceAggregateService',
            'fetchManualAcceptances',
            `Failed to get pending manual acceptances: ${message}`,
        );
    }
}

export async function sendResponsibility(bookmakerIds?: number[]) {
    const bookieIds = bookmakerIds || getStoreValue(store.sportsbook.aggregatedTicketAcceptances.filteredBookmakerIds);
    const localStorageKey = 'responsible-bookmaker-ma-last-send';
    const lastSend = localStorage.getItem(localStorageKey);
    if (!lastSend || dayjs(lastSend).isBefore(dayjs().subtract(10, 'seconds'))) {
        localStorage.setItem(localStorageKey, dayjs().toISOString());
        try {
            await sendMAResponsibility(bookieIds);
        } catch (error) {
            logger.error('ManualAcceptanceAggregateService', 'sendResponsibility', error);
        }
    }
}

export function isTicketExpired(ticket: ProcessedAggregatedAcceptances) {
    const now = dayjs();
    const ticketMaTime = dayjs(ticket.created_at).add(5, 'minutes');
    const bettingEndTime = dayjs(getBettingEndDate(ticket.unique_selections)).subtract(15, 'minutes');

    if (ticketMaTime <= now || bettingEndTime < now) {
        return true;
    }
    return false;
}

export function getBettingEndDate(selections: ManualAcceptanceSelection[]) {
    return selections
        .map(selection => selection.betting_end)
        .reduce((newDate, currentDate) => (currentDate < newDate ? currentDate : newDate));
}

export function getResponsibleBookmakers(selections: ManualAcceptanceSelection[]) {
    return selections.flatMap(selection => {
        if (!selection.responsible_bookmakers || !selection.responsible_bookmakers.length) {
            return 0;
        }
        return selection.responsible_bookmakers;
    });
}

export function getTicketsFilteredByOthers(notYetExpiredTickets: ProcessedAggregatedAcceptances[]) {
    const activeWatchers = getStoreValue(store.sportsbook.aggregatedTicketAcceptances.activeWatchers);
    const othersWatching = activeWatchers.flatMap(watcher => watcher.watching);

    return notYetExpiredTickets.filter(ticket => {
        const bookmakers = getResponsibleBookmakers(ticket.unique_selections);
        return bookmakers.some(bookmakerId => othersWatching.includes(bookmakerId));
    });
}

export function getTicketsFilteredByMe(
    manualAcceptancesList: ProcessedAggregatedAcceptances[],
    filteredBookmakerIds: number[],
) {
    return manualAcceptancesList.filter(ticket => {
        const bookmakers = getResponsibleBookmakers(ticket.unique_selections);
        return bookmakers.some(bookmakerId => filteredBookmakerIds.includes(bookmakerId));
    });
}

export function getResultingRequestsFilteredByMe(
    resultingRequests: CustomerResultingRequestWithDetails[],
    filteredBookmakerIds: number[],
) {
    return resultingRequests.filter(resultingRequest => {
        return resultingRequest.responsible_bookmakers.some(bookmakerId => filteredBookmakerIds.includes(bookmakerId));
    });
}

export function calculateNumberOfPendingTickets(numberOfTickets: number) {
    const isNotificationEnabled = getStoreValue(store.isNotificationEnabled);
    const pendingRequestsCount = getStoreValue(store.sportsbook.aggregatedTicketAcceptances.pendingRequestsCount);
    if (pendingRequestsCount !== numberOfTickets) {
        const numberOfNewTickets = numberOfTickets - pendingRequestsCount;
        if (numberOfNewTickets > 0 && isNotificationEnabled && document.visibilityState === 'visible') {
            notification.warning({ message: `${numberOfNewTickets} new ticket request(s)!` });
        }
        store.sportsbook.aggregatedTicketAcceptances.pendingRequestsCount.set(numberOfTickets);
    }
}

export function scaleTicketSummary(ma: ProcessedManualAcceptanceAggregate, newStake?: number): TicketSummary {
    const scaleFactor = !newStake || !ma.stake_requested ? 1 : newStake / ma.stake_requested;
    const ma_potential_return = ma.ticket_summary.ma_potential_return * scaleFactor;
    const ma_potential_return_bc = ma.ticket_summary.ma_potential_return_bc * scaleFactor;
    const ma_stake = ma.ticket_summary.ma_stake * scaleFactor;
    const ma_stake_bc = ma.ticket_summary.ma_stake_bc * scaleFactor;
    return mapValues(
        {
            ...ma.ticket_summary,
            ma_potential_return,
            ma_potential_return_bc,
            total_ma_potential_return: ma_potential_return + ma.ticket_summary.not_ma_potential_return,
            total_ma_potential_return_bc: ma_potential_return_bc + ma.ticket_summary.not_ma_potential_return_bc,
            ma_stake,
            ma_stake_bc,
            total_stake: ma.ticket_summary.not_ma_stake + ma_stake,
            total_stake_bc: ma.ticket_summary.not_ma_stake_bc + ma_stake_bc,
        },
        v => round(v, 2),
    );
}

export function scaleBetsInfo(ma: DatabaseAggregatedManualAcceptance, newStake?: number): ProcessedBetsInfo[] {
    const scaleFactor = newStake && ma.stake_requested ? newStake / ma.stake_requested : 1;
    return (
        ma.bets_info?.map(
            betInfo =>
                mapValues(
                    {
                        betType: betInfo.bet_type,
                        numberOfBets: betInfo.number_of_bets,
                        placedStake: betInfo.placed_stake,
                        placedStakeBc: betInfo.placed_stake_bc,
                        requestedStake: betInfo.requested_stake * scaleFactor,
                        requestedStakeBc: betInfo.requested_stake * scaleFactor,
                        totalStake: betInfo.not_ma_total_stake + betInfo.ma_total_stake * scaleFactor,
                        totalStakeBc: betInfo.not_ma_total_stake_bc + betInfo.ma_total_stake * scaleFactor,
                        totalOdds: betInfo.total_odds,
                        potentialReturn: betInfo.not_ma_potential_return + betInfo.ma_potential_return * scaleFactor,
                        potentialReturnBc:
                            betInfo.not_ma_potential_return_bc + betInfo.ma_potential_return * scaleFactor,
                    },
                    v => (isNumber(v) ? round(v, 2) : v),
                ) as ProcessedBetsInfo,
        ) || []
    );
}

export interface ProcessedManualAcceptanceAggregate extends DatabaseAggregatedManualAcceptance {
    userRiskGroupName: string;
    isLimitedInCategory: boolean;
    tryAccept: number;
}

export type ProcessedAggregatedAcceptances = ProcessedManualAcceptanceAggregate | DatabaseAggregatedVoidTicket;

export interface ProcessedBetsInfo {
    betType: SystemBetType;
    numberOfBets: number;
    placedStake: number;
    placedStakeBc: number;
    requestedStake: number;
    totalStake: number;
    totalStakeBc: number;
    totalOdds: number;
    potentialReturn: number;
    potentialReturnBc: number;
}
