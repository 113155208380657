import { color } from 'style/variables';
import styled from 'styled-components';

export default styled.div`
    color: ${color.blue500};
    width: 100%;

    .left {
        float: left;
    }

    .right {
        float: right;
    }
`;
