import styled from 'styled-components';
import { Radio, RadioGroupProps } from 'antd';

const { Group } = Radio;

interface Props extends RadioGroupProps {
    isNoWrap: boolean;
}

export default styled(Group)<Props>`
    ${props => props.isNoWrap && 'white-space: nowrap;'}
`;
