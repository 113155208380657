import { useRouter } from 'services/router';
import { stringify } from 'query-string';
import { useState } from 'react';

interface TabsWithNavigation {
    tabs: string[];
    isTabFromUrl?: boolean;
    tabQueryParamName?: string;
    children?: string[];
}

export function useTabsWithNavigation({
    tabs,
    isTabFromUrl = false,
    tabQueryParamName,
    children = [],
}: TabsWithNavigation) {
    const [activeTab, setActiveTab] = useState(tabs[0]);
    const { pathname, queryParams, navigateTo } = useRouter();

    const tabName = getTabNameFromUrl();
    const isNavigationUnused = !isTabFromUrl && !tabQueryParamName;

    const hasTabInList = tabs.includes(pathname);
    const activeTabWithNavigation = tabs.find(isActiveTabWithNavigation) ?? tabs[0];

    function getTabNameFromUrl() {
        if (tabQueryParamName) {
            return queryParams[tabQueryParamName];
        }

        if (isTabFromUrl) {
            return pathname;
        }
    }

    function isActiveTabWithNavigation(tab: string) {
        if (tabQueryParamName) {
            return tab === tabName;
        }

        if (isTabFromUrl) {
            return hasTabInList ? tab === tabName : pathname.includes(tab);
        }

        return false;
    }

    function changeActiveTabWithNavigation(value: string) {
        if (tabQueryParamName) {
            const queryParamsWithoutChildrenAndNull = Object.keys(queryParams)
                .filter(item => !children.includes(item) && queryParams[item])
                .reduce((acc, item) => ({ ...acc, [item]: queryParams[item] }), {});

            return navigateTo({
                search: stringify({ ...queryParamsWithoutChildrenAndNull, [tabQueryParamName]: value }),
            });
        }

        if (isTabFromUrl) {
            return navigateTo(value);
        }
    }

    return {
        activeTab: isNavigationUnused ? activeTab : activeTabWithNavigation,
        changeActiveTab: isNavigationUnused ? setActiveTab : changeActiveTabWithNavigation,
    };
}
