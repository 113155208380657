import { Modal } from 'antd';
import styled from 'styled-components';

export default styled(Modal)`
    & .ant-modal-content {
        padding: 1rem 1.5rem 0.675rem 1.5rem;
    }

    & .modal-divider {
        width: calc(100% + 3rem);
        margin: 0 -1.5rem;
    }

    & .modal-top-divider {
        margin-bottom: 1.5rem;
    }

    & .modal-bottom-divider {
        margin-top: 1.5rem;
    }

    & .ant-modal-footer {
        margin-top: 0.675rem;
    }
` as typeof Modal;
