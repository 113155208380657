import { useEffect, useRef } from 'react';

export function useInterval(callback, intervalInMilliseconds: number, watchProperties: any[] = []) {
    const callbackRef = useRef(callback);

    useEffect(() => {
        callbackRef.current = callback;
    }, watchProperties);

    useEffect(() => {
        if (!intervalInMilliseconds) {
            return;
        }

        function callabackRefFunc() {
            callbackRef.current();
        }

        const intervalReference = setInterval(callabackRefFunc, intervalInMilliseconds);

        return () => {
            if (intervalReference) {
                clearInterval(intervalReference);
            }
        };
    }, [intervalInMilliseconds]);
}
