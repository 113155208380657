import { Empty, Modal, Space, Switch } from 'antd';
import { useState } from 'react';
import { WAREHOUSE_MODAL_CONFIGURATION } from '../../../../../services/warehouse-management';
import { WarehouseDeliveryDetails, WarehouseDeliverySettings } from '../../../../../types/warehouse-management';
import { WarehouseCardRow } from '../../../card/row/WarehouseCardRow';
import { WarehouseCardWrapper } from '../../../card/wrapper/WarehouseCardWrapper';

interface Props {
    details: WarehouseDeliveryDetails | undefined;
    onProcessSettingsChange: (id: number, settings: Partial<WarehouseDeliverySettings>) => Promise<void>;
}

export function WarehouseDeliveryDetailsProcessSettings({ details, onProcessSettingsChange }: Props) {
    const [isUpdating, setIsUpdating] = useState(false);

    async function handleProcessSettingsConfirm(id: number, enabled: boolean) {
        Modal.confirm({
            ...WAREHOUSE_MODAL_CONFIGURATION,
            title: `Are you sure you want to update table ${table_name}?`,
            onOk: async () => {
                setIsUpdating(true);
                await onProcessSettingsChange(id, { enabled });
                setIsUpdating(false);
            },
        });
    }

    if (!details) {
        return <Empty />;
    }

    const { settings, table_name } = details;

    return (
        <WarehouseCardWrapper title="Process settings">
            <Space direction="vertical">
                {settings.map(({ id, name, enabled }) => (
                    <WarehouseCardRow
                        key={id}
                        title={name}
                        component={
                            <Switch
                                key={id}
                                checked={enabled}
                                checkedChildren="On"
                                unCheckedChildren="Off"
                                disabled={isUpdating}
                                onChange={() => handleProcessSettingsConfirm(id, !enabled)}
                            />
                        }
                    />
                ))}
            </Space>
        </WarehouseCardWrapper>
    );
}
