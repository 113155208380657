import {
    DatabaseAggregatedManualAcceptance,
    ManualAcceptanceAggregateFilter,
} from '@staycool/bets-types/manual-acceptance';
import { getServiceUrl, httpPost } from 'services/api';

export const getUrl = url => getServiceUrl('bets', url);

export function getManualAcceptances(filter: ManualAcceptanceAggregateFilter) {
    const url = getUrl('manual-acceptance/aggregate/list');
    return httpPost<{ manual_acceptance_aggregates: DatabaseAggregatedManualAcceptance[]; all_sources: string[] }>(
        url,
        filter,
    );
}
