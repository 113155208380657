const TYPE_LOG = 'LOG';
const TYPE_DEV = 'DEV';
const TYPE_DEBUG = 'DEBUG';
const TYPE_ERROR = 'ERROR';
const TYPE_INFO = 'INFO';

const css = {
    [TYPE_LOG]: `background-color: #ccc; color: black;`,
    [TYPE_DEV]: `background-color: #ccc; color: black;`,
    [TYPE_DEBUG]: `background-color: orange; color: white;`,
    [TYPE_ERROR]: `background-color: red; color: white;`,
    [TYPE_INFO]: `background-color: lightblue; color: white;`,
};

function getRest(rest) {
    return rest.length ? [...rest] : [];
}

// function reportError({ className, methodName, data }) {
//     // pass
//     // TODO: Set up custom error reporting
//     // const stringifiedData = (data || []).map(segment => JSON.stringify(segment, null, 2));
//     // Raven.captureMessage(`${className} - ${methodName} - ${stringifiedData.join(' - ')}`);
// }

function log(type, className, methodName, ...rest) {
    if (type === TYPE_ERROR) {
        // reportError({ className, methodName, data: [...rest] });
    }

    if (type === TYPE_LOG) {
        // eslint-disable-next-line no-console
        console.log(`%c ${type} `, css[type], ...rest);
        return;
    }

    // eslint-disable-next-line no-console
    console.log(`%c ${type} `, css[type], className, methodName, ...rest);
}

export const logger = {
    dev: (className, methodName, ...rest) => {
        log(TYPE_DEV, className, methodName, ...getRest(rest));
    },
    debug: (className, methodName, ...rest) => {
        log(TYPE_DEBUG, className, methodName, ...getRest(rest));
    },
    info: (className, methodName, ...rest) => {
        log(TYPE_INFO, className, methodName, ...getRest(rest));
    },
    error: (className, methodName, ...rest) => {
        log(TYPE_ERROR, className, methodName, ...getRest(rest));
    },
    log: (...rest) => {
        log(TYPE_LOG, null, null, ...getRest(rest));
    },
};
