import { getVoidTickets } from '../../microservices/bets/void-tickets-aggregate';
import { getStoreValue, store } from '../../stores/store';
import { parseAPIErrorMessage } from '../api';
import { logger } from '../logger';

export async function fetchVoidTickets() {
    const manualAcceptanceFilters = getStoreValue(store.sportsbook.aggregatedTicketAcceptances.filter);
    const { stakeLimitMin: _, stakeLimitMax: __, ...voidTicketsFilters } = manualAcceptanceFilters;
    try {
        const { void_ticket_aggregates: voidTickets } = await getVoidTickets(voidTicketsFilters);
        store.sportsbook.aggregatedTicketAcceptances.voidTicketsList.set(voidTickets || []);
    } catch (error) {
        const message = parseAPIErrorMessage(error);
        logger.error(
            'VoidTicketAggregateService',
            'fetchVoidTickets',
            `Failed to get pending void tickets: ${message}`,
        );
    }
}
