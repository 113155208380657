import { logger } from '../../../services/logger';
import { useEffect, useState } from 'react';
import { getWarehouseProcessList, updateWarehouseProcess } from '../../../microservices/warehouse-management';
import { WarehouseProcess } from '../../../types/warehouse-management';
import { notification } from 'antd';

export function useWarehouseStatusList() {
    const [processList, setProcessList] = useState<WarehouseProcess[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        loadWarehouseProcessList();
    }, []);

    async function loadWarehouseProcessList() {
        setIsLoading(true);

        try {
            const warehouseProcessList = await getWarehouseProcessList();
            setProcessList(warehouseProcessList);
        } catch (error) {
            logger.log('useWarehouseStatusList', 'loadWarehouseProcessList', error);
            notification.error({ message: 'Error loading process list' });
        }

        setIsLoading(false);
    }

    async function changeWarehouseProcess(id: number, process: Partial<WarehouseProcess>) {
        setIsUpdating(true);

        try {
            const updatedWarehouseProcess = await updateWarehouseProcess(id, process);

            setProcessList(processList =>
                processList.map(process =>
                    process.id === updatedWarehouseProcess.id ? updatedWarehouseProcess : process,
                ),
            );

            notification.success({ message: 'Process was updated successfully!' });
        } catch (error) {
            logger.log('useWarehouseStatusList', 'changeWarehouseProcess', error);
            notification.error({ message: 'Error updating process' });
        }

        setIsUpdating(false);
    }

    return { processList, isLoading, isUpdating, changeWarehouseProcess };
}
