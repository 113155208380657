type MediaTypes = keyof typeof mediaQuery;

export type GetMedia = Record<MediaTypes, boolean> & { deviceType: MediaTypes };

export const breakpoints = {
    phoneMax: 767,
    tabletMax: 1200,
    desktopMin: 1440,
    desktopSmall: 1600,
};

export const mediaQuery = {
    isPhone: `(max-width: ${breakpoints.phoneMax}px)`,
    isTablet: `(min-width: ${breakpoints.phoneMax + 1}px) and (max-width: ${breakpoints.tabletMax}px)`,
    isLaptop: `(min-width: ${breakpoints.tabletMax + 1}px) and (max-width: ${breakpoints.desktopMin}px)`,
    isDesktop: `(min-width: ${breakpoints.desktopMin + 1}px)`,
    isDesktopSmall: `(max-width: ${breakpoints.desktopSmall}px)`,
};

export const getMedia = (): GetMedia => {
    const media = Object.keys(mediaQuery).reduce((media, key) => {
        media[key] = window.matchMedia(mediaQuery[key]).matches;
        return media;
    }, {}) as GetMedia;

    media.deviceType = Object.keys(media).filter(key => media[key])[0] as MediaTypes;
    return media;
};
