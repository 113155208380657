export interface CategoryType {
    id: number;
    name: string;
    depth: CategoryTypeDepth;
    active: boolean;
    is_favourite?: boolean;
}

export enum CategoryTypeDepth {
    Sport = 1,
    Region = 2,
    League = 3,
}
interface CurrentCategoryType extends CategoryType {
    parent_category_id: number;
    grand_parent_id: number;
}

export interface CategoryByIdType {
    currentCategory: CurrentCategoryType;
    sports: CategoryType[];
    regions: CategoryType[];
    leagues: CategoryType[];
}
