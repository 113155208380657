import { Col, Row } from 'antd';
import { ReactNode } from 'react';

interface Props {
    title: string;
    component: ReactNode;
}

export function WarehouseCardRow({ title, component }: Props) {
    return (
        <Row justify="space-between">
            <Col>{`${title}:`}</Col>
            <Col>{component}</Col>
        </Row>
    );
}
