import { logger } from '../../../services/logger';
import { useEffect, useState } from 'react';
import { getWarehouseDeliveryList } from '../../../microservices/warehouse-management';
import { WarehouseDelivery } from '../../../types/warehouse-management';
import { notification } from 'antd';

export function useWarehouseDeliveryList() {
    const [deliveryList, setDeliveryList] = useState<WarehouseDelivery[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        loadWarehouseDeliveryList();
    }, []);

    async function loadWarehouseDeliveryList() {
        setIsLoading(true);

        try {
            const warehouseDeliveryList = await getWarehouseDeliveryList();
            setDeliveryList(warehouseDeliveryList);
        } catch (error) {
            logger.log('useWarehouseExtractList', 'loadWarehouseDeliveryList', error);
            notification.error({ message: 'Error loading delivery list' });
        }

        setIsLoading(false);
    }

    return { deliveryList, isLoading };
}
