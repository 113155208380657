import type { TicketType } from '@staycool/bets-types';
import type { TicketsDetailRetailResponse } from '@staycool/bets-types/tickets';
import type {
    CategoryConfigurationLayout,
    DatabaseSequence,
    MarketStatus,
    MarketViewType,
    MatchStatus,
    MatchType,
    OutcomeStatus,
} from '@staycool/sports-types';
import { getServiceUrl, httpPost, httpGet, parseAPIErrorMessage } from 'services/api';
import { logger } from 'services/logger';
import type { SystemBetType } from './history';
import type { UUID } from './types';
import type { TicketList } from 'microservices/sportsbook-reports';
import { notification } from 'antd';
import type {
    GetBetPartialClawbacksParams,
    GetBetPartialClawbacksResponse,
} from '@staycool/bets-types/bet-partial-clawback';

export const getUrl = url => getServiceUrl('bets', url);

export function getBetsPendingBetsStatistics(params: PendingBetsParams) {
    const url = getUrl('stats/bets/pending');
    return httpGet<ExpectedPendingBetResolutionSchedule[]>(url, params);
}

export function getTicketsWithBetData(ticketIds: string[]) {
    const url = getUrl('tickets/retail/ticketsWithBetData');
    return httpPost<(TicketsDetailRetailResponse & { id: string })[]>(url, { ticketIds });
}

export async function isMoneylineAndTotalsSameMatchComboEligible(matchIds: number[]) {
    if (!matchIds.length) {
        return {};
    }
    try {
        return await httpPost<Record<number, boolean>>(getUrl('validation/is-moneyline-and-totals-combo-eligible'), {
            matchIds,
        });
    } catch (e) {
        logger.error('BetsMicroservice', 'isMoneylineAndTotalsSameMatchComboEligible', e);
        return {};
    }
}

export function getResultRequestList() {
    const url = getUrl(`result-request/list`);
    return httpGet(url);
}

export function resolveTicketRequest(ticketId: string) {
    const url = getUrl(`result-request/resolve/`);
    return httpPost(url, { ticket_id: ticketId });
}

export function getResultRequestCount() {
    const url = getUrl(`result-request/count`);
    return httpGet(url);
}

export async function getBetPartialClawbacks(params: GetBetPartialClawbacksParams) {
    try {
        const url = getUrl('bet-partial-clawback');
        return httpGet<GetBetPartialClawbacksResponse>(url, params);
    } catch (err) {
        logger.log('BetsMicroservice', 'getBetPartialClawbacks', err);
        notification.error({ message: 'Failed to get partial clawbacks', description: parseAPIErrorMessage(err) });
        return { result: [], count: 0 } as GetBetPartialClawbacksResponse;
    }
}

export async function isSameMatchComboEligibleFromSets(payload: Record<number, number[]>) {
    try {
        return await httpPost<Record<number, boolean>>(getUrl('validation/is-same-match-combo-eligible-v2'), payload);
    } catch (e) {
        logger.error('BetsMicroservice', 'isSameMatchComboEligibleFromSets', e);
        return {};
    }
}

export interface Bet {
    id?: UUID;
    oddsIdByOutcomeId: OddsIdByOutcomeId;
    stake: number;
    stakeRequest?: number;
    userBonusId?: UUID;
}

export interface FOTranslationsByOutcomeId {
    [outcomeId: number]: FOTranslations;
}

export interface FOTranslations {
    outcomeName: string;
    marketName: string;
    matchName: string;
}

export interface SystemBet {
    bankerOddsIdByOutcomeId?: OddsIdByOutcomeId;
    systemStakes: Partial<Record<SystemBetType, number>>;
    maSystemStakes?: Partial<Record<SystemBetType, number>>;
    oddsIdByOutcomeId: OddsIdByOutcomeId;
}

export interface OddsIdByOutcomeId {
    [outcomeId: number]: string;
}

export interface TicketWithMarketDetails extends TicketReceipt, FixturesData {
    selectionsMeta: SelectionMeta[];
    systemsMeta: {
        [key: string]: {
            betsCount: number;
            stake: number;
        };
    };
    totalOdds: number;
}

export interface SelectionMeta {
    odds: number;
    outcome_id: number;
    isBanker?: boolean;
}

export interface TicketReceipt extends BaseTicket {
    banker_outcome_ids: number[];
    ma_stake_requested: number;
    num_bets_lost: number;
    num_bets_won: number;
    num_bets_pending: number;
    num_bets_total: number;
    num_bets_ma: number;
    total_stake: number;
    potential_return: number;
}

export interface BaseTicket {
    id: UUID;
    transaction_id: string;
    user_id: string;
    display_id: number;
    ticket_type: TicketType;
    currency: string;
    updated_at: Date;
    created_at: Date;
    bets: Bet[];
    ma_bets: Bet[]; // always null for now
}

export interface FixturesData {
    matchesById: MatchesById;
    marketsById: MarketsById;
    outcomesById: OutcomesById;
    outcomeTranslationData?: OutcomeTranslationData[];
}

export interface OutcomeTranslationData {
    outcome_id: number;
    outcome_name: string;
    market_id: number;
    home_team: string;
    away_team: string;
    category_id: number;
    sport_category_id: number;
    region_id: number;
    line: number;
    market_name: string;
    market_type_name: string;
    category_name: string;
    market_type_id: number;
    market_sequence: DatabaseSequence;
    view_type: string;
    match_name: string;
    player_names: string[];
    team_names: string[];
    layout: CategoryConfigurationLayout;
}

export interface MatchesById {
    [matchId: string]: Match;
}

export interface MarketsById {
    [marketId: string]: Market;
}

export interface OutcomesById {
    [outcomeId: string]: Outcome;
}

export interface Match {
    id: number;
    matchStart: Date;
    bettingStart: Date;
    bettingEnd: Date;
    status: MatchStatus;
    expectedResultTime: Date;
    homeTeamName?: string;
    awayTeamName?: string;
    name: string;
    livebetOur: boolean;
    excludedCountries: string[];
    livebetGracePeriod: number;
    leagueCategoryId: number;
    regionCategoryId: number;
    sportCategoryId: number;
    matchType: MatchType;
}

export interface Market {
    id: number;
    status: MarketStatus;
    highRisk: boolean;
    singles_only: boolean;
    match_id: number;
    name: string;
    market_type_id: number;
    line: number;
    view_type: MarketViewType;
    in_play: boolean;
    betting_end: Date;
    excludedCountries: string[];
    sameMatchComboAllowed: boolean;
}

export interface Outcome {
    id: number;
    limitTotal: number;
    limitUser: number;
    name?: string;
    market_id?: number;
    team_id: number;
    excludedCountries: string[];
    result_key: string;
    status: OutcomeStatus;
}

export interface ExpectedPendingBetResolutionSchedule {
    bet_count: number;
    timestamp: Date;
}

export interface PendingBetsParams {
    maxResultTime: Date;
}

export interface ResultRequest {
    ticket_id: string;
    status: ResultRequestStatus;
    created_at?: Date;
    updated_at?: Date;
}

export type ResultRequestStatus = 'PENDING' | 'RESOLVED';

export interface ResultRequestWithDetails extends ResultRequest {
    ticket: TicketList;
}
