import styled from 'styled-components';
import { Layout } from 'antd';
import { color } from '../../style/variables';

export default styled(Layout)`
    .table-warehouse {
        & th:before {
            display: none;
        }

        tbody {
            tr {
                &.background-green > .ant-table-cell {
                    background-color: ${color.green200};
                }

                &.background-red > .ant-table-cell {
                    background-color: ${color.red200};
                }

                & td {
                    border-bottom: none;
                }
            }
        }
    }

    .table-warehouse-bordered {
        tbody {
            tr {
                & td {
                    border-bottom: 1px solid ${color.gray300};
                }
            }
        }
    }

    .table-warehouse-hovered {
        tbody {
            tr {
                &.odd,
                &.even {
                    > .ant-table-cell-row-hover {
                        background-color: ${color.gray300};
                    }
                }

                &.background-green > .ant-table-cell-row-hover {
                    background-color: ${color.green300};
                }

                &.background-red > .ant-table-cell-row-hover {
                    background-color: ${color.red300};
                }
            }
        }
    }

    .secondary-card {
        margin: -1.5rem;
        margin-top: -1.5rem;
    }

    .form-details {
        & .ant-form-item {
            margin-bottom: 0;

            & .ant-form-item-label {
                text-align: left;
            }

            & .ant-form-item-control-input-content {
                display: flex;
                justify-content: end;
            }
        }
    }

    .back-link {
        width: 100%;
        margin-bottom: 2.25rem;
        position: relative;
        background-color: ${color.gray100};
        box-shadow: 0 0 0 1.25rem ${color.gray100};
        & .ant-typography {
            font-weight: normal;
            margin-bottom: 0;
        }
    }

    .divider {
        margin: 0;
    }

    .status-tag {
        font-weight: normal;
        font-size: 0.75rem;
        letter-spacing: 0;
        border: none;
    }

    .no-wrap-text {
        white-space: nowrap;
    }

    .header-select {
        width: 200px;
    }
` as typeof Layout;
