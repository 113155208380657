import { ObjectValues } from './util';

export const WAREHOUSE_ACTIVITY_STATUS = {
    ACTIVE: 'ACTIVE',
    INTERRUPTED: 'INTERRUPTED',
    FAILED: 'FAILED',
    SUCCESS: 'SUCCESS',
} as const;

export type WarehouseActivityStatus = ObjectValues<typeof WAREHOUSE_ACTIVITY_STATUS>;

export const WAREHOUSE_PROCESS_STATUS = {
    HEALTHY: 'HEALTHY',
    REQUIRES_ATTENTION: 'REQUIRES_ATTENTION',
    UNHEALTHY: 'UNHEALTHY',
    UNKNOWN: 'UNKNOWN',
} as const;

export type WarehouseProcessStatus = ObjectValues<typeof WAREHOUSE_PROCESS_STATUS>;

export const WAREHOUSE_EXTRACT_BOOKMARK_ETL_STEP = {
    EXTRACT: 'EXTRACT',
    VALIDATION: 'VALIDATION',
} as const;

export type WarehouseExtractBookmarkEtlStep = ObjectValues<typeof WAREHOUSE_EXTRACT_BOOKMARK_ETL_STEP>;

export interface WarehouseActivity {
    duration: number | null;
    end_time: string | null;
    id: number;
    start_time: string;
    status: WarehouseActivityStatus;
}

export interface WarehouseActivityHistory {
    duration: number;
    end_time: string;
    schema: string;
    start_time: string;
    status: WarehouseActivityStatus;
    table_name: string;
}

export interface WarehouseProcess {
    activity: WarehouseActivity[];
    delay: number;
    enabled: boolean;
    id: number;
    name: string;
    status: WarehouseProcessStatus;
}

export interface WarehouseExtract {
    columns: string[];
    copy_to_wh: boolean;
    created: string;
    created_bookmark: string;
    database: string;
    disabled: boolean;
    exists_in_wh: boolean;
    id: number;
    live_row_count: number | null;
    source_table: string;
    updated_bookmark: string;
    validation_logs: boolean;
    warehouse_table: string;
    wh_row_count: number | null;
}

interface WarehouseExtractColumnLimits {
    character_maximum_length: number;
    numeric_precision: number;
    numeric_scale: number;
}

export interface WarehouseExtractColumn extends WarehouseExtractColumnLimits {
    column_name: string;
    copy_to_wh: boolean;
    created: string;
    disabled: boolean;
    id: number;
    is_confidential: boolean;
    is_gdpr: boolean;
    primary_key: boolean;
    table_mapping_id: number;
    type: string;
    updated: string;
}

export interface WarehouseExtractColumnHistory {
    action: string;
    column_name: string;
    created: string;
    limits: WarehouseExtractColumnLimits;
    primary_key: boolean;
    type: string;
}

export interface WarehouseExtractBookmark {
    bookmark: string;
    etl_step: WarehouseExtractBookmarkEtlStep;
}

export interface WarehouseExtractHistoricalValidationSettings {
    current_interval: string;
    id: number;
    next_validation: string;
    period: string;
    table_mapping_id: number;
    validation_coefficient: number;
}

export interface WarehouseExtractTableHistory {
    action: string;
    created: string;
    id: number;
}

export interface BasicErrorsFilter {
    page: number;
    limit: number;
}

export interface GeneralErrorsFilter extends BasicErrorsFilter {
    etl_step: string;
    level: string;
    warehouse_table: string;
    message: string;
}

export interface ValidationErrorsFilter extends BasicErrorsFilter {
    schema: string;
    table_name: string;
    error: string;
}

export interface RegularValidationErrorsFilter extends BasicErrorsFilter {
    schema: string;
    table_name: string;
    validation_name: string;
}

export interface WarehouseGeneralError {
    created: string;
    etl_step: string;
    handled: boolean;
    id: number;
    level: string;
    message: string;
    warehouse_table: string;
}

export interface WarehouseGeneralErrors {
    data: WarehouseGeneralError[];
    etl_steps: string[];
    levels: string[];
    total_pages: number;
}

export interface WarehouseValidationError {
    error: string;
    rows: number;
    schema: string;
    table_name: string;
}

export interface WarehouseValidationErrors {
    data: WarehouseValidationError[];
    total_pages: number;
}

export interface WarehouseRegularValidationError {
    count: number;
    period_end: string;
    period_start: string;
    row: Object;
    schema: string;
    table_name: string;
    validation_name: string;
}

export interface WarehouseRegularValidationErrors {
    data: WarehouseRegularValidationError[];
    total_pages: number;
}

export interface WarehouseGeneralHandleErrors {
    id__in: number[];
}

export interface WarehouseDelivery {
    created: string;
    enabled: boolean;
    id: number;
    process_id: number;
    schema: string;
    table_name: string;
    updated: string;
    wh_row_count: number | null;
    exists_in_wh: boolean;
}

export interface WarehouseDeliverySettings {
    enabled: boolean;
    id: number;
    name: string;
}

export interface WarehouseDeliveryDetails extends WarehouseDelivery {
    settings: WarehouseDeliverySettings[];
}

export interface WarehouseDeliveryBookmark {
    bookmark: number;
    dependency: string;
    id: number;
    prev_bookmark: number;
    schema: string;
    table_name: string;
}

export type WarehouseDeliveryBookmarkRequest = Pick<WarehouseDeliveryBookmark, 'id' | 'bookmark'>;

export interface WarehouseDeliveryValidationSettings {
    bookmark: number;
    dependency: string;
    id: number;
    period: number;
}

export interface WarehouseDeliveryRegularValidationSettings {
    enabled: boolean;
    id: number;
    next_validation: string;
    period: string;
    validation_interval: string;
    validation_name: string;
}

export interface WarehouseDeliveryRegularValidationSettingsRequest extends WarehouseDeliveryRegularValidationSettings {
    schema: string;
    table_name: string;
}
