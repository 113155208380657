import styled from 'styled-components';
import { Link } from 'react-router-dom';
import hexToRgba from 'hex-to-rgba';
import { color } from '../../../../../../../style/variables';

export default styled(Link)`
    display: block;
    padding: 0 0.5rem;

    &:hover {
        background-color: ${hexToRgba(color.white, 0.08)};
    }
` as typeof Link;
