import { useRouter } from '../../services/router';
import { stringify } from 'query-string';

export function useFilterWithNavigation<T extends Record<keyof T, any>>(defaultFilter: T) {
    const { queryParams, navigateTo } = useRouter();

    const filterWithNavigation = Object.keys(defaultFilter).reduce((acc, key) => {
        const query = queryParams[key];

        if (!query) {
            acc[key] = defaultFilter[key];
        } else {
            acc[key] = convertQuery(query);
        }

        return acc;
    }, {} as T);

    function convertQuery(query: string | string[]) {
        if (query === 'true' || query === 'false') {
            return query === 'true';
        }

        return query;
    }

    function changeFilterWithNavigation(key: keyof T, value: string) {
        const queryParamsWithoutNull = Object.keys(queryParams)
            .filter(item => queryParams[item])
            .reduce((acc, item) => ({ ...acc, [item]: queryParams[item] }), {});

        navigateTo({
            search: stringify({ ...queryParamsWithoutNull, [key]: value }),
        });
    }

    return { filterWithNavigation, changeFilterWithNavigation };
}
