import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { store } from '../stores/store';
import { getStoreValue } from '../stores/utils';
import { logger } from './logger';

export const initSentry = async () => {
    try {
        const { SENTRY_DSN, ROOT_URL, CLIENT_NAME, ENVIRONMENT } = getStoreValue(store.environment);
        const { version } = await fetch('/sentry-version.json').then(response => response.json());
        if (SENTRY_DSN && version) {
            const host = ROOT_URL.startsWith('http') ? new URL(ROOT_URL).host : ROOT_URL;

            Sentry.init({
                environment: `${CLIENT_NAME}-${ENVIRONMENT}`,
                dsn: SENTRY_DSN,
                release: version,
                allowUrls: [`${host}/static/js`],
                integrations: [new Integrations.BrowserTracing()], // When I created the project it recommended turning it on, idk, worth a try
                tracesSampleRate: 0.01,
                beforeSend: (event, hint) => {
                    const error = hint?.originalException as Error;
                    if (error instanceof Error && error.message.match(/Loading chunk \d+ failed/)) {
                        return null;
                    }
                    return event;
                },
            });
            store.sentryVersion.set(version);
        } else {
            logger.info(
                'SentryService',
                'initSentry',
                `Sentry is not configured, SENTRY_DSN: ${SENTRY_DSN}, version: ${version}`,
            );
        }
    } catch (error) {
        logger.error('SentryService', 'initSentry', error);
    }
};
