import React, { useEffect, useState } from 'react';
import Wrapper from './styles';

interface Props {
    options?: any;
    locales?: string;
}

export function Clock({ options, locales }: Props) {
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        const timer = setInterval(() => setDate(new Date()), 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return <Wrapper>{date.toLocaleString(locales, options)}</Wrapper>;
}
