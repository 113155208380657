import type { UserBoTokenInfo } from '@staycool/types';
import merge from 'lodash/merge';
import { renewToken } from 'microservices/auth-bo';
import { stringify } from 'query-string';
import { getStoreValue, store } from 'stores/store';
import { updateCoolbetAuthToken } from './auth';

const RESPONSE_ERROR = {
    MAX_AGE_EXCEEDED: 'maxAge exceeded',
    TOKEN_EXPIRED: 'Token has expired',
    TOKEN_EXPIRED_LOGIN_REQUIRED: 'Token expired, re-login required',
    TOKEN_INVALID: 'Token is invalid',
};

// eslint-disable-next-line rulesdir/no-classes
export class JsonParseError extends Error {}

export const getSportsUrl = (url: string) => getServiceUrl('sports-internal', url);

interface FetchOptions {
    autoHeaders?: boolean;
}

export async function httpGet<T = any>(
    url,
    params?,
    { autoHeaders = true, ...parseOptions }: FetchOptions & ParseOptions = {},
) {
    url = params ? `${url}?${stringify(params)}` : url;
    return handleRequest(
        () =>
            fetch(url, {
                method: 'GET',
                headers: autoHeaders ? getHeaders() : {},
            }),
        parseOptions,
    ) as Promise<T>;
}

export const jsonGet: typeof httpGet = (url, params, opts) =>
    httpGet(url, params, { throwIfNotJSONResponse: true, ...opts });

export async function httpPost<T = any>(url, data = {}, config?, isBlob = false, isReturningHeaders = false) {
    return handleRequest(
        () =>
            fetch(
                url,
                extendConfig(
                    {
                        method: 'POST',
                        headers: getHeaders(),
                        body: JSON.stringify(data),
                    },
                    config,
                ),
            ),
        { isBlob },
        isReturningHeaders,
    ) as Promise<T>;
}

export async function httpPatch<T = any>(url, data = {}, config?) {
    return handleRequest(() =>
        fetch(
            url,
            extendConfig(
                {
                    method: 'PATCH',
                    headers: getHeaders(),
                    body: JSON.stringify(data),
                },
                config,
            ),
        ),
    ) as Promise<T>;
}

export async function httpPostFile<T = any>(url, data = {}, config?) {
    return handleRequest(() =>
        fetch(
            url,
            extendConfig(
                {
                    method: 'POST',
                    headers: getHeaders({ withoutContentType: true }),
                    body: data,
                },
                config,
            ),
        ),
    ) as Promise<T>;
}

export async function httpPutFile<T = any>(url, data = {}, config?) {
    return handleRequest(() =>
        fetch(
            url,
            extendConfig(
                {
                    method: 'PUT',
                    headers: getHeaders({ withoutContentType: true }),
                    body: data,
                },
                config,
            ),
        ),
    ) as Promise<T>;
}

export async function httpPut<T = any>(url, data = {}) {
    return handleRequest(() =>
        fetch(url, {
            method: 'PUT',
            headers: getHeaders(),
            body: JSON.stringify(data),
        }),
    ) as Promise<T>;
}

export async function httpDelete<T = any>(url, data?, config?) {
    return handleRequest(() =>
        fetch(
            url,
            extendConfig(
                {
                    method: 'DELETE',
                    headers: getHeaders(),
                    body: JSON.stringify(data),
                },
                config,
            ),
        ),
    ) as Promise<T>;
}

function extendConfig(config, extraConfig?: any) {
    extraConfig = extraConfig || {};

    if (extraConfig.headers) {
        config.headers = merge(config.headers, extraConfig.headers);
    }

    return config;
}

interface ParseOptions {
    throwIfNotJSONResponse?: boolean;
    isBlob?: boolean;
}

async function handleRequest(requestFactory, parseOptions = {} as ParseOptions, isReturningHeaders = false) {
    try {
        const response = await requestFactory();
        const headers = response.clone().headers;
        const parsedResponse = await parseResponse(response, parseOptions);
        if (isReturningHeaders) {
            return { response: parsedResponse, headers };
        }
        return parsedResponse;
    } catch (error) {
        if ([RESPONSE_ERROR.TOKEN_INVALID, RESPONSE_ERROR.TOKEN_EXPIRED_LOGIN_REQUIRED].includes(error.message)) {
            // window.location.reload();
        }

        if (isTokenExpiredResponse(error)) {
            await updateCoolbetAuthToken();
            return parseResponse(await requestFactory());
        }

        throw error;
    }
}

async function parseResponse(response: any, options = {} as ParseOptions) {
    if (response.status < 200 || response.status >= 300) {
        const parsedBody = await parseBodyFromResponse(response, options);
        const errorMessage = `Request to ${response.url} failed with ${response.status}`;
        if (typeof parsedBody !== 'object') {
            throw new Error(`${errorMessage}: ${parsedBody}`);
        }
        if (Array.isArray(parsedBody)) {
            throw new Error(`${errorMessage}: ${JSON.stringify(parsedBody)}`); // I don't think this will happen but better safe than sorry
        }
        const error = new Error(`${errorMessage}: ${JSON.stringify(parsedBody)}`);
        Object.assign(error, parsedBody);
        throw error;
    }
    return parseBodyFromResponse(response, options);
}

function isTokenExpiredResponse(response) {
    const isMaxAgeExceededError =
        response.title === 'Unauthorized' && response.description === RESPONSE_ERROR.MAX_AGE_EXCEEDED;
    const isUnauthorizedTokenHasExpiredResponse =
        response.title === 'Unauthorized' && response.description === RESPONSE_ERROR.TOKEN_EXPIRED;
    const isTokenHasExpiredResponse = response.message === RESPONSE_ERROR.TOKEN_EXPIRED;
    return isUnauthorizedTokenHasExpiredResponse || isTokenHasExpiredResponse || isMaxAgeExceededError;
}

async function parseBodyFromResponse(response, { throwIfNotJSONResponse, isBlob }: ParseOptions) {
    try {
        return isBlob ? await response.clone().blob() : await response.clone().json();
    } catch (error) {
        const text = await response.text();
        if (throwIfNotJSONResponse) {
            throw new JsonParseError(`Not JSON response: ${(text || '').slice(0, 50)}`);
        }
        return text;
    }
}

function parseJoiValidationErrorMessage(err) {
    return err?.meta?.details?.[0]?.message;
}

export function parseAPIErrorMessage(error) {
    const err = error.data || error;
    return (
        parseJoiValidationErrorMessage(err) ||
        err?.meta?.message ||
        `${err.description || ''} ${JSON.stringify(err.meta) || ''}`.trim() ||
        err.message
    );
}

function getHeaders(options: any = {}) {
    const tokenCbAuth = getStoreValue(store.tokenCbAuth);
    const tokenAuth = getStoreValue(store.tokenAuth);
    const deviceType = getStoreValue(store.deviceType);
    const applicationType = getStoreValue(store.applicationType);
    const headers: HeadersInit = {};

    if (!options.withoutContentType) {
        headers['Content-Type'] = 'application/json; charset=utf-8';
    }

    if (deviceType) {
        headers['X-Device'] = deviceType;
    }

    if (applicationType) {
        headers['X-App'] = applicationType;
    }

    if (tokenAuth) {
        headers.authorization = `Bearer ${tokenAuth}`;
    }

    if (tokenCbAuth) {
        headers.cbauth = `Bearer ${tokenCbAuth}`;
        const { sub, login_session_id } = getCbTokenInfo(tokenCbAuth);
        headers.User_Id = `${sub}`;
        headers.Login_Session_Id = `${login_session_id}`;
    }

    return headers;
}

function getCbTokenInfo(token: string): Partial<UserBoTokenInfo & { login_session_id: string }> {
    try {
        const [, infoString] = token.split('.');
        return JSON.parse(atob(infoString));
    } catch {
        return {};
    }
}

export function getServiceUrl(serviceName: string, url: string) {
    const serviceUrl = `/s/${serviceName}/${url}`;

    if (process.env.REACT_APP_CI) {
        return getProxyUrl(serviceUrl);
    }

    return serviceUrl;
}

export const retailMiddlewareUrl = 'http://localhost:3000';

export function getMiddlewareUrl(url) {
    return `${retailMiddlewareUrl}/${url}`;
}

export function getProxyUrl(url = '') {
    return `http://localhost:7001${url}`;
}

export function getExternalUrl(host, query) {
    return `${host}/${query}`;
}
export function getDevelopmentUrl() {
    return 'http://localhost:8081';
}

export async function httpPostBinaryFile(url: string, body = {}): Promise<Blob> {
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(body),
        });
        return response.blob();
    } catch (error) {
        if (isTokenExpiredResponse(error)) {
            await updateCoolbetAuthToken();
            return httpPostBinaryFile(url, body);
        }
        throw error;
    }
}

export async function httpGetBinaryFile(url: string, internal = true): Promise<Blob> {
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: internal ? getHeaders() : {},
        });
        return response.blob();
    } catch (error) {
        if (isTokenExpiredResponse(error)) {
            await renewToken();
            return httpGetBinaryFile(url);
        }
        throw error;
    }
}
