import { Space, Typography } from 'antd';
import { ReactNode } from 'react';
import { useTabsWithNavigation } from '../../hooks/navigation/useTabsWithNavigation';
import { ROUTES } from '../../routes';
import { getRoute } from '../../services/router';
import { WarehouseDeliveryErrorsPage } from './delivery/errors/WarehouseDeliveryErrorsPage';
import { WarehouseDeliveryPage } from './delivery/WarehouseDeliveryPage';
import { WarehouseExtractErrorsPage } from './extract/errors/WarehouseExtractErrorsPage';
import { WarehouseExtractPage } from './extract/WarehouseExtractPage';
import { WarehouseStatusPage } from './status/WarehouseStatusPage';
import Wrapper from './styles';
import { UiCard } from '../../components/ui/card/UiCard';

const { Title } = Typography;

export function WarehousePage() {
    const warehouseTabsContent: Record<string, { name: string; component: ReactNode }> = {
        [getRoute(ROUTES.warehouse.status)]: { name: 'Status', component: <WarehouseStatusPage /> },
        [getRoute(ROUTES.warehouse.extract)]: { name: 'Extract', component: <WarehouseExtractPage /> },
        [getRoute(ROUTES.warehouse.delivery)]: { name: 'Delivery', component: <WarehouseDeliveryPage /> },
        [getRoute(ROUTES.warehouse.extract.errors)]: {
            name: 'Extract errors',
            component: <WarehouseExtractErrorsPage />,
        },
        [getRoute(ROUTES.warehouse.delivery.errors)]: {
            name: 'Delivery errors',
            component: <WarehouseDeliveryErrorsPage />,
        },
    };

    const { activeTab: activeWarehouseTab, changeActiveTab: changeActiveWarehouseTab } = useTabsWithNavigation({
        isTabFromUrl: true,
        tabs: Object.keys(warehouseTabsContent),
    });

    return (
        <Wrapper>
            <Space className="back-link" size={16} align="center">
                <Title level={5}>Data warehouse</Title>
            </Space>
            <UiCard
                tabList={Object.entries(warehouseTabsContent).map(([key, value]) => ({ key, tab: value.name }))}
                activeTabKey={activeWarehouseTab}
                onTabChange={changeActiveWarehouseTab}
                tabProps={{ size: 'middle' }}
            >
                {warehouseTabsContent[activeWarehouseTab].component}
            </UiCard>
        </Wrapper>
    );
}
