import { FolderCategory, FolderTree } from 'types/folder';
import { DuplicatedSegmentError, Segment, SegmentFilterType } from '../types/segment';
import { createStores, Store } from './utils';

interface Folders {
    expandedKeys: Record<FolderCategory, Store<number[]>>;
    folderTree: FolderTree[];
    selectedFolderKey: number | undefined;
}

export const marketing = createStores({
    segments: createStores({
        segmentFilterTypes: [] as SegmentFilterType[],
        usedFilterTypeNames: [] as string[],
        segment: {} as Segment,
        isSegmentUsersUpdateInProgress: false,
        isLoadingSegment: false,
        duplicationError: undefined as DuplicatedSegmentError | undefined,
    }),
    folders: createStores<Folders>({
        expandedKeys: createStores({
            article: [] as number[],
            segment: [] as number[],
            onsiteMessage: [] as number[],
            mediaGallery: [] as number[],
        }),
        folderTree: [] as FolderTree[],
        selectedFolderKey: undefined,
    }),
});
