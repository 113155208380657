import { DatabaseAggregatedVoidTicket } from '@staycool/bets-types/dist/types/manual-ticket-void-acceptance';
import { ManualAcceptanceAggregateFilter } from '@staycool/bets-types/manual-acceptance';
import { getServiceUrl, httpPost } from 'services/api';

export const getUrl = url => getServiceUrl('bets', url);

export function getVoidTickets(filter: ManualAcceptanceAggregateFilter) {
    const url = getUrl('manual-acceptance/void/aggregate/list');
    return httpPost<{ void_ticket_aggregates: DatabaseAggregatedVoidTicket[]; all_sources: string[] }>(url, filter);
}
