import { getStoreValue, store } from './../../stores/store';
import { DatabaseOddsPair } from '@staycool/sports-types';
import { OddsLine } from 'microservices/sports/odds-lines';
import isNumber from 'lodash/isNumber';
import round from 'lodash/round';
import { ruleBasedOddsRounding } from '@staycool/odds-rounding';
import { OutcomeWithOdds } from 'services/market';
import { Odds } from '../../microservices/sports-decider';

export const IS_AMERICAN_ODDS_FORMAT = 'isOddsFormatAmerican';

export function convertDecimalToAmerican(odds: string, isReadOnly = true) {
    const parsedOdds = parseFloat(odds) || 1;
    if (parsedOdds === 1) {
        return isReadOnly ? '-' : '1';
    }
    if (parsedOdds >= 2) {
        return `+${Math.round((parsedOdds - 1) * 100)}`;
    }
    return `${Math.round(-100 / (parsedOdds - 1))}`;
}

export function convertAmericanToDecimal(odds: string) {
    const parsedOdds = parseFloat(odds) || 1;
    return parsedOdds > 0 ? (parsedOdds === 1 ? parsedOdds : parsedOdds / 100 + 1) : 1 - 100 / parsedOdds;
}

const calculateMarginKey = (allOdds: number[]) => allOdds.reduce((acc, odds) => acc + 1 / odds, 0);

export const adjustOddsByRules = (
    odds: { outcome_id: number; value: number }[],
    rulesByOutcomeIds: Record<number, number>,
    targetMarginKey: number,
) => {
    const MAX_CORRECTION_ITERATIONS = 10;
    const MIN_MARGIN_KEY = 1.0001;
    const MARGIN_KEY_INCREMENTOR = 0.0001;

    const adjustedOdds = {} as Record<number, number>;
    let i = 0;

    while (i < MAX_CORRECTION_ITERATIONS) {
        let sumOfProbability = 0;
        let sumOfUnruledProbabilities = 0;
        const outcomeProbabilities = odds.map(outcome => {
            const outcomeRule = rulesByOutcomeIds[outcome.outcome_id] ?? null;
            const valueWithRule = outcome.value + outcomeRule;
            const outcomeProbability = 1 / valueWithRule;
            sumOfProbability += outcomeProbability;
            sumOfUnruledProbabilities += !isNumber(outcomeRule) ? outcomeProbability : 0;
            return {
                outcomeId: outcome.outcome_id,
                probability: outcomeProbability,
                rule_value: outcomeRule,
            };
        });
        const probabilityOffset = targetMarginKey - sumOfProbability;
        outcomeProbabilities.forEach(({ outcomeId, probability, rule_value }) => {
            const hasRule = isNumber(rule_value);
            const distributedProbability = hasRule ? 0 : (probability / sumOfUnruledProbabilities) * probabilityOffset;
            const adjustedProbability = distributedProbability + probability;
            const odds = Math.max(1, round(1 / adjustedProbability, 2));
            adjustedOdds[outcomeId] = hasRule ? odds : ruleBasedOddsRounding(odds);
        });
        const newMargin = calculateMarginKey(Object.values(adjustedOdds));
        if (newMargin >= MIN_MARGIN_KEY) {
            break;
        }
        targetMarginKey += MARGIN_KEY_INCREMENTOR;
        i += 1;
    }

    return adjustedOdds;
};

export function handlePairsChange(oddsLineId: number | null) {
    const emptyLine = { pairs: [] as DatabaseOddsPair[] } as OddsLine;
    if (!oddsLineId) {
        return emptyLine;
    }
    const allLines = getStoreValue(store.sportsbook.matchView.oddsLines);
    const lineWithPairs = allLines.find(({ id }) => id === oddsLineId);
    return lineWithPairs || emptyLine;
}

export const negativeAmericanOddsLimit = -101;
export const positiveAmericanOddsLimit = 100;

export const isAmericanOddsValueNotMeetRequirements = (value: number) =>
    value > negativeAmericanOddsLimit && positiveAmericanOddsLimit > value && value !== 1;

export function getForcedAmericanOdds(oddsValue: number) {
    if (oddsValue === negativeAmericanOddsLimit + 1) {
        return positiveAmericanOddsLimit;
    } else if (oddsValue === positiveAmericanOddsLimit - 1) {
        return negativeAmericanOddsLimit;
    }
    return oddsValue;
}

export function getOddsDeciderPayload(odds: OutcomeWithOdds['odds']) {
    return {
        match_id: odds.match_id,
        market_id: odds.market_id,
        outcome_id: odds.outcome_id,
        value: odds.value,
        status: odds.status,
    } as Odds;
}

export function adjustDecimalOddsPrecision(oddsValue: number) {
    return round(convertAmericanToDecimal(oddsValue.toString()), 6);
}
