import { mediaQuery } from 'services/media';
import styled from 'styled-components';

export default styled.div`
    display: inline-flex;
    min-width: 5rem;

    .img-logo {
        margin: auto 1rem auto 5.7rem;
    }

    .coolbet-logo {
        max-width: 9rem;
    }

    .wynnbet-logo {
        max-width: 9rem;
    }

    .ivc-logo {
        max-width: 6.5rem;
    }

    @media ${mediaQuery.isDesktopSmall} {
        .img-logo {
            left: ${({ isCategoryTreeOpen }) => (isCategoryTreeOpen ? '18.85rem' : '5.7rem')};
        }

        .coolbet-logo {
            top: 1.6rem;
            width: 7rem;
        }
    }
`;
