import { Tooltip } from 'antd';
import classNames from 'classnames';
import { UiButtonProps } from 'components/ui/button/types';
import React from 'react';
import { filterStyleProps } from 'services/util';
import Wrapper from './styles';
import { BaseButtonProps } from 'antd/es/button/button';

export function UiButton(props: UiButtonProps) {
    const oldCustomTypesToProps: Partial<Record<Exclude<UiButtonProps['type'], undefined>, Partial<BaseButtonProps>>> =
        {
            danger: { type: 'default', color: 'danger' },
            success: { type: 'primary' },
            warning: { type: 'primary' },
        };

    const defaultProps: UiButtonProps = {
        type: 'primary',
        size: 'small',
        ...props,
        ...(oldCustomTypesToProps[props.type || ''] || {}),
    };
    const { btnName, tooltip, title, className, ...btnProps } = defaultProps;
    let { icon: Icon } = defaultProps;

    if (React.isValidElement(Icon)) {
        Icon = React.cloneElement(Icon as React.ReactElement, { className: Icon.props.className + ' text-sm' });
    }

    if (title || tooltip) {
        return (
            <Tooltip {...{ title, ...tooltip }}>
                <Wrapper
                    {...filterStyleProps(btnProps)}
                    className={classNames(className, {
                        'green-btn': props.type === 'success',
                        'red-btn': props.type === 'danger',
                        'yellow-btn': props.type === 'warning',
                    })}
                    icon={Icon}
                >
                    {props.btnName}
                </Wrapper>
            </Tooltip>
        );
    }

    return (
        <Wrapper
            {...filterStyleProps(btnProps)}
            className={classNames(className, {
                'green-btn': props.type === 'success',
                'red-btn': props.type === 'danger',
                'yellow-btn': props.type === 'warning',
            })}
            icon={Icon}
        >
            {props.btnName}
        </Wrapper>
    );
}
