import { getServiceUrl, httpPost, httpGet, httpPut, httpDelete } from 'services/api';
import { CmsType } from 'types/cms';

export const getUrl = url => getServiceUrl('digital-board', url);

export function createOddsView(oddsView: Partial<OddsView>) {
    const url = getUrl('odds-view');
    return httpPost<OddsView>(url, oddsView);
}

export function getFilteredOddsViews(filter?: { whereIn?: { field: string; values: string[] | number[] }[] }) {
    const url = getUrl('odds-view/list');
    return httpPost<ListOddsViewsResponse>(url, filter);
}

export async function getOddsViewById(id: number) {
    const url = getUrl(`odds-view/${id}`);
    return httpGet<OddsView>(url);
}

export function updateOddsView(id: number, oddsView: Partial<OddsView>) {
    const url = getUrl(`odds-view/${id}`);
    return httpPut<OddsView>(url, oddsView);
}

export function getBoardById(id: number) {
    const url = getUrl(`board/${id}`);
    return httpGet<Board>(url);
}

export function updateBoard(id: number, board: Partial<Board>) {
    const url = getUrl(`board/${id}`);
    return httpPut<Board>(url, board);
}

export async function deleteBoardOrView(id: number, type: CmsType) {
    const url = getUrl(type === CmsType.DigitalBoards ? `board/${id}` : `odds-view/${id}`);
    return httpDelete(url);
}

export function createBoard(board: Partial<Board>) {
    const url = getUrl('board');
    return httpPost<Board>(url, board);
}

export function getAllBoards() {
    const url = getUrl('board/list');
    return httpPost<ListBoardsResponse>(url);
}

export interface OddsView {
    id: number;
    title: string;
    sportName: string;
    leagueName: string;
    matchIds: number[];
    marketTypeIds: number[];
    createdAt: string;
    updatedAt: Date;
    sendToThirdParty: boolean;
    sportIcon?: OddsBoardSportIcon;
    isTurnoverEnabled: boolean;
    pageDurationInSeconds: number;
    displayLiveMatch: boolean;
    updatedBy: number;
    leagueId: number;
    futuresEnabled?: boolean;
    template: OddsBoardTemplate;
}

export interface Board {
    id: number;
    name: string;
    views: BoardView[];
    createdAt: Date;
    updatedAt: Date;
}
export interface BoardView {
    id: number;
    type: ViewType;
}

export interface LeagueView extends BoardView {
    position: number;
}

export enum ViewType {
    OddsView = 'Odds View',
}

export interface ListOddsViewsResponse {
    items: OddsView[];
    total: number;
}

export interface ListBoardsResponse {
    items: Board[];
    total: number;
}

export interface OddsBoardContent {
    title: string;
    sportName: string;
    leagueName: string;
    sportIcon?: OddsBoardSportIcon;
    pageDurationInSeconds: number;
    sendToThirdParty: boolean;
    excludedMatchIds?: number[];
    marketTypeIds?: number[];
    isTurnoverEnabled?: boolean;
    displayLiveMatch: boolean;
    leagueId?: number;
    template: OddsBoardTemplate;
    futuresEnabled?: boolean;
    futuresMatchId?: number;
}

export enum OddsBoardSportIcon {
    NoTheme = 'No Theme',
    Baseball = 'Baseball',
    Basketball = 'Basketball',
    Boxing = 'Boxing',
    Football = 'Football',
    Hockey = 'Hockey',
}

export enum OddsBoardTemplate {
    Single = 'Single-Column',
    Double = 'Two-Column',
    Triple = 'Three-Column',
    SingleColumnPortrait = 'Single-Column-Portrait',
}

export enum ViewMode {
    CreateNew = 'Create New',
    List = 'List',
    Edit = 'Edit',
}
