import React from 'react';
import { SportsbookCategoryAndMatchSearch } from '../SportsbookCategoryAndMatchSearch';
import { UiModal } from 'components/ui/modal/UiModal';
import { store, useStore } from 'stores/store';

export function SportsbookCategoryAndMatchSearchModal() {
    const [isCategoryAndMatchSearchModalVisible, setIsCategoryAndMatchSearchModalVisible] = useStore(
        store.sportsbook.isCategoryAndMatchSearchModalVisible,
    );

    function handleCancel() {
        setIsCategoryAndMatchSearchModalVisible(false);
    }

    return (
        <UiModal
            title="Search category or match by name or id"
            open={isCategoryAndMatchSearchModalVisible}
            footer={null}
            onCancel={handleCancel}
        >
            <SportsbookCategoryAndMatchSearch closeModal={handleCancel} />
        </UiModal>
    );
}
