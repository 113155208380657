import { CategoryNames } from '@staycool/sports-types/dist/features/category/types';
import { ROUTES } from 'routes';
import { getRoute } from './router';

export const IS_DISPLAY_NOT_ACTIVE_ENABLED_KEY = 'isDisplayNotActiveEnabled';

export function displayCategoryPath(category: CategoryNames) {
    const sportName = category.sport_name && category.sport_name !== category.name ? `${category.sport_name} > ` : '';
    const parentName =
        category.sport_name && category.sport_name !== category.name && category.sport_name !== category.parent_name
            ? `${category.parent_name} > `
            : '';
    return sportName + parentName + category.name;
}

export function getCategoryUrl(categoryId: number | number[], matchId?: number | number[]) {
    const categoryIds = Array.isArray(categoryId) ? categoryId : [categoryId];
    const matchIds = Array.isArray(matchId) ? matchId : [matchId];

    let categoryUrl = getRoute(ROUTES.sportsbook.categories);
    if (categoryId && categoryIds.length === 1) {
        categoryUrl += `/${categoryIds[0]}/matches`;
    }
    if (matchId && matchIds.length === 1) {
        categoryUrl += `/${matchIds[0]}`;
    }
    return categoryUrl;
}
