import {
    DatabaseMatch,
    DatabaseMatchSettings,
    DatabaseMatchTranslation,
    MarketProvider,
    MatchStatus,
} from '@staycool/sports-types';
import { getSportsUrl, httpGet, httpPatch, httpPost, httpPut, parseAPIErrorMessage } from 'services/api';
import {
    BoFullMatchData,
    BoMatch,
    MatchFilter,
    MatchInfo,
    MatchInfoWithLeagueAndTeamNames,
    UpdateMarketsOddsSource,
    UpdateMatch,
} from '@staycool/sports-types/match';
import { logger } from 'services/logger';
import { message, notification } from 'antd';
import { ReportsMatchesFilter, ReportsMatchesResponse } from '@staycool/sports-types/search';
import { Dayjs } from 'services/dayjs';
import { ReportsSuspendedRisksResponse } from '@staycool/sports-types/market';
import { UNIQUE_VIOLATION_ERROR_CODE } from '../../services/match';
import { BoMatchSearchQuery } from '../../types/match';

export function searchMatch({ search, sportId, statuses, matchId }: BoMatchSearchQuery) {
    const url = getSportsUrl('search/bo-match');
    return httpGet<SearchMatchByNameBo[]>(url, { search, sports: sportId, statuses: statuses?.join(','), matchId });
}

export async function getMatchesByIds(ids: number[]) {
    if (!ids?.length) {
        return [];
    }
    const url = getSportsUrl(`match/`);
    return httpGet<SearchMatchByNameBo[]>(url, { id__in: ids.join(',') });
}

export function getMatches(filter: MatchFilter) {
    const url = getSportsUrl('match/search');
    return httpPost<MatchInfo[]>(url, filter);
}

export function createMatch(match: Partial<DatabaseMatch>) {
    const url = getSportsUrl('match');
    return httpPost<DatabaseMatch>(url, match);
}

export async function updateMatch(matchId: number, matchData: Partial<UpdateMatch>) {
    try {
        const url = getSportsUrl(`match/${matchId}/manual`);
        const result = await httpPatch<UpdateMatch>(url, matchData);
        message.success('Match updated');
        return result;
    } catch (error) {
        logger.log('SportsMatchMicroservice', 'updateMatch', error);
        if (error.code === UNIQUE_VIOLATION_ERROR_CODE) {
            throw error;
        }
        notification.error({ message: 'Failed to update match', description: parseAPIErrorMessage(error) });
    }
}

export async function updateMatchMarketProvider(
    matchId: number,
    provider: MarketProvider | undefined,
    inplay: boolean,
) {
    const url = getSportsUrl(`match/${matchId}/match-market-provider`);
    await httpPut(url, { provider, inplay });
}

export async function updateFeaturedCountries(matchId: number, featured_countries: string[]) {
    try {
        const url = getSportsUrl(`match/${matchId}/featured-countries`);
        const result = await httpPost(url, { featured_countries });
        message.success('Match updated');
        return result;
    } catch (error) {
        logger.log('SportsMatchMicroservice', 'updateFeaturedCountries', error);
        notification.error({ message: 'Failed to update match', description: parseAPIErrorMessage(error) });
    }
}

export function cancelMatch(matchId: number, cancelReason: string, cancelAll: boolean) {
    const url = getSportsUrl('match/cancel');
    return httpPost<DatabaseMatch>(url, {
        match_id: matchId,
        cancel_reason_id: cancelReason,
        cancel_all: cancelAll,
    });
}

export function unCancelMatch(matchId: number) {
    const url = getSportsUrl(`match/${matchId}/uncancel`);
    return httpPost<DatabaseMatch>(url);
}

export function cancelLiveMarketsForMatch(matchId: number, cancelReason: string) {
    const url = getSportsUrl('match/cancel-live-markets');
    return httpPost<number>(url, {
        match_id: matchId,
        cancel_reason_id: cancelReason,
    });
}

export async function getMatch(matchId: number) {
    try {
        const url = getSportsUrl(`match/${matchId}`);
        return await httpGet<MatchInfoWithLeagueAndTeamNames>(url);
    } catch (error) {
        logger.log('SportsMatchMicroservice', 'getMatch', error);
        notification.error({ message: 'Failed to get match data', description: parseAPIErrorMessage(error) });
    }
}

export async function getBoMatch(matchId: number) {
    try {
        const url = getSportsUrl(`match/bo/${matchId}`);
        return await httpGet<BoMatch>(url);
    } catch (error) {
        logger.log('SportsMatchMicroservice', 'getBoMatch', error);
        notification.error({ message: 'Failed to get match data', description: parseAPIErrorMessage(error) });
    }
}

export async function getBoMatchFull(matchId: number) {
    try {
        const url = getSportsUrl(`match/bo/full/${matchId}`);
        return await httpGet<BoFullMatchData>(url);
    } catch (error) {
        logger.log('SportsMatchMicroservice', 'getBoMatchFull', error);
        notification.error({ message: 'Failed to get match data', description: parseAPIErrorMessage(error) });
    }
}

export async function saveMatchSettings(matchId: number, settings: Partial<DatabaseMatchSettings>) {
    try {
        const url = getSportsUrl(`match-settings/${matchId}`);
        const result = await httpPost<number>(url, settings);
        message.success('Match settings updated');
        return result;
    } catch (error) {
        logger.log('SportsMatchMicroservice', 'saveMatchSettings', error);
        notification.error({ message: 'Failed to save match settings', description: parseAPIErrorMessage(error) });
    }
}

export async function overrideCoreResulting(matchId: number, override_core_resulting: boolean) {
    try {
        const url = getSportsUrl(`match-settings/${matchId}`);
        const result = await httpPut<number>(url, { override_core_resulting });
        message.success('Core resulting override settings updated');
        return result;
    } catch (error) {
        logger.log('SportsMatchMicroservice', 'overrideCoreResulting', error);
        notification.error({
            message: 'Failed to override core resulting value',
            description: parseAPIErrorMessage(error),
        });
    }
}

export async function updateMatchBetbuilderExcludedCountries(matchId: number, excludedCountries: string[]) {
    const url = getSportsUrl(`match/${matchId}/betbuilder-exclude-countries`);
    await httpPost<string>(url, { betbuilder_excluded_countries: excludedCountries });
}

export function upsertMatchTranslations(translations: Partial<DatabaseMatchTranslation>[]) {
    if (!translations?.length) {
        return [];
    }
    const url = getSportsUrl('translations/match');
    return httpPost<DatabaseMatchTranslation[]>(url, translations);
}

export async function recalculateAndSaveSidebets(matchId: number) {
    try {
        const url = getSportsUrl(`match/recalculate-and-save-sidebets/${matchId}`);
        const result = await httpPost(url);
        message.success('Odds recalculated and saved');

        return result;
    } catch (error) {
        logger.log('SportsMatchMicroservice', 'recalculateAndSaveSidebets', error);
        notification.error({
            message: 'Failed to recalculate and save odds',
            description: parseAPIErrorMessage(error),
        });
    }
}

export async function overrideResponsibleBookmakersMatch(
    matchId: number,
    data: {
        responsible_bookmakers: number[];
        market_type_group_ids: number[];
    },
) {
    const url = getSportsUrl(`match/${matchId}/override-responsible-bookmakers`);
    return await httpPut<MatchMarketCount>(url, data);
}

export async function updateMarketsStatusesMatch(
    matchId: number,
    data: {
        status: string;
        provider_name?: string;
        market_type_group_id?: number;
    },
) {
    const url = getSportsUrl(`match/${matchId}/update-markets-status`);
    return await httpPut<MatchMarketCount>(url, data);
}

export async function updateMarketsOddsSourceByMatchId(matchId: number, data: UpdateMarketsOddsSource) {
    const url = getSportsUrl(`match/${matchId}/update-markets-odds-source`);
    return await httpPut<MatchMarketCount>(url, data);
}

export function getReportsMatches(filter: ReportsMatchesFilter) {
    const url = getSportsUrl('search/reports-matches');
    return httpPost<ReportsMatchesResponse>(url, filter);
}

export function getReportSuspendedRisksMarketMatches(filter: Omit<ReportsMatchesFilter, 'statuses'>) {
    const url = getSportsUrl('market/reports-suspended-risks');
    return httpGet<ReportsSuspendedRisksResponse>(url, filter);
}

export async function toggleMatch(data: { action: 'open' | 'suspend'; matchId: number }) {
    const url = getSportsUrl('market/match-live-markets');
    return await httpPost<void>(url, data);
}

export type SearchMatchByNameBo = DatabaseMatch & {
    leagueName: string;
    countryName: string;
    sportName: string;
    swappedName?: string;
    awayTeamName?: string;
    homeTeamName?: string;
};

export interface MatchMarketCount {
    initialMarketCount: number;
    updatedMarketCount: number;
    isPartial: boolean;
}

export interface CreateMatchForm {
    name: string;
    homeTeamId: number;
    awayTeamId: number;
    matchStart: Dayjs;
    bettingStart: Dayjs;
    bettingEnd: Dayjs;
    expectedResultTime: Dayjs;
}

export enum MatchCreateType {
    Create = 'create',
    CreateAndClone = 'create and clone',
}

export interface MatchStatusUpdate {
    id: number;
    status: MatchStatus;
}
