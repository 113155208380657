import { Col, Row, Tag } from 'antd';
import { getPrettyEnumValue } from '../../../../../../services/util';
import { backgroundTagColorByProcessStatus } from '../../../../../../services/warehouse-management';
import { WarehouseProcessStatus } from '../../../../../../types/warehouse-management';

interface Props {
    status: WarehouseProcessStatus;
    name: string;
}

export function WarehouseStatusListCardTitle({ status, name }: Props) {
    return (
        <Row justify="space-between">
            <Col>{name}</Col>
            <Col>
                <Tag className="status-tag" color={backgroundTagColorByProcessStatus[status]}>
                    {getPrettyEnumValue(status)}
                </Tag>
            </Col>
        </Row>
    );
}
