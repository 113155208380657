import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import Wrapper from './styles';

export function UiSelectorSearch({ option }: { option: string }) {
    return (
        <Wrapper>
            <div className="left">{option}</div>
            <div className="right">
                <SearchOutlined />
            </div>
        </Wrapper>
    );
}
