import { CardProps } from 'antd';
import Wrapper from './styles';
import { filterStyleProps, omit } from '../../../services/util';

export function UiCard({ onTabChange, tabList, ...rest }: Omit<CardProps, 'bordered'>) {
    //Newer versions of antd can accept children as a part of tabList elements, but renders them within header, which is not expected here, enforce prior usage by ommitting.
    const cardProps = {
        ...rest,
        tabList: tabList?.map(tab => omit(tab, ['children'])),
    };

    return <Wrapper bordered={false} onTabChange={onTabChange} {...filterStyleProps(cardProps)} />;
}
