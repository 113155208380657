import { Country, CountryByMarket, Market } from '@staycool/location';
import uniq from 'lodash/uniq';
import { store } from '../stores/store';
import { getStoreValue } from '../stores/utils';

export function getCoreMarkets() {
    const { AVAILABLE_MARKETS } = getStoreValue(store.environment);
    return AVAILABLE_MARKETS || [];
}

export function getCoreCountries() {
    const markets = getCoreMarkets();
    const countries = markets.map(market => CountryByMarket[market]);
    return uniq(countries);
}

export const COUNTRY_FLAG = {
    [Country.CANADA]: {
        png: '/assets/images/flags/png/canada-ca.png',
        svg: '/assets/images/flags/svg/canada-ca.svg',
    },
    [Country.CHILE]: {
        png: '/assets/images/flags/png/chile-cl.png',
        svg: '/assets/images/flags/svg/chile-cl.svg',
    },
    EN: {
        png: '/assets/images/flags/png/united-kingdom-uk.png',
        svg: '/assets/images/flags/svg/united-kingdom-uk.svg',
    },
    [Country.ESTONIA]: {
        png: '/assets/images/flags/png/estonia-ee.png',
        svg: '/assets/images/flags/svg/estonia-ee.svg',
    },
    [Country.FINLAND]: {
        png: '/assets/images/flags/png/finland-fi.png',
        svg: '/assets/images/flags/svg/finland-fi.svg',
    },
    DE: {
        png: '/assets/images/flags/png/germany-de.png',
        svg: '/assets/images/flags/svg/germany-de.svg',
    },
    [Country.ICELAND]: {
        png: '/assets/images/flags/png/iceland-is.png',
        svg: '/assets/images/flags/svg/iceland-is.svg',
    },
    MT: {
        png: '/assets/images/flags/png/malta-mt.png',
        svg: '/assets/images/flags/svg/malta-mt.svg',
    },
    [Country.NORWAY]: {
        png: '/assets/images/flags/png/norway-no.png',
        svg: '/assets/images/flags/svg/norway-no.svg',
    },
    [Country.PERU]: {
        png: '/assets/images/flags/png/peru-pe.png',
        svg: '/assets/images/flags/svg/peru-pe.svg',
    },
    RU: {
        png: '/assets/images/flags/png/russia-ru.png',
        svg: '/assets/images/flags/svg/russia-ru.svg',
    },
    ES: {
        png: '/assets/images/flags/png/spain-es.png',
        svg: '/assets/images/flags/svg/spain-es.svg',
    },
    [Country.SWEDEN]: {
        png: '/assets/images/flags/png/sweden-se.png',
        svg: '/assets/images/flags/svg/sweden-se.svg',
    },
    [Country.USA]: {
        png: '/assets/images/flags/png/united-states-us.png',
        svg: '/assets/images/flags/svg/united-states-us.svg',
    },
    [Country.ECUADOR]: {
        png: '/assets/images/flags/png/ecuador-ec.png',
        svg: '/assets/images/flags/svg/ecuador-ec.svg',
    },
    [Country.MEXICO]: {
        png: '/assets/images/flags/png/mexico-mx.png',
        svg: '/assets/images/flags/svg/mexico-mx.svg',
    },
    EU: {
        png: '/assets/images/flags/png/europe-eu.png',
        svg: '/assets/images/flags/svg/europe-eu.svg',
    },
    [Country.REST_OF_THE_WORLD]: {
        png: '/assets/images/flags/png/rest-of-word-row.png',
    },
    ROW: {
        png: '/assets/images/flags/png/rest-of-word-row.png',
    },
};

export const MARKET_COUNTRY_FLAG: Record<Market, { png: string; svg?: string }> = {
    [Market.CANADA]: {
        png: '/assets/images/flags/png/canada-ca.png',
        svg: '/assets/images/flags/svg/canada-ca.svg',
    },
    [Market.CANADA_ONTARIO]: {
        png: '/assets/images/flags/png/canada-ontario-on.png',
        svg: '/assets/images/flags/svg/canada-ontario-on.svg',
    },
    [Market.CHILE]: {
        png: '/assets/images/flags/png/chile-cl.png',
        svg: '/assets/images/flags/svg/chile-cl.svg',
    },
    [Market.ECUADOR]: {
        png: '/assets/images/flags/png/ecuador-ec.png',
        svg: '/assets/images/flags/svg/ecuador-ec.svg',
    },
    [Market.ESTONIA]: {
        png: '/assets/images/flags/png/estonia-ee.png',
        svg: '/assets/images/flags/svg/estonia-ee.svg',
    },
    [Market.FINLAND]: {
        png: '/assets/images/flags/png/finland-fi.png',
        svg: '/assets/images/flags/svg/finland-fi.svg',
    },
    [Market.ICELAND]: {
        png: '/assets/images/flags/png/iceland-is.png',
        svg: '/assets/images/flags/svg/iceland-is.svg',
    },
    [Market.MEXICO]: {
        png: '/assets/images/flags/png/mexico-mx.png',
        svg: '/assets/images/flags/svg/mexico-mx.svg',
    },
    [Market.NORWAY]: {
        png: '/assets/images/flags/png/norway-no.png',
        svg: '/assets/images/flags/svg/norway-no.svg',
    },
    [Market.PERU]: {
        png: '/assets/images/flags/png/peru-pe.png',
        svg: '/assets/images/flags/svg/peru-pe.svg',
    },
    [Market.SWEDEN]: {
        png: '/assets/images/flags/png/sweden-se.png',
        svg: '/assets/images/flags/svg/sweden-se.svg',
    },
    [Market.USA]: {
        png: '/assets/images/flags/png/united-states-us.png',
        svg: '/assets/images/flags/svg/united-states-us.svg',
    },
    [Market.USA_ARIZONA]: {
        png: '/assets/images/flags/png/united-states-us.png',
        svg: '/assets/images/flags/svg/united-states-us.svg',
    },
    [Market.USA_ARKANSAS]: {
        png: '/assets/images/flags/png/united-states-us.png',
        svg: '/assets/images/flags/svg/united-states-us.svg',
    },
    [Market.USA_MASSACHUSETTS]: {
        png: '/assets/images/flags/png/united-states-us.png',
        svg: '/assets/images/flags/svg/united-states-us.svg',
    },
    [Market.USA_MICHIGAN]: {
        png: '/assets/images/flags/png/united-states-us.png',
        svg: '/assets/images/flags/svg/united-states-us.svg',
    },
    [Market.USA_MISSISSIPPI]: {
        png: '/assets/images/flags/png/united-states-us.png',
        svg: '/assets/images/flags/svg/united-states-us.svg',
    },
    [Market.USA_NEVADA]: {
        png: '/assets/images/flags/png/united-states-us.png',
        svg: '/assets/images/flags/svg/united-states-us.svg',
    },
    [Market.USA_NEW_YORK]: {
        png: '/assets/images/flags/png/united-states-us.png',
        svg: '/assets/images/flags/svg/united-states-us.svg',
    },
    [Market.USA_WEST_VIRGINIA]: {
        png: '/assets/images/flags/png/united-states-us.png',
        svg: '/assets/images/flags/svg/united-states-us.svg',
    },
    [Market.REST_OF_THE_WORLD]: {
        png: '/assets/images/flags/png/rest-of-word-row.png',
    },
};
