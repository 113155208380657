import { useEffect } from 'react';
import { getMedia, mediaQuery } from 'services/media';
import { store } from 'stores/store';

export function SystemMediaQueryHooks() {
    useEffect(() => {
        for (const key of Object.keys(mediaQuery)) {
            window.matchMedia(mediaQuery[key]).addListener(() => {
                store.media.set(getMedia());
            });
        }
    }, []);

    return null;
}
