import React from 'react';

export function SplashScreen() {
    return (
        <div id="splash-screen">
            <div className="center">
                <div className="spinner-wrapper">
                    <div className="spinner">
                        <div className="inner">
                            <div className="gap" />

                            <div className="left">
                                <div className="half-circle" />
                            </div>

                            <div className="right">
                                <div className="half-circle" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
