import { logger } from '../../../services/logger';
import { useEffect, useState } from 'react';
import {
    WarehouseExtract,
    WarehouseExtractBookmark,
    WarehouseExtractColumn,
    WarehouseExtractColumnHistory,
    WarehouseExtractHistoricalValidationSettings,
    WarehouseExtractTableHistory,
} from '../../../types/warehouse-management';
import { notification } from 'antd';
import {
    getWarehouseExtractBookmarks,
    getWarehouseExtractColumnHistory,
    getWarehouseExtractColumnList,
    getWarehouseExtractDetails,
    getWarehouseExtractHistoricalValidationSettings,
    getWarehouseExtractTableHistory,
    updateWarehouseExtractBookmark,
    updateWarehouseExtractColumn,
    updateWarehouseExtractDetails,
    updateWarehouseExtractHistoricalValidationSettings,
} from '../../../microservices/warehouse-management';

export function useWarehouseExtractDetails(detailsId: number) {
    const [extractDetails, setExtractDetails] = useState<WarehouseExtract>();
    const [extractColumnList, setExtractColumnList] = useState<WarehouseExtractColumn[]>([]);
    const [extractColumnHistory, setExtractColumnHistory] = useState<WarehouseExtractColumnHistory[]>([]);
    const [extractBookmarks, setExtractBookmarks] = useState<WarehouseExtractBookmark[]>([]);
    const [extractHistoricalValidationSettings, setExtractHistoricalValidationSettings] =
        useState<WarehouseExtractHistoricalValidationSettings>();
    const [extractTableHistory, setExtractTableHistory] = useState<WarehouseExtractTableHistory[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        loadWarehouseExtractDetailsData();
    }, []);

    async function loadWarehouseExtractDetailsData() {
        setIsLoading(true);

        await Promise.all([
            loadWarehouseExtractDetails(),
            loadWarehouseExtractColumnList(),
            loadWarehouseExtractColumnHistory(),
            loadWarehouseExtractBookmarks(),
            loadWarehouseExtractHistoricalValidationSettings(),
            loadWarehouseExtractTableHistory(),
        ]);

        setIsLoading(false);
    }

    async function loadWarehouseExtractDetails() {
        try {
            const warehouseExtractDetails = await getWarehouseExtractDetails(detailsId);
            setExtractDetails(warehouseExtractDetails);
        } catch (error) {
            logger.log('useWarehouseExtractDetails', 'loadWarehouseExtractDetails', error);
            notification.error({ message: 'Error loading extract details' });
        }
    }

    async function changeWarehouseExtractDetails(id: number, details: Partial<WarehouseExtract>) {
        try {
            const updatedWarehouseExtractDetails = await updateWarehouseExtractDetails(id, details);
            setExtractDetails(updatedWarehouseExtractDetails);
            notification.success({ message: 'Extract details updated successfully' });
        } catch (error) {
            logger.log('useWarehouseExtractDetails', 'changeWarehouseExtractDetails', error);
            notification.error({ message: 'Error updating extract details' });
        }
    }

    async function loadWarehouseExtractColumnList() {
        try {
            const updatedWarehouseExtractColumnList = await getWarehouseExtractColumnList(detailsId);
            setExtractColumnList(updatedWarehouseExtractColumnList);
        } catch (error) {
            logger.log('useWarehouseExtractDetails', 'loadWarehouseExtractColumnList', error);
            notification.error({ message: 'Error loading extract table columns' });
        }
    }

    async function changWarehouseExtractColumn(id: number, column: Partial<WarehouseExtractColumn>) {
        try {
            const updatedWarehouseExtractColumn = await updateWarehouseExtractColumn(id, column);
            setExtractColumnList(
                extractColumnList.map(column =>
                    column.id === updatedWarehouseExtractColumn.id ? updatedWarehouseExtractColumn : column,
                ),
            );
            notification.success({ message: 'Extract column updated successfully' });
        } catch (error) {
            logger.log('useWarehouseExtractDetails', 'changWarehouseExtractColumn', error);
            notification.error({ message: 'Error updating extract column' });
        }
    }

    async function loadWarehouseExtractColumnHistory() {
        try {
            const warehouseExtractColumnHistory = await getWarehouseExtractColumnHistory(detailsId);
            setExtractColumnHistory(warehouseExtractColumnHistory);
        } catch (error) {
            logger.log('useWarehouseExtractDetails', 'loadWarehouseExtractColumnHistory', error);
            notification.error({ message: 'Error loading extract column history' });
        }
    }

    async function loadWarehouseExtractBookmarks() {
        try {
            const warehouseExtractBookmarks = await getWarehouseExtractBookmarks(detailsId);
            setExtractBookmarks(warehouseExtractBookmarks);
        } catch (error) {
            logger.log('useWarehouseExtractDetails', 'loadWarehouseExtractBookmarks', error);
            notification.error({ message: 'Error loading extract bookmarks' });
        }
    }

    async function changeWarehouseExtractBookmarks(id: number, bookmarks: WarehouseExtractBookmark[]) {
        try {
            await Promise.all(bookmarks.map(bookmark => updateWarehouseExtractBookmark(id, bookmark)));
            await loadWarehouseExtractBookmarks();
            notification.success({ message: 'Extract bookmark updated successfully' });
        } catch (error) {
            logger.log('useWarehouseExtractDetails', 'changeWarehouseExtractBookmarks', error);
            notification.error({ message: 'Error updating extract bookmark' });
        }
    }

    async function loadWarehouseExtractHistoricalValidationSettings() {
        try {
            const warehouseExtractHistoricalValidationSettings = await getWarehouseExtractHistoricalValidationSettings(
                detailsId,
            );
            setExtractHistoricalValidationSettings(warehouseExtractHistoricalValidationSettings);
        } catch (error) {
            logger.log('useWarehouseExtractDetails', 'loadWarehouseExtractHistoricalValidationSettings', error);
            notification.error({ message: 'Error loading extract historical validation settings' });
        }
    }

    async function changeWarehouseExtractHistoricalValidationSettings(
        id: number,
        historicalValidationSettings: Partial<WarehouseExtractHistoricalValidationSettings>,
    ) {
        try {
            const updatedWarehouseExtractHistoricalValidationSettings =
                await updateWarehouseExtractHistoricalValidationSettings(id, historicalValidationSettings);
            setExtractHistoricalValidationSettings(updatedWarehouseExtractHistoricalValidationSettings);
            notification.success({ message: 'Extract historical validation settings updated successfully' });
        } catch (error) {
            logger.log('useWarehouseExtractDetails', 'changeWarehouseExtractHistoricalValidationSettings', error);
            notification.error({ message: 'Error updating extract historical validation settings' });
        }
    }

    async function loadWarehouseExtractTableHistory() {
        try {
            const warehouseExtractTableHistory = await getWarehouseExtractTableHistory(detailsId);
            setExtractTableHistory(warehouseExtractTableHistory);
        } catch (error) {
            logger.log('useWarehouseExtractDetails', 'loadWarehouseExtractTableHistory', error);
            notification.error({ message: 'Error loading extract bookmarks' });
        }
    }

    return {
        extractDetails,
        extractColumnList,
        extractColumnHistory,
        extractBookmarks,
        extractHistoricalValidationSettings,
        extractTableHistory,
        changeWarehouseExtractDetails,
        changWarehouseExtractColumn,
        changeWarehouseExtractBookmarks,
        changeWarehouseExtractHistoricalValidationSettings,
        isLoading,
    };
}
