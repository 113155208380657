import { Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { sorter } from '../../../../../services/sorter';
import { WAREHOUSE_DETAILS_TABLE_CONFIGURATION } from '../../../../../services/warehouse-management';
import { WarehouseExtractColumnHistory } from '../../../../../types/warehouse-management';
import { UiTable } from '../../../../ui/table/UiTable';
import { WarehouseCardWrapper } from '../../../card/wrapper/WarehouseCardWrapper';
import { WarehouseCheckSign } from '../../../check-sign/WarehouseCheckSign';
import dayjs from 'services/dayjs';

const { Text } = Typography;

interface Props {
    columnHistory: WarehouseExtractColumnHistory[];
}

export function WarehouseExtractDetailsColumnHistory({ columnHistory }: Props) {
    const columnHistoryTableColumns: ColumnsType<WarehouseExtractColumnHistory> = [
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
            render: (_, { created }) => <Text className="no-wrap-text">{dayjs(created).fromNow()}</Text>,
            sorter: (a, b) => sorter(a.created, b.created),
            defaultSortOrder: 'descend',
            width: 130,
        },
        {
            title: 'Column name',
            dataIndex: 'column_name',
            key: 'column_name',
            sorter: (a, b) => sorter(a.column_name, b.column_name),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            sorter: (a, b) => sorter(a.action, b.action),
            width: 110,
        },
        {
            title: 'Primary key',
            dataIndex: 'primary_key',
            key: 'primary_key',
            render: (_, { primary_key }) => <WarehouseCheckSign value={primary_key} />,
            sorter: (a, b) => sorter(a.primary_key, b.primary_key),
            width: 130,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            sorter: (a, b) => sorter(a.type, b.type),
            width: 200,
        },
        {
            title: 'Length',
            dataIndex: ['limits', 'character_maximum_length'],
            key: 'character_maximum_length',
            sorter: (a, b) => sorter(a.limits.character_maximum_length, b.limits.character_maximum_length),
            width: 90,
        },
        {
            title: 'Precision',
            dataIndex: ['limits', 'numeric_precision'],
            key: 'numeric_precision',
            sorter: (a, b) => sorter(a.limits.numeric_precision, b.limits.numeric_precision),
            width: 90,
        },
        {
            title: 'Scale',
            dataIndex: ['limits', 'numeric_scale'],
            key: 'numeric_scale',
            sorter: (a, b) => sorter(a.limits.numeric_scale, b.limits.numeric_scale),
            width: 80,
        },
    ];

    return (
        <WarehouseCardWrapper title="Column history">
            <UiTable
                rowKey={({ created, column_name, action }) => `${created}_${column_name}_${action}`}
                columns={columnHistoryTableColumns}
                dataSource={columnHistory}
                {...WAREHOUSE_DETAILS_TABLE_CONFIGURATION}
            />
        </WarehouseCardWrapper>
    );
}
