import React from 'react';
import { oldRoutes } from 'routes';
import { useRouter } from 'services/router';
import Wrapper from './styles';
import { getStoreValue, store } from '../../stores/store';

export function OldBackofficePage() {
    const { OLD_BACKOFFICE_URL } = getStoreValue(store.environment);
    const { params } = useRouter();

    let page = oldRoutes[params.url];
    const id = params.id;

    if (!page) {
        return <div className="root">Page not found</div>;
    }

    if (page === 'tournaments/list' && id) {
        page = 'tournaments/details';
    }

    const path = id ? `${page}/${id}` : page;
    const url = `${OLD_BACKOFFICE_URL}/${path}?disableHeader=true`;
    return (
        <Wrapper>
            <div className="iframe-container">
                <iframe title="old-backoffice" src={url} width="100%" height="100%" />
            </div>
        </Wrapper>
    );
}
