import { useState } from 'react';

export function useTableFilter<T>(defaultFilterState: T) {
    const [filterState, setFilterState] = useState<T>(defaultFilterState);

    function changeFilterState(values: Partial<T>) {
        setFilterState({ ...filterState, ...values });
    }

    return { filterState, changeFilterState };
}
