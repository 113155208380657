import { Divider, ModalProps } from 'antd';
import React from 'react';
import { filterStyleProps, ROOT_CONTAINER } from '../../../services/util';
import Wrapper from './styles';

interface Props extends Omit<ModalProps, 'okType'> {
    children: React.ReactNode;
    okType?: ModalProps['okType'] | 'success';
}

export function UiModal({ children, onOk, footer, ...props }: Props) {
    const modalProps: ModalProps = {
        centered: true,
        getContainer: ROOT_CONTAINER,
        footer,
        ...(props as ModalProps),
    };

    return (
        <Wrapper onOk={onOk} {...filterStyleProps(modalProps)}>
            <Divider className="modal-divider modal-top-divider" />
            {children}
            {footer !== null && <Divider className="modal-divider modal-bottom-divider" />}
        </Wrapper>
    );
}
