import { AlertOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from 'routes';
import { ALARM_VOLUME_KEY } from 'services/alarm';
import { getRoute } from 'services/router';
import { useStore, store, getStoreValue } from 'stores/store';
import useSound from 'use-sound';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { logger } from '../../../../services/logger';

export function SportsbookAlarmsIcon() {
    const [notificationAlarmsOutcomesIds] = useStore(store.sportsbook.alarm.notificationAlarmsOutcomesIds);
    const [previousAlarmIds, setPreviousAlarmIds] = useState<string[]>([]);
    const [isNotificationEnabled] = useStore(store.isNotificationEnabled);
    const [soundVolume] = useLocalStorage(ALARM_VOLUME_KEY, 50);
    const volume = soundVolume ? soundVolume / 100 : 0.5;
    const [play] = useSound('/assets/sounds/alarm.mp3', { volume, interrupt: true });

    useEffect(() => {
        const tabElection = getStoreValue(store.tabElection) as any;
        const debugInfo = {
            notificationAlarmsOutcomesIds: Boolean(notificationAlarmsOutcomesIds.length),
            isLeader: tabElection?.isLeader,
            isNotificationEnabled,
            hasNewAlarms: hasNewAlarms(notificationAlarmsOutcomesIds, previousAlarmIds),
        };
        logger.debug('SportsbookAlarmsIcon', 'SportsbookAlarmsIcon', { debugInfo });
        if (
            notificationAlarmsOutcomesIds.length &&
            tabElection?.isLeader &&
            isNotificationEnabled &&
            hasNewAlarms(notificationAlarmsOutcomesIds, previousAlarmIds)
        ) {
            logger.debug('SportsbookAlarmsIcon', 'SportsbookAlarmsIcon', 'play alarm sound');
            play();
        }
    }, [notificationAlarmsOutcomesIds]);

    function hasNewAlarms(notificationAlarmsOutcomesIds: string[], previousAlarmIds: string[]) {
        const newAlarms = notificationAlarmsOutcomesIds.filter(id => !previousAlarmIds.includes(id));
        setPreviousAlarmIds(notificationAlarmsOutcomesIds);
        return Boolean(newAlarms.length);
    }

    return (
        <Link to={getRoute(ROUTES.sportsbook.alarms)}>
            <Badge count={notificationAlarmsOutcomesIds.length} className="pointer">
                <AlertOutlined className="alarm-icon" />
            </Badge>
        </Link>
    );
}
