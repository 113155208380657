import store from 'store';
import { useState } from 'react';
export function storageGet(key) {
    return store.get(key);
}

export function storageSet(key, value) {
    store.set(key, value);
}

export function storageRemove(key) {
    store.remove(key);
}

export function useStorage(key) {
    const [get, set] = useState(storageGet(key));

    function setValue(value) {
        if (value) {
            storageSet(key, value);
        } else {
            storageRemove(key);
        }

        set(value);
    }

    return [get, setValue];
}
