import { UiSwitch } from 'components/ui/switch/UiSwitch';
import React from 'react';
import { IS_DISPLAY_NOT_ACTIVE_ENABLED_KEY } from 'services/category';
import { useStore } from 'stores/store';
import { store } from 'stores/store';

export function HeaderUserSettingsDisplayNotActive() {
    const [isDisplayNotActiveEnabled, setIsDisplayNotActiveEnabled] = useStore(
        store.sportsbook.isDisplayNotActiveEnabled,
    );

    function onChange(value: boolean) {
        localStorage.setItem(IS_DISPLAY_NOT_ACTIVE_ENABLED_KEY, String(value));
        setIsDisplayNotActiveEnabled(value);
    }

    return <UiSwitch name="Show inactive in sports tree" isChecked={isDisplayNotActiveEnabled} onChange={onChange} />;
}
