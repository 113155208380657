import { Badge } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from 'routes';
import { getRoute } from 'services/router';
import { getResultRequestCount } from 'microservices/bets/bets';
import { ExceptionOutlined } from '@ant-design/icons';
import { logger } from '@sentry/utils';
import { useSocketSubscribeUnsubscribe, useSocketTopicEvents } from 'microservices/feeder';
import { useDebounce } from 'hooks/useDebounce';
import { ResultRequestChannelSettings } from 'services/result-request';

export function SportsbookResultRequestIcon() {
    const [missingResultAlarms, setMissingResultAlarms] = useState(0);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = useDebounce(
        async () => {
            await fetchMissingResultAlarms();
        },
        100,
        { maxWait: 1000 },
    );

    useSocketSubscribeUnsubscribe('public', { params: ResultRequestChannelSettings, resubscribeOnReconnect: true });

    useSocketTopicEvents('public-bets-result-request-new', fetchData, []);
    useSocketTopicEvents('public-bets-result-request-ticket-resulted', fetchData, []);

    async function fetchMissingResultAlarms() {
        try {
            const missingResultAlarmsTotal = await getResultRequestCount();
            setMissingResultAlarms(missingResultAlarmsTotal);
        } catch (error) {
            logger.log('SportsbookResultRequestIcon', 'fetchMissingResultAlarms', error);
        }
    }

    return (
        <Link to={getRoute(ROUTES.sportsbook.missingResults)}>
            <Badge count={missingResultAlarms} className="pointer">
                <ExceptionOutlined className="alarm-icon" />
            </Badge>
        </Link>
    );
}
