import { MissingResultQuery } from '@staycool/sports-types/resulting';
import { getSportsUrl, httpGet } from '../../services/api';
import { GetUnresultedMarketsReportsResponse } from '@staycool/sports-types/unresulted-markets';

export function getUnresultedMarketsReports(filters: MissingResultQuery) {
    const url = getSportsUrl(`unresulted-markets`);
    return httpGet<GetUnresultedMarketsReportsResponse>(url, filters);
}

export function getUnresultedMarketsReportsCount(filters: MissingResultQuery) {
    const url = getSportsUrl(`unresulted-markets/count`);
    return httpGet<number>(url, filters);
}
