import { ReconciliationIntegrations } from '../types/reconciliation';
import { createStores } from './utils';

export const reconciliation = createStores({
    integrations: {
        providers: [],
        operatorAccounts: [],
        providersWithExceptionalBouncedPayout: [],
        providersWithOperatorAccount: [],
        uploadProviders: [],
        currencyDifferenceProviders: [],
        automaticDownloadProviders: [],
    } as ReconciliationIntegrations,
});
