import { Card } from 'antd';
import { color } from 'style/variables';
import styled from 'styled-components';

export default styled(Card)`
    &.ant-card {
        background-color: ${color.gray100};
        overflow: hidden;
    }

    & .ant-spin {
        display: block;
    }

    & > .ant-card-head {
        margin-bottom: 0;
        min-height: auto;
        height: 2.875rem;
    }

    &.ant-card-small {
        & .ant-card-body {
            padding: 0.75rem;
        }
        & .ant-card-head {
            height: 2.375rem;
        }
    }
` as typeof Card;
