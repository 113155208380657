import { MarketViewType, OutcomeStatus, OutcomeTradingPosition } from '@staycool/sports-types';
import { TradingViewMarket, TradingViewOutcomeV2 } from '@staycool/sports-types/trading-view';
import orderBy from 'lodash/orderBy';
import { changePositionInArray } from 'services/util';
import { color } from 'style/variables';
import { ProcessedMatchMarketOutcome, shouldDisplayMarketLine } from '../market';
import { ParlayCardOutcomeInput } from 'services/sports/parlay-card/parlay-card';

export function getReportOutcomeName(outcome: string) {
    return outcome
        .replace(/Draw/g, 'X')
        .replace(/\[Home]/g, '1')
        .replace(/\[Away]/g, '2')
        .replace(/\[Player 1]/g, '1')
        .replace(/\[Player 2]/g, '2');
}

export function getPositionColor({ position }: { position: OutcomeTradingPosition | 'Mix' }) {
    switch (position) {
        case 'PR':
            return color.gold700;
        case '100%':
            return color.red500;
        case 'Real':
            return color.green500;
        case 'Mix':
            return color.blue700;
        default:
            return '';
    }
}

export function getOutcomesByOrder(
    outcomes: ProcessedMatchMarketOutcome[],
    marketType: MarketViewType,
    outcomesOrderBy?: string | null,
) {
    const orderByDictionary = {
        odds: 'odds.value',
        outcomeCreated: 'created',
        outcomeName: 'name',
    };
    const orderingValue = (outcomesOrderBy && orderByDictionary[outcomesOrderBy]) || 'odds.value';
    const isOrderOddsValueMatket = ['generic', 'variable', 'team_outright', 'player_outright'].includes(marketType);
    return isOrderOddsValueMatket ? orderBy(outcomes, [orderingValue]) : outcomes;
}

export type OutcomesByOutcomeId = Record<
    number,
    {
        outcomeId: number;
        odds?: number;
        limitTotal: number;
        limitUser: number;
        tradingPosition: OutcomeTradingPosition;
    }
>;

const hideSignMarketNames = ['over', 'under', 'total'];

export function isMarketShowSign(typeName?: string) {
    if (!typeName) {
        return true;
    }
    typeName = typeName.toLowerCase();
    for (const name of hideSignMarketNames) {
        if (typeName.includes(name)) {
            return false;
        }
    }
    return true;
}

export const awayPlaceholders = ['away', '[away]', '[player 2]'];

// Last team name is considered an "away" in placeholder markets of OUTRIGHT matches for handicap line display purposes
// market_name: Series Handicap
// team_names: ['Arsenal', 'Aston Villa']
// line: +1.5
// outcomes with line: Arsenal +1.5 ; Aston Villa -1.5
export function isAwayOutcome(outcomeName: string, teamNames?: string[]) {
    return (!!teamNames && teamNames.indexOf(outcomeName) > 0) || awayPlaceholders.includes(outcomeName.toLowerCase());
}

export function formatOutcomeName(outcomeName: string, away_team_name: string, home_team_name: string) {
    return outcomeName
        .replace('[Home]', home_team_name)
        .replace('[Player 1]', home_team_name)
        .replace('[Away]', away_team_name)
        .replace('[Player 2]', away_team_name);
}

export function getOutcomeName({
    marketName,
    outcomeName,
    home_team,
    away_team,
    view_type,
    marketLine,
    team_names,
}: {
    marketName: string;
    outcomeName: string;
    home_team?: string;
    away_team?: string;
    view_type?: string;
    marketLine?: number;
    team_names?: string[];
}) {
    let name = outcomeName;
    const awayTeam = away_team || '';
    const homeTeam = home_team || '';
    const isLineVisible = shouldDisplayMarketLine({
        view_type,
        line: marketLine,
        market_type_name: marketName.toLocaleLowerCase(),
    });
    marketLine = (marketLine ?? 0) as number;

    if (isLineVisible || marketLine) {
        const isShowSign = isMarketShowSign(marketName);
        if (isShowSign) {
            const isAway = isAwayOutcome(outcomeName, team_names);
            if (isAway) {
                marketLine *= -1;
            }
            const sign = marketLine > 0 ? '+' : '';
            name = `${name} ${sign}${marketLine}`;
        } else {
            name = `${name} ${Math.abs(marketLine)}`;
        }
    }
    return formatOutcomeName(name, awayTeam, homeTeam);
}

export function getForcedLine({ forcedLineAdjustment, marketLine, marketName, outcomeName }: ParlayCardOutcomeInput) {
    if (!forcedLineAdjustment) {
        return '';
    }
    const isAway = isAwayOutcome(outcomeName);
    let forcedLine = marketLine + forcedLineAdjustment;
    if (isAway) {
        forcedLine *= -1;
    }
    return `${forcedLine}`;
}

export function renderOutcome(market: ParlayCardOutcomeInput) {
    const { marketName, outcomeName, homeTeam, awayTeam, marketLine } = market;
    const renderedOutcomeName = getOutcomeName({
        marketName,
        outcomeName,
        home_team: homeTeam,
        away_team: awayTeam,
        marketLine,
    });
    const forcedLine = getForcedLine(market);
    return `${renderedOutcomeName} ${forcedLine}`;
}

export function getOddsStatus(outcomeStatus?: OutcomeStatus) {
    switch (outcomeStatus) {
        case 'DISABLED':
            return 'SUSPENDED';
        case 'OPEN':
            return 'OPEN';
        default:
            return 'CLOSED';
    }
}

export function isOutcomeDisabled(outcomeStatus: OutcomeStatus) {
    return ['DISABLED', 'RESULTED'].includes(outcomeStatus);
}

export function getSortedOutcomes(outcomes: TradingViewOutcomeV2[] | null, shouldReverseOutcomes = false) {
    if (!outcomes) return [];
    if (shouldReverseOutcomes) {
        outcomes = [...outcomes].reverse();
    }

    const indexOfDraw = outcomes.findIndex(element => element.name === 'Draw');
    if (indexOfDraw !== -1) {
        outcomes = changePositionInArray(outcomes, indexOfDraw, outcomes.length - 1);
    }
    return outcomes;
}

export const isOverUnder = (market: TradingViewMarket | undefined) =>
    market && market.outcomes?.some(outcome => ['Over', 'Under', 'Total'].includes(outcome.name));

export interface OutcomeLimit {
    limit_total: number;
    limit_user: number;
}

export type OutcomeLimitsByOutcomeId = Record<number, OutcomeLimit>;

export type OutcomeLimitsByMarketId = Record<number, OutcomeLimitsByOutcomeId>;
