import { Transaction } from '@staycool/retail-types/transaction';
import { Category } from '@staycool/sports-types/dist/features/category/types';
import { RcFile } from 'antd/lib/upload';
import isEqual from 'lodash/isEqual';
import camelCase from 'lodash/camelCase';
import cloneDeep from 'lodash/cloneDeep';
import isFunction from 'lodash/isFunction';
import throttle from 'lodash/throttle';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import { CategoryDepth } from 'microservices/sports/category';
import Loadable from 'react-loadable';
import { getStoreValue, store } from 'stores/store';
import { ClientName, FaviconTypes } from 'types/client';
import { getDevelopmentUrl } from './api';
import { DOWNLOAD_EXTENSIONS, downloadFile } from './file';
import { RetailIdMasks } from './retail';

import {
    BOOLEAN_SELECTOR_OPTION,
    BooleanSelectorLabels,
    BooleanSelectorOption,
    CsvDataToExport,
    FILE_SIZE_UNITS,
    FileSizeUnits,
    OPTIONS_LABEL_STYLE,
    OptionsLabelStyle,
    OS,
} from '../types/util';
import { sorter } from './sorter';
import mapKeys from 'lodash/mapKeys';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import { stringify } from 'query-string';
import { UiSelectOption } from 'components/ui/selector/types';
import startCase from 'lodash/startCase';

export const toSentenceCase = (str: string) => (str ? capitalize(startCase(str).toLowerCase()) : '');

export function asyncComponent(loader) {
    return Loadable({
        loader: async () => {
            try {
                const module = await loader();
                const defaultExport = module.default;
                const namedExport = module[Object.keys(module)[0]];

                if (isFunction(defaultExport)) {
                    return defaultExport;
                } else if (isFunction(namedExport)) {
                    return namedExport;
                } else {
                    window.location.reload();
                }
            } catch (error) {
                window.location.reload();
            }
        },
        loading: () => {
            return '';
        },
    });
}

const overwriteClientName = {
    'ivc-ms': ClientName.IVC,
};

export const loadEnvironment = async () => {
    const environment = await fetch(`/environment.json`).then(response => response.json());
    environment.CLIENT_NAME = overwriteClientName[environment.CLIENT_NAME] || environment.CLIENT_NAME;
    // ensure there is no trailing slash
    environment.FRONTOFFICE_URL = environment.FRONTOFFICE_URL?.replace(/\/$/, '');
    store.environment.set(environment);
};

export function isProd() {
    const { ENVIRONMENT } = getStoreValue(store.environment);
    return Boolean(ENVIRONMENT === 'prod');
}

export function isProdOrQa() {
    const { ENVIRONMENT } = getStoreValue(store.environment);
    return ENVIRONMENT === 'prod' || ENVIRONMENT === 'qa';
}

export function isDevelopment() {
    return window.location.hostname === 'localhost';
}

export function isB2bEnvironment() {
    const { CLIENT_NAME } = getStoreValue(store.environment);
    return CLIENT_NAME !== ClientName.COOLBET;
}

export function camelCaseKeys(target) {
    if (Array.isArray(target)) {
        return target.map(element => camelCaseKeys(element));
    } else if (typeof target === 'object' && target !== null) {
        const formattedObject = {};
        Object.keys(target).forEach(key => {
            formattedObject[camelCase(key)] = camelCaseKeys(target[key]);
        });
        return formattedObject;
    }
    return target;
}

export const flattenObject = obj => {
    const flattened = {};

    Object.keys(obj).forEach(key => {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            Object.assign(flattened, flattenObject(obj[key]));
        } else {
            flattened[key] = obj[key];
        }
    });

    return flattened;
};

export function openNewTab(url: string) {
    if (!url.length) {
        return;
    }

    const newTabReference = window.open(url, '_blank', 'noreferrer');
    if (newTabReference) {
        newTabReference.opener = null; // Explanation: https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
    }
}

export function changePositionInArray<T>(array: T[], from: number, to: number) {
    const newArray = [...array];
    newArray.splice(to, 0, newArray.splice(from, 1)[0]);
    return newArray;
}

export const capitalize = string => {
    return `${string[0].toUpperCase()}${string.slice(1)}`;
};

export function getEnvironmentUrl() {
    const { ROOT_URL } = getStoreValue(store.environment);
    return isDevelopment() ? getDevelopmentUrl() : ROOT_URL;
}

export function timedQueue<T = number>(
    functionToCallWithMultipleIds: (ids: T[]) => void,
    collectForMilliSeconds = 500,
) {
    const idsCollector: T[] = [];

    const throttledFunction = throttle(
        () => {
            functionToCallWithMultipleIds(uniq(idsCollector));
            idsCollector.length = 0;
        },
        collectForMilliSeconds,
        { leading: false, trailing: true },
    );

    return (id: T) => {
        idsCollector.push(id);
        throttledFunction();
    };
}

export function filterArrayOfObjectsByValue(arrayOfObject: any[], value: string) {
    const filteredArrayOfObjects = arrayOfObject?.filter(obj => {
        const objectKeys = Object.keys(obj);
        return objectKeys.some(key => {
            return obj?.[key]?.toString().toLowerCase().includes(value.toLowerCase());
        });
    });

    return filteredArrayOfObjects;
}

export function applyMask(input: string, mask: string) {
    if (!input?.length) {
        return '';
    }
    input = input.replace(/-/g, '');
    let index = 0;
    return mask
        .replace(/-/g, ' ')
        .split('')
        .map(char => (char === '_' ? input[index++] : char))
        .join('')
        .trim()
        .replace(/\s/g, '-');
}

export function filterStyleProps(props) {
    if (!props) {
        return;
    }

    const onEventRegex = /^on[A-Z].*$/;
    const styleProps = {};

    for (const propName of Object.keys(props)) {
        const isInexistentNativeEventHandler = propName.match(onEventRegex) && !globalEventHandlers.includes(propName);
        const isInvalidDomProp = invalidDomProps.includes(propName);

        if (isInexistentNativeEventHandler || isInvalidDomProp) {
            continue;
        }

        styleProps[propName] = props[propName];
    }

    return styleProps;
}

export function RetailTicketOrVoucherIdFromTransaction(transaction: Transaction) {
    const { ticket_id, voucher_id, loyalty_id, entry_id } = transaction || {};
    const secondaryId = voucher_id || loyalty_id;
    if (ticket_id) {
        return applyMask(ticket_id, RetailIdMasks.TicketIdMaskRestricted);
    }

    if (entry_id) {
        return applyMask(entry_id, RetailIdMasks.TicketIdMaskRestricted);
    }

    if (secondaryId) {
        return applyMask(secondaryId || '', RetailIdMasks.VoucherIdMaskRestricted);
    }

    return '';
}

const invalidDomProps = ['forwardedRef', 'isLoading', 'fullStoryBlock', 'iconSize'];

const globalEventHandlers = [
    'onAbort',
    'onAnimationEnd',
    'onAnimationIteration',
    'onAuxClick',
    'onBlur',
    'onCancel',
    'onCanPlay',
    'onCanPlayThrough',
    'onChange',
    'onClick',
    'onClose',
    'onContextMenu',
    'onDoubleClick',
    'onDurationChange',
    'onEnded',
    'onError',
    'onFocus',
    'onGotPointerCapture',
    'onInput',
    'onInvalid',
    'onKeyDown',
    'onKeyPress',
    'onKeyUp',
    'onLoad',
    'onLoadedData',
    'onLoadedMetadata',
    'onLoadStart',
    'onLostPointerCapture',
    'onMouseDown',
    'onMouseEnter',
    'onMouseLeave',
    'onMouseMove',
    'onMouseOut',
    'onMouseOver',
    'onMouseUp',
    'onPause',
    'onPlay',
    'onPointerCancel',
    'onPointerDown',
    'onPointerEnter',
    'onPointerLeave',
    'onPointerMove',
    'onPointerOut',
    'onPointerOver',
    'onPointerUp',
    'onReset',
    'onScroll',
    'onSelect',
    'onSubmit',
    'onTouchCancel',
    'onTouchStart',
    'onTransitionEnd',
    'onWheel',
];
export const filterOutFunctionPropsCauseTheyAreRedefinedAlwaysIfNotMemod = (prevProps, nextProps) =>
    Object.entries(prevProps)
        .filter(([propKey, propVal]) => !isFunction(propVal))
        .every(([propKey, propVal]) => propVal === nextProps[propKey]);

export const omit = <T extends object, K extends keyof T>(originalObject: T, propertiesToOmit: K[]): Omit<T, K> => {
    const clonedObject = { ...originalObject };

    for (const property of propertiesToOmit) {
        delete clonedObject[property];
    }

    return clonedObject;
};

let isPreviousTitleDefault = true;

export function updateDocumentTitle(title: string, isDefault = false) {
    if ((!isDefault && isPreviousTitleDefault) || isPreviousTitleDefault === isDefault) {
        document.title = title;
    }
    isPreviousTitleDefault = isDefault;
}

export function updateFavicon(favicon: FaviconTypes) {
    let link = document.querySelector("link[rel='shortcut icon']");
    if (!link) {
        link = document.createElement('link');
        link.setAttribute('rel', 'shortcut icon');
        document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.setAttribute('href', `/favicons/${favicon}.ico`);
}

export function getBase64(img: RcFile, callback: (s: string | ArrayBuffer | null) => void) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

export function handleSortEnd(oldIndex: number, newIndex: number, records: any) {
    if (oldIndex === newIndex) {
        return null;
    }
    const recordIndex = records.indexOf(records.find(banner => banner.position === oldIndex));
    const recordToMove = records[recordIndex];

    const newRecords = records.map(record => {
        if (oldIndex < newIndex && record.position <= newIndex && record.position >= oldIndex) {
            return { ...record, position: record.position - 1 };
        } else if (oldIndex > newIndex && record.position >= newIndex && record.position < oldIndex) {
            return { ...record, position: record.position + 1 };
        }
        return record;
    });
    newRecords.splice(recordIndex, 1);
    newRecords.splice(newIndex - 1, 0, { ...recordToMove, position: newIndex });
    return newRecords;
}

export function getUrlStringAttribute(url: string, searchValue: string) {
    return new URLSearchParams(url.split('?')[1]).get(searchValue) as string;
}

export function isLeagueCategory(category: Category) {
    return category.depth === CategoryDepth.League;
}

export function createCsvDataToExport({ arrayHeader, customDelimiter, arrayData }: CsvDataToExport) {
    const delimiter = customDelimiter ?? ',';

    let csv = '';

    if (arrayHeader) {
        csv = csv + arrayHeader.join(delimiter) + '\n';
    }

    arrayData.forEach((obj: any) => {
        const row: any = [];
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                row.push(obj[key]);
            }
        }
        csv = csv + row.join(delimiter) + '\n';
    });

    return csv;
}

export function createExportCsv(csv, fileName: string) {
    downloadFile(fileName, csv, DOWNLOAD_EXTENSIONS.CSV, { disableCsvUtf8: true });
}

export function rotationMatchName(
    name: string,
    homeObject?: { rotation: number | null; name: string },
    awayObject?: { rotation: number | null; name: string },
) {
    if (!homeObject || !awayObject || !name) {
        return name;
    }
    const { rotation: homeRotation, name: homeName } = homeObject;
    const { rotation: awayRotation, name: awayName } = awayObject;
    if (homeRotation || awayRotation) {
        return (
            (homeRotation ? `[${homeRotation}] ` : '') +
            homeName +
            ' - ' +
            (awayRotation ? `[${awayRotation}] ` : '') +
            awayName
        );
    }
    return name;
}

const depthMap = {
    2: 'sport_id',
    3: 'region_id',
    4: 'league_id',
};

export function setFilterCategoryIdByCategory<T>(filter: T, category: Category) {
    if (category.depth in depthMap) {
        const newFilter = cloneDeep(filter);
        newFilter[depthMap[category.depth]] = category.id;
        return newFilter;
    }
    return filter;
}

export function sortSet(list: string[]) {
    return [...new Set(list)].sort();
}

export function splitArrayIntoColumns(array: any[], cols: number) {
    return [...Array(cols).keys()].map(col => array.filter((_, i) => i % cols === col));
}

export function getUniqueAndSortOptions(uiSelectOptions: UiSelectOption[]) {
    const uniqueOptions = uniqBy(uiSelectOptions, 'value');
    return sortBy(uniqueOptions, 'label');
}

export function getOptionsFromEnum<T extends Record<string, string | number>>(
    enumObject: T,
    {
        isDisabled = () => false,
        labelOverrides = {},
        labelStyle = OPTIONS_LABEL_STYLE.CAPITALIZED,
        isHidden = () => false,
    }: {
        isDisabled?: (value: T[keyof T]) => boolean;
        labelOverrides?: { [key in T[keyof T]]?: string };
        labelStyle?: OptionsLabelStyle;
        isHidden?: (value: T[keyof T]) => boolean;
    } = {},
): { label: string; value: T[keyof T]; disabled: boolean }[] {
    return (Object.values(enumObject) as T[keyof T][])
        .filter(value => !isHidden(value))
        .map(value => ({
            label: labelOverrides[value] ?? getPrettyEnumValue(value, labelStyle),
            value,
            disabled: isDisabled(value),
        }));
}

export function getPrettyEnumValue(
    value: string | number,
    labelStyle: OptionsLabelStyle = OPTIONS_LABEL_STYLE.CAPITALIZED,
) {
    const stringifiedValue = String(value);
    const formattedValue = stringifiedValue.replace(/_/g, ' ');

    switch (labelStyle) {
        case OPTIONS_LABEL_STYLE.CAPITALIZED:
            return capitalize(formattedValue.toLowerCase());
        case OPTIONS_LABEL_STYLE.UPPERCASED:
            return stringifiedValue.toUpperCase();
        case OPTIONS_LABEL_STYLE.NORMALIZED:
            return formattedValue;
        default:
            return stringifiedValue;
    }
}

export function cbRound(value, precisionModifier = 100): number {
    const SMALL_CONST_VALUE = 0.000000001;
    const valueToRound = value + SMALL_CONST_VALUE;

    return Math.round(valueToRound * precisionModifier) / precisionModifier;
}

export function toCurrencyFormat({
    amount,
    minimumFractionDigits = 2,
}: {
    amount: string | number | undefined | null;
    minimumFractionDigits?: number;
}): string | undefined {
    return amount?.toLocaleString(undefined, { minimumFractionDigits, maximumFractionDigits: 2 });
}

export function isSmallerThanSize(file: File, sizeInMB: number) {
    return file.size / 1024 / 1024 < sizeInMB;
}

export function getBoUserFullName(userId: number) {
    const user = getStoreValue(store.boUsersById)[userId];
    return user ? `${user.first_name} ${user.last_name}` : userId;
}

export function replaceOldValueByNewValue<T extends {}>(object: T, oldValues: any[], newValue: any) {
    return Object.entries(object).reduce((acc, [key, value]) => {
        if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
            acc[key] = replaceOldValueByNewValue(value, oldValues, newValue);
        } else if (oldValues.every(oldValue => !isEqual(value, oldValue))) {
            acc[key] = value;
        } else {
            acc[key] = newValue;
        }

        return acc;
    }, {} as T);
}

export function replaceEmptyValuesByUndefined<T extends {}>(object: T, isNullAdded: boolean = false) {
    return replaceOldValueByNewValue(object, ['', [], ...(isNullAdded ? [null] : [])], undefined);
}

export function getBooleanSelectorOptions(labels?: BooleanSelectorLabels) {
    return Object.entries(BOOLEAN_SELECTOR_OPTION).map(([label, value]) => ({
        label: getPrettyEnumValue(labels?.[label] ?? label),
        value,
    }));
}

export function filterBooleanSelectorOption(filter: BooleanSelectorOption, value: BooleanSelectorOption) {
    return filter === BOOLEAN_SELECTOR_OPTION.ALL || filter === value;
}

export function filterInput(filter: string, value: string) {
    return value.toLowerCase().includes(filter.toLowerCase());
}

export async function sortArrayOfObjectByKey(array: any[], key: string, order) {
    return array.sort(function (a, b) {
        if (order !== 'ascend') {
            return sorter(a[key], b[key]);
        } else {
            return sorter(b[key], a[key]);
        }
    });
}

export const renameProps = <Key extends string>(
    originalObject: Record<Key, any>,
    newPropertyNames: Partial<Record<Key, string>>,
) => {
    return mapKeys(originalObject, (_, originalKey) => newPropertyNames[originalKey] || originalKey);
};

export const ROOT_CONTAINER = '#root';

export function transformFileSize(size: number | undefined, unit: FileSizeUnits = FILE_SIZE_UNITS.B) {
    const weight = 1024;
    let exponent = 0;

    switch (unit) {
        case FILE_SIZE_UNITS.KB:
            exponent = 1;
            break;
        case FILE_SIZE_UNITS.MB:
            exponent = 2;
            break;
        case FILE_SIZE_UNITS.GB:
            exponent = 3;
            break;
        case FILE_SIZE_UNITS.TB:
            exponent = 4;
            break;
    }

    return `${size ? Math.trunc(size / Math.pow(weight, exponent)) : 0}${unit}`;
}

export function updateUrlFilter(filter: Record<string, any>, history: any) {
    const search = {};

    Object.entries(filter).forEach(([key, value]) => {
        if ((isArray(value) && !value?.length) || !value) {
            return;
        }

        if (isPlainObject(value)) {
            Object.entries(value).forEach(([deepKey, value]) => {
                search[`${key}${capitalize(deepKey)}`] = value as any;
            });
        } else if (isArray(value) && value.some(isPlainObject)) {
            search[key] = String(value.map(e => (isPlainObject(e) ? JSON.stringify(e) : e)));
        } else {
            search[key] = String(value);
        }
    });

    history.push({ search: stringify({ ...search }) });
}

export const getOS = () =>
    (navigator.appVersion || navigator.userAgent).includes('Windows') ? OS.WINDOWS : OS.MACINTOSH;

export function isCoolbet() {
    const { CLIENT_NAME } = getStoreValue(store.environment) || {};
    return CLIENT_NAME === 'coolbet';
}

export const DEFAULT_DEBOUNCE_TIMEOUT = 800;

// eslint-disable-next-line rulesdir/no-classes
export class DefaultDict {
    constructor(defaultInit) {
        return new Proxy(
            {},
            {
                get: (target, name) =>
                    name in target
                        ? target[name]
                        : (target[name] =
                              typeof defaultInit === 'function' ? new defaultInit().valueOf() : defaultInit),
            },
        );
    }
}

export function cleanObject<T extends object>(object: T): T {
    return Object.fromEntries(Object.entries(object).map(([key, value]) => [key, value === '' ? null : value])) as T;
}

export function isSportsbookCore() {
    const { CLIENT_NAME } = getStoreValue(store.environment);
    return CLIENT_NAME === ClientName.SPORTSBOOKCORE;
}
