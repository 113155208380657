import { getRoute } from '../../../../services/router';
import { WAREHOUSE_SEARCH_TABLE_NAME_PARAM } from '../../../../services/warehouse-management';
import Wrapper from './styles';

interface Props {
    name: string;
    route: {};
    searchParam: string;
}

export function WarehouseErrorsAction({ name, route, searchParam }: Props) {
    return (
        <Wrapper
            btnName={name}
            type="link"
            onClick={() =>
                window.open(`${getRoute(route)}?${WAREHOUSE_SEARCH_TABLE_NAME_PARAM}=${searchParam}`, '_blank')
            }
        />
    );
}
