import styled from 'styled-components';

export default styled.div`
    .iframe-container {
        width: 100%;
        height: calc(100vh - 120px);
        overflow: hidden;
        padding: 0;
        margin: 0;
        border: none;
    }
`;
