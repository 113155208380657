import { SupportedLanguages } from '@staycool/config/dist/types';
import { color } from 'style/variables';

export interface DataTableRequest {
    offset: number;
    limit: number;
    searchTerm: string | undefined;
    orderBy: string;
}

export interface ArticleFilter extends DataTableRequest {
    folderId?: number;
    deleted: 'true' | 'false' | 'all';
    archiveStatus: ArticleArchiveStatus;
    articleStatus?: string;
    lastEditedBy?: string;
}

export interface ArticleTemplateFilter extends DataTableRequest {
    archiveStatus: ArticleArchiveStatus;
}

export enum ArticleStatus {
    scheduled = 'Scheduled',
    published = 'Published',
    inactive = 'Inactive',
    draft = 'Draft',
}

export const articleStatusColorDict: Record<ArticleStatus, string> = {
    [ArticleStatus.scheduled]: color.blue400,
    [ArticleStatus.published]: color.green500,
    [ArticleStatus.inactive]: color.gray500,
    [ArticleStatus.draft]: color.orange500,
};

export enum ArticleType {
    promotion = 'Promotion',
    static = 'Static',
}

export enum ArticleArchiveStatus {
    archived = 'archived',
    active = 'active',
    all = 'all',
}

export type HtmlVariableType = Record<string, VariableContentType>;

export enum VariableContentType {
    TEXT = 'text',
    BLOCK = 'block',
    WYSIWYG = 'WYSIWYG',
    NUMBER = 'number',
    CODE = 'CODE',
}

export interface HtmlVariable {
    key: string;
    type: VariableContentType;
    variants: HtmlVariableVariant[];
}

export interface HtmlVariableVariant {
    type: VariableVariant;
    value?: string | boolean | number;
    content?: any;
}

export interface ArticleTemplate {
    id: number;
    name: string;
    html: string;
    css: string;
    html_variable_type: HtmlVariableType;
    created_by: number;
    archived_at?: Date | null;
    deleted_at?: Date;
    created_at: Date;
    updated_at: Date;
    themes: Partial<ArticleTemplateTheme>[];
}

export interface ArticleTemplateTheme {
    id?: number;
    template_id?: number;
    name: string;
    css_values: Record<string, string>;
    deleted_at?: Date;
    created_at?: Date;
    updated_at?: Date;
}

export interface TemplateResponseWithArticlesCount extends ArticleTemplate {
    articles_count: number;
    themes: Partial<ArticleTemplateTheme>[];
}

export interface PaginatedList<T> {
    rows: T[];
    total: number;
    offset: number;
    limit: number;
}

export type ArticleTemplateListResponse = PaginatedList<TemplateResponseWithArticlesCount>;

export interface ArticleTemplateResponse extends ArticleTemplate {
    themes: ArticleTemplateTheme[];
}

export type ArticleListResponse = PaginatedList<ArticleWithTagsAndTranslations>;

export interface ArticleOldOrNewResponse {
    id: string;
    title: string;
    isOldArticle: boolean;
}

export interface ArticleWithTagsAndTranslations extends Article {
    translations?: ArticleTranslation[];
    tags?: Tag[];
}

export interface Article {
    id: number;
    template_id: number;
    theme_id: number;
    folder_id: number;
    name: string;
    slug: string;
    status: ArticleStatus;
    type: ArticleType;
    start_date?: Date;
    end_date?: Date;
    products?: string[];
    rethinkdb_id?: string;
    created_by: number;
    updated_by?: number;
    deleted_at?: Date;
    created_at: Date;
    updated_at: Date;
    title?: string;
}

export interface ArticleTranslation {
    id: number;
    article_id: number;
    language: SupportedLanguages;
    slug: string;
    body?: string;
    html_variables?: HtmlVariable[];
    seo_title?: string;
    seo_desc?: string;
    seo_img?: string;
    promotion_title?: string;
    promotion_subtitle?: string;
    promotion_img?: string;
    created_by: number;
    updated_by?: number;
    deleted_at?: Date;
    created_at?: Date;
    updated_at?: Date;
    title?: string;
}

export type ArticlesList = PaginatedList<Article>;

export interface Tag {
    id?: number;
    name: string;
}

export interface TranslationRequest
    extends Omit<
        ArticleTranslation,
        'id' | 'article_id' | 'title' | 'created_by' | 'updated_by' | 'deleted_at' | 'created_at' | 'updated_at'
    > {
    id?: number;
}

export interface ArticleCreationAndUpdateRequest
    extends Omit<
        Article,
        'id' | 'slug' | 'rethinkdb_id' | 'created_by' | 'updated_by' | 'deleted_at' | 'created_at' | 'updated_at'
    > {
    translations?: TranslationRequest[];
    tags?: Tag[];
}

export interface CreateArticleTemplateRequestBody {
    name: string;
}

export enum VariableVariant {
    DEFAULT = 'default',
    SHOW_ON_DATE = 'show-on-date',
    SHOW_UNTIL_DATE = 'show-until-date',
    SHOW_AFTER_DATE = 'show-after-date',
    LOGGED_IN = 'logged-in',
    LOGGED_OUT = 'logged-out',
    OPEN_MODAL = 'open-modal',
}

export const variableVariantOptionsDict: Record<VariableVariant, string> = {
    [VariableVariant.DEFAULT]: 'Default',
    [VariableVariant.SHOW_ON_DATE]: 'Show on',
    [VariableVariant.SHOW_UNTIL_DATE]: 'Show until',
    [VariableVariant.SHOW_AFTER_DATE]: 'Show after',
    [VariableVariant.LOGGED_IN]: 'Logged In',
    [VariableVariant.LOGGED_OUT]: 'Logged Out',
    [VariableVariant.OPEN_MODAL]: 'Open modal',
};
