import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Select } from 'antd';
import keyBy from 'lodash/keyBy';
import { UiSelectorSearch } from './search/UiSelectorSearch';
import isPlainObject from 'lodash/isPlainObject';
import { LabeledValue } from 'antd/lib/select';
import Wrapper from './styles';
import { filterStyleProps } from '../../../services/util';
import { UiSelectorProps } from './search/types';

type id = number | string | string[] | undefined;

function UiSelector(props: UiSelectorProps, ref) {
    const {
        search,
        initialValue,
        isDisabled,
        onSearch,
        onSelected,
        onDeselect,
        placeholder,
        options,
        autoFocus,
        onBlur,
        defaultOpen,
        allowClear = true,
        showSearch = true,
        defaultActiveFirstOption = false,
        displayIdWithOption = false,
        showArrow = false,
        renderOptions,
        fieldNames = { label: 'label', value: 'value', title: 'title' },
        includeEmptyOption = false,
        isTableInput,
        popupClassName,
        showNotFoundContent = false,
        wrapperClassName,
        filteringDisabled = false,
        ...selectGenericProps
    } = props;
    const { Option } = Select;
    const [selectedId, setSelectedId] = useState<id>(initialValue);
    const optionsById = keyBy(options, fieldNames.value);
    const [displayOptions, setDisplayOptions] = useState<any[]>(options);
    const isArrayOfObjects = isPlainObject(displayOptions?.[0]);
    const searchId = 'searchId';

    useEffect(() => {
        setSelectedId(initialValue);
    }, [initialValue]);

    useEffect(() => {
        const clonedOptions = [...options];
        if (search?.value) {
            clonedOptions.unshift({ id: searchId, name: search.value });
        }
        setDisplayOptions(clonedOptions);
    }, [options]);

    function onChange(id: id) {
        if (!onSelected) return;

        setSelectedId(id);
        if (!id) search?.setValue(undefined);
        if (searchId === id) {
            onSelected(undefined);
            return;
        }
        if (options.length > 0) {
            if (isArrayOfObjects) {
                if (selectGenericProps.mode === 'multiple') {
                    onSelected(id);
                } else {
                    onSelected(optionsById[id as number]);
                }
            } else {
                onSelected(id);
            }
        }
    }

    function renderOptionView(option: LabeledValue) {
        if (option[fieldNames.value] && displayIdWithOption) {
            return option[fieldNames.value] === searchId ? (
                <UiSelectorSearch option={option[fieldNames.label] as string} />
            ) : (
                `${option[fieldNames.value]} - ${option[fieldNames.label]}`
            );
        }

        return option[fieldNames.value] === searchId ? (
            <UiSelectorSearch option={option[fieldNames.label] as string} />
        ) : (
            option[fieldNames.label]
        );
    }

    useImperativeHandle(ref, () => ({
        clear() {
            if (onSelected) {
                onSelected(undefined);
            }
            setSelectedId(null as any);
        },
        setSelected(value: id) {
            onChange(value);
        },
    }));

    function renderOptionsItems() {
        if (!!renderOptions) {
            return renderOptions();
        }
        const defaultOption = (
            <Option value="" key="empty">
                {''}
            </Option>
        );
        const allOptions = isArrayOfObjects
            ? displayOptions?.map(t => (
                  <Option
                      value={t[fieldNames.value]}
                      key={t[fieldNames.value]}
                      label={t[fieldNames.label]}
                      disabled={t.disabled}
                      className={t.className}
                      title={t[fieldNames.title || fieldNames.label]}
                  >
                      {renderOptionView(t)}
                  </Option>
              ))
            : displayOptions?.map((value, index) => (
                  <Option value={value} key={index}>
                      {value}
                  </Option>
              ));
        return includeEmptyOption
            ? [defaultOption, ...(allOptions || [])]
            : allOptions?.filter(o => o.props.value !== undefined);
    }

    return (
        <Wrapper
            className={wrapperClassName}
            allowClear={allowClear}
            showSearch={showSearch}
            value={selectedId ? selectedId : initialValue}
            placeholder={placeholder}
            defaultActiveFirstOption={defaultActiveFirstOption}
            showArrow={showArrow}
            filterOption={(input, option: any) => {
                if (filteringDisabled) {
                    return true;
                }
                if (option?.value === searchId) {
                    return true;
                }
                if (typeof option?.children === 'string') {
                    return option.children.toLowerCase().includes(input.toLowerCase());
                }
                return (
                    option?.children
                        ?.filter(child => typeof child === 'string')
                        .findIndex(string => string.toLowerCase().includes(input.toLowerCase())) !== -1
                );
            }}
            notFoundContent={showNotFoundContent ? undefined : null}
            disabled={isDisabled}
            onSearch={search?.setValue || onSearch}
            onChange={onChange}
            optionLabelProp="label"
            autoFocus={autoFocus}
            onBlur={onBlur}
            getPopupContainer={trigger => (isTableInput ? document.body : trigger.parentNode)}
            defaultOpen={defaultOpen}
            popupClassName={popupClassName}
            onDeselect={onDeselect}
            {...filterStyleProps(selectGenericProps)}
        >
            {renderOptionsItems()}
        </Wrapper>
    );
}

export default forwardRef(UiSelector);
