import { Popover, Radio, RadioGroupProps } from 'antd';
import { RadioGroupButtonStyle, RadioGroupOptionType } from 'antd/lib/radio/interface';
import Wrapper from './styles';

interface Props extends RadioGroupProps {
    options: Option[];
    isMobile?: boolean;
    isNoWrap?: boolean;
    optionType?: RadioGroupOptionType;
    buttonStyle?: RadioGroupButtonStyle;
}

const { Button } = Radio;

export function UiRadio({
    options,
    defaultValue,
    onChange,
    size,
    value,
    isMobile = false,
    isNoWrap = false,
    optionType = 'button',
    buttonStyle = 'outline',
    disabled,
}: Props) {
    function getSize() {
        if (size) {
            return size;
        }

        return isMobile ? 'small' : 'large';
    }

    return (
        <Wrapper
            defaultValue={defaultValue}
            onChange={onChange}
            size={getSize()}
            optionType={optionType}
            buttonStyle={buttonStyle}
            value={value}
            disabled={disabled}
            isNoWrap={isNoWrap}
        >
            {Object.values(options).map(({ label, value, tooltip, className }) =>
                tooltip ? (
                    <Popover key={value.toString()} trigger="hover" content={tooltip}>
                        {/* eslint-disable-next-line rulesdir/forbid-jsx-component */}
                        <Button value={value} className={className}>
                            {label}
                        </Button>
                    </Popover>
                ) : (
                    // eslint-disable-next-line rulesdir/forbid-jsx-component
                    <Button key={value.toString()} value={value} className={className}>
                        {label}
                    </Button>
                ),
            )}
        </Wrapper>
    );
}

interface Option {
    label: string;
    value: string | number | boolean;
    tooltip?: string;
    className?: string;
}
