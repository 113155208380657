import { getServiceUrl, httpGet, httpPost, httpPut } from '../services/api';
import { replaceEmptyValuesByUndefined } from '../services/util';
import {
    WarehouseProcess,
    WarehouseActivityHistory,
    WarehouseExtract,
    WarehouseExtractColumn,
    WarehouseExtractBookmark,
    WarehouseExtractHistoricalValidationSettings,
    WarehouseExtractTableHistory,
    WarehouseExtractColumnHistory,
    WarehouseGeneralErrors,
    WarehouseGeneralHandleErrors,
    WarehouseDelivery,
    WarehouseDeliveryDetails,
    WarehouseDeliveryBookmark,
    WarehouseDeliveryValidationSettings,
    WarehouseDeliveryRegularValidationSettings,
    WarehouseDeliverySettings,
    WarehouseDeliveryBookmarkRequest,
    WarehouseDeliveryRegularValidationSettingsRequest,
    GeneralErrorsFilter,
    ValidationErrorsFilter,
    WarehouseValidationErrors,
    RegularValidationErrorsFilter,
    WarehouseRegularValidationErrors,
} from '../types/warehouse-management';

const getUrl = url => getServiceUrl('warehouse-management', url);

export async function getWarehouseProcessList() {
    const url = getUrl('process/list');
    return httpGet<WarehouseProcess[]>(url);
}

export async function updateWarehouseProcess(id: number, process: Partial<WarehouseProcess>) {
    const url = getUrl(`process/${id}`);
    return httpPut<WarehouseProcess>(url, { ...process, id });
}

export async function getWarehouseActivityHistory(id: number) {
    const url = getUrl('process/table-history/');
    return httpGet<WarehouseActivityHistory[]>(url, { process_history_id: id });
}

export async function getWarehouseExtractList() {
    const url = getUrl('extract/list');
    return httpGet<WarehouseExtract[]>(url);
}

export async function getWarehouseExtractDetails(id: number) {
    const url = getUrl(`extract/${id}`);
    return httpGet<WarehouseExtract>(url);
}

export async function updateWarehouseExtractDetails(id: number, details: Partial<WarehouseExtract>) {
    const url = getUrl(`extract/${id}`);
    return httpPut<WarehouseExtract>(url, { ...details, id });
}

export async function getWarehouseExtractColumnList(id: number) {
    const url = getUrl(`extract/column/list/${id}`);
    return httpGet<WarehouseExtractColumn[]>(url);
}

export async function updateWarehouseExtractColumn(id: number, column: Partial<WarehouseExtractColumn>) {
    const url = getUrl(`extract/column/${id}`);
    return httpPut<WarehouseExtractColumn>(url, { ...column, id });
}

export async function getWarehouseExtractColumnHistory(id: number) {
    const url = getUrl(`extract/column/history/${id}`);
    return httpGet<WarehouseExtractColumnHistory[]>(url);
}

export async function getWarehouseExtractBookmarks(id: number) {
    const url = getUrl(`extract/bookmarks/${id}`);
    return httpGet<WarehouseExtractBookmark[]>(url);
}

export async function updateWarehouseExtractBookmark(id: number, bookmark: WarehouseExtractBookmark) {
    const url = getUrl(`extract/bookmarks/${id}`);
    return httpPut<WarehouseExtractBookmark>(url, bookmark);
}

export async function getWarehouseExtractHistoricalValidationSettings(id: number) {
    const url = getUrl(`extract/historical-validation/${id}`);
    return httpGet<WarehouseExtractHistoricalValidationSettings>(url);
}

export async function updateWarehouseExtractHistoricalValidationSettings(
    id: number,
    historicalValidationSettings: Partial<WarehouseExtractHistoricalValidationSettings>,
) {
    const url = getUrl(`extract/historical-validation/${id}`);
    return httpPut<WarehouseExtractHistoricalValidationSettings>(url, historicalValidationSettings);
}

export async function getWarehouseExtractTableHistory(id: number) {
    const url = getUrl(`extract/table/history/${id}`);
    return httpGet<WarehouseExtractTableHistory[]>(url);
}

export async function getWarehouseExtractErrors(filter: GeneralErrorsFilter) {
    const url = getUrl(`error/extract/list`);
    return httpGet<WarehouseGeneralErrors>(url, replaceEmptyValuesByUndefined(filter));
}

export async function getWarehouseDeliveryGeneralErrors(filter: GeneralErrorsFilter) {
    const url = getUrl(`error/delivery/list`);
    return httpGet<WarehouseGeneralErrors>(url, replaceEmptyValuesByUndefined(filter));
}

export async function getWarehouseDeliveryValidationErrors(filter: ValidationErrorsFilter) {
    const url = getUrl(`error/delivery/validation/list`);
    return httpGet<WarehouseValidationErrors>(url, replaceEmptyValuesByUndefined(filter));
}

export async function getWarehouseDeliveryRegularValidationErrors(filter: RegularValidationErrorsFilter) {
    const url = getUrl(`error/delivery/regular-validation/list`);
    return httpGet<WarehouseRegularValidationErrors>(url, replaceEmptyValuesByUndefined(filter));
}

export async function updateWarehouseGeneralErrors(errors: WarehouseGeneralHandleErrors) {
    const url = getUrl(`error/extract/handle/bulk`);
    return httpPut<WarehouseGeneralErrors>(url, errors);
}

export async function getWarehouseDeliveryList() {
    const url = getUrl('delivery/table/list');
    return httpGet<WarehouseDelivery[]>(url);
}

export async function getWarehouseDeliveryDetails(id: number) {
    const url = getUrl(`delivery/table/${id}`);
    return httpGet<WarehouseDeliveryDetails>(url);
}

export async function updateWarehouseDeliveryProcessSettings(id: number, settings: Partial<WarehouseDeliverySettings>) {
    const url = getUrl(`process/table/${id}`);
    return httpPut<WarehouseDelivery>(url, { ...settings, id });
}

export async function getWarehouseDeliveryBookmarks(id: number) {
    const url = getUrl(`delivery/bookmarks/${id}`);
    return httpGet<WarehouseDeliveryBookmark[]>(url);
}

export async function updateWarehouseDeliveryBookmark({ id, bookmark }: WarehouseDeliveryBookmarkRequest) {
    const url = getUrl(`delivery/bookmarks/${id}`);
    return httpPut<WarehouseDeliveryBookmark>(url, { id, bookmark });
}

export async function getWarehouseDeliveryValidationSettings(id: number) {
    const url = getUrl(`delivery/validation/list/${id}`);
    return httpGet<WarehouseDeliveryValidationSettings[]>(url);
}

export async function updateWarehouseDeliveryValidationSettings(
    id: number,
    settings: Partial<WarehouseDeliveryValidationSettings>,
) {
    const url = getUrl(`delivery/validation/${id}`);
    return httpPut<WarehouseDeliveryValidationSettings>(url, { ...settings, id });
}

export async function getWarehouseDeliveryRegularValidationSettings(id: number) {
    const url = getUrl(`delivery/regular-validation/list/${id}`);
    return httpGet<WarehouseDeliveryRegularValidationSettings[]>(url);
}

export async function createWarehouseDeliveryRegularValidationSettings(
    settings: WarehouseDeliveryRegularValidationSettingsRequest,
) {
    const url = getUrl('delivery/regular-validation');
    return httpPost<WarehouseDeliveryRegularValidationSettings>(url, settings);
}

export async function updateWarehouseDeliveryRegularValidationSettings(
    id: number,
    settings: Partial<WarehouseDeliveryRegularValidationSettings>,
) {
    const url = getUrl(`delivery/regular-validation/${id}`);
    return httpPut<WarehouseDeliveryRegularValidationSettings>(url, { ...settings, id });
}
