import { UiSwitch } from 'components/ui/switch/UiSwitch';
import React from 'react';
import { IS_AMERICAN_ODDS_FORMAT } from 'services/odds/odds';
import { store, useStore } from 'stores/store';

export function HeaderUserSettingsOddsFormat() {
    const [isOddsFormatAmerican, setIsOddsFormatAmerican] = useStore(store.sportsbook.isOddsFormatAmerican);

    function onChange(value: boolean) {
        localStorage.setItem(IS_AMERICAN_ODDS_FORMAT, String(value));
        setIsOddsFormatAmerican(value);
        window.location.reload();
    }

    return <UiSwitch name="American View" isChecked={isOddsFormatAmerican} onChange={onChange} />;
}
