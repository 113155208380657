import dayjs from 'services/dayjs';
import { sendAlarmResponsibility } from 'microservices/sports/responsible-bookmaker';
import { store } from 'stores/store';
import { getStoreValue } from 'stores/utils';
import { logger } from './logger';
import { RiskReachedAlerts } from '@staycool/sportsbook-risk-types/alarms';
import { MatchStatus, OutcomeTradingPosition } from '@staycool/sports-types';

export const alarmsChannelSettings = {
    service: 'sportsbook-reports',
    channel: 'alarms',
    keepAlive: false,
};

export const riskReachedChannelSettings = {
    service: 'sportsbook-reports',
    channel: 'risk-reached',
    keepAlive: false,
};

export const responsibleBookmakerChannelSettings = {
    service: 'sports',
    channel: 'responsible-bookmaker',
    keepAlive: true,
};

export const ALARM_BOOKMAKER_KEY = 'alarmsResponsibleBookmakers';
export const ALARM_BOOKMAKER_CHANNEL = 'alarmsBookmakerChannel';
export const ALARM_VOLUME_KEY = 'settings-alarm-sound-volume';

export async function sendResponsibility(bookmakerIds?: number[]) {
    const bookieIds = bookmakerIds || getStoreValue(store.sportsbook.alarm.filteredBookmakerIds);
    const localStorageKey = 'responsible-bookmaker-alarms-last-send';
    const lastSend = localStorage.getItem(localStorageKey);
    if (!lastSend || dayjs(lastSend).isBefore(dayjs().subtract(10, 'seconds'))) {
        localStorage.setItem(localStorageKey, dayjs().toISOString());
        try {
            await sendAlarmResponsibility(bookieIds);
        } catch (error) {
            logger.error('AlarmService', 'sendResponsibility', error);
        }
    }
}

export function isAlarmWithHighRisk(alarm: RiskReachedAlerts) {
    const alarmPercentage = -1 * (alarm.risk_reached * 100);
    return alarmPercentage > 80;
}

export function isAlarmExpired(alarm: RiskReachedAlerts) {
    const now = dayjs();
    const bettingEndTime = getBettingEndTime(alarm);
    const alarmLastReached = getAlarmReached(alarm);
    return alarmLastReached <= now || bettingEndTime < now;
}

export function getAlarmsFilteredByMe(alarmsList: RiskReachedAlerts[], filteredBookmakerIds: number[]) {
    return alarmsList.filter(alarm => {
        const bookmakers = alarm.responsible_bookmakers || [];
        return bookmakers.some(bookmaker => filteredBookmakerIds.includes(bookmaker));
    });
}

export function getAlarmsFilteredByOthers(alarmsList: RiskReachedAlerts[]) {
    const activeWatchers = getStoreValue(store.sportsbook.alarm.activeWatchers);
    const othersWatching = activeWatchers.flatMap(watcher => watcher.watching);

    return alarmsList.filter(alarm => {
        const bookmakers = alarm.responsible_bookmakers || [];
        return bookmakers.some(bookmaker => othersWatching.includes(bookmaker));
    });
}

export function getAlarmExpirationTimeInMilliseconds(alarm: RiskReachedAlerts) {
    const alarmLastReachedTimeDuration = getAlarmLastReachedInMilliseconds(alarm);
    const bettingEndTimeDuration = getBettingEndInMilliseconds(alarm);

    return alarmLastReachedTimeDuration < bettingEndTimeDuration
        ? alarmLastReachedTimeDuration
        : bettingEndTimeDuration;
}

function getAlarmLastReachedInMilliseconds(alarm: RiskReachedAlerts) {
    const alarmLastReached = getAlarmReached(alarm);
    const now = dayjs();
    return dayjs.duration(alarmLastReached.diff(now)).as('milliseconds');
}

function getAlarmReached(alarm: RiskReachedAlerts) {
    const alarmPercentage = -1 * (alarm.risk_reached * 100);
    return alarmPercentage >= 100
        ? dayjs(alarm.last_reached_at).add(5, 'minutes')
        : dayjs(alarm.last_reached_at).add(10, 'minutes');
}

function getBettingEndInMilliseconds(alarm: RiskReachedAlerts) {
    const now = dayjs();
    const bettingEndTime = getBettingEndTime(alarm);
    return dayjs.duration(bettingEndTime.diff(now)).as('milliseconds');
}

function getBettingEndTime(alarm: RiskReachedAlerts) {
    return dayjs(alarm.betting_end).subtract(30, 'minutes');
}

export function getAlarmsPercentage(risk: number = 0) {
    return Math.floor(Math.max(-1 * (risk * 100), 0));
}

export type RiskByOutcomeId = Record<string, AlarmRow>;

export interface AlarmRow {
    marketLine: number;
    matchId: number;
    matchName: string;
    homeTeamName: string;
    awayTeamName: string;
    teamNames: string[];
    matchStatus: MatchStatus;
    sportId: number;
    sportName: string;
    marketId: number;
    leagueId: number;
    leagueName: string;
    regionId: number;
    regionName: string;
    matchStartTime: Date;
    marketName: string;
    outcomeName: string;
    tradingPosition: OutcomeTradingPosition;
    oddsValue: number;
    netRisk: number;
    riskReached: number;
    lastReachedAt: Date | null;
    outcomeId: number;
    offerDoubleLimits: boolean;
    responsibleBookmakers: number[] | null;
    marketTypeId: number;
    marketOutcomeId: number;
    marketTypeGroup: string;
    internalComment: string | null;
    source: string | null;
}
