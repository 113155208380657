import styled from 'styled-components';

export default styled.span`
    .status-icon,
    .anticon svg {
        width: 1rem;
    }
    .alarm-icon {
        font-size: 1.7em;
    }
    .time-container {
        font-weight: 500;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        user-select: none;
    }
`;
