import { mediaQuery } from 'services/media';
import styled from 'styled-components';

export default styled.div`
    display: flex;
    background-color: ${props => props.backgroundColor};

    .header-menu.ant-menu {
        &,
        & .ant-menu-submenu-selected {
            background-color: inherit;
        }
    }

    .header-menu.ant-menu-overflow {
        display: flex;
        border-bottom: none;
    }

    .header-menu {
        text-align: center;
        flex-grow: 1;
        min-width: 0;
        flex: auto;
    }

    .header-menu.ant-menu .ant-menu-submenu {
        padding: 0 1rem;
    }

    .header-menu.ant-menu .ant-menu-submenu .ant-menu-title-content {
        margin-left: 0.5rem;
    }

    @media ${mediaQuery.isDesktopSmall} {
        .header-menu.ant-menu .ant-menu-submenu {
            padding: 0 0.65rem;
        }

        .header-menu.ant-menu .ant-menu-submenu .ant-menu-title-content {
            margin-left: 0.2rem;
        }
    }
`;
