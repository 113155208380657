import { Filter } from 'microservices/retail-proxy';
import { color } from 'style/variables';
import { TerminalErrors, TerminalStatus } from '@staycool/retail-types/terminal';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import dayjs from 'dayjs';
import isObject from 'lodash/isObject';
import { FoCategoryMatchMarket, FoSidebetsMarketGroupMarket } from '@staycool/sbgate-types';
import { RetailMatchMarket } from '@staycool/sports-types/search';
import { FormattedParlayMarketBO, ParlayMarketOutcomeBO } from './sports/parlay-card/pdf-generator';
import { addZero } from './market';

export const reportDateOptions: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
};

export const systemBetTypeBySystemKey = {
    1: 'SINGLE',
    2: 'DOUBLE',
    3: 'TREBLE',
    4: 'FOURFOLD',
    5: 'FIVEFOLD',
    6: 'SIXFOLD',
    7: 'SEVENFOLD',
    8: 'EIGHTFOLD',
    9: 'NINEFOLD',
    10: 'TENFOLD',
};

export const ticketTypeUsaNames = {
    single: 'single',
    combo: 'parlay',
    system: 'round-robin',
    teaser: 'teaser',
    parlayCard: 'parlay card',
};

export enum RetailIdMasks {
    VoucherIdMask = '____-____-__',
    TicketIdMask = '________-____-____-____-____________',
    VoucherIdMaskWithCurtain = '____-****-**',
    TicketIdMaskWithCurtain = '________-____-____-____-************',
    VoucherIdMaskRestricted = '____-...',
    TicketIdMaskRestricted = '________-____-...',
}

export function getOverUnderLine(
    viewType: string,
    outcome: FoCategoryMatchMarket['outcomes'][0] | FoSidebetsMarketGroupMarket['outcomes'][0] | ParlayMarketOutcomeBO,
    market: FoCategoryMatchMarket | FoSidebetsMarketGroupMarket | RetailMatchMarket | FormattedParlayMarketBO,
    shouldDisplayLetter: boolean = true,
    forcedLine: string = '',
) {
    if (viewType === 'line' && outcome && ['Over', 'Under'].includes(outcome.result_key) && market) {
        const line = `${shouldDisplayLetter ? outcome.name.slice(0, 1) : ''} ${addZero(Number(market.raw_line))}`;
        return forcedLine || line;
    }
    return null;
}

export function getStatusColor(status: TerminalStatus, errors?: TerminalErrors) {
    const colorByStatus = {
        [TerminalStatus.Blocked]: color.gold700,
        [TerminalStatus.Enabled]: color.green500,
        [TerminalStatus.Disabled]: color.red500,
    };
    if (status === TerminalStatus.Enabled && errors?.length) {
        return color.gold700;
    }
    return colorByStatus[status];
}

export function getTerminalStatus(status: TerminalStatus) {
    if (status === TerminalStatus.Blocked) {
        return TerminalStatus.Enabled;
    }

    return status;
}

export function formatToUsLocaleNumber(value: string | number): string {
    return Number(value).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
}

export function formatAmount(amount: number) {
    const formatedAmount = formatToUsLocaleNumber(Math.abs(amount));
    return amount < 0 ? `- $${formatedAmount}` : `$${formatedAmount}`;
}

export function formatDate(
    date: Date,
    options: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    },
) {
    return new Date(date).toLocaleString('en-US', options);
}

export function onTablePageChange(
    pageNumber: number,
    pageSize: number = 15,
    setCurrentPage: (pageNumber: number) => void,
    setPageSize: (pageNumber: number) => void,
) {
    setCurrentPage(pageNumber);
    setPageSize(pageSize);
}

export function onTableChange(
    sorter: Record<string, any>,
    orderBy: Filter['orderBy'],
    setOrderBy: (orderBy: Filter['orderBy']) => void,
) {
    const { field = orderBy?.field, order = orderBy?.direction } = sorter;
    const direction = order === 'ascend' ? 'asc' : 'desc';
    if (!(orderBy && orderBy.field === field && orderBy.direction === direction)) {
        setOrderBy({ field, direction });
    }
}

export const keyboardLetters = ['a', 'b', 'c', 'd', 'e', 'f'];

export function ticketTypeFormatter(type?: string) {
    if (type?.toLowerCase() === 'betbuilder') {
        return 'SGP';
    }
    return type || '';
}

export function getFilter(filter, isReportType = false) {
    const ignoredFields = ['type', 'inquiryFilterActiveField'];
    !isReportType && ignoredFields.push('report');
    return omitBy(omit(filter, ignoredFields), field => (isObject(field) ? isEmpty(field) : !field));
}

export function getReportTimestamp() {
    return dayjs().format('MM-DD-YYYY-hh-mm-ss-a');
}

export interface UserMinimalFilter {
    name?: string;
    orderBy?: string;
}

export const retailPageSizeOptions = [50, 100, 250];
export const retailDefaultPageSize = 50;

export enum ReportDatePresets {
    TODAY = 'Today',
    YESTERDAY = 'Yesterday',
    THIS_WEEK = 'This week',
    LAST_WEEK = 'Last week',
    LAST_6_HOURS = 'Last 6 hours',
    LAST_12_HOURS = 'Last 12 hours',
}
