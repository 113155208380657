import { getStoreValue, store } from 'stores/store';
import { ClientName } from './types/client';

export type ClientOnlyFeature =
    | 'amlMonitoring'
    | 'amlReports'
    | 'amlSettings'
    | 'articles'
    | 'articles_v2'
    | 'articlesOld'
    | 'autoPayout'
    | 'automaticAccountReopeningScheduling'
    | 'AVATARS'
    | 'bannerMessages'
    | 'betBuilderExcludedCountries'
    | 'betBuilder'
    | 'betradar'
    | 'betslipBankers'
    | 'blog'
    | 'bonusAssignment'
    | 'bonusCodes'
    | 'bonuses'
    | 'bonusAutoApply'
    | 'enterLoyaltyManually'
    | 'bonusExpiryWindow'
    | 'bonusFeatureRecurrencySettings'
    | 'bonusFeatureStatistics'
    | 'bonusFeatureCostThresholdAlerts'
    | 'bonusProductCasino'
    | 'bonusProductPoker'
    | 'bonusProductSportsbook'
    | 'bonusProductVirtualSports'
    | 'bonusTypesCampaigns'
    | 'bonusTypesDeposit'
    | 'bonusTypesFreeBet'
    | 'bonusTypesFreeBetV2'
    | 'bonusTypesFreeMoney'
    | 'bonusTypesFreeSpins'
    | 'bonusTypesFeatureFreeSpins'
    | 'bonusTypesMatchOfTheDay'
    | 'bonusTypesMultiplier'
    | 'bonusTypesPending'
    | 'bonusTypesRiskFreeBet'
    | 'campaigns'
    | 'campaigns_v2'
    | 'cams'
    | 'cancelOnlyPendingTickets'
    | 'cashout'
    | 'cashoutDetails'
    | 'casino'
    | 'chipsPayment'
    | 'cmsGeneralSettings'
    | 'contests'
    | 'coreBOlink'
    | 'coreMarketCheck'
    | 'createNewUser'
    | 'crm'
    | 'defaultMarginKey'
    | 'depositLock'
    | 'digitalBoard'
    | 'displayLastWagerDate'
    | 'dynamicLimits'
    | 'email'
    | 'excludedCountries'
    | 'externalComment'
    | 'featuredCountries'
    | 'folders'
    | 'frontOffice'
    | 'governmentIds'
    | 'highRisk'
    | 'hotEvents'
    | 'inPerson'
    | 'kyc'
    | 'kycMasterMail'
    | 'limitsConfiguration'
    | 'limitsLock'
    | 'loginHistory'
    | 'loginToFo'
    | 'loginToFoValidation'
    | 'loyalty'
    | 'loyaltyId'
    | 'managedWallet'
    | 'manualAcceptance'
    | 'manualCorrectionCustomerGoodwill'
    | 'manualCorrectionTaxWithholding'
    | 'manualCorrectionUnclaimedProperty'
    | 'manualCorrectionsGuide'
    | 'mediaGallery'
    | 'coolbetOpen'
    | 'manualPayout'
    | 'marketing'
    | 'matchOfTheDay'
    | 'matchPromotion'
    | 'middleName'
    | 'multiCurrencyWallet'
    | 'oddsConversion'
    | 'onsiteMessageDisplayAsModal'
    | 'onsiteMessageDisplayAsNotification'
    | 'openbet'
    | 'overallRisk'
    | 'overallRiskV2'
    | 'parlayCard'
    | 'parlayCardDownload'
    | 'paymentMethodLinking'
    | 'payments'
    | 'paymentsExportProviders'
    | 'paymentsOthers'
    | 'paymentPackages'
    | 'paymentsProviderRouting'
    | 'payoutTable'
    | 'pendingBetStatistics'
    | 'pep'
    | 'poker'
    | 'printApplication'
    | 'profanity'
    | 'payback'
    | 'promotions'
    | 'pspBalances'
    | 'quiz'
    | 'racebookRaces'
    | 'reconciliation'
    | 'referAFriend'
    | 'registrationBtobBasic'
    | 'registryCheck'
    | 'responsibleGaming'
    | 'responsibleGamingAdvancedTimeouts'
    | 'resultRequest'
    | 'resultingRequest'
    | 'retail'
    | 'retailOtc'
    | 'retailOtcCustomerFace'
    | 'retailVoidManualAcceptance'
    | 'reusableBonusBets'
    | 'riskAssessment'
    | 'riskAssessmentTool'
    | 'riskGroups'
    | 'riskGroupsUsaOnly'
    | 'salutation'
    | 'sameMatchParlay'
    | 'scratchcards'
    | 'securityMatches'
    | 'segments'
    | 'segmentsOld'
    | 'segmentUserIdValidation'
    | 'sendGdprEmailToCustomer'
    | 'signupProperty'
    | 'smartResulting'
    | 'snippets'
    | 'snippetsOld'
    | 'socialMediaLogin'
    | 'sourceOfWealth'
    | 'teasers'
    | 'termsAndConditions'
    | 'tickets'
    | 'ticketListLoyalty'
    | 'tournaments'
    | 'tradingPositions'
    | 'transactionsHistoryReport'
    | 'translations'
    | 'turnover'
    | 'twoFactorAuth'
    | 'upsell'
    | 'USE_NEW_OTC_AUTH'
    | 'userAddress'
    | 'userAlias'
    | 'userCategoryLimitRatios'
    | 'userComments'
    | 'userCommentsAml'
    | 'userCommentsComment'
    | 'userDetailsEditing'
    | 'userLocation'
    | 'userMarketTypeLimitRatios'
    | 'userOccupation'
    | 'userPersonalInformation'
    | 'userPhone'
    | 'userProvince'
    | 'userRegistrationDate'
    | 'userRegistrationSource'
    | 'users'
    | 'userShowInLeaderboard'
    | 'userSportsBonusAbuser'
    | 'userSportsbookClosed'
    | 'userSportsbookStatus'
    | 'userSubscriptions'
    | 'userVerification'
    | 'usLanguage'
    | 'virtualCurrencies'
    | 'virtualSports'
    | 'w2g'
    | 'warehouse'
    | 'whiteHatGaming'
    | 'winlist'
    | 'withdrawals'
    | 'sportsUserKPI'
    | 'sportsOutcomeLimits'
    | 'tableau'
    | 'dwStats'
    | 'ticketListLegacy'
    | 'rackspaceV2';

export type CoreOnlyFeature =
    | 'betgenius'
    | 'clientBOlinks'
    | 'clientConfigs'
    | 'clientCore'
    | 'core'
    | 'feedUsers'
    | 'initialMarginKey'
    | 'liveMarketsCancelButton'
    | 'onlyEditableInCore'
    | 'referenceFeed'
    | 'resultingRequest'
    | 'eiai';

export type CommonFeature =
    | 'alarms'
    | 'addNewMarket'
    | 'ASBformulas'
    | 'betShopFilter'
    | 'bulkMarketSourceUpdatePrematchOnly'
    | 'dotdigital'
    | 'euroConversion'
    | 'feedRules'
    | 'internalComment'
    | 'linksToOldBo'
    | 'livebet'
    | 'livebetConsole'
    | 'maintenance'
    | 'missingResults'
    | 'missingResultsV2'
    | 'mobilePartialManualAcceptance'
    | 'oddsLadders'
    | 'smallReports'
    | 'responsibleBookmakers'
    | 'retailAvailable'
    | 'retailPartialManualAcceptance'
    | 'sameMatchCombo'
    | 'singlesOnly'
    | 'simpleBet'
    | 'sportsOrder'
    | 'ticketFreeze'
    | 'topMarkets'
    | 'trading'
    | 'sportsUsers'
    | 'usaDateFormat'
    | 'comboCard'
    | 'sourceColumn'
    | 'rotationNumbers'
    | 'ticketList'
    | 'manualAcceptanceAggregate'
    | 'marketStatusHistoryInOddsHistory'
    | 'netrisk'
    | 'iterable'
    | 'sportsbook'
    | 'sms2'
    | 'ticketListResponsibleBookmakerLive'
    | 'comboCardSingleMatchMarkets'
    | 'comboCardMultipleMatchMarkets'
    | 'comboCardBetbuilderMarkets'
    | 'smsMessente'
    | 'xtremepush';

export type Feature = ClientOnlyFeature | CoreOnlyFeature | CommonFeature;

const clientOnlyFeatures: Record<ClientOnlyFeature, boolean> = {
    amlMonitoring: true,
    amlReports: false,
    amlSettings: true,
    articles_v2: true,
    articles: true,
    articlesOld: true,
    autoPayout: true,
    automaticAccountReopeningScheduling: false,
    AVATARS: false,
    bannerMessages: true,
    betBuilderExcludedCountries: false,
    betradar: false,
    betslipBankers: true,
    blog: true,
    bonusAssignment: true,
    bonusCodes: true,
    bonuses: true,
    bonusAutoApply: false,
    betBuilder: true,
    bonusExpiryWindow: false,
    bonusFeatureRecurrencySettings: false,
    bonusFeatureStatistics: false,
    bonusFeatureCostThresholdAlerts: false,
    bonusProductCasino: true,
    bonusProductPoker: true,
    bonusProductSportsbook: true,
    bonusProductVirtualSports: true,
    bonusTypesCampaigns: true,
    bonusTypesDeposit: true,
    bonusTypesFreeBet: true,
    bonusTypesFreeBetV2: false,
    bonusTypesFreeMoney: true,
    bonusTypesFreeSpins: true,
    bonusTypesFeatureFreeSpins: false,
    bonusTypesMatchOfTheDay: true,
    bonusTypesMultiplier: true,
    bonusTypesPending: true,
    bonusTypesRiskFreeBet: true,
    campaigns_v2: false,
    campaigns: false,
    cams: false,
    cancelOnlyPendingTickets: true,
    cashout: true,
    cashoutDetails: true,
    casino: false,
    chipsPayment: false,
    cmsGeneralSettings: false,
    contests: false,
    coreBOlink: true,
    coreMarketCheck: true,
    createNewUser: false,
    crm: false,
    defaultMarginKey: true,
    depositLock: false,
    digitalBoard: false,
    displayLastWagerDate: false,
    dynamicLimits: true,
    email: true,
    excludedCountries: false,
    externalComment: true,
    featuredCountries: true,
    folders: true,
    frontOffice: true,
    governmentIds: false,
    highRisk: true,
    hotEvents: true,
    inPerson: false,
    kyc: true,
    kycMasterMail: false,
    limitsConfiguration: true,
    limitsLock: false,
    loginHistory: false,
    loginToFo: true,
    loginToFoValidation: false,
    loyalty: false,
    loyaltyId: false,
    managedWallet: true,
    manualAcceptance: true,
    manualCorrectionCustomerGoodwill: false,
    manualCorrectionTaxWithholding: false,
    manualCorrectionUnclaimedProperty: false,
    manualCorrectionsGuide: true,
    coolbetOpen: true,
    manualPayout: false,
    marketing: true,
    matchOfTheDay: true,
    matchPromotion: true,
    mediaGallery: false,
    middleName: false,
    multiCurrencyWallet: false,
    oddsConversion: true,
    onsiteMessageDisplayAsModal: false,
    onsiteMessageDisplayAsNotification: false,
    openbet: false,
    overallRisk: false,
    overallRiskV2: false,
    parlayCard: true,
    parlayCardDownload: false,
    enterLoyaltyManually: false,
    paymentMethodLinking: false,
    payments: true,
    paymentsExportProviders: false,
    paymentsOthers: false,
    paymentPackages: false,
    paymentsProviderRouting: false,
    payoutTable: true,
    pendingBetStatistics: true,
    pep: false,
    poker: false,
    printApplication: false,
    profanity: false,
    payback: false,
    promotions: false,
    pspBalances: false,
    quiz: false,
    racebookRaces: false,
    rackspaceV2: true,
    reconciliation: false,
    referAFriend: true,
    registrationBtobBasic: false,
    registryCheck: false,
    responsibleGaming: true,
    responsibleGamingAdvancedTimeouts: true,
    resultRequest: false,
    resultingRequest: false,
    retail: false,
    retailOtc: false,
    retailOtcCustomerFace: false,
    retailVoidManualAcceptance: false,
    riskAssessment: true,
    riskAssessmentTool: false,
    riskGroups: true,
    riskGroupsUsaOnly: true,
    salutation: false,
    sameMatchParlay: true,
    scratchcards: true,
    securityMatches: false,
    segments: true,
    segmentsOld: true,
    segmentUserIdValidation: true,
    sendGdprEmailToCustomer: false,
    signupProperty: false,
    smartResulting: true,
    snippets: true,
    snippetsOld: true,
    socialMediaLogin: true,
    sourceOfWealth: true,
    teasers: true,
    termsAndConditions: true,
    tickets: true,
    ticketListLoyalty: false,
    tournaments: false,
    tradingPositions: true,
    transactionsHistoryReport: false,
    translations: true,
    turnover: true,
    twoFactorAuth: false,
    upsell: false,
    USE_NEW_OTC_AUTH: false,
    userAlias: false,
    userAddress: true,
    userCategoryLimitRatios: true,
    userComments: false,
    userCommentsAml: true,
    userCommentsComment: true,
    userDetailsEditing: false,
    userLocation: true,
    userMarketTypeLimitRatios: true,
    userOccupation: true,
    userPersonalInformation: true,
    userPhone: true,
    userProvince: true,
    userRegistrationDate: true,
    userRegistrationSource: true,
    users: true,
    userShowInLeaderboard: true,
    userSportsBonusAbuser: true,
    userSportsbookClosed: true,
    userSportsbookStatus: true,
    userSubscriptions: true,
    userVerification: true,
    usLanguage: false,
    virtualCurrencies: false,
    virtualSports: true,
    w2g: false,
    warehouse: true,
    whiteHatGaming: false,
    winlist: true,
    withdrawals: true,
    reusableBonusBets: false,
    sportsUserKPI: true,
    sportsOutcomeLimits: true,
    tableau: false,
    dwStats: false,
    ticketListLegacy: false,
};

const commonFeatures: Record<CommonFeature, boolean> = {
    alarms: true,
    addNewMarket: true,
    ASBformulas: true,
    betShopFilter: false,
    bulkMarketSourceUpdatePrematchOnly: false,
    dotdigital: false,
    euroConversion: true,
    feedRules: true,
    internalComment: true,
    linksToOldBo: false,
    livebet: true,
    livebetConsole: false,
    maintenance: false,
    missingResults: true,
    missingResultsV2: true,
    mobilePartialManualAcceptance: true,
    oddsLadders: true,
    smallReports: true,
    responsibleBookmakers: true,
    retailAvailable: true,
    retailPartialManualAcceptance: false,
    sameMatchCombo: false,
    singlesOnly: true,
    simpleBet: false,
    sportsOrder: true,
    ticketFreeze: true,
    topMarkets: true,
    trading: true,
    usaDateFormat: false,
    comboCard: false,
    sportsUsers: true,
    sourceColumn: false,
    rotationNumbers: true,
    netrisk: true,
    ticketList: true,
    manualAcceptanceAggregate: true,
    marketStatusHistoryInOddsHistory: true,
    iterable: true,
    sportsbook: true,
    sms2: false,
    ticketListResponsibleBookmakerLive: true,
    comboCardMultipleMatchMarkets: true,
    comboCardSingleMatchMarkets: false,
    comboCardBetbuilderMarkets: false,
    smsMessente: false,
    xtremepush: true,
};

const retailFeatures: Partial<Record<Feature, boolean>> = {
    betShopFilter: true,
    digitalBoard: true,
    retail: true,
    retailOtc: true,
    retailOtcCustomerFace: true,
    resultRequest: true,
};

const wynnbetFeatures: Partial<Record<Feature, boolean>> = {
    amlMonitoring: false,
    articles_v2: false,
    autoPayout: false,
    bannerMessages: false,
    bonusAssignment: false,
    bonusCodes: false,
    bonusExpiryWindow: true,
    betslipBankers: false,
    cancelOnlyPendingTickets: false,
    casino: false,
    email: false,
    kyc: false,
    maintenance: true,
    matchOfTheDay: false,
    overallRisk: true,
    overallRiskV2: true,
    payments: false,
    poker: false,
    referAFriend: false,
    responsibleGaming: false,
    responsibleGamingAdvancedTimeouts: false,
    riskAssessment: false,
    scratchcards: false,
    securityMatches: false,
    sendGdprEmailToCustomer: false,
    socialMediaLogin: false,
    sourceOfWealth: false,
    ticketFreeze: true,
    ticketListLoyalty: true,
    userAddress: false,
    userCommentsAml: false,
    userCommentsComment: false,
    userLocation: false,
    userOccupation: false,
    userPersonalInformation: false,
    userPhone: false,
    userProvince: false,
    userRegistrationDate: false,
    userRegistrationSource: false,
    userShowInLeaderboard: false,
    userSportsBonusAbuser: false,
    userSportsbookClosed: false,
    userSportsbookStatus: false,
    userSubscriptions: false,
    userVerification: false,
    virtualSports: false,
    whiteHatGaming: true,
};

const featuresByClient: Record<string, Partial<Record<Feature, boolean>>> = {
    // coolbet will have ALL the features until coolbet starts working on sportscook-core feed, then "core: true" can be removed
    [ClientName.COOLBET]: {
        ...clientOnlyFeatures,
        ...commonFeatures,
        amlReports: true,
        betBuilderExcludedCountries: true,
        bonusAutoApply: true,
        campaigns_v2: true,
        campaigns: true,
        cancelOnlyPendingTickets: false,
        casino: true,
        cmsGeneralSettings: true,
        contests: true,
        crm: true,
        depositLock: true,
        dotdigital: true,
        excludedCountries: true,
        limitsLock: true,
        linksToOldBo: true,
        livebetConsole: true,
        loginHistory: true,
        loginToFoValidation: true,
        maintenance: true,
        manualPayout: true,
        mediaGallery: true,
        oddsConversion: false,
        overallRisk: true,
        paymentMethodLinking: true,
        paymentsExportProviders: true,
        paymentsOthers: true,
        paymentsProviderRouting: true,
        pep: true,
        poker: true,
        profanity: true,
        promotions: true,
        payback: true,
        pspBalances: true,
        quiz: true,
        rackspaceV2: false,
        reconciliation: true,
        registryCheck: true,
        riskAssessmentTool: true,
        riskGroupsUsaOnly: false,
        scratchcards: true,
        securityMatches: true,
        sendGdprEmailToCustomer: true,
        tournaments: true,
        transactionsHistoryReport: true,
        turnover: true,
        twoFactorAuth: true,
        upsell: true,
        userAlias: true,
        userComments: true,
        winlist: true,
        comboCard: true,
        tableau: true,
        dwStats: true,
        betradar: true,
    },
    [ClientName.COOLBET_PERU]: {
        ...clientOnlyFeatures,
        ...commonFeatures,
        amlReports: true,
        betBuilderExcludedCountries: true,
        bonusAutoApply: true,
        campaigns_v2: true,
        campaigns: true,
        cancelOnlyPendingTickets: false,
        casino: true,
        cmsGeneralSettings: true,
        mediaGallery: true,
        contests: true,
        crm: true,
        depositLock: true,
        dotdigital: true,
        excludedCountries: true,
        limitsLock: true,
        linksToOldBo: true,
        livebetConsole: true,
        loginHistory: true,
        loginToFoValidation: true,
        maintenance: true,
        manualPayout: true,
        oddsConversion: false,
        overallRisk: true,
        paymentMethodLinking: true,
        paymentsExportProviders: true,
        paymentsOthers: true,
        paymentsProviderRouting: true,
        pep: true,
        poker: false,
        profanity: true,
        promotions: true,
        payback: true,
        pspBalances: true,
        quiz: true,
        reconciliation: true,
        registryCheck: true,
        riskAssessmentTool: true,
        riskGroupsUsaOnly: false,
        scratchcards: true,
        securityMatches: true,
        sendGdprEmailToCustomer: true,
        tournaments: true,
        transactionsHistoryReport: true,
        turnover: true,
        twoFactorAuth: true,
        upsell: true,
        userAlias: true,
        userComments: true,
        winlist: true,
        comboCard: true,
        tableau: true,
        dwStats: true,
        betradar: true,
    },
    default: {
        ...clientOnlyFeatures,
        ...commonFeatures,
        addNewMarket: false,
        cancelOnlyPendingTickets: false,
        sendGdprEmailToCustomer: false,
        eiai: false,
        // some clients maybe dont want all of these "client" features, so here we can disabled them
    },
    [ClientName.DEMO]: {
        ...clientOnlyFeatures,
        ...commonFeatures,
        ...retailFeatures,
        maintenance: true,
        sendGdprEmailToCustomer: false,
        scratchcards: false,
        // some clients maybe dont want all of these "client" features, so here we can disabled them
    },
    [ClientName.STATION]: {
        ...clientOnlyFeatures,
        ...commonFeatures,
        ...retailFeatures,
        amlMonitoring: false,
        amlSettings: false,
        articles_v2: false,
        enterLoyaltyManually: true,
        bannerMessages: false,
        betslipBankers: false,
        bonusAutoApply: true,
        bulkMarketSourceUpdatePrematchOnly: true,
        cams: true,
        cancelOnlyPendingTickets: true,
        casino: false,
        chipsPayment: true,
        createNewUser: true,
        contests: true,
        displayLastWagerDate: true,
        euroConversion: false,
        governmentIds: true,
        inPerson: true,
        kyc: false,
        loginHistory: true,
        loginToFo: false,
        loyaltyId: true,
        maintenance: true,
        manualCorrectionCustomerGoodwill: true,
        manualCorrectionTaxWithholding: true,
        manualCorrectionUnclaimedProperty: true,
        manualCorrectionsGuide: false,
        middleName: true,
        mobilePartialManualAcceptance: false,
        parlayCardDownload: true,
        poker: false,
        printApplication: true,
        racebookRaces: true,
        registrationBtobBasic: true,
        responsibleGamingAdvancedTimeouts: false,
        salutation: true,
        securityMatches: false,
        signupProperty: true,
        socialMediaLogin: false,
        sourceOfWealth: false,
        ticketFreeze: true,
        ticketListLoyalty: true,
        transactionsHistoryReport: true,
        usaDateFormat: true,
        userDetailsEditing: true,
        userLocation: false,
        userOccupation: false,
        userVerification: false,
        usLanguage: true,
        virtualSports: false,
        w2g: true,
        warehouse: true,
        retailVoidManualAcceptance: true,
        ticketList: true,
    },
    [ClientName.FONTAINEBLEAU_NV]: {
        ...clientOnlyFeatures,
        ...commonFeatures,
        ...retailFeatures,
        amlMonitoring: false,
        amlSettings: false,
        articles_v2: false,
        enterLoyaltyManually: true,
        bannerMessages: false,
        betslipBankers: false,
        bonusAutoApply: true,
        bulkMarketSourceUpdatePrematchOnly: true,
        cams: true,
        cancelOnlyPendingTickets: true,
        casino: false,
        chipsPayment: true,
        createNewUser: true,
        contests: true,
        displayLastWagerDate: true,
        euroConversion: false,
        governmentIds: true,
        inPerson: true,
        kyc: false,
        loginHistory: true,
        loginToFo: false,
        loyaltyId: true,
        maintenance: true,
        manualCorrectionCustomerGoodwill: true,
        manualCorrectionTaxWithholding: true,
        manualCorrectionUnclaimedProperty: true,
        manualCorrectionsGuide: false,
        middleName: true,
        mobilePartialManualAcceptance: false,
        parlayCardDownload: true,
        poker: false,
        printApplication: true,
        racebookRaces: true,
        registrationBtobBasic: true,
        responsibleGamingAdvancedTimeouts: false,
        salutation: true,
        securityMatches: false,
        signupProperty: true,
        socialMediaLogin: false,
        sourceOfWealth: false,
        ticketFreeze: true,
        ticketListLoyalty: true,
        transactionsHistoryReport: true,
        usaDateFormat: true,
        userDetailsEditing: true,
        userLocation: false,
        userOccupation: false,
        userVerification: false,
        usLanguage: true,
        virtualSports: false,
        w2g: true,
        warehouse: true,
        retailVoidManualAcceptance: true,
        ticketList: true,
    },
    [ClientName.IVC]: {
        ...clientOnlyFeatures,
        ...commonFeatures,
        ...retailFeatures,
        betslipBankers: false,
        cancelOnlyPendingTickets: false,
        euroConversion: false,
        maintenance: true,
        overallRisk: true,
        sendGdprEmailToCustomer: false,
        ticketFreeze: true,
        bonuses: false,
        scratchcards: false,
        sportsUsers: false,
    },
    [ClientName.WYNNBET]: {
        ...clientOnlyFeatures,
        ...commonFeatures,
        ...retailFeatures,
        ...wynnbetFeatures,
        euroConversion: false,
        managedWallet: false,
        retailVoidManualAcceptance: true,
    },
    [ClientName.WYNNBET_MA]: {
        ...clientOnlyFeatures,
        ...commonFeatures,
        ...retailFeatures,
        ...wynnbetFeatures,
    },
    [ClientName.WYNNBET_WV]: {
        ...clientOnlyFeatures,
        ...commonFeatures,
        ...retailFeatures,
        ...wynnbetFeatures,
        euroConversion: false,
        retailVoidManualAcceptance: true,
    },
    [ClientName.WYNNBET_CORE]: {
        ...commonFeatures,
        betBuilder: true,
        betradar: true,
        bonuses: true,
        bonusExpiryWindow: true,
        bonusProductSportsbook: true,
        bonusTypesFreeBet: true,
        bonusTypesMultiplier: true,
        bonusTypesRiskFreeBet: true,
        clientBOlinks: true,
        clientConfigs: true,
        clientCore: true,
        core: true,
        euroConversion: false,
        folders: false,
        initialMarginKey: true,
        liveMarketsCancelButton: true,
        oddsConversion: true,
        onlyEditableInCore: true,
        rotationNumbers: true,
        segments: true,
        segmentUserIdValidation: false,
        reusableBonusBets: true,
        sourceColumn: true,
        whiteHatGaming: true,
        netrisk: true,
        teasers: true,
        overallRiskV2: true,
    },
    [ClientName.WYNNBET_NV]: {
        ...clientOnlyFeatures,
        ...commonFeatures,
        ...retailFeatures,
        ...wynnbetFeatures,
        amlSettings: false,
        articles_v2: true,
        autoPayout: true,
        bannerMessages: true,
        bonusAssignment: true,
        bonusAutoApply: true,
        bonusCodes: true,
        bonusExpiryWindow: false,
        cams: true,
        cancelOnlyPendingTickets: true,
        createNewUser: true,
        displayLastWagerDate: true,
        email: true,
        euroConversion: false,
        governmentIds: true,
        inPerson: true,
        loginHistory: true,
        loginToFo: false,
        loyaltyId: true,
        manualCorrectionCustomerGoodwill: true,
        manualCorrectionTaxWithholding: true,
        manualCorrectionUnclaimedProperty: true,
        manualCorrectionsGuide: false,
        matchOfTheDay: true,
        middleName: true,
        mobilePartialManualAcceptance: false,
        overallRisk: false,
        parlayCardDownload: true,
        payments: true,
        printApplication: true,
        racebookRaces: true,
        referAFriend: true,
        registrationBtobBasic: true,
        responsibleGaming: true,
        retailVoidManualAcceptance: true,
        riskAssessment: true,
        salutation: true,
        scratchcards: true,
        securityMatches: true,
        signupProperty: true,
        usLanguage: true,
        usaDateFormat: true,
        userAddress: true,
        userCommentsAml: true,
        userCommentsComment: true,
        userDetailsEditing: true,
        userPersonalInformation: true,
        userPhone: true,
        userProvince: true,
        userRegistrationDate: true,
        userRegistrationSource: true,
        userShowInLeaderboard: true,
        userSportsBonusAbuser: true,
        userSportsbookClosed: true,
        userSportsbookStatus: true,
        userSubscriptions: true,
        w2g: true,
        whiteHatGaming: false,
        // some clients maybe don't want all of these "client" features, so here we can disabled them
    },
    [ClientName.ORCA]: {
        ...clientOnlyFeatures,
        ...commonFeatures,
        addNewMarket: false,
        cancelOnlyPendingTickets: false,
        sendGdprEmailToCustomer: false,
        casino: true,
        multiCurrencyWallet: true,
    },
    [ClientName.SPORTSBOOKCORE]: {
        ...commonFeatures,
        betgenius: true,
        clientBOlinks: true,
        clientConfigs: true,
        core: true,
        feedUsers: true,
        initialMarginKey: true,
        liveMarketsCancelButton: true,
        onlyEditableInCore: true,
        referenceFeed: true,
        rotationNumbers: true,
        livebetConsole: true,
        sportsUsers: false,
        smartResulting: true,
        sourceColumn: true,
        netrisk: false,
        eiai: true,
    },
};

const featuresByWynnState: Record<string, Partial<Record<Feature, boolean>>> = {
    [ClientName.WYNNBET_MA_WEB]: {
        ...featuresByClient[ClientName.WYNNBET],
        betShopFilter: false,
    },
    [ClientName.WYNNBET_NY]: {
        ...featuresByClient[ClientName.WYNNBET],
        betShopFilter: false,
    },
};

export function isOldFeatureAvailable(featureName: Feature) {
    const { CLIENT_NAME } = getStoreValue(store.environment) || {};
    if (!CLIENT_NAME) {
        return false;
    }
    if (CLIENT_NAME === ClientName.WYNNBET) {
        return isFeatureAvailableByWynnState(featureName);
    }
    return Boolean((featuresByClient[CLIENT_NAME] || featuresByClient.default)?.[featureName]);
}

function isFeatureAvailableByWynnState(featureName: Feature) {
    const { CLIENT_NAME, KEYCLOAK_AUTH_REALM } = getStoreValue(store.environment) || {};
    if (!KEYCLOAK_AUTH_REALM) {
        return false;
    }
    return Boolean(
        (featuresByWynnState[KEYCLOAK_AUTH_REALM] || featuresByClient[CLIENT_NAME] || featuresByClient.default)?.[
            featureName
        ],
    );
}
