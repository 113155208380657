import { getServiceUrl, httpDelete, httpGet, httpPost, httpPut } from '../../services/api';
import { CmsBody, CmsSettings } from '../../types/cms';
export const getUrl = url => getServiceUrl('cms', url);

export async function getCmsSettings(keys?: string[]) {
    const url = keys ? `settings?keys=${keys.join(',')}` : `settings`;
    return httpGet<CmsSettings[]>(getUrl(url));
}

export function createCmsSetting(data: CmsBody) {
    const url = getUrl(`settings`);
    return httpPost<CmsSettings>(url, data);
}

export async function updateCmsSetting(key: string, data: CmsBody) {
    const url = getUrl(`settings/${key}`);
    return httpPut<CmsSettings>(url, data);
}

export async function deleteCmsSetting(keys: string[]) {
    const url = getUrl(`settings`);
    await httpDelete(url, { deletedKeys: keys });
}
