import { Empty, Form, Space } from 'antd';
import { useEffect, useState } from 'react';
import {
    WAREHOUSE_DETAILS_FORM_CONFIGURATION,
    WAREHOUSE_EXTRACT_DETAILS_FORM_CONFIGURATION,
} from '../../../../../services/warehouse-management';
import { WarehouseExtract } from '../../../../../types/warehouse-management';
import { UiFormSwitch } from '../../../../ui/form/switch/UiFormSwitch';
import UiSelector from '../../../../ui/selector/UiSelector';
import { WarehouseCardAction } from '../../../card/action/WarehouseCardAction';
import { WarehouseCardWrapper } from '../../../card/wrapper/WarehouseCardWrapper';

interface Props {
    details: WarehouseExtract | undefined;
    onDetailsChange: (id: number, details: Partial<WarehouseExtract>) => Promise<void>;
}

export function WarehouseExtractDetailsExtractSettings({ details, onDetailsChange }: Props) {
    const [form] = Form.useForm<Partial<WarehouseExtract>>();
    const [initialValues, setInitialValues] = useState<Partial<WarehouseExtract>>(details ? details : {});
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        details && setInitialValues(details);
    }, [details]);

    useEffect(() => form.resetFields(), [initialValues]);

    async function handleFinish(values: Partial<WarehouseExtract>) {
        setIsUpdating(true);

        await onDetailsChange(id, values);

        setIsUpdating(false);
    }

    if (!details) {
        return <Empty />;
    }

    const { id, columns } = details;

    return (
        <Form
            form={form}
            name="extract-settings"
            initialValues={initialValues}
            onFinish={handleFinish}
            {...WAREHOUSE_DETAILS_FORM_CONFIGURATION}
            {...WAREHOUSE_EXTRACT_DETAILS_FORM_CONFIGURATION}
        >
            <WarehouseCardWrapper
                title="Extract settings"
                button={<WarehouseCardAction name="Save" isUpdating={isUpdating} />}
            >
                <Space direction="vertical">
                    <Form.Item label="Created bookmark" name="created_bookmark">
                        <UiSelector
                            placeholder="Select created bookmark"
                            options={columns}
                            allowClear={false}
                            includeEmptyOption
                            showArrow
                            isTableInput
                            listHeight={600}
                        />
                    </Form.Item>
                    <Form.Item label="Updated bookmark" name="updated_bookmark">
                        <UiSelector
                            placeholder="Select updated bookmark"
                            options={columns}
                            allowClear={false}
                            includeEmptyOption
                            showArrow
                            isTableInput
                            listHeight={600}
                        />
                    </Form.Item>
                    <UiFormSwitch
                        label="Copy to warehouse"
                        name="copy_to_wh"
                        checkedChildren="On"
                        unCheckedChildren="Off"
                    />
                    <UiFormSwitch
                        label="Save validation logs"
                        name="validation_logs"
                        checkedChildren="On"
                        unCheckedChildren="Off"
                    />
                    <UiFormSwitch label="Disabled" name="disabled" checkedChildren="On" unCheckedChildren="Off" />
                </Space>
            </WarehouseCardWrapper>
        </Form>
    );
}
