import { logger } from '@sentry/utils';
import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { getWarehouseDeliveryRegularValidationErrors } from '../../../microservices/warehouse-management';
import { RegularValidationErrorsFilter, WarehouseRegularValidationErrors } from '../../../types/warehouse-management';
import { useTableFilter } from '../../table/useTableFilter';

const defaultFilter = { page: 0, limit: 100, schema: '', table_name: '', validation_name: '' };

export function useWarehouseRegularValidationErrors() {
    const [regularValidationErrors, setRegularValidationErrors] = useState<WarehouseRegularValidationErrors>();
    const { filterState, changeFilterState } = useTableFilter<RegularValidationErrorsFilter>(defaultFilter);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        loadWarehouseRegularValidationErrors(filterState);
    }, [filterState]);

    async function loadWarehouseRegularValidationErrors(filter: RegularValidationErrorsFilter) {
        setIsLoading(true);

        try {
            const warehouseValidationErrors = await getWarehouseDeliveryRegularValidationErrors(filter);
            setRegularValidationErrors(warehouseValidationErrors);
        } catch (error) {
            logger.log('useWarehouseRegularValidationErrors', 'loadWarehouseRegularValidationErrors', error);
            notification.error({ message: `Error loading delivery regular validation errors` });
        }

        setIsLoading(false);
    }

    return { regularValidationErrors, filterState, changeFilterState, isLoading };
}
