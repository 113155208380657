import isNumber from 'lodash/isNumber';
import isBoolean from 'lodash/isBoolean';
import isString from 'lodash/isString';
import dayjs from 'services/dayjs';

export function sorter(a, b) {
    if (a === null && b === null) {
        return 0;
    }

    if (a === null) {
        return -1;
    }

    if (b === null) {
        return 1;
    }

    if (isString(a) && isString(b)) {
        return sorterString(a, b);
    }

    if (isNumber(a) && isNumber(b)) {
        return sorterNumber(a, b);
    }

    if (isBoolean(a) && isBoolean(b)) {
        return sorterBoolean(a, b);
    }

    if (dayjs(a).isValid() && dayjs(b).isValid()) {
        return sorterDate(a, b);
    }

    return 0;
}

function sorterString(a: string, b: string) {
    return a.localeCompare(b);
}

function sorterNumber(a: number, b: number) {
    return a - b;
}

function sorterBoolean(a: boolean, b: boolean) {
    return Number(a) - Number(b);
}

function sorterDate(a: dayjs.Dayjs, b: dayjs.Dayjs) {
    return dayjs(a).unix() - dayjs(b).unix();
}
