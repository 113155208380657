import { Col, Row, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useWarehouseStatusDetails } from '../../../../hooks/warehouse/status/useWarehouseStatusDetails';
import { useRouter } from '../../../../services/router';
import { sorter } from '../../../../services/sorter';
import { getWarehousePrettyDuration, showWarehouseDate } from '../../../../services/warehouse-management';
import { WAREHOUSE_ACTIVITY_STATUS, WarehouseActivityHistory } from '../../../../types/warehouse-management';
import { UiSwitch } from '../../../ui/switch/UiSwitch';
import { UiTable } from '../../../ui/table/UiTable';
import { WarehouseDebounceInputNumber } from '../../debounce/input-number/WarehouseDebounceInputNumber';
import { WarehouseDebounceInput } from '../../debounce/input/WarehouseDebounceInput';
import { WarehouseStatusIcon } from '../icon/WarehouseStatusIcon';

const { Title } = Typography;

export function WarehouseStatusDetails() {
    const { params } = useRouter();
    const { detailsId } = params;

    const { filteredActivityHistoryList, filterState, changeFilterState, isLoading } =
        useWarehouseStatusDetails(detailsId);

    const activityHistoryTableColumns: ColumnsType<WarehouseActivityHistory> = [
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, { status }) => <WarehouseStatusIcon status={status} />,
            sorter: (a, b) => sorter(a.status, b.status),
            width: 100,
        },
        {
            title: 'Schema',
            dataIndex: 'schema',
            key: 'schema',
            sorter: (a, b) => sorter(a.schema, b.schema),
            width: 200,
        },
        {
            title: 'Table name',
            dataIndex: 'table_name',
            key: 'table_name',
            sorter: (a, b) => sorter(a.table_name, b.table_name),
        },
        {
            title: 'Start time',
            dataIndex: 'start_time',
            key: 'start_time',
            render: (_, { start_time }) => showWarehouseDate(start_time),
            sorter: (a, b) => sorter(a.start_time, b.start_time),
            width: 300,
        },
        {
            title: 'End time',
            dataIndex: 'end_time',
            key: 'end_time',
            render: (_, { end_time }) => showWarehouseDate(end_time),
            sorter: (a, b) => sorter(a.end_time, b.end_time),
            width: 300,
        },
        {
            title: 'Duration (s)',
            dataIndex: 'duration',
            key: 'duration',
            render: (_, { duration }) => getWarehousePrettyDuration(duration, false),
            sorter: (a, b) => sorter(a.duration, b.duration),
            align: 'right',
            width: '12%',
        },
    ];

    return (
        <UiTable
            rowKey="start_time"
            className="table-warehouse"
            renderHeader={() => (
                <Row gutter={[16, 16]} align="middle" justify="space-between">
                    <Col>
                        <Row gutter={16}>
                            <Col>
                                <Title level={5} className="no-margin">
                                    {detailsId}
                                </Title>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row gutter={[16, 16]} align="middle">
                            <Col>
                                <Title level={5} className="no-margin">
                                    {`Rows: ${filteredActivityHistoryList.length}`}
                                </Title>
                            </Col>
                            <Col>
                                <UiSwitch
                                    disabled={isLoading}
                                    isChecked={filterState.isSuccessStatusExcluded}
                                    name={`Exclude ${WAREHOUSE_ACTIVITY_STATUS.SUCCESS.toLocaleLowerCase()} status:`}
                                    isPaddingDisabled
                                    onChange={() =>
                                        changeFilterState({
                                            page: 1,
                                            isSuccessStatusExcluded: !filterState.isSuccessStatusExcluded,
                                        })
                                    }
                                />
                            </Col>
                            <Col>
                                <WarehouseDebounceInputNumber
                                    placeholder="Enter min duration"
                                    isLoading={isLoading}
                                    onChange={durationCutoff =>
                                        changeFilterState({
                                            page: 1,
                                            durationCutoff,
                                        })
                                    }
                                />
                            </Col>
                            <Col>
                                <WarehouseDebounceInput
                                    placeholder="Search table name"
                                    isLoading={isLoading}
                                    onChange={search =>
                                        changeFilterState({
                                            page: 1,
                                            search,
                                        })
                                    }
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
            columns={activityHistoryTableColumns}
            dataSource={filteredActivityHistoryList}
            pagination={false}
            loading={isLoading}
            scroll={{ y: undefined }}
            size="small"
        />
    );
}
