import { ColumnsType } from 'antd/lib/table';
import { ExpandableConfig, GetRowKey } from 'antd/lib/table/interface';
import { BasicErrorsFilter } from '../../../../types/warehouse-management';
import { UiTable } from '../../../ui/table/UiTable';

interface Props<T, K> {
    rowKey?: string | GetRowKey<K>;
    renderHeader: () => JSX.Element;
    expandable?: ExpandableConfig<K>;
    tableColumns: ColumnsType<K>;
    errors: T | undefined;
    filterState: BasicErrorsFilter;
    changeFilterState: (partialFilter: Partial<BasicErrorsFilter>) => void;
    isLoading: boolean;
}

export function WarehouseErrorsTable<T extends { data: K[]; total_pages: number }, K>({
    rowKey,
    renderHeader,
    expandable,
    tableColumns,
    errors,
    filterState,
    changeFilterState,
    isLoading,
}: Props<T, K>) {
    const { page, limit } = filterState;

    return (
        <UiTable
            rowKey={rowKey ?? 'id'}
            className="table-warehouse"
            renderHeader={renderHeader}
            expandable={expandable}
            columns={tableColumns}
            dataSource={errors?.data}
            pagination={{
                current: page + 1,
                total: errors ? errors.total_pages * limit : 0,
                showQuickJumper: true,
                showSizeChanger: true,
                pageSize: limit,
                pageSizeOptions: [25, 50, 100, 200, 500, 1000, 2000],
                onChange: (page, pageSize) =>
                    changeFilterState({ page: limit === pageSize ? page - 1 : 0, limit: pageSize }),
                size: 'default',
            }}
            loading={isLoading}
            scroll={{ y: undefined }}
            size="small"
        />
    );
}
