import { DatabaseMarketOddsSource } from '@staycool/sports-types';
import { message, notification } from 'antd';
import { getServiceUrl, httpDelete, httpGet, httpPatch, httpPost, parseAPIErrorMessage } from '../services/api';
import { ClientMatchMapping } from '../types/client';
import { logger } from 'services/logger';
import {
    DatabaseFailedMatch,
    DatabasePickTable,
    DatabasePickTableKey,
    SavePickConfigurationPayload,
} from '../types/client-receiver';

export const getUrl = url => getServiceUrl('client-receiver', url);

export async function getCoreMarketOddsSource(marketId) {
    try {
        const url = getUrl(`client-query/market-odds-source/${marketId}`);
        return await httpGet<Partial<DatabaseMarketOddsSource>>(url);
    } catch (e) {
        logger.log('ClientReceiverMicroservice', 'getCoreMarketOddsSource', e);
        notification.error({ message: 'Error getting CORE odds source for the market' });
    }
}

export async function getCoreMatchDetails(filters: { matchId: number; categoryId: number; marketId?: number }) {
    try {
        const url = getUrl(`mappings/open-in-core`);
        return await httpPost<{
            core_match_id: number;
            core_category_id: number;
            core_market_id: number | null;
        }>(url, filters);
    } catch (e) {
        if (![403, 404].includes(e.status)) {
            logger.log('ClientReceiverMicroservice', 'getCoreMatchDetails', e);
            notification.error({ message: `Corresponding match couldn't be found in CORE` });
        }
    }
}

export async function toggleMatchUpdatesFromCoreFeed(matchId: number, coreFeedEnabled: boolean) {
    try {
        const url = getUrl(`mappings/match/core-feed-state`);
        const response = await httpPatch<ClientMatchMapping>(url, { matchId, core_feed_enabled: coreFeedEnabled });
        message.success('CORE feed status updated');
        return response;
    } catch (e) {
        logger.log('ClientReceiverMicroservice', 'toggleMatchUpdatesFromCoreFeed', e);
        notification.error({
            message: `Error while setting CORE feed state for the match ${matchId}`,
            description: parseAPIErrorMessage(e),
        });
    }
}

interface ClientRefFeedOdds {
    outcome_id: number;
    value: number;
    status: number;
}

export async function getClientRefFeedOdds(outcomeIds: number[]) {
    try {
        const url = getUrl('ref-feed-odds');
        return await httpPost<ClientRefFeedOdds[]>(url, { outcomeIds });
    } catch (e) {
        logger.log('ClientReceiverMicroservice', 'getClientRefFeedOdds', e);
        notification.error({
            message: `Failed to request Reference Feed odds for outcome ids ${outcomeIds}`,
            description: parseAPIErrorMessage(e),
        });
        return [];
    }
}

export async function getPickConfigTables() {
    try {
        const url = getUrl('pick/table');
        return await httpGet<DatabasePickTable[]>(url);
    } catch (error) {
        logger.log('ClientReceiverMicroservice', 'getPickConfigTables', error);
        notification.error({
            message: `Failed to get database tables list`,
            description: parseAPIErrorMessage(error),
        });
        return [];
    }
}

export async function getPickTableKeyConfig(databaseTable: string) {
    try {
        const url = getUrl(`pick/table-key/${databaseTable}`);
        return await httpGet<DatabasePickTableKey[]>(url);
    } catch (error) {
        logger.log('ClientReceiverMicroservice', 'getPickTableKeyConfig', error);
        notification.error({
            message: `Failed to get ${databaseTable} config`,
            description: parseAPIErrorMessage(error),
        });
        return [];
    }
}

export async function savePickTableKeyConfig(databaseTable: string, pickConfiguration: SavePickConfigurationPayload[]) {
    const url = getUrl(`pick/table-key/${databaseTable}`);
    return httpPost<DatabasePickTable[]>(url, pickConfiguration);
}

export async function deletePickTableKeyConfig(databaseTable: string, key: string) {
    try {
        const url = getUrl(`pick/table-key/${databaseTable}/${key}`);
        const response = await httpDelete(url);
        notification.success({ message: `Removed configuration for '${key}'` });
        return response;
    } catch (error) {
        logger.log('ClientReceiverMicroservice', 'deletePickTableKeyConfig', error);
        notification.error({
            message: `Failed to get remove configuration for ${databaseTable}/${key}`,
            description: parseAPIErrorMessage(error),
        });
        return [];
    }
}

export async function getFailedMatchesByCategoryId(categoryId: number) {
    const url = getUrl(`matches/failed-match/${categoryId}`);
    return httpGet<DatabaseFailedMatch[]>(url);
}
