import React, { useEffect } from 'react';
import { Drawer, Layout } from 'antd';
import { getRoute, useRouter } from 'services/router';
import { matchPath } from 'react-router-dom';
import { ROUTES } from 'routes';
import Wrapper from './styles';
import CategoryTreeMenu from './menu/SportsbookCategoryTreeMenu';
import { isDesktopSmall, isMobile } from 'services/browser';
import { MenuFoldOutlined, MenuUnfoldOutlined, SearchOutlined } from '@ant-design/icons';
import { store, useStore } from 'stores/store';

export default function SportsbookCategoryTree() {
    const treeRoutes = [
        getRoute(ROUTES.sportsbook.categories),
        getRoute(ROUTES.sportsbook.netRisk),
        getRoute(ROUTES.sportsbook.netRisk),
    ];

    const { location } = useRouter();
    const isTreeRoute = () => treeRoutes.some(path => matchPath(location.pathname, { path, strict: true }));
    const [isCategoryTreeOpen, setIsCategoryTreeOpen] = useStore(store.sportsbook.isCategoryTreeOpen);

    useEffect(() => {
        setIsCategoryTreeOpen(isTreeRoute());
    }, [location.pathname]);

    const renderSider = () =>
        isMobile() ? (
            <Drawer
                title="Sports Tree"
                placement="left"
                closable={true}
                onClose={() => setIsCategoryTreeOpen(!isCategoryTreeOpen)}
                width={300}
                open={isCategoryTreeOpen}
            >
                <CategoryTreeMenu />
            </Drawer>
        ) : (
            <Layout.Sider
                collapsible
                collapsed={!isCategoryTreeOpen}
                onCollapse={() => setIsCategoryTreeOpen(!isCategoryTreeOpen)}
                collapsedWidth={0}
                className="layout"
                width={isDesktopSmall() ? 220 : 250}
            >
                <CategoryTreeMenu />
            </Layout.Sider>
        );

    return (
        <Wrapper isMobile={isMobile()} isCategoryTreeOpen={isCategoryTreeOpen}>
            {isCategoryTreeOpen && renderSider()}

            <div data-testid="category-tree-trigger" className="category-tree-trigger">
                {isCategoryTreeOpen ? (
                    <MenuFoldOutlined onClick={() => setIsCategoryTreeOpen(false)} />
                ) : (
                    <MenuUnfoldOutlined onClick={() => setIsCategoryTreeOpen(true)} />
                )}
                <SearchOutlined onClick={() => store.sportsbook.isCategoryAndMatchSearchModalVisible.set(true)} />
            </div>
        </Wrapper>
    );
}
