import { mediaQuery } from 'services/media';
import { color } from 'style/variables';
import styled from 'styled-components';

export default styled.div`
    height: ${({ isMobile }) => (isMobile ? '100%' : '100vh')};
    display: flex;
    flex-direction: column;

    .favourite-button {
        position: absolute;
        right: 5px;
        display: none;
        cursor: pointer;
        &.active {
            display: inline-block;
        }

        svg {
            fill: yellow;
        }
    }

    .menu-favourites {
        font-weight: normal;
        background-color: ${color.gray100};

        .sub-menu-favourites {
            font-weight: normal;
        }
    }

    .menu-favourites .ant-menu-inline .ant-menu-item {
        margin: 0;

        &:not(:last-child) {
            margin: 0;
        }
    }

    .menu-favourites .ant-menu-sub.ant-menu-inline {
        background-color: transparent;
    }

    .sports-tree-menu {
        overflow: hidden auto;
        margin: 0;
    }

    .tree-menu {
        padding: 0;
        list-style: none;
        background: ${color.gray100};
        &::before,
        &::after {
            display: table;
            content: '';
        }
        &::after {
            clear: both;
        }
        .sub-menu {
            padding-left: 20px;
            border-radius: 0;
            list-style: none;
            border: 0;
            box-shadow: none;
        }
    }

    .sub-menu-region {
        .sports-tree-menu-item {
            &:first-child {
                margin-top: 8px;
            }

            &:last-child {
                margin-bottom: 4px;
            }

            &.disabled > .title {
                border-left: 0.2em solid ${color.gold300};
            }

            .title {
                padding: 0 12px;
                border-left: 0.2em solid ${color.gold600};
            }
        }
    }

    .sub-menu-league {
        padding-left: 6px !important;

        .sports-tree-menu-item {
            &:first-child {
                margin-top: 4px;
            }

            &:last-child {
                margin-bottom: 4px;
            }

            &.disabled {
                &:hover {
                    .favourite-button svg {
                        fill: ${color.gray600};
                    }
                }
                .title {
                    border-left: 2px solid ${color.green300};
                }
            }

            .title {
                padding: 0 12px;
                border-left: 2px solid ${color.green600};
            }

            &.selected {
                background: rgba(76, 156, 230, 0.36);
            }
            &:hover {
                .favourite-button:not(.active) {
                    display: inline-block;

                    svg {
                        fill: ${color.gray900};
                    }
                }
            }
            .favourite-button {
                top: 0.5em;
                right: 0.5em;
                padding: 0.3em;
                &.active {
                    display: inline-block;
                }
            }
        }
    }

    .sports-tree-menu-item {
        position: relative;

        &.opened {
            &:not(.sub-menu-league .sports-tree-menu-item) {
                background-color: ${color.gray200};
            }
        }

        &.disabled {
            > .title {
                color: ${color.gray600};
            }
        }
    }

    .title {
        display: block;
        white-space: nowrap;
        cursor: pointer;
        width: calc(100% - 2em);
        line-height: 3em;
        padding: 0 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${color.gray900};

        &:hover {
            color: ${color.blue500};

            ~ .arrow {
                color: ${color.blue500};
            }
        }
    }

    .arrow {
        position: absolute;
        top: 1.75em;
        right: 1em;
        padding: 0.2em 0;
        width: 0.75em;
        height: 1em;
        color: rgba(255, 255, 255, 0.85);
        transform: translateY(-50%);
        transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        cursor: pointer;

        &::before,
        &::after {
            position: absolute;
            width: 0.4em;
            height: 1.5px;
            background-color: currentColor;
            border-radius: 2px;
            content: '';
        }

        &::before {
            transform: rotate(-45deg) translateX(2.5px);
        }
        &::after {
            transform: rotate(45deg) translateX(-2.5px);
        }

        &.open {
            &::before {
                transform: rotate(45deg) translateX(2.5px);
            }
            &::after {
                transform: rotate(-45deg) translateX(-2.5px);
            }
        }
    }

    @media ${mediaQuery.isDesktopSmall} {
        .title {
            padding: 0 1.1rem;
        }
    }
`;
