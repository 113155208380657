import { logger } from '../../../services/logger';
import { useEffect, useState } from 'react';
import { getWarehouseExtractList } from '../../../microservices/warehouse-management';
import { WarehouseExtract } from '../../../types/warehouse-management';
import { notification } from 'antd';

export function useWarehouseExtractList() {
    const [extractList, setExtractList] = useState<WarehouseExtract[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        loadWarehouseExtractList();
    }, []);

    async function loadWarehouseExtractList() {
        setIsLoading(true);

        try {
            const warehouseExtractList = await getWarehouseExtractList();
            setExtractList(warehouseExtractList);
        } catch (error) {
            logger.log('useWarehouseExtractList', 'loadWarehouseExtractList', error);
            notification.error({ message: 'Error loading extract list' });
        }

        setIsLoading(false);
    }

    return { extractList, isLoading };
}
