import { Col, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useWarehouseValidationErrors } from '../../../../hooks/warehouse/errors/useWarehouseValidationErrors';
import { WarehouseValidationErrors, WarehouseValidationError } from '../../../../types/warehouse-management';
import { WarehouseDebounceInput } from '../../debounce/input/WarehouseDebounceInput';
import { WarehouseErrorsTable } from '../table/WarehouseErrorsTable';

export function WarehouseErrorsValidation() {
    const { validationErrors, filterState, changeFilterState, isLoading } = useWarehouseValidationErrors();

    const validationErrorsTableColumns: ColumnsType<WarehouseValidationError> = [
        {
            title: 'Schema',
            dataIndex: 'schema',
            key: 'schema',
            width: 200,
        },
        {
            title: 'Table name',
            dataIndex: 'table_name',
            key: 'table_name',
            width: '30%',
        },
        {
            title: 'Message',
            dataIndex: 'error',
            key: 'error',
        },
        {
            title: 'Rows',
            dataIndex: 'rows',
            key: 'rows',
            width: 200,
        },
    ];

    return (
        <WarehouseErrorsTable<WarehouseValidationErrors, WarehouseValidationError>
            rowKey={({ schema, table_name, error }) => `${schema}_${table_name}_${error}`}
            renderHeader={() => (
                <Row gutter={[16, 16]}>
                    <Col>
                        <WarehouseDebounceInput
                            placeholder="Search schema"
                            isLoading={isLoading}
                            onChange={schema => changeFilterState({ page: 0, schema })}
                        />
                    </Col>
                    <Col>
                        <WarehouseDebounceInput
                            placeholder="Search table name"
                            isLoading={isLoading}
                            onChange={table_name => changeFilterState({ page: 0, table_name })}
                        />
                    </Col>
                    <Col>
                        <WarehouseDebounceInput
                            placeholder="Search message"
                            isLoading={isLoading}
                            onChange={error => changeFilterState({ page: 0, error })}
                        />
                    </Col>
                </Row>
            )}
            tableColumns={validationErrorsTableColumns}
            errors={validationErrors}
            filterState={filterState}
            changeFilterState={changeFilterState}
            isLoading={isLoading}
        />
    );
}
