import { Col, Row, Space, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import classNames from 'classnames';
import { useFilterWithNavigation } from '../../../../hooks/navigation/useFilterWithNavigation';
import { useWarehouseDeliveryList } from '../../../../hooks/warehouse/delivery/useWarehouseDeliveryList';
import { ROUTES } from '../../../../routes';
import { getRoute } from '../../../../services/router';
import { sorter } from '../../../../services/sorter';
import { WarehouseDelivery } from '../../../../types/warehouse-management';
import { UiRadio } from '../../../ui/radio/UiRadio';
import { UiTable } from '../../../ui/table/UiTable';
import { WarehouseCheckSign } from '../../check-sign/WarehouseCheckSign';
import { WarehouseDebounceInput } from '../../debounce/input/WarehouseDebounceInput';
import { filterBooleanSelectorOption, filterInput, getBooleanSelectorOptions } from '../../../../services/util';
import { BOOLEAN_SELECTOR_OPTION, BooleanSelectorOption } from '../../../../types/util';
import { getWarehouseFormattedBigNumber } from '../../../../services/warehouse-management';
import dayjs from 'services/dayjs';

const { Text, Title } = Typography;

interface TableFilter {
    page: number;
    limit: number;
    schemaSearch: string;
    tableNameSearch: string;
    enabledStatus: BooleanSelectorOption;
    existsInWhStatus: BooleanSelectorOption;
}

export function WarehouseDeliveryList() {
    const { deliveryList, isLoading } = useWarehouseDeliveryList();
    const { filterWithNavigation, changeFilterWithNavigation } = useFilterWithNavigation<TableFilter>({
        page: 1,
        limit: 2000,
        schemaSearch: '',
        tableNameSearch: '',
        enabledStatus: BOOLEAN_SELECTOR_OPTION.ALL,
        existsInWhStatus: BOOLEAN_SELECTOR_OPTION.ALL,
    });

    const filteredDeliveryList = deliveryList.filter(delivery => {
        if (!filterBooleanSelectorOption(filterWithNavigation.enabledStatus, delivery.enabled)) {
            return false;
        }

        if (!filterBooleanSelectorOption(filterWithNavigation.existsInWhStatus, delivery.exists_in_wh)) {
            return false;
        }

        if (!filterInput(filterWithNavigation.schemaSearch, delivery.schema)) {
            return false;
        }

        return filterInput(filterWithNavigation.tableNameSearch, delivery.table_name);
    });

    const deliveryTableColumns: ColumnsType<WarehouseDelivery> = [
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
            render: (_, { created }) => <Text className="no-wrap-text">{dayjs(created).fromNow()}</Text>,
            sorter: (a, b) => sorter(a.created, b.created),
            defaultSortOrder: 'descend',
            width: 140,
        },
        {
            title: 'Schema',
            dataIndex: 'schema',
            key: 'schema',
            sorter: (a, b) => sorter(a.schema, b.schema),
            width: 230,
        },
        {
            title: 'Table name',
            dataIndex: 'table_name',
            key: 'table_name',
            sorter: (a, b) => sorter(a.table_name, b.table_name),
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            render: (_, { enabled }) => <WarehouseCheckSign value={enabled} />,
            sorter: (a, b) => sorter(a.enabled, b.enabled),
            width: 165,
        },
        {
            title: 'Row count',
            dataIndex: 'wh_row_count',
            key: 'wh_row_count',
            render: (_, { wh_row_count }) => getWarehouseFormattedBigNumber(wh_row_count),
            sorter: (a, b) => sorter(a.wh_row_count, b.wh_row_count),
            align: 'right',
            width: '9%',
        },
        {
            title: 'Exists in WH',
            dataIndex: 'exists_in_wh',
            key: 'exists_in_wh',
            render: (_, { exists_in_wh }) => <WarehouseCheckSign value={exists_in_wh} />,
            sorter: (a, b) => sorter(a.exists_in_wh, b.exists_in_wh),
            width: '9%',
        },
    ];

    return (
        <UiTable
            rowKey="id"
            renderHeader={() => (
                <Row gutter={[16, 16]} align="middle">
                    <Col>
                        <Title level={5} className="no-margin">
                            {`Rows: ${filteredDeliveryList.length}`}
                        </Title>
                    </Col>
                    <Col>
                        <WarehouseDebounceInput
                            placeholder="Search schema"
                            isLoading={isLoading}
                            defaultValue={filterWithNavigation.schemaSearch}
                            onChange={schemaSearch => changeFilterWithNavigation('schemaSearch', schemaSearch)}
                        />
                    </Col>
                    <Col>
                        <WarehouseDebounceInput
                            placeholder="Search table name"
                            isLoading={isLoading}
                            defaultValue={filterWithNavigation.tableNameSearch}
                            onChange={tableNameSearch => changeFilterWithNavigation('tableNameSearch', tableNameSearch)}
                        />
                    </Col>
                    <Col>
                        <Space>
                            <Text>Enabled:</Text>
                            <UiRadio
                                options={getBooleanSelectorOptions()}
                                disabled={isLoading}
                                value={filterWithNavigation.enabledStatus}
                                onChange={event => changeFilterWithNavigation('enabledStatus', event.target.value)}
                                size="middle"
                                buttonStyle="solid"
                            />
                        </Space>
                    </Col>
                    <Col>
                        <Space>
                            <Text>Exists in WH:</Text>
                            <UiRadio
                                options={getBooleanSelectorOptions()}
                                disabled={isLoading}
                                value={filterWithNavigation.existsInWhStatus}
                                onChange={event => changeFilterWithNavigation('existsInWhStatus', event.target.value)}
                                size="middle"
                                buttonStyle="solid"
                            />
                        </Space>
                    </Col>
                </Row>
            )}
            className="table-warehouse table-warehouse-bordered table-warehouse-hovered"
            columns={deliveryTableColumns}
            dataSource={filteredDeliveryList}
            components={{
                body: {
                    row: props => (
                        <Tooltip title="View details">
                            <tr {...props} />
                        </Tooltip>
                    ),
                },
            }}
            rowClassName={({ enabled }) => classNames('pointer', { 'background-red': !enabled })}
            onRow={({ id }) => {
                return { onClick: () => window.open(`${getRoute(ROUTES.warehouse.delivery)}/${id}`, '_blank') };
            }}
            pagination={false}
            loading={isLoading}
            scroll={{ y: undefined }}
            size="small"
        />
    );
}
