import {
    DatabaseCategory,
    DatabaseCategoryPlayer,
    DatabaseCategoryTranslation,
    DatabasePlayer,
} from '@staycool/sports-types';
import { SetCategoryOrderingBody, SetNoLocaleOrderingBody } from '@staycool/sports-types/category-order';
import {
    Category,
    CategoryFilters,
    CategoryNames,
    CategoryUpdateMarketsOddsSourceBody,
    CategoryWithOrdering,
} from '@staycool/sports-types/dist/features/category/types';
import { notification } from 'antd';
import { CategoryByIdType } from 'components/sportsbook/category/tree/types';
import { getSportsUrl, httpDelete, httpGet, httpPost, httpPut, parseAPIErrorMessage } from 'services/api';
import { logger } from 'services/logger';

export function createCategory(name: string, parentCategoryId: number) {
    const url = getSportsUrl('category/');
    return httpPost<DatabaseCategory>(url, { name, parentId: parentCategoryId });
}

export async function getCategoryById(categoryId: number) {
    try {
        const url = getSportsUrl(`category/${categoryId}/full`);
        return await httpGet<Category>(url);
    } catch (error) {
        logger.log('SportsCategoryMicroservice', 'getCategoryById', error);
        notification.error({ message: 'Failed to get category data', description: parseAPIErrorMessage(error) });
    }
}

export function updateCategory(categoryId: number, updateFields: Partial<Category>) {
    const url = getSportsUrl(`category/${categoryId}`);
    return httpPut<Category>(url, updateFields);
}

export async function overrideResponsibleBookmakersCategory(
    categoryId: number,
    data: {
        responsible_bookmakers: number[];
        market_type_group_ids?: number[];
    },
) {
    const url = getSportsUrl(`category/${categoryId}/override-responsible-bookmakers`);
    return await httpPut<CategoryMarketCount>(url, data);
}

export function getCategoryNamesByNameOrIds(filters: CategoryFilters = {}) {
    const url = getSportsUrl('category/category-names');
    return httpPost<CategoryNames[]>(url, filters);
}

export function upsertCategoryTranslations(translations: Partial<DatabaseCategoryTranslation>[]) {
    if (!translations?.length) {
        return [];
    }
    const url = getSportsUrl('translations/category');
    return httpPost<DatabaseCategoryTranslation[]>(url, translations);
}

// eslint-disable-next-line rulesdir/no-extraneous-functions
export function getSportCategories() {
    return getChildCategoriesByParents([1]);
}

export function getChildCategoriesByParents(parentIds: number[] = []) {
    if (!parentIds.length) {
        return Promise.resolve([]);
    }
    const url = getSportsUrl('category/tree/by-parents');
    return httpPost<CategoryType[]>(url, { parentIds });
}

export function getCategoryPlayers(categoryId: number) {
    const url = getSportsUrl('category/players');
    return httpGet<CategoryPlayer[]>(url, { categoryId });
}

export async function getCategoryWithRelatives(categoryId: number) {
    try {
        const url = getSportsUrl(`category/tree/by-id?categoryId=${categoryId}`);
        return httpGet<CategoryByIdType>(url);
    } catch (error) {
        logger.log('SportsCategoryMicroservice', 'getCategoryWithRelatives', error);
        notification.error({ message: 'Error while requesting sports tree' });
    }
}

export async function toggleCategoryFavourite(categoryId: number, isFavourite: boolean) {
    const url = getSportsUrl(`category/tree/favourites`);
    if (isFavourite) {
        await httpDelete(url, { categoryId });
    } else {
        await httpPost(url, { categoryId });
    }
}

export async function getFavourites() {
    const url = getSportsUrl(`category/tree/favourites`);
    return httpGet<CategoryType[]>(url);
}

export async function getCategoriesWithOrdering(filter: CategoryWithOrderingFilter) {
    try {
        const url = getSportsUrl(`category/categories-with-ordering/`);
        return await httpPost<CategoryWithOrdering[]>(url, filter);
    } catch (error) {
        logger.log('SportsCategoryMicroservice', 'getCategoriesWithOrdering', error);
        notification.error({ message: 'Failed to get categories with ordering!' });
    }
}

export async function copyCategory(categoryToId: number, categoryFromId: number) {
    try {
        const url = getSportsUrl('category/copy-category');
        await httpPost(url, { categoryToId, categoryFromId });
        notification.success({ message: 'Category settings copied successfully' });
    } catch (e) {
        logger.log('SportsCategoryMicroservice', 'copyCategory', e);
        notification.error({ message: 'Category settings copying failed', description: parseAPIErrorMessage(e) });
    }
}

export function addPlayersToCategory(categoryId: number, playerIds: number[]) {
    const url = getSportsUrl('category/add-players');
    return httpPost<string>(url, { categoryId, playerIds });
}

export function addTeamsToCategory(categoryId: number, teamIds: number[]) {
    const url = getSportsUrl('category/add-teams');
    return httpPost<string>(url, { categoryId, teamIds });
}

export function removePlayerFromCategory(categoryId: number, playerId: number) {
    const url = getSportsUrl('category/remove-player');
    return httpPost<number>(url, { categoryId, playerId });
}

export function removeTeamFromCategory(categoryId: number, teamId: number) {
    const url = getSportsUrl('category/remove-team');
    return httpPost<number>(url, { categoryId, teamId });
}

export async function updateSportsOrder(payload: SetNoLocaleOrderingBody) {
    const url = getSportsUrl(`category/order/sports`);
    await httpPost(url, payload);
}

export async function updateRegionsOrder(payload: SetNoLocaleOrderingBody) {
    const url = getSportsUrl('category/order/regions');
    await httpPost(url, payload);
}

export async function updateLeaguesOrder(payload: SetCategoryOrderingBody) {
    const url = getSportsUrl('category/order/leagues');
    await httpPost(url, payload);
}

export async function copyLeagueOrderToAllLocales(payload: CopyLeagueOrderToAllLocalesPayload) {
    const url = getSportsUrl('category/order/leagues/copy-to-all-locales');
    await httpPost(url, payload);
}

export async function moveMatchesToCategory(matchIds: number[], newCategoryId: number, updateBGMappings?: boolean) {
    const url = getSportsUrl('category/move/matches');
    await httpPost(url, { matchIds, newCategoryId, ...(updateBGMappings !== undefined && { updateBGMappings }) });
}

export async function updateMarketsOddsSourceByCategoryId(
    categoryId: number,
    data: CategoryUpdateMarketsOddsSourceBody,
) {
    const url = getSportsUrl(`category/${categoryId}/update-markets-odds-source`);
    return await httpPut<number>(url, data);
}

export interface CategoryType {
    id: number;
    name: string;
    depth: number;
    active: boolean;
}

export type CategoryPlayer = DatabasePlayer & DatabaseCategoryPlayer;

export type categoryResponsibleBookmakers = Pick<Category, 'responsible_bookmakers'>;

export enum CategoryDepth {
    Sport = 2,
    Region = 3,
    League = 4,
}

export interface CategoryWithOrderingFilter {
    depth?: number;
    active?: boolean;
    parentId?: number;
}

export interface CategoryWithOrderingRow {
    id: number;
    name: string;
    parent_category_id: number;
    position: number | null;
}

export interface CategoryOrder {
    locale: string;
    parentId: number | null;
    records: CategoryOrderRecord[];
}

export interface CategoryOrderRecord {
    category_id: number;
    locale: string;
    parent_id: number | null;
    position: number;
}

export interface CategoryMarketCount {
    updated_market_count: number;
}

export interface CopyLeagueOrderToAllLocalesPayload {
    localeSource: string;
    localesTarget: string[];
    parentId: number | null;
    records: Omit<CategoryOrderRecord, 'locale'>[];
}
