import { useEffect } from 'react';
import { useStore, store } from 'stores/store';
import TabElect from 'tab-elect';

export function SystemTabElection() {
    const [tabElection, setTabElection] = useStore(store.tabElection);
    const [isAuthenticated] = useStore(store.isAuthenticated);

    useEffect(() => {
        if (isAuthenticated) {
            start();
        } else {
            stop();
        }
    }, [isAuthenticated]);

    function start() {
        stop();
        setTabElection(new TabElect('notifications'));
    }

    function stop() {
        if (!tabElection) {
            return;
        }
        (tabElection as any).destroy();
        setTabElection(undefined);
    }

    return null;
}
