import { renewToken } from 'microservices/auth-bo';
import { getStoreValue, store } from 'stores/store';
import { logoutFromKeycloak } from './keycloak';
import decode from 'jwt-decode';
import { storageSet } from './storage';
import { socketLogout } from 'microservices/feeder';

export async function updateCoolbetAuthToken(): Promise<void> {
    const { token } = await renewToken();
    store.tokenCbAuth.set(token);
    storageSet('tokenCbAuth', token);
}

export function logout(): void {
    logoutFromKeycloak();
    socketLogout();
    store.isAuthenticated.set(false);
}

export function isTokenValid(token?: string): boolean {
    try {
        return decode(token).exp * 1000 > Date.now();
    } catch (error) {
        return false;
    }
}

export function getToken() {
    return getStoreValue(store.tokenCbAuth);
}
