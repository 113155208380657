import { getServiceUrl, httpGet, httpPatch } from '../services/api';
import { Feature, FeatureHistory, FeaturesQueryParams, FeaturesResponse } from 'types/features';

export const getUrl = (url: string) => getServiceUrl('features', url);

export async function getFeatures(param: FeaturesQueryParams) {
    const url = getUrl(`v1/flags`);
    return httpGet<FeaturesResponse>(url, param);
}

export async function loadEnabledFeatures() {
    const url = getUrl('v1/flags/BACK_OFFICE');
    return httpGet<string[]>(url);
}

export async function toggleFeature(service, name, update) {
    const url = getUrl(`v1/flags/toggle/${service}/${name}`);
    return httpPatch(url, update);
}

export async function updateFeature(
    name: string,
    updates: Partial<Pick<Feature, 'is_enabled' | 'description' | 'service' | 'area'>>,
) {
    const url = getUrl(`flags/${name}`);
    return httpPatch<Feature>(url, updates);
}

export async function getFeatureHistory(id: number) {
    const url = getUrl(`v1/history/${id}`);
    return httpGet<FeatureHistory[]>(url);
}
