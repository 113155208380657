import { Form, Space } from 'antd';
import { UiButton } from '../../../../ui/button/UiButton';

interface Props {
    isEditingMode: boolean;
    isUpdating: boolean;
    onSaveButtonClick: () => void;
    onCancelButtonClick: () => void;
    onEditButtonClick: () => void;
}

export function WarehouseDeliveryDetailsTableAction({
    isEditingMode,
    isUpdating,
    onSaveButtonClick,
    onCancelButtonClick,
    onEditButtonClick,
}: Props) {
    return (
        <Space>
            {isEditingMode ? (
                <>
                    <Form.Item noStyle shouldUpdate>
                        {({ getFieldsError }) => (
                            <UiButton
                                type="link"
                                size="small"
                                btnName="Save"
                                disabled={isUpdating || !!getFieldsError().find(field => field.errors.length)}
                                onClick={onSaveButtonClick}
                            />
                        )}
                    </Form.Item>
                    <UiButton
                        type="link"
                        size="small"
                        btnName="Cancel"
                        disabled={isUpdating}
                        onClick={onCancelButtonClick}
                    />
                </>
            ) : (
                <UiButton type="link" size="small" btnName="Edit" disabled={isUpdating} onClick={onEditButtonClick} />
            )}
        </Space>
    );
}
