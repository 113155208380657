import styled from 'styled-components';
import { color } from '../../../style/variables';

export default styled.div`
    padding: ${props => (props.isPaddingDisabled ? 0 : '0.25em 0.25em')};

    .text-secondary {
        color: ${color.gray700};
    }

    .space-full-width {
        width: 100%;

        & > .ant-space-item:first-child {
            margin-right: auto;
        }
    }
`;
