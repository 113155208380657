import styled from 'styled-components';
import { Select } from 'antd';
import { color } from 'style/variables';

export default styled(Select)`
    width: 100%;

    & .ant-select-selection-search {
        min-width: auto;
    }

    &.ant-select-status-error {
        &:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
            .ant-select-selector {
            border-color: ${color.red500};
        }
    }
` as typeof Select;
