import React, { useEffect } from 'react';
import clx from 'classnames';
import { CategoryType, CategoryTypeDepth } from '../../types';
import { StarFilled, StarOutlined } from '@ant-design/icons';
import { Link, matchPath } from 'react-router-dom';
import { getRoute, useRouter } from '../../../../../../services/router';
import { ROUTES } from '../../../../../../routes';
import { treeNavigationLocalStorageKey } from '../../../../../../services/netrisk';

interface Props {
    isOpen: boolean;
    category: CategoryType;
    scrollToMenuItem: (name: string) => void;
    onMenuClick: Function;
    onFavourite?: Function;
    currentCategoryId?: number;
    children?: any;
    dataTest?: string;
}

export function SportsbookCategoryTreeMenuItem({
    isOpen,
    category: { id, name, depth, is_favourite, active },
    onMenuClick,
    children,
    currentCategoryId,
    onFavourite,
    dataTest,
    scrollToMenuItem,
}: Props) {
    const dataTestId = `${dataTest}-${name}`;
    const { location } = useRouter();

    useEffect(() => {
        if (currentCategoryId === id) {
            scrollToMenuItem(name);
        }
    }, []);

    function getRouteForLink() {
        const isNetRiskPage = matchPath(location.pathname, {
            path: '/sportsbook/net-risk',
            strict: true,
        });

        if (isNetRiskPage) {
            return depth === CategoryTypeDepth.League && `${getRoute(ROUTES.sportsbook.netRisk)}/${id}`;
        }

        const isNewNetRiskPage = matchPath(location.pathname, {
            path: '/sportsbook/net-risk',
            strict: true,
        });

        if (isNewNetRiskPage) {
            return depth === CategoryTypeDepth.League && `${getRoute(ROUTES.sportsbook.netRisk)}/${id}`;
        }

        if (depth === CategoryTypeDepth.League) {
            const isNetRiskOpen = localStorage.getItem(treeNavigationLocalStorageKey);
            const type = isNetRiskOpen ? 'net-risk' : 'matches';
            return `${getRoute(ROUTES.sportsbook.categories)}/${id}/${type}`;
        } else {
            return `${getRoute(ROUTES.sportsbook.categories)}/${id}/settings`;
        }
    }

    return (
        <li
            className={clx('sports-tree-menu-item', {
                selected: currentCategoryId === id,
                opened: isOpen,
                disabled: !active,
            })}
        >
            <Link
                to={getRouteForLink}
                className="title"
                data-test={dataTestId}
                title={name}
                onClick={() => onMenuClick(id, depth)}
            >
                {name}
            </Link>
            {!onFavourite ? (
                <span
                    data-testid={`tree-menu-arrow-${id}`}
                    className={clx('arrow', { open: isOpen })}
                    onClick={() => onMenuClick(id, depth, true)}
                />
            ) : (
                <span
                    className={clx('favourite-button', {
                        active: is_favourite,
                    })}
                    data-testid={`tree-menu-favourite-${id}`}
                    onClick={event => onFavourite(event)}
                >
                    {is_favourite ? <StarFilled /> : <StarOutlined />}
                </span>
            )}
            {isOpen && children}
        </li>
    );
}
