import { Empty } from 'antd';
import { WarehouseActivity } from '../../../../../../types/warehouse-management';
import { WarehouseStatusListCardActivitiesProcess } from './process/WarehouseStatusListCardActivitiesProcess';
import Wrapper from './styles';

interface Props {
    activity: WarehouseActivity[];
}

export function WarehouseStatusListCardActivities({ activity }: Props) {
    function renderCard() {
        if (!activity.length) {
            return <Empty />;
        }

        return activity.map(process => <WarehouseStatusListCardActivitiesProcess key={process.id} process={process} />);
    }

    return <Wrapper direction="vertical">{renderCard()}</Wrapper>;
}
