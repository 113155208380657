import { store } from 'stores/store';

export function SystemKeyboardShortcuts() {
    window.addEventListener('keydown', ({ ctrlKey, shiftKey, keyCode }) => {
        if (ctrlKey && shiftKey && keyCode === 70) {
            store.sportsbook.isCategoryAndMatchSearchModalVisible.set(true);
        }
    });

    return null;
}
