import { CheckCircleFilled, ClockCircleFilled, CloseCircleFilled, PauseCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { ReactNode } from 'react';
import { getPrettyEnumValue } from '../../../../services/util';
import { WarehouseActivityStatus, WAREHOUSE_ACTIVITY_STATUS } from '../../../../types/warehouse-management';

interface Props {
    status: WarehouseActivityStatus;
}
export function WarehouseStatusIcon({ status }: Props) {
    const iconsForStatus: Record<WarehouseActivityStatus, ReactNode> = {
        [WAREHOUSE_ACTIVITY_STATUS.ACTIVE]: <ClockCircleFilled spin />,
        [WAREHOUSE_ACTIVITY_STATUS.INTERRUPTED]: <PauseCircleFilled className="warning" />,
        [WAREHOUSE_ACTIVITY_STATUS.FAILED]: <CloseCircleFilled className="danger" />,
        [WAREHOUSE_ACTIVITY_STATUS.SUCCESS]: <CheckCircleFilled className="success" />,
    };

    return <Tooltip title={getPrettyEnumValue(status)}>{iconsForStatus[status]}</Tooltip>;
}
