import { Navigator } from '../../../components/navigator/Navigator';
import { WarehouseStatusDetails } from '../../../components/warehouse/status/details/WarehouseStatusDetails';
import { WarehouseStatusList } from '../../../components/warehouse/status/list/WarehouseStatusList';
import { ROUTES } from '../../../routes';
import { getRoute, useRoutes } from '../../../services/router';

export function WarehouseStatusPage() {
    const routes = useRoutes([
        {
            path: getRoute(ROUTES.warehouse.status),
            component: WarehouseStatusList,
            exact: true,
        },
        {
            path: `${getRoute(ROUTES.warehouse.status)}/:detailsId`,
            component: WarehouseStatusDetails,
            exact: true,
        },
    ]);

    return <Navigator routes={routes} />;
}
