import { useEffect } from 'react';
import { Category } from '@staycool/sports-types/dist/features/category/types';
import capitalize from 'lodash/capitalize';
import { updateDocumentTitle } from 'services/util';
import { store } from 'stores/store';
import { getStoreValue } from 'stores/utils';
import { useRouter } from 'services/router';

export function useDocumentTitle(defaultTitle: boolean, dependencyList?: Array<any>, category?: Category) {
    const { CLIENT_NAME } = getStoreValue(store.environment);
    const { pathname } = useRouter();

    function cleanPath(path: string) {
        const potentialTitles = Array.from(path.match(/([a-zA-Z0-9]|-)+/g) || []);
        if (potentialTitles.length <= 0) {
            return '';
        }
        return capitalize(potentialTitles[potentialTitles.length - 1].replaceAll('-', ' ')) + ' - ';
    }

    function updateHeaderTitle() {
        let title = `${cleanPath(pathname)}${capitalize(CLIENT_NAME)} - Backoffice`;
        if (category && category.depth === 4) {
            title = `${category.name} ${title}`;
        }
        updateDocumentTitle(title, defaultTitle);
    }
    useEffect(updateHeaderTitle, [pathname, ...(dependencyList ? dependencyList : [])]);
}
