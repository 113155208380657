import { FieldTimeOutlined } from '@ant-design/icons';
import { ReportsCountNotificationType } from '@staycool/sports-types/unresulted-markets';
import { Badge, notification } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from 'routes';
import { getRoute } from 'services/router';
import useSound from 'use-sound';
import { useFetch } from '../../../../hooks/useFetch';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { useSocketSubscribeUnsubscribe, useSocketTopicEvents } from '../../../../microservices/feeder';
import { getUnresultedMarketsReportsCount } from '../../../../microservices/sports/missing-results-v-2';
import { getStoreValue, store } from '../../../../stores/store';

export function SportsbookMissingResultsV2Icon() {
    const [missingResultsCount, setMissingResultsCount] = useState<number>();
    const isNotificationEnabled = getStoreValue(store.isNotificationEnabled);
    const [soundVolume] = useLocalStorage('settings-ma-sound-volume', 50);
    const volume = soundVolume ? soundVolume / 100 : 0.5;
    const [playSound] = useSound('/assets/sounds/missing-results.mp3', { volume, interrupt: true });

    const { response: count } = useFetch(() => getUnresultedMarketsReportsCount({}), {
        errorText: 'Failed to initialize missing results count',
    });

    useEffect(() => {
        count && setMissingResultsCount(count);
    }, [count]);

    useSocketSubscribeUnsubscribe('public', {
        params: { service: 'sports', channel: 'unresulted-markets-reports-notification' },
    });

    useSocketTopicEvents(
        'unresulted-markets-reports-count-change',
        (notif: { type: ReportsCountNotificationType; data: number }) => {
            setMissingResultsCount(notif.data);
            if (notif.type === 'count-increase' && isNotificationEnabled && document.visibilityState === 'visible') {
                notification.warning({
                    message: `new missing results detected !`,
                    icon: <FieldTimeOutlined />,
                });
                playSound();
            }
        },
    );

    return (
        <Link to={getRoute(ROUTES.sportsbook.missingResultsV2)}>
            <Badge count={missingResultsCount} className="pointer">
                <FieldTimeOutlined className="status-icon" />
            </Badge>
        </Link>
    );
}
