import styled from 'styled-components';
import { color } from 'style/variables';
import { Card } from 'antd';
import { WarehouseProcessStatus, WAREHOUSE_PROCESS_STATUS } from '../../../../../types/warehouse-management';

const backgroundColorByProcessStatus: Record<WarehouseProcessStatus, string | undefined> = {
    [WAREHOUSE_PROCESS_STATUS.HEALTHY]: color.green200,
    [WAREHOUSE_PROCESS_STATUS.REQUIRES_ATTENTION]: color.gold200,
    [WAREHOUSE_PROCESS_STATUS.UNHEALTHY]: color.red200,
    [WAREHOUSE_PROCESS_STATUS.UNKNOWN]: undefined,
};

export default styled(Card)`
    & .ant-card-head {
        background-color: ${props => backgroundColorByProcessStatus[props.status]};
    }
`;
