import { getSportsUrl, httpPost, httpGet } from 'services/api';

export function sendMAResponsibility(bookieIds: number[]) {
    const url = getSportsUrl('responsible-bookmaker/tracking/ma');
    return httpPost<boolean>(url, bookieIds);
}

export function sendAlarmResponsibility(bookieIds: number[]) {
    const url = getSportsUrl('responsible-bookmaker/tracking/alarms');
    return httpPost<boolean>(url, bookieIds);
}

export function sendTicketListResponsibility(bookieIds: number[]) {
    const url = getSportsUrl('responsible-bookmaker/tracking/ticket-list');
    return httpPost<boolean>(url, bookieIds);
}

export function getMAResponsibilities() {
    const url = getSportsUrl('responsible-bookmaker/tracking/ma');
    return httpGet<GetResponsibleBookmakersForPage>(url);
}

export async function getTicketListResponsibility() {
    const url = getSportsUrl('responsible-bookmaker/tracking/ticket-list');
    return await httpGet<GetResponsibleBookmakersForPage>(url);
}

export function getAlarmResponsibilities() {
    const url = getSportsUrl('responsible-bookmaker/tracking/alarms');
    return httpGet<GetResponsibleBookmakersForPage>(url);
}

export interface GetResponsibleBookmakersForPage {
    most_recent_update: Date;
    responsible_bookmakers: ResponsibleBookmakerWatcher[];
}

export interface ResponsibleBookmakerWatcher {
    watcher: number;
    page: ResponsibleBookmakerPage;
    watching: number[];
    most_recent_update: Date;
}

export type ResponsibleBookmakerPage = 'manual-acceptance' | 'alarms' | 'pinnacle';
