import { theme, ThemeConfig } from 'antd';
import { color } from 'style/variables';
import hexToRgba from 'hex-to-rgba';

const antTheme: ThemeConfig = {
    algorithm: theme.darkAlgorithm,
    token: {
        borderRadius: 4,
        borderRadiusLG: 4,
        borderRadiusSM: 4,
        colorBgBase: color.gray50,
        colorBgContainer: color.gray100,
        colorBgElevated: color.gray100,
        colorBorder: color.gray400,
        colorBorderSecondary: color.gray400,
        colorError: color.red500,
        colorErrorBg: color.red500,
        colorErrorText: color.red500,
        colorErrorTextActive: color.red500,
        colorLink: color.blue500,
        colorPrimary: color.blue500,
        colorPrimaryBg: color.blue500,
        colorPrimaryText: color.blue500,
        colorTextSecondary: color.gray800,
        colorTextTertiary: color.gray800,
        colorSplit: color.gray400,
        colorSuccess: color.success,
        colorSuccessBg: color.success,
        colorSuccessText: color.success,
        colorText: color.gray900,
        controlOutline: hexToRgba(color.blue500, 0.2),
        controlTmpOutline: color.gray400,
    },
    components: {
        Button: {
            colorText: color.gray900,
        },
        Card: {
            colorBgContainer: color.gray200,
            borderRadiusLG: 6,
        },
        Table: {
            colorBgContainer: hexToRgba(color.gray200, 0.25),
        },
        Alert: {
            colorSuccessBg: hexToRgba(color.green300, 0.5),
            colorErrorBg: hexToRgba(color.red300, 0.5),
        },
        Menu: {
            borderRadiusLG: 0,
            colorItemTextSelected: color.blue500,
            colorItemTextHover: color.blue500,
        },
        Tabs: {
            fontSizeLG: 14,
            padding: 12,
        },
        Form: {
            colorError: color.red500,
        },
        DatePicker: {
            colorError: color.red500,
        },
    },
};

export default antTheme;
