import { useEffect, useRef, useState } from 'react';
import { getStoreValue, store, useStore } from 'stores/store';

import { useDebounce } from 'hooks/useDebounce';
import { useInterval } from 'hooks/useInterval';
import { useThrottle } from 'hooks/useThrottle';
import differenceBy from 'lodash/differenceBy';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import { useSocketSubscribeUnsubscribe, useSocketTopicEvents } from 'microservices/feeder';
import { GetResponsibleBookmakersForPage, getMAResponsibilities } from 'microservices/sports/responsible-bookmaker';
import dayjs from 'services/dayjs';
import { logger } from 'services/logger';
import { loadUsers } from 'services/user/user';
import {
    MAAggregateTicketsChannelSettings,
    MA_BOOKMAKER_CHANNEL,
    MA_BOOKMAKER_KEY,
    ProcessedAggregatedAcceptances,
    calculateNumberOfPendingTickets,
    fetchManualAcceptances,
    getBettingEndDate,
    getTicketsFilteredByMe,
    getTicketsFilteredByOthers,
    isTicketExpired,
    responsibleBookmakerChannelSettings,
    sendResponsibility,
} from '../../../services/manual-acceptance-aggregate/manual-acceptance-aggregate';
import { fetchVoidTickets } from '../../../services/void-ticket-aggregate/void-ticket-aggregate';
import { isFeatureAvailable } from '../../../services/features';

export function SystemManualAcceptanceAggregate() {
    const [mostRecentResponsibleBookmakerUpdate, setMostRecentResponsibleBookmakerUpdate] = useState<Date>();
    const timeoutRef = useRef<NodeJS.Timeout>();
    const [ignoreResponsibleBookmakers] = useStore(
        store.sportsbook.aggregatedTicketAcceptances.ignoreResponsibleBookmakers,
    );
    const [manualAcceptanceFilter, setManualAcceptanceFilter] = useStore(
        store.sportsbook.aggregatedTicketAcceptances.filter,
    );
    const [filteredBookmakerIds] = useStore(store.sportsbook.aggregatedTicketAcceptances.filteredBookmakerIds);
    const bookmakerChannel = useRef(new BroadcastChannel(MA_BOOKMAKER_CHANNEL));
    const loadUsersIf = (userIds: string[]) => isFeatureAvailable('sportsUsers') && loadUsers(userIds);

    useEffect(() => {
        initBroadcastChannel();
        initResponsibleBookmakersWatchers();
        setInitialFilter();

        return function cleanup() {
            if (bookmakerChannel.current) {
                bookmakerChannel.current.close();
            }
        };
    }, []);

    useEffect(() => {
        processPendingTickets();
    }, [ignoreResponsibleBookmakers]);

    useEffect(() => {
        if (isEmpty(manualAcceptanceFilter)) {
            return;
        }
        fetchNewManualAcceptanceAndVoidTickets();
    }, [manualAcceptanceFilter, filteredBookmakerIds]);

    function setInitialFilter() {
        setManualAcceptanceFilter({
            // sportIds: [],
            stakeLimitMax: undefined,
            stakeLimitMin: undefined,
            // tradingPositions: [],
            // userCountryCodes: [],
            // userGroupIds: [],
        });
    }

    function initBroadcastChannel() {
        bookmakerChannel.current.onmessage = e => {
            const filteredBookmakerIds = getStoreValue(
                store.sportsbook.aggregatedTicketAcceptances.filteredBookmakerIds,
            );
            localStorage.setItem(MA_BOOKMAKER_KEY, JSON.stringify(e.data));
            if (!isEqual(sortBy(filteredBookmakerIds), sortBy(e.data))) {
                store.sportsbook.aggregatedTicketAcceptances.filteredBookmakerIds.set(e.data);
                sendResponsibility(e.data);
            }
        };
    }

    async function initResponsibleBookmakersWatchers() {
        try {
            onBookmakerChange(await getMAResponsibilities());
        } catch (error) {
            logger.error('SystemManualAcceptanceAggregate', 'initResponsibleBookmakersWatchers', error);
        }
    }

    const fetchNewManualAcceptanceAndVoidTickets = useDebounce(
        async () => {
            await fetchManualAcceptances();
            await fetchVoidTickets();
            await processPendingTickets();
        },
        100,
        { maxWait: 1000 },
    );

    useSocketSubscribeUnsubscribe('public', { params: responsibleBookmakerChannelSettings });
    useSocketSubscribeUnsubscribe('public', { params: MAAggregateTicketsChannelSettings });

    useSocketTopicEvents('responsible-bookmaker-ma-update', data => onBookmakerChange(data), []);
    useSocketTopicEvents(MAAggregateTicketsChannelSettings.channel, fetchNewManualAcceptanceAndVoidTickets, []);

    useInterval(sendResponsibility, 10 * 1000);

    const onBookmakerChange = useThrottle((watchers: GetResponsibleBookmakersForPage) => {
        if (
            !mostRecentResponsibleBookmakerUpdate ||
            new Date(watchers.most_recent_update) > mostRecentResponsibleBookmakerUpdate
        ) {
            setMostRecentResponsibleBookmakerUpdate(new Date(watchers.most_recent_update));
            store.sportsbook.aggregatedTicketAcceptances.activeWatchers.set(watchers.responsible_bookmakers);
            processPendingTickets();
        }
    }, 500);

    async function processPendingTickets() {
        const manualAcceptancesList = getStoreValue(store.sportsbook.aggregatedTicketAcceptances.manualAcceptancesList);
        const voidTicketsList = getStoreValue(store.sportsbook.aggregatedTicketAcceptances.voidTicketsList);
        const aggregatedTickets = [...manualAcceptancesList, ...voidTicketsList];
        const filteredBookmakerIds = getStoreValue(store.sportsbook.aggregatedTicketAcceptances.filteredBookmakerIds);
        const ignoreResponsibleBookmakers = getStoreValue(
            store.sportsbook.aggregatedTicketAcceptances.ignoreResponsibleBookmakers,
        );
        setTimeoutForExpiredTicket(aggregatedTickets);
        const filteredTickets: ProcessedAggregatedAcceptances[] = [];

        if (ignoreResponsibleBookmakers) {
            filteredTickets.push(...aggregatedTickets);
            store.sportsbook.aggregatedTicketAcceptances.filteredOutTicketsCount.set(0);
        } else if (filteredBookmakerIds.length) {
            const ticketsFilteredByMe = getTicketsFilteredByMe(aggregatedTickets, filteredBookmakerIds);
            filteredTickets.push(...ticketsFilteredByMe);
        } else {
            const notYetExpiredTickets = aggregatedTickets.filter(ticket => !isTicketExpired(ticket));
            const alreadyExpiredTickets = aggregatedTickets.filter(ticket => isTicketExpired(ticket));
            const ticketsFilteredByOthers = getTicketsFilteredByOthers(notYetExpiredTickets);
            store.sportsbook.aggregatedTicketAcceptances.filteredOutTicketsCount.set(ticketsFilteredByOthers.length);
            const notFilteredTickets = differenceBy(notYetExpiredTickets, ticketsFilteredByOthers, ma => ma.ticket_id);
            filteredTickets.push(...alreadyExpiredTickets, ...notFilteredTickets);
        }
        store.sportsbook.aggregatedTicketAcceptances.filteredPendingTickets.set(filteredTickets);
        await loadUsersIf(filteredTickets.map(data => data.ticket.user_id));
        calculateNumberOfPendingTickets(filteredTickets.length);
    }

    function setTimeoutForExpiredTicket(manualAcceptancesList: ProcessedAggregatedAcceptances[]) {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        let timeInMilliseconds = 0;
        const now = dayjs();

        manualAcceptancesList
            .filter(ticket => !isTicketExpired(ticket))
            .forEach(ticket => {
                const ticketMaTime = dayjs(ticket.created_at).add(5, 'minutes');
                const bettingEndTime = dayjs(getBettingEndDate(ticket.unique_selections)).subtract(15, 'minutes');

                const maTimeDuration = dayjs.duration(ticketMaTime.diff(now)).as('milliseconds');
                const bettingEndTimeDuration = dayjs.duration(bettingEndTime.diff(now)).as('milliseconds');

                if (maTimeDuration < bettingEndTimeDuration) {
                    if (!timeInMilliseconds || timeInMilliseconds > maTimeDuration) {
                        timeInMilliseconds = maTimeDuration;
                    }
                } else if (!timeInMilliseconds || timeInMilliseconds > bettingEndTimeDuration) {
                    timeInMilliseconds = bettingEndTimeDuration;
                }
            });

        if (timeInMilliseconds) {
            timeoutRef.current = setTimeout(() => {
                processPendingTickets();
            }, timeInMilliseconds);
        }
    }

    return null;
}
