import { Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { sorter } from '../../../../../services/sorter';
import { WAREHOUSE_DETAILS_TABLE_CONFIGURATION } from '../../../../../services/warehouse-management';
import { WarehouseExtractTableHistory } from '../../../../../types/warehouse-management';
import { UiTable } from '../../../../ui/table/UiTable';
import { WarehouseCardWrapper } from '../../../card/wrapper/WarehouseCardWrapper';
import dayjs from 'services/dayjs';

const { Text } = Typography;

interface Props {
    tableHistory: WarehouseExtractTableHistory[];
}

export function WarehouseExtractDetailsTableHistory({ tableHistory }: Props) {
    const tableHistoryTableColumns: ColumnsType<WarehouseExtractTableHistory> = [
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
            render: (_, { created }) => <Text className="no-wrap-text">{dayjs(created).fromNow()}</Text>,
            sorter: (a, b) => sorter(a.created, b.created),
            defaultSortOrder: 'descend',
            width: '50%',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            sorter: (a, b) => sorter(a.action, b.action),
        },
    ];

    return (
        <WarehouseCardWrapper title="Table history">
            <UiTable
                rowKey="id"
                columns={tableHistoryTableColumns}
                dataSource={tableHistory}
                {...WAREHOUSE_DETAILS_TABLE_CONFIGURATION}
            />
        </WarehouseCardWrapper>
    );
}
