import { Form, Input } from 'antd';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import { useEffect, useState } from 'react';
import { getPrettyEnumValue } from '../../../../../services/util';
import {
    getWarehouseDateForUpdate,
    showWarehouseDate,
    WAREHOUSE_DETAILS_FORM_CONFIGURATION,
    WAREHOUSE_EXTRACT_DETAILS_FORM_CONFIGURATION,
} from '../../../../../services/warehouse-management';
import {
    WarehouseExtractBookmark,
    WarehouseExtractBookmarkEtlStep,
    WAREHOUSE_EXTRACT_BOOKMARK_ETL_STEP,
} from '../../../../../types/warehouse-management';
import { WarehouseCardAction } from '../../../card/action/WarehouseCardAction';
import { WarehouseCardWrapper } from '../../../card/wrapper/WarehouseCardWrapper';

type EditBookmarksForm = Record<WarehouseExtractBookmarkEtlStep, string>;

interface Props {
    bookmarks: WarehouseExtractBookmark[];
    onBookmarksChange: (bookmarks: WarehouseExtractBookmark[]) => Promise<void>;
}

export function WarehouseExtractDetailsBookmarks({ bookmarks, onBookmarksChange }: Props) {
    const [form] = Form.useForm<EditBookmarksForm>();
    const [initialValues, setInitialValues] = useState<EditBookmarksForm>(getBookmarksObject(bookmarks));
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        setInitialValues(getBookmarksObject(bookmarks));
    }, [bookmarks]);

    useEffect(() => form.resetFields(), [initialValues]);

    function getBookmarksObject(bookmarks: WarehouseExtractBookmark[]) {
        return bookmarks.reduce((acc, { etl_step, bookmark }) => {
            acc[etl_step] = showWarehouseDate(bookmark) as string;

            return acc;
        }, {} as EditBookmarksForm);
    }

    async function handleFinish(values: WarehouseExtractBookmarkEtlStep) {
        setIsUpdating(true);

        const changedValues = pickBy(values, (value, key) => !isEqual(initialValues[key], value));
        await onBookmarksChange(
            Object.entries(changedValues).map(([key, value]) => ({
                etl_step: key as WarehouseExtractBookmarkEtlStep,
                bookmark: getWarehouseDateForUpdate(value),
            })),
        );

        setIsUpdating(false);
    }

    return (
        <Form
            form={form}
            name="extract-bookmarks"
            initialValues={initialValues}
            onFinish={handleFinish}
            {...WAREHOUSE_DETAILS_FORM_CONFIGURATION}
            {...WAREHOUSE_EXTRACT_DETAILS_FORM_CONFIGURATION}
        >
            <WarehouseCardWrapper
                title="Bookmarks"
                button={<WarehouseCardAction name="Save" isUpdating={isUpdating} />}
            >
                {Object.values(WAREHOUSE_EXTRACT_BOOKMARK_ETL_STEP).map(etlStep => (
                    <Form.Item
                        key={etlStep}
                        label={`${getPrettyEnumValue(etlStep)} bookmark`}
                        name={etlStep}
                        rules={[{ required: true, message: '' }]}
                    >
                        <Input />
                    </Form.Item>
                ))}
            </WarehouseCardWrapper>
        </Form>
    );
}
