import { UiSwitch } from 'components/ui/switch/UiSwitch';
import React from 'react';
import { IS_NOTIFICATION_ENABLED_KEY } from 'services/notification';
import { store, useStore } from 'stores/store';

export function HeaderUserSettingsNotification() {
    const [isNotificationEnabled, setIsNotificationEnabled] = useStore(store.isNotificationEnabled);

    function onChange(value: boolean) {
        setIsNotificationEnabled(value);
        localStorage.setItem(IS_NOTIFICATION_ENABLED_KEY, String(value));
    }

    return <UiSwitch name="Use Notification" isChecked={isNotificationEnabled} onChange={onChange} />;
}
