import { Spin } from 'antd';
import React, { Suspense } from 'react';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import Wrapper from './styles';

interface Props {
    routes: RouteProps[];
}

export function Navigator({ routes }: Props) {
    const validRoutes = routes.filter(Boolean);
    let redirectTo = validRoutes[0] && validRoutes[0].path;
    if (redirectTo === undefined) {
        redirectTo = '/';
    }

    const queryParams = window.location.search || '';
    redirectTo = `${redirectTo}${queryParams}`;

    return (
        <Wrapper>
            <Suspense
                fallback={
                    <div className="spinner-div">
                        <Spin size="large" />
                    </div>
                }
            >
                <Switch>
                    {validRoutes.filter(Boolean).map((route: RouteProps) => (
                        <Route key={String(route.path)} {...route} />
                    ))}
                    {redirectTo && <Redirect to={redirectTo} />}
                </Switch>
            </Suspense>
        </Wrapper>
    );
}
