import styled from 'styled-components';

export default styled.div`
    .input {
        pointer-events: none;
    }

    .hide-arrows {
        & .ant-input-number-handler-wrap {
            display: none;
        }

        &:hover .ant-input-number-handler-wrap {
            display: block;
        }
    }
`;
