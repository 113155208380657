import { CmsType } from 'types/cms';
import { MAVoidTicketIds, TicketMAVoidRequest } from 'types/retail-otc';
import { createStores } from './utils';
import { ExtendedTerminal } from '@staycool/retail-types/terminal';
import { Settings } from '@staycool/retail-types/settings';

export const retail = createStores({
    betShopTerminals: [] as ExtendedTerminal[],
    cmsListFilter: Object.values(CmsType),
    retailSettings: {} as Settings['settings'],
    retailMaTicketId: '',
    retailVoidTicket: undefined as MAVoidTicketIds | undefined,
    ticketMaVoidRequest: undefined as TicketMAVoidRequest | undefined,
});
