import {
    CanadaProvince,
    Country,
    CountryNameByCountryCode,
    MexicoProvince,
    PeruProvince,
    UsaProvince,
} from '@staycool/location';
import { notification } from 'antd';
import { map } from 'bluebird';
import capitalize from 'lodash/capitalize';
import chunk from 'lodash/chunk';
import keyBy from 'lodash/keyBy';
import round from 'lodash/round';
import uniq from 'lodash/uniq';
import { ProductTypeEnum } from 'microservices/bets/types';
import { KycStatus, KycUser } from 'microservices/kyc';
import { DetailedUser, getUsersListAdmin } from 'microservices/users-proxy';
import { isFeatureAvailable } from 'services/features';
import { getOptionsFromEnum } from 'services/util';
import { getStoreValue, store } from 'stores/store';
import { AmlUser } from 'types/aml';
import {
    AccountCloseReason,
    Gender,
    KycMasterMailGeneralSection,
    KycMasterMailProofOfPaymentSection,
    RegistrationSource,
    RiskAssessment,
    UserComment,
    UserVerifiedStatus,
} from 'types/users';
import { UserRiskSettings } from '../../microservices/bets/risk-settings';
import { filterUserComments, getCloseReasons } from '../../microservices/users';
import { UserRgTimeouts } from '../../types/responsible-gaming';
import { UserSecurityMatches } from '../../types/security-match';
import { getCoreCountries } from '../country';
import { logger } from '../logger';

export async function loadUsers(userIds: string[]) {
    if (!userIds?.length) {
        return {};
    }

    const uniqUserIds = uniq(userIds);
    const usersById = getStoreValue(store.usersById);
    const missingUsersIds = uniqUserIds.filter(userId => !(userId in usersById));
    if (!missingUsersIds.length) {
        return;
    }

    const missingUsers = (
        await map(
            chunk(missingUsersIds, 100),
            async usersIdsChunck => {
                return (
                    await getUsersListAdmin({
                        ids: usersIdsChunck.join(','),
                        pageSize: 100,
                    })
                ).result;
            },
            { concurrency: 10 },
        )
    ).flat();

    const mergedUsersById = { ...usersById, ...keyBy(missingUsers, 'id') };

    store.usersById.set(usersById => ({ ...usersById, ...keyBy(missingUsers, 'id') }));
    return mergedUsersById;
}

export function getUserFullName(userId: string) {
    const user = getStoreValue(store.usersById)[userId];
    return user ? `${user.firstName} ${user.lastName}` : userId;
}

export function getUserAlias(userId: string) {
    const user = getStoreValue(store.usersById)[userId];
    return user ? user.alias : userId;
}

export function getDisplayGroupLimit(
    groupName: string,
    limitPrematch: number,
    limitLivebet: number,
    product: ProductTypeEnum,
    userCategoryLimitRatio: { livebet?: number | null; prematch?: number | null } = {},
) {
    let isLimitedInCategory = Boolean(userCategoryLimitRatio.livebet) || Boolean(userCategoryLimitRatio.prematch);
    if (product === ProductTypeEnum.LIVE) {
        isLimitedInCategory = Boolean(userCategoryLimitRatio.livebet);
    } else if (product === ProductTypeEnum.PREMATCH) {
        isLimitedInCategory = Boolean(userCategoryLimitRatio.prematch);
    }
    const groupLimitName = getUserGroupLimitName(groupName, limitPrematch, limitLivebet, product);
    return { groupLimitName, isLimitedInCategory };
}

export function getUserGroupLimitName(
    groupName: string,
    limitPrematch: number,
    limitLivebet: number,
    product: ProductTypeEnum,
) {
    const displayLimitPrematch = round(limitPrematch * 100, 2);
    const displayLimitLivebet = round(limitLivebet * 100, 2);

    let groupLimitName = '';
    if (product === ProductTypeEnum.PREMATCH) {
        groupLimitName = `${groupName}${displayLimitPrematch}`;
    } else if (product.startsWith(ProductTypeEnum.LIVE)) {
        groupLimitName = `${groupName}${displayLimitLivebet}`;
    } else {
        groupLimitName = `${groupName}${Math.min(displayLimitPrematch, displayLimitLivebet)}`;
    }

    return groupLimitName;
}

export const SHARK_GROUP_NAMES = ['WG', 'SW', 'UW/BA', 'SY', 'SHARK', 'MD'];
export const NORMAL_GROUP_NAMES = ['New', 'VIP+', 'VIP', 'Normal', 'Review', 'Poss BA', 'NewFem'];
export const OTHER_GROUP_NAMES = ['NewFem', 'New', 'Review', 'Poss BA'];
export const GOLDFISH_GROUP_NAMES = ['Normal', 'VIP+', 'VIP'];

export function getAdminName(id?: number | string): string {
    if (!id) {
        return 'No user id';
    }

    const boUsersById = getStoreValue(store.boUsersById);
    const user = boUsersById[id];

    return user ? `${user.first_name} ${user.last_name}` : 'System';
}

export async function getUserComments(userId: string, type: string) {
    const { items } = await filterUserComments({ userId, type });
    return items;
}

export function getUserListFilterSettings(
    closeReasons: AccountCloseReason[],
    country?: Country | string,
): TableFilterSettings[] {
    const countries = getCoreCountries();
    const defaultCountry = country ?? (countries.length === 1 ? countries[0] : '');
    const provinceOptions = getProvinceOptionsForCountry(country);

    const countryOptions = countries.map(value => {
        return { label: CountryNameByCountryCode[value], value };
    });

    function getProvinceOptionsForCountry(country?: Country | string): { label: string; value: string }[] | [] {
        if (country && PROVINCES_BY_COUNTRY[country]) {
            return getOptionsFromEnum(PROVINCES_BY_COUNTRY[country]);
        }

        return [];
    }

    function getCloseReasonOptions(closeReasons: AccountCloseReason[]): { label: string; value: number }[] | [] {
        if (!closeReasons.length) {
            return [];
        }

        return closeReasons.map(reason => ({
            label: capitalize(reason.reason).replace(/_/g, ' '),
            value: reason.id,
        }));
    }

    const isUserPersonalInformationFeatureAvailable = isFeatureAvailable('userPersonalInformation');
    const isUserAddressFeatureAvailable = isFeatureAvailable('userAddress');
    const isUserProvinceFeatureAvailable = isFeatureAvailable('userProvince');
    const isUserRegistrationSourceFeatureAvailable = isFeatureAvailable('userRegistrationSource');
    const isUserVerificationFeatureAvailable = isFeatureAvailable('userVerification');
    const isPokerFeatureAvailable = isFeatureAvailable('poker');
    const isLoyaltyIdFeatureAvailable = isFeatureAvailable('loyaltyId');

    return [
        {
            label: 'Poker alias',
            key: 'pokerAlias',
            type: FilterType.INPUT,
            value: '',
            isHidden: !isPokerFeatureAvailable,
        },
        {
            label: 'Personal ID',
            key: 'personalId',
            type: FilterType.INPUT,
            value: '',
            isHidden: !isUserPersonalInformationFeatureAvailable,
        },
        {
            label: 'Date of birth',
            key: 'birthDate',
            type: FilterType.DATE,
            value: '',
            placeholder: 'YYYY-MM-DD',
            isHidden: !isUserPersonalInformationFeatureAvailable,
        },
        {
            label: 'Address',
            key: 'address',
            type: FilterType.INPUT,
            value: '',
            isHidden: !isUserAddressFeatureAvailable,
        },
        {
            label: 'City',
            key: 'city',
            type: FilterType.INPUT,
            value: '',
            isHidden: !isUserAddressFeatureAvailable,
        },
        {
            label: 'Zip',
            key: 'zip',
            type: FilterType.INPUT,
            value: '',
            isHidden: !isUserAddressFeatureAvailable,
        },
        {
            label: 'Country',
            key: 'country',
            type: FilterType.SELECT,
            options: countryOptions,
            value: defaultCountry,
            isHidden: countries.length === 1,
        },
        {
            label: 'Province',
            key: 'province',
            type: FilterType.SELECT,
            options: provinceOptions,
            value: '',
            isHidden: !isUserProvinceFeatureAvailable,
        },
        {
            label: 'Gender',
            key: 'gender',
            type: FilterType.SELECT,
            options: getOptionsFromEnum(Gender, {
                labelOverrides: genderDescription,
            }),
            value: '',
            isHidden: !isUserPersonalInformationFeatureAvailable,
        },
        {
            label: 'Account type',
            key: 'isTest',
            type: FilterType.SELECT,
            options: [
                { label: 'Test', value: 'true' },
                { label: 'Real', value: 'false' },
            ],
            value: '',
        },
        {
            label: 'Registered with',
            key: 'registrationSource',
            type: FilterType.SELECT,
            options: getOptionsFromEnum(RegistrationSource),
            value: '',
            isHidden: !isUserRegistrationSourceFeatureAvailable,
        },
        {
            label: 'Account status',
            key: 'closed',
            type: FilterType.SELECT,
            options: [
                { label: 'Closed', value: 'true' },
                { label: 'Open', value: 'false' },
            ],
            value: '',
        },
        {
            label: 'Verification',
            key: 'verification',
            type: FilterType.SELECT,
            options: getOptionsFromEnum(UserVerifiedStatus),
            value: '',
            isHidden: !isUserVerificationFeatureAvailable,
        },
        {
            label: 'Close reason',
            key: 'closeReasonId',
            type: FilterType.SELECT,
            options: getCloseReasonOptions(closeReasons),
            value: [],
            mode: 'multiple',
        },
        {
            label: 'Loyalty ID',
            key: 'loyaltyId',
            type: FilterType.INPUT,
            value: '',
            isHidden: !isLoyaltyIdFeatureAvailable,
        },
    ];
}

export async function loadCloseReasons() {
    try {
        return await getCloseReasons();
    } catch (error) {
        logger.error('UserService', 'loadCloseReasons', error);
        notification.error({ message: 'Failed to load account close reasons' });
        return [];
    }
}

export interface UserCommentsWithPagination {
    total: number;
    items: UserComment[];
}

export const userCommentLimit = 25;

export const userHistoryLimit = 25;

export const regexLatinAlphabet = {
    pattern: /^([A-Za-zÀ-ÖØ-öø-ÿĀ-ž\s.'-]*)$/,
    message: 'Field accepts only letters from the latin alphabet',
};

export const PROVINCES_BY_COUNTRY = {
    [Country.CANADA]: CanadaProvince,
    [Country.MEXICO]: MexicoProvince,
    [Country.USA]: UsaProvince,
    [Country.PERU]: PeruProvince,
};

export enum FilterType {
    INPUT = 'input',
    SELECT = 'select',
    DATETIME = 'datetime',
    DATE = 'date',
    CHECKBOX = 'checkbox',
}

export interface Filters {
    [key: string]: any;
}

export type Comparator = '<' | '>' | '>=' | '<=' | '=' | 'like' | 'ilike';
interface FilterSettings {
    key: string;
    label: string;
    isHidden?: boolean;
    placeholder?: string;
    size?: {
        span: number;
        lg?: number;
    };
}

interface InputFilterSetting extends FilterSettings {
    type: FilterType.INPUT;
    value: string;
    isExact?: boolean;
    comparator?: Comparator;
}

interface SelectFilterSetting extends FilterSettings {
    type: FilterType.SELECT;
    value: string | string[] | number[];
    options: { label: string; value: string | number; disabled?: boolean }[];
    mode?: 'multiple' | 'tags';
}
interface DateTimeFilterSetting extends FilterSettings {
    type: FilterType.DATETIME;
    value: string;
    dbColumn?: string;
    comparator: Comparator;
}

interface DateFilterSetting extends FilterSettings {
    type: FilterType.DATE;
    value: string;
}

interface CheckboxFilterSetting extends FilterSettings {
    type: FilterType.CHECKBOX;
    value: string;
}

export type TableFilterSettings =
    | InputFilterSetting
    | SelectFilterSetting
    | DateTimeFilterSetting
    | DateFilterSetting
    | CheckboxFilterSetting;

export interface QueryFilter {
    where: { [field: string]: number | string | boolean };
    andWhere?: {
        field: string;
        comparator: Comparator;
        value: string;
    }[];
    whereIn?: { field: string; values: string[] | number[] }[];
    limit: number;
    offset: number;
    orderBy: string;
}

export const genderDescription: Record<Gender, string> = {
    [Gender.Male]: 'Male',
    [Gender.Female]: 'Female',
    [Gender.Other]: 'Other',
};

export const kycMasterMailSectionDescription: Record<
    KycMasterMailGeneralSection | KycMasterMailProofOfPaymentSection,
    string
> = {
    [KycMasterMailGeneralSection.CLOSED_ACCOUNT]: 'Closed account',
    [KycMasterMailGeneralSection.PROOF_OF_ID]: 'Proof of ID',
    [KycMasterMailGeneralSection.PROOF_OF_ADDRESS]: 'Proof of address',
    [KycMasterMailGeneralSection.PROOF_OF_PAYMENT]: 'Proof of payment',
    [KycMasterMailGeneralSection.FREE_SPIN]: 'Free spin',
    [KycMasterMailProofOfPaymentSection.GENERAL_BANK]: 'General bank',
    [KycMasterMailProofOfPaymentSection.SPECIFIC_BANK]: 'Specific bank',
    [KycMasterMailProofOfPaymentSection.CREDIT_CARD]: 'Credit card',
    [KycMasterMailProofOfPaymentSection.ASTROPAY]: 'Astropay',
    [KycMasterMailProofOfPaymentSection.RAPID]: 'Rapid',
    [KycMasterMailProofOfPaymentSection.SKRILL]: 'Skrill',
    [KycMasterMailProofOfPaymentSection.NETELLER]: 'Neteller',
    [KycMasterMailProofOfPaymentSection.ECOPAYZ]: 'EcoPayz',
    [KycMasterMailProofOfPaymentSection.JETON_WALLET]: 'Jeton wallet',
    [KycMasterMailProofOfPaymentSection.CRYPTO_WALLET]: 'Crypto wallet',
};

export function isFreeSpinForKycMasterMailDisabled(
    user: DetailedUser,
    amlUser?: AmlUser,
    kycUser?: KycUser,
    securityMatches?: UserSecurityMatches,
    rgTimeouts?: UserRgTimeouts,
    riskSettings?: UserRiskSettings,
) {
    if (user.country === Country.SWEDEN) {
        return true;
    }

    if (Boolean(user.casinoClosedInRegulator) || user.isCasinoBonusAbuser || user.isSportsBonusAbuser) {
        return true;
    }

    if (riskSettings && riskSettings.display_limit_ratio_prematch < 20) {
        return true;
    }

    if (user.isClosed || kycUser?.kycStatus === KycStatus.SECURITY) {
        return true;
    }

    if (amlUser && [RiskAssessment.MEDIUM, RiskAssessment.HIGH].includes(amlUser.riskAssessment)) {
        return true;
    }

    if (rgTimeouts?.casino?.end_date && new Date(rgTimeouts.casino.end_date) > new Date()) {
        return true;
    }

    if (
        securityMatches?.handledUsers.length ||
        securityMatches?.unHandledHighRiskUsers.length ||
        securityMatches?.unHandledLowRiskUsers.length
    ) {
        return true;
    }

    return false;
}

export interface AddPersonForm {
    firstName: string;
    lastName: string;
    email: string;
    birthDate: Date;
}
