import { Col, Divider, Modal, Row, Switch, Typography } from 'antd';
import { useState } from 'react';
import { WAREHOUSE_MODAL_CONFIGURATION } from '../../../../../services/warehouse-management';
import { WarehouseProcess } from '../../../../../types/warehouse-management';
import { UiNumberInput } from '../../../../ui/number-input/UiNumberInput';
import { WarehouseStatusListCardActivities } from './activities/WarehouseStatusListCardActivities';
import Wrapper from './styles';
import { WarehouseStatusListCardTitle } from './title/WarehouseStatusListCardTitle';

const { Text } = Typography;

interface Props {
    process: WarehouseProcess;
    isUpdating: boolean;
    changeProcess: (id: number, process: Partial<WarehouseProcess>) => Promise<void>;
}

export function WarehouseStatusListCard({ process, isUpdating, changeProcess }: Props) {
    const { id, name, status, enabled, delay, activity } = process;

    const [isOpen, setIsOpen] = useState(false);
    const [delayForUpdate, setDelayForUpdate] = useState(delay);

    async function handleChangeProcessConfirm(process: Partial<WarehouseProcess>) {
        setIsOpen(true);

        Modal.confirm({
            ...WAREHOUSE_MODAL_CONFIGURATION,
            title: 'Are you sure you want to update Schema settings?',
            onOk: async () => {
                await changeProcess(id, process);
                setIsOpen(false);
            },
            onCancel: () => setIsOpen(false),
        });
    }

    return (
        <Wrapper title={<WarehouseStatusListCardTitle status={status} name={name} />} bordered={false} status={status}>
            <Row gutter={[0, 16]}>
                <Col span={24}>
                    <Row justify="space-between">
                        <Col>
                            <Text strong>Enabled</Text>
                        </Col>
                        <Col>
                            <Switch
                                checkedChildren="On"
                                unCheckedChildren="Off"
                                checked={enabled}
                                disabled={isUpdating}
                                onChange={() => handleChangeProcessConfirm({ enabled: !enabled })}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row justify="space-between">
                        <Col>
                            <Text strong>Delay (seconds)</Text>
                        </Col>
                        <Col span={7}>
                            <UiNumberInput
                                value={delayForUpdate}
                                onChange={setDelayForUpdate}
                                onPressEnter={() => handleChangeProcessConfirm({ delay: delayForUpdate })}
                                onBlur={() => !isOpen && setDelayForUpdate(delay)}
                                min={0}
                                precision={0}
                                disabled={isUpdating}
                                size="small"
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Divider className="divider" />
                </Col>
                <Col span={24}>
                    <WarehouseStatusListCardActivities activity={activity} />
                </Col>
            </Row>
        </Wrapper>
    );
}
