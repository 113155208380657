import { mediaQuery } from 'services/media';
import { color } from 'style/variables';
import styled from 'styled-components';

export default styled.div`
    height: ${({ isCategoryTreeOpen }) => (!isCategoryTreeOpen ? 'auto' : '100vh')};
    position: relative;

    .layout {
        height: 100%;
    }

    .category-tree-trigger {
        position: absolute;
        top: 0.5em;
        left: ${({ isMobile, isCategoryTreeOpen }) => (isMobile || !isCategoryTreeOpen ? '0' : 'auto')};
        right: ${({ isMobile, isCategoryTreeOpen }) => (isMobile || !isCategoryTreeOpen ? 'auto' : '-4em')};
        z-index: 1;
        height: 2em;
        color: #fff;
        font-size: 1.5em;
        line-height: 2em;
        cursor: pointer;
        .anticon {
            padding: 0.5em;

            &:hover {
                color: ${color.blue500};
            }
        }
    }

    @media ${mediaQuery.isDesktopSmall} {
        .category-tree-trigger {
            right: ${({ isMobile, isCategoryTreeOpen }) => (isMobile || !isCategoryTreeOpen ? 'auto' : '-4.5rem')};
            .anticon {
                padding: 0.5rem;
            }
        }
        .category-tree-trigger svg {
            font-size: 1.1rem;
        }
    }
`;
