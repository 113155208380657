import { Navigator } from '../../../components/navigator/Navigator';
import { WarehouseExtractDetails } from '../../../components/warehouse/extract/details/WarehouseExtractDetails';
import { WarehouseExtractList } from '../../../components/warehouse/extract/list/WarehouseExtractList';
import { ROUTES } from '../../../routes';
import { getRoute, useRoutes } from '../../../services/router';

export function WarehouseExtractPage() {
    const routes = useRoutes([
        {
            path: getRoute(ROUTES.warehouse.extract),
            component: WarehouseExtractList,
            exact: true,
        },
        {
            path: `${getRoute(ROUTES.warehouse.extract)}/:detailsId`,
            component: WarehouseExtractDetails,
            exact: true,
        },
    ]);

    return <Navigator routes={routes} />;
}
