import React, { useEffect, useRef, useState } from 'react';
import {
    alarmsChannelSettings,
    ALARM_BOOKMAKER_CHANNEL,
    ALARM_BOOKMAKER_KEY,
    getAlarmExpirationTimeInMilliseconds,
    getAlarmsFilteredByMe,
    getAlarmsFilteredByOthers,
    getAlarmsPercentage,
    isAlarmExpired,
    isAlarmWithHighRisk,
    responsibleBookmakerChannelSettings,
    riskReachedChannelSettings,
    sendResponsibility,
} from 'services/alarm';
import { getStoreValue, store, useStore } from 'stores/store';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import { useInterval } from 'hooks/useInterval';
import { useThrottle } from 'hooks/useThrottle';
import { useDebounce } from 'hooks/useDebounce';
import differenceBy from 'lodash/differenceBy';
import isEmpty from 'lodash/isEmpty';
import min from 'lodash/min';
import { getAlarmResponsibilities, GetResponsibleBookmakersForPage } from 'microservices/sports/responsible-bookmaker';
import { usePublicSubscribeListen, useSocketSubscribeUnsubscribe, useSocketTopicEvents } from 'microservices/feeder';
import { notification } from 'antd';
import { AlertOutlined } from '@ant-design/icons';
import { logger } from 'services/logger';
import { getAlarms } from '../../../microservices/sportsbook-risk';
import { RiskReachedAlerts } from '@staycool/sportsbook-risk-types/alarms';

export function SystemAlarm() {
    const timeoutRef = useRef<NodeJS.Timeout>();
    const [ignoreResponsibleBookmakers] = useStore(store.sportsbook.alarm.ignoreResponsibleBookmakers);
    const [alarmFilter, setAlarmFilter] = useStore(store.sportsbook.alarm.filter);
    const [mostRecentResponsibleBookmakerUpdate, setMostRecentResponsibleBookmakerUpdate] = useState<Date>();
    const bookmakerChannel = useRef(new BroadcastChannel(ALARM_BOOKMAKER_CHANNEL));

    useEffect(() => {
        initBroadcastChannel();
        initResponsibleBookmakersWatchers();
        setInitialFilter();
        return function cleanup() {
            if (bookmakerChannel.current) {
                bookmakerChannel.current.close();
            }
        };
    }, []);

    useEffect(() => {
        if (isEmpty(alarmFilter)) {
            return;
        }
        fetchNewAlarms();
    }, [alarmFilter, ignoreResponsibleBookmakers]);

    function setInitialFilter() {
        setAlarmFilter({
            reached: { min: 5 },
            risk: { min: 25 },
            limit: 30,
            orderBy: {
                name: 'risk_reached',
                desc: false,
            },
            tradingPositions: [],
        });
    }

    function initBroadcastChannel() {
        bookmakerChannel.current.onmessage = async e => {
            const filteredBookmakerIds = getStoreValue(store.sportsbook.alarm.filteredBookmakerIds);
            localStorage.setItem(ALARM_BOOKMAKER_KEY, JSON.stringify(e.data));
            if (!isEqual(sortBy(filteredBookmakerIds), sortBy(e.data))) {
                store.sportsbook.alarm.filteredBookmakerIds.set(e.data);
                sendResponsibility(e.data);
            }
        };
    }

    async function initResponsibleBookmakersWatchers() {
        try {
            onBookmakerChange(await getAlarmResponsibilities());
        } catch (error) {
            logger.error('SystemAlarm', 'initResponsibleBookmakersWatchers', error);
        }
    }

    const fetchNewAlarms = useDebounce(
        async () => {
            const filter = getStoreValue(store.sportsbook.alarm.filter);
            const alarms = await getAlarms(filter);
            store.sportsbook.alarm.list.set(alarms);
            processPendingAlarms();
        },
        400,
        { maxWait: 2000 },
    );

    usePublicSubscribeListen('sportsbook_risk', 'risk-alarms', 'risk-alarms-change', onAlarmUpdate);
    usePublicSubscribeListen('sportsbook_risk', 'risk-reached', 'risk-reached-risk-change', onAlarmUpdate);

    useSocketSubscribeUnsubscribe('public', { params: responsibleBookmakerChannelSettings });
    useSocketSubscribeUnsubscribe('public', { params: alarmsChannelSettings });
    useSocketSubscribeUnsubscribe('public', { params: riskReachedChannelSettings });

    useSocketTopicEvents('responsible-bookmaker-alarms-update', data => onBookmakerChange(data), []);
    useSocketTopicEvents(
        `public-${alarmsChannelSettings.service}-${alarmsChannelSettings.channel}-change`,
        onAlarmUpdate,
        [],
    );
    useSocketTopicEvents(
        `public-${riskReachedChannelSettings.service}-${riskReachedChannelSettings.channel}-change`,
        onRiskReachedUpdate,
        [],
    );

    async function onAlarmUpdate() {
        await fetchNewAlarms();
    }

    async function onRiskReachedUpdate() {
        await fetchNewAlarms();
    }

    useInterval(sendResponsibility, 10 * 1000);

    const onBookmakerChange = useThrottle((watchers: GetResponsibleBookmakersForPage) => {
        if (
            !mostRecentResponsibleBookmakerUpdate ||
            new Date(watchers.most_recent_update) > mostRecentResponsibleBookmakerUpdate
        ) {
            setMostRecentResponsibleBookmakerUpdate(new Date(watchers.most_recent_update));
            store.sportsbook.alarm.activeWatchers.set(watchers.responsible_bookmakers);
            processPendingAlarms();
        }
    }, 500);

    const processPendingAlarms = useDebounce(
        async () => {
            const previouslyExpiredAlarms = getStoreValue(store.sportsbook.alarm.filteredPendingAlarms).filter(alarm =>
                isAlarmWithHighRisk(alarm),
            );
            const alarmsList = getStoreValue(store.sportsbook.alarm.list);
            const ignoreResponsibleBookmakers = getStoreValue(store.sportsbook.alarm.ignoreResponsibleBookmakers);
            const filteredBookmakerIds = getStoreValue(store.sportsbook.alarm.filteredBookmakerIds);
            const filteredAlarms: RiskReachedAlerts[] = [];

            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            if (ignoreResponsibleBookmakers) {
                filteredAlarms.push(...alarmsList);
            } else if (filteredBookmakerIds.length) {
                const alarmsFilteredByMe = getAlarmsFilteredByMe(alarmsList, filteredBookmakerIds);
                filteredAlarms.push(...alarmsFilteredByMe);
            } else {
                const alarmsFilteredByOthers = getAlarmsFilteredByOthers(alarmsList);
                const expiredFilteredAlarms = alarmsFilteredByOthers
                    .filter(alarm => isAlarmWithHighRisk(alarm))
                    .filter(alarm => isAlarmExpired(alarm));

                const notYetExpiredFilteredAlarms = alarmsFilteredByOthers
                    .filter(alarm => isAlarmWithHighRisk(alarm))
                    .filter(alarm => !isAlarmExpired(alarm));

                if (notYetExpiredFilteredAlarms.length) {
                    setTimeoutForNotExpiredAlarms(notYetExpiredFilteredAlarms);
                }

                const notFilteredAlarms = differenceBy(
                    alarmsList,
                    alarmsFilteredByOthers,
                    alarm => `${alarm.outcome_id}_${alarm.source}`,
                );
                filteredAlarms.push(...expiredFilteredAlarms, ...notFilteredAlarms);
            }
            store.sportsbook.alarm.filteredPendingAlarms.set(filteredAlarms);
            showNotification(filteredAlarms, previouslyExpiredAlarms);
        },
        300,
        { maxWait: 1000 },
    );

    function setTimeoutForNotExpiredAlarms(alarms: RiskReachedAlerts[]) {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        const timeInMillisecondsList = alarms.map(alarm => getAlarmExpirationTimeInMilliseconds(alarm));
        const timeInMilliseconds = min(timeInMillisecondsList);

        if (timeInMilliseconds) {
            timeoutRef.current = setTimeout(() => {
                processPendingAlarms();
            }, timeInMilliseconds);
        }
    }

    function showNotification(
        filteredPendingAlarms: RiskReachedAlerts[],
        previouslyExpiredAlarms: RiskReachedAlerts[],
    ) {
        const isNotificationEnabled = getStoreValue(store.isNotificationEnabled);
        const newExpiredAlarms = filteredPendingAlarms.filter(alarm => isAlarmWithHighRisk(alarm));
        const notNotifiedAlarms = differenceBy(
            newExpiredAlarms,
            previouslyExpiredAlarms,
            alarm => `${alarm.outcome_id}_${alarm.source}`,
        );
        store.sportsbook.alarm.notificationAlarmsOutcomesIds.set(
            newExpiredAlarms.map(a => `${a.outcome_id}_${a.source}`),
        );
        if (!isNotificationEnabled) return;
        notNotifiedAlarms.forEach(alarm => {
            const alarmPercentage = getAlarmsPercentage(alarm.risk_reached);
            if (document.visibilityState === 'visible') {
                notification.warning({
                    message: `New alarm reached ${alarmPercentage}%`,
                    className: `alarm ${alarmPercentage > 100 ? 'critical' : ''}`,
                    icon: <AlertOutlined />,
                });
            }
        });
    }

    return null;
}
