import { Rule } from 'antd/lib/form';
import { Form } from 'antd';
import { HTMLAttributes, ReactNode } from 'react';
import { FormItemProps } from 'antd/lib/form/FormItem';
import Wrapper from './styles';
import { filterStyleProps } from '../../../services/util';

interface Props extends HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    rules?: Rule[];
    inputNode: ReactNode;
    formItemProps?: FormItemProps;
}

export function UiEditableTableFormCell({
    editing,
    dataIndex,
    rules,
    inputNode,
    children,
    formItemProps,
    ...restProps
}: Props) {
    return (
        <Wrapper {...filterStyleProps(restProps)}>
            {editing ? (
                <Form.Item name={dataIndex} rules={rules} {...formItemProps}>
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </Wrapper>
    );
}
