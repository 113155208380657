import { Empty, Space } from 'antd';
import { ROUTES } from '../../../../../routes';
import { WarehouseDeliveryDetails } from '../../../../../types/warehouse-management';
import { WarehouseCardRow } from '../../../card/row/WarehouseCardRow';
import { WarehouseCardWrapper } from '../../../card/wrapper/WarehouseCardWrapper';
import { WarehouseErrorsAction } from '../../../errors/action/WarehouseErrorsAction';
import dayjs from 'services/dayjs';

interface Props {
    details: WarehouseDeliveryDetails | undefined;
}

export function WarehouseDeliveryDetailsDetails({ details }: Props) {
    if (!details) {
        return <Empty />;
    }

    const { created, schema, table_name } = details;

    return (
        <WarehouseCardWrapper
            title="Details"
            button={
                <WarehouseErrorsAction
                    name="See delivery errors"
                    route={ROUTES.warehouse.delivery.errors}
                    searchParam={table_name}
                />
            }
        >
            <Space direction="vertical">
                <WarehouseCardRow title="Created" component={dayjs(created).fromNow()} />
                <WarehouseCardRow title="Schema" component={schema} />
            </Space>
        </WarehouseCardWrapper>
    );
}
