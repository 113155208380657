import { SearchOutlined } from '@ant-design/icons';
import { UiInputDebounce } from '../../../ui/input/debounce/UiInputDebounce';
import { DEFAULT_DEBOUNCE_TIMEOUT } from 'services/util';

interface Props {
    placeholder: string;
    isLoading: boolean;
    defaultValue?: string;
    onChange: (value: string) => void;
}

export function WarehouseDebounceInput({ placeholder, isLoading, defaultValue, onChange }: Props) {
    return (
        <UiInputDebounce
            defaultValue={defaultValue}
            placeholder={placeholder}
            size="middle"
            disabled={isLoading}
            debounceTimeout={DEFAULT_DEBOUNCE_TIMEOUT}
            addonAfter={<SearchOutlined />}
            onChange={onChange}
        />
    );
}
