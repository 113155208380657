import { AlarmListFilter, RiskReachedAlerts } from '@staycool/sportsbook-risk-types/alarms';
import { GetAllFilterValuesResponse } from '@staycool/sportsbook-risk-types/filters';
import { NetRiskFilter, OutcomesRiskByOutcomeId } from '@staycool/sportsbook-risk-types/netrisk';
import { OutcomeRiskFilter, OutcomeRiskRow } from '@staycool/sportsbook-risk-types/outcome-risk';
import { OverallRisk } from '@staycool/sportsbook-risk-types/overall-risk';
import { notification } from 'antd';
import { getServiceUrl, httpGet, httpPost, parseAPIErrorMessage } from 'services/api';
import { logger } from 'services/logger';
import { isFeatureAvailable } from '../services/features';
import { formatFiltersForSportsbookRisk } from '../services/netrisk';
import { getAlarms as getAlarmsOld } from './sportsbook-reports';

export const getUrl = url => getServiceUrl('sportsbook-risk', url);

export async function getAlarms(filters: Partial<AlarmListFilter>) {
    try {
        const url = getUrl('alarms');
        return await httpPost<RiskReachedAlerts[]>(url, filters);
    } catch (e) {}
    return getAlarmsOld(filters as any) as unknown as Promise<RiskReachedAlerts[]>;
}

export async function getOutcomeRisk(filters: OutcomeRiskFilter) {
    if (Object.values(filters).flat().filter(Boolean).length === 0) {
        return [];
    }
    return await httpPost<OutcomeRiskRow[]>(getUrl('outcome-risk/sum-by-outcome'), filters);
}

export async function getOutcomesRiskByOutcomeId(filters) {
    if (!isFeatureAvailable('netrisk')) {
        return [];
    }

    const fixedFilter: NetRiskFilter = formatFiltersForSportsbookRisk(filters);

    try {
        const url = getUrl('net-risk/outcomes-risk-by-outcome-id');
        return await httpPost<OutcomesRiskByOutcomeId>(url, fixedFilter);
    } catch (e) {
        logger.log('SportsbookRiskMicroservice', 'getOutcomesRiskByOutcomeId', e);
        notification.error({
            message: 'Failed to get getOutcomesRiskByOutcomeId',
            description: parseAPIErrorMessage(e),
        });
    }
}

export function getOverallRisk(filter: Record<string, any>) {
    const url = getUrl('overall-risk');
    return httpPost<OverallRisk[]>(url, filter);
}

export function getAllFiltersValues() {
    return httpGet<GetAllFilterValuesResponse>(getUrl('filters/all'));
}
