import { InfoListItem, TextByLanguage } from 'types/cms';

export interface Promotion {
    id: number;
    name: string;
    match_id: number;
    collection_id: number;
    match_type: MatchTypes;
    active_from: Date;
    active_to: Date;
    countries: string[];
    created_at: Date;
    updated_at: Date;
    deleted_at: Date | null;
    title: TextByLanguage;
    is_archived: boolean;
    status: PromotionStatus;
    use_highlight_text: boolean;
    use_content_text: boolean;
    default_language?: string;
    sport_type?: string;
    type: MatchPromotionTypes;
    market_type_id?: number | null;
    outcome_id?: number | null;
    market_id?: number | null;
    link?: string | null;
}

export interface PromotionCollection {
    id: number;
    name: string;
    is_archived: false;
    nb_active_promotion?: number;
    nb_inactive_promotion?: number;
    nb_draft_promotion?: number;
    nb_scheduled_promotion?: number;
    created_at: string;
    updated_at: string;
}

export interface PromotionValidation {
    isPromotionValid: boolean;
    errors?: InfoListItem[];
    warnings?: InfoListItem[];
    info?: InfoListItem[];
    allMessages?: InfoListItem[];
}

export enum MatchTypes {
    PREMATCH = 'PREMATCH',
    LIVE = 'LIVE',
    BOTH = 'BOTH',
}

export enum MatchPromotionTypes {
    CTA = 'cta',
    ODD = 'odd',
}

export enum PromotionStatus {
    ACTIVE = 'active',
    DRAFT = 'draft',
    INACTIVE = 'inactive',
    SCHEDULED = 'scheduled',
}

export const TEMPLATE_COLLECTION_ID = 1;

export const PROMOTION_OPTION_ALL = 'ALL';

export const PROMOTION_OPTION = [
    { value: PROMOTION_OPTION_ALL, label: 'All statuses' },
    { value: PromotionStatus.ACTIVE, label: 'Active' },
    { value: PromotionStatus.DRAFT, label: 'Draft' },
    { value: PromotionStatus.INACTIVE, label: 'Inactive' },
    { value: PromotionStatus.SCHEDULED, label: 'Scheduled' },
];

export enum MatchPromotionTranslationKey {
    UI_PROMOTION_LINK_TEXT = 'ui.promotion.link-text',
    UI_PROMOTION_HIGHLIGHT = 'ui.promotion.highlight',
    UI_PROMOTION_ODD_TEXT = 'ui.promotion.odd-text',
    UI_PROMOTION_CONTENT = 'ui.promotion.content',
    UI_PROMOTION_HIGHLIGHT_CTA = 'ui.promotion.highlight-cta',
}
