import { getServiceUrl, httpPost } from 'services/api';
import {
    DatabaseSequence,
    Market,
    MarketStatus,
    MarketViewType,
    MatchStatus,
    OutcomeStatus,
    OutcomeTradingPosition,
    ParlayCardStatus,
} from '@staycool/sports-types';
import { notification } from 'antd';
import { logger } from 'services/logger';
import { IExtraInfo, ProductTypeEnum } from './bets/types';

export const getUrl = url => getServiceUrl('sportsbook-reports', url);

/** @deprecated **/
export function getTickets(filters: TicketListFilter) {
    const url = getUrl('ticket-list');
    return httpPost<TicketList[]>(url, filters);
}

/** @deprecated **/
export async function getTicketsTotalCount(filters: TicketListFilter) {
    const url = getUrl('ticket-list/count');
    const [{ count }] = await httpPost<{ count: number }[]>(url, filters);
    return count;
}

/**
 * @deprecated use sportsbook-risk
 */
export async function getAlarms(filters: AlarmsFilter) {
    try {
        const url = getUrl('risk/alarms');
        return await httpPost<Alarm[]>(url, filters);
    } catch (e) {
        logger.log('SportsbookReportsMicroservice', 'getAlarms', e);
        return [];
    }
}

/** @deprecated **/
export function getOverallRisk(filter: Record<string, any>) {
    const url = getUrl('overall-risk');
    return httpPost<OverallRiskRow[]>(url, filter);
}

export function getCashoutTickets(filters) {
    const url = getUrl('cashout-list');
    return httpPost<CashoutList[]>(url, filters);
}

export async function getParlayCardStats(filters: ParlayFilters) {
    try {
        const url = getUrl(`parlay-card/statistics`);
        return await httpPost<ParlayCardStatisticsResponse>(url, filters);
    } catch (e) {
        notification.error({ message: 'Error getting Parlay card statistics!' });
    }
}

interface ParlayCardStatisticsResponse {
    result: ParlayStatistics[];
    count: number;
}

interface ParlayFilters {
    pageNumber: number;
    pageSize?: number;
    statuses: ParlayCardStatus[];
}

export interface ParlayStatistics {
    parlay_card_id: number;
    parlay_card_name: string;
    turnover: number;
    winnings: number;
    max_win_paid: number;
    pending_max_win: number;
}

export interface OverallRiskRow {
    average_stake: number;
    bet_count: number;
    country_id: number;
    country_name: string;
    group_by: number;
    groupBy?: string;
    league_id?: number;
    league_name?: string;
    market_type_group_id?: number;
    market_type_group_name?: string;
    market_type_id?: number;
    market_type_name?: string;
    match_id?: number;
    match_name?: string;
    name: string;
    risk_reached: number;
    sport_id: number;
    sport_name: string;
    turnover: number;
    market_line?: number;
}

export interface OutcomeRisk {
    outcome_id: number;
    updated_at: number;
    risk_bc: number;
    risk_reached: number;
    last_reached_at: Date;
    market_id: number;
    match_id: number;
    bets_count: number;
    reached?: number;
}

export interface TicketListSearchFilter {
    categoryId?: number;
    specific?: boolean;
    ticketType?: string | null;
    leagueIds: number[];
    marketIds?: number[];
    marketTypeGroups: number[];
    marketTypeIds: number[];
    matchIds?: number[];
    outcomeIds?: number[];
    products: string[];
    regionIds: number[];
    responsibleBookmakerIds?: number[];
    sportIds: number[];
    stakeLimitMax?: number | null;
    stakeLimitMin?: number | null;
    maxStakeLimitMax?: number | null;
    maxStakeLimitMin?: number | null;
    statuses?: string[];
    ticketIds?: string[];
    ticketTypes?: string[];
    timeFrom: string | null;
    timeTo: string | null;
    tradingPositions?: string[];
    userCountryCodes?: string[];
    userGroupIds?: number[];
    userGroupNames?: string[];
    userLimitFrom?: string | null;
    userLimitTo?: string | null;
    minProfit?: number | null;
    minLoss?: number | null;
    expectedResultDate?: string | null;
    loyaltyId?: string | null;
}

export interface TicketListPagesAndOrderingFilter {
    descending: boolean;
    orderBy: string | null;
    pageNumber: number;
    size: number;
}

export type TicketListFilter = TicketListSearchFilter & TicketListPagesAndOrderingFilter;

export enum TicketType {
    isCashOut = 'isCashOut',
    isManualAcceptance = 'isManualAcceptance',
}

export interface TicketList {
    ticket_id: string;
    ticket_type: string;
    user_id: string;
    risk_group_name: string;
    risk_limit_ratio_prematch: number;
    risk_limit_ratio_livebet: number;
    min_user_limit_ratio: {
        prematch: number | null;
        livebet: number | null;
    };
    bet_shop_id: number | null;
    status: string;
    category_ids: number[];
    match_ids: number[];
    match_names: string[];
    market_ids: number[];
    responsible_bookmaker_ids: number[];
    internal_comments: string[];
    market_types: string[];
    market_type_ids: number[];
    market_type_groups: string[];
    outcomes: string[];
    outcome_ids: number[];
    bets_count: number;
    first_bet_odds: number;
    stake: number;
    stake_uc: number;
    max_stake_uc: number | null;
    country_code: string;
    product: ProductTypeEnum;
    teaser_points?: number;
    time: string;
    winnings: number;
    is_cashed_out: boolean;
    trading_positions: {
        outcome_id: number;
        trading_position: OutcomeTradingPosition;
    }[];
    extra?: IExtraInfo;
    home_teams: string[];
    away_teams: string[];
    expected_result_date?: string;
}

export interface AlarmsFilter {
    reached: { max?: number; min: number };
    risk: { max?: number; min: number };
    limit: number;
    statuses?: MarketStatus[];
    orderBy: { name: 'risk_reached' | 'risk'; desc: boolean };
    tradingPositions?: string[];
}

export interface Alarm {
    name: string;
    risk_reached: number;
    responsible_bookmakers: number[];
    outcome_id: number;
    trading_position: string | null;
    market_type_id: number;
    market_type_group: string;
    betting_end: Date;
    category_id: number;
    internal_comment: string;
    last_reached_at: Date;
    market_id: number;
    market_in_play: boolean;
    market_line: number;
    market_name: string;
    market_status: string;
    match_id: number;
    match_name: string;
    match_start: Date;
    match_status: string;
    outcome_name: string;
    risk_bc: number;
}

export interface NetRiskRowFromReports {
    category_id: number;
    kick_off_time: string;
    market_id: number;
    sequence: DatabaseSequence | null;
    market_internal_comment: string;
    market_line: number;
    market_name: string;
    market_status: MarketStatus;
    market_type_group_id: number;
    market_type_group_name: string;
    market_type_id: number;
    market_type_name: string;
    market_type_result_keys: string[];
    market_in_play: boolean;
    match_id: number;
    match_name: string;
    match_status: MatchStatus;
    outcomes: OutcomeRowFromReports[];
    view_type: MarketViewType;
    sport_id: number;
    home_team_name: string;
    away_team_name: string;
}

export type NetRiskRow = Market.GetOpenMatchMarketsWithExtras;

export interface OutcomeRowFromReports {
    bet_count: number;
    limit_total: number;
    limit_user: number;
    outcome_id: number;
    outcome_name: string;
    outcome_net_risk: number;
    outcome_net_risk_reached: number;
    outcome_result_key: string;
    outcome_status: OutcomeStatus;
    trading_position: OutcomeTradingPosition;
}

export type OutcomeRow = Market.GetOpenMatchMarketsWithExtrasOutcome;

export interface CashoutList {
    bets: Bets[];
    bets_count: number;
    cashout_amount: number;
    category_ids: number[];
    country_code: string;
    first_bet_odds: number;
    market_ids: number[];
    market_type_groups: string[];
    market_type_ids: number[];
    market_types: string[];
    match_ids: number[];
    match_names: string[];
    min_user_limit_ratio: {
        prematch: number | null;
        livebet: number | null;
    };
    outcome_ids: number[];
    outcomes: string[];
    product: ProductTypeEnum;
    profit: number;
    risk_group_name: string;
    risk_limit_ratio_livebet: number;
    risk_limit_ratio_prematch: number;
    stake: number;
    stake_uc: number;
    max_stake_uc: number | null;
    ticket_id: string;
    ticket_type: string;
    time: string;
    trading_positions: {
        outcome_id: number;
        trading_position: OutcomeTradingPosition;
    }[];
    user_id: string;
    extra?: IExtraInfo;
    home_teams: string[];
    away_teams: string[];
}

interface Bets {
    bet_id: string;
    is_ma: boolean;
    odds: number[];
}

export interface FormulaDataUpdate {
    match_id: number;
    expected_goals?: number;
    supremacy_number?: number;
    manual_supremacy?: boolean;
    draw_killer?: number;
    favourite_multiplier?: number;
    formula_set_id?: number;
}

export interface PointsBasedFormulaDataUpdate {
    match_id: number;
    expected_points?: number;
    supremacy_number?: number;
}
