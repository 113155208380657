import { ColProps, Form, Switch } from 'antd';
import { FormLabelAlign } from 'antd/lib/form/interface';
import { SwitchClickEventHandler } from 'antd/lib/switch';
import { Feature } from 'feature-config';
import { isFeatureAvailable } from 'services/features';
import React from 'react';

interface Props {
    name: string;
    label: string | React.ReactElement;
    labelCol?: ColProps;
    changedFields?: string[];
    labelAlign?: FormLabelAlign;
    feature?: Feature;
    checkedChildren?: string;
    unCheckedChildren?: string;
    onClick?: SwitchClickEventHandler;
    isDisabled?: boolean;
    tooltip?: string;
}

export function UiFormSwitch({
    name,
    label,
    changedFields,
    labelCol = { span: 18 },
    labelAlign = 'right',
    feature,
    checkedChildren = 'Yes',
    unCheckedChildren = 'No',
    onClick,
    isDisabled = false,
    tooltip,
}: Props) {
    if (feature && !isFeatureAvailable(feature)) {
        return null;
    }

    return (
        <Form.Item
            label={label}
            name={name}
            valuePropName="checked"
            labelAlign={labelAlign}
            labelCol={labelCol}
            className={`${changedFields?.includes(name) ? 'changed' : ''}`}
            tooltip={tooltip}
        >
            <Switch
                disabled={isDisabled}
                checkedChildren={checkedChildren}
                unCheckedChildren={unCheckedChildren}
                onClick={onClick}
            />
        </Form.Item>
    );
}
