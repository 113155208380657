import { Empty, Form, Input, Space } from 'antd';
import { useEffect, useState } from 'react';
import {
    getWarehouseDateForUpdate,
    showWarehouseDate,
    WAREHOUSE_DETAILS_FORM_CONFIGURATION,
    WAREHOUSE_EXTRACT_DETAILS_FORM_CONFIGURATION,
} from '../../../../../services/warehouse-management';
import { WarehouseExtractHistoricalValidationSettings } from '../../../../../types/warehouse-management';
import { UiNumberInput } from '../../../../ui/number-input/UiNumberInput';
import { WarehouseCardAction } from '../../../card/action/WarehouseCardAction';
import { WarehouseCardWrapper } from '../../../card/wrapper/WarehouseCardWrapper';

interface Props {
    historicalValidationSettings: WarehouseExtractHistoricalValidationSettings | undefined;
    onHistoricalValidationSettingsChange: (
        id: number,
        historicalValidationSettings: Partial<WarehouseExtractHistoricalValidationSettings>,
    ) => Promise<void>;
}

export function WarehouseExtractDetailsHistoricalValidationSettings({
    historicalValidationSettings,
    onHistoricalValidationSettingsChange,
}: Props) {
    const [form] = Form.useForm<WarehouseExtractHistoricalValidationSettings>();
    const [initialValues, setInitialValues] = useState<Partial<WarehouseExtractHistoricalValidationSettings>>(
        historicalValidationSettings ? getInitialValuesForForm(historicalValidationSettings) : {},
    );
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        historicalValidationSettings && setInitialValues(getInitialValuesForForm(historicalValidationSettings));
    }, [historicalValidationSettings]);

    useEffect(() => form.resetFields(), [initialValues]);

    function getInitialValuesForForm(historicalValidationSettings: WarehouseExtractHistoricalValidationSettings) {
        return {
            ...historicalValidationSettings,
            next_validation: showWarehouseDate(historicalValidationSettings.next_validation),
        };
    }

    async function handleFinish(values: WarehouseExtractHistoricalValidationSettings) {
        setIsUpdating(true);

        await onHistoricalValidationSettingsChange(table_mapping_id, {
            ...values,
            next_validation: getWarehouseDateForUpdate(values.next_validation),
        });

        setIsUpdating(false);
    }

    if (!historicalValidationSettings) {
        return <Empty />;
    }

    const { table_mapping_id } = historicalValidationSettings;

    return (
        <Form
            form={form}
            name="historical-validations-settings"
            initialValues={initialValues}
            onFinish={handleFinish}
            {...WAREHOUSE_DETAILS_FORM_CONFIGURATION}
            {...WAREHOUSE_EXTRACT_DETAILS_FORM_CONFIGURATION}
        >
            <WarehouseCardWrapper
                title="Historical validation settings"
                button={<WarehouseCardAction name="Save" isUpdating={isUpdating} />}
            >
                <Space direction="vertical">
                    <Form.Item
                        label="Validation coefficient"
                        name="validation_coefficient"
                        rules={[{ required: true, message: '' }]}
                    >
                        <UiNumberInput />
                    </Form.Item>
                    <Form.Item label="Period" name="period" rules={[{ required: true, message: '' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Current interval"
                        name="current_interval"
                        rules={[{ required: true, message: '' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="Next validation" name="next_validation" rules={[{ required: true, message: '' }]}>
                        <Input />
                    </Form.Item>
                </Space>
            </WarehouseCardWrapper>
        </Form>
    );
}
