import { Card, Col, Spin, Typography } from 'antd';
import { useWarehouseExtractDetails } from '../../../../hooks/warehouse/extract/useWarehouseExtractDetails';
import { useRouter } from '../../../../services/router';
import { WarehouseExtractDetailsDetails } from './details/WarehouseExtractDetailsDetails';
import { WarehouseExtractDetailsExtractSettings } from './extract-settings/WarehouseExtractDetailsExtractSettings';
import { WarehouseExtractDetailsTableHistory } from './table-history/WarehouseExtractDetailsTableHistory';
import { WarehouseExtractDetailsColumnHistory } from './column-history/WarehouseExtractDetailsColumnHistory';
import Wrapper from './styles';
import { WarehouseExtractDetailsColumns } from './columns/WarehouseExtractDetailsColumns';
import { WarehouseExtractDetailsBookmarks } from './bookmarks/WarehouseExtractDetailsBookmarks';
import { WarehouseExtractDetailsHistoricalValidationSettings } from './historical-validation-settings/WarehouseExtractDetailsHistoricalValidationSettings';
import { WarehouseExtractBookmark } from '../../../../types/warehouse-management';

const { Title } = Typography;

export function WarehouseExtractDetails() {
    const { params } = useRouter();
    const { detailsId } = params;

    const {
        extractDetails,
        extractHistoricalValidationSettings,
        extractBookmarks,
        extractColumnList,
        extractTableHistory,
        extractColumnHistory,
        changeWarehouseExtractDetails,
        changWarehouseExtractColumn,
        changeWarehouseExtractBookmarks,
        changeWarehouseExtractHistoricalValidationSettings,
        isLoading,
    } = useWarehouseExtractDetails(detailsId);

    if (isLoading) {
        return <Spin size="large" />;
    }

    return (
        <Wrapper gutter={[16, 16]} justify="center">
            <Col span={24}>
                <Title level={4} className="no-margin">
                    {extractDetails?.warehouse_table}
                </Title>
            </Col>
            <Col span={24} lg={12} xl={8} xxl={6}>
                <Card bordered={false} className="card-full-height">
                    <WarehouseExtractDetailsDetails details={extractDetails} />
                </Card>
            </Col>
            <Col span={24} lg={12} xl={8} xxl={6}>
                <Card bordered={false} className="card-full-height">
                    <WarehouseExtractDetailsExtractSettings
                        details={extractDetails}
                        onDetailsChange={changeWarehouseExtractDetails}
                    />
                </Card>
            </Col>
            <Col span={24} lg={12} xl={8} xxl={6}>
                <Card bordered={false} className="card-full-height">
                    <WarehouseExtractDetailsBookmarks
                        bookmarks={extractBookmarks}
                        onBookmarksChange={(bookmarks: WarehouseExtractBookmark[]) =>
                            changeWarehouseExtractBookmarks(detailsId, bookmarks)
                        }
                    />
                </Card>
            </Col>
            <Col span={24} lg={12} xl={8} xxl={6}>
                <Card bordered={false} className="card-full-height">
                    <WarehouseExtractDetailsHistoricalValidationSettings
                        historicalValidationSettings={extractHistoricalValidationSettings}
                        onHistoricalValidationSettingsChange={changeWarehouseExtractHistoricalValidationSettings}
                    />
                </Card>
            </Col>
            <Col span={24}>
                <Card bordered={false}>
                    <WarehouseExtractDetailsColumns
                        columnList={extractColumnList}
                        onColumnChange={changWarehouseExtractColumn}
                    />
                </Card>
            </Col>
            <Col span={24} xl={6}>
                <Card bordered={false}>
                    <WarehouseExtractDetailsTableHistory tableHistory={extractTableHistory} />
                </Card>
            </Col>
            <Col span={24} xl={18}>
                <Card bordered={false}>
                    <WarehouseExtractDetailsColumnHistory columnHistory={extractColumnHistory} />
                </Card>
            </Col>
        </Wrapper>
    );
}
