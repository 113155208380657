import { OddsFormat } from '@staycool/bets-types';
import { LegacyLanguage } from '@staycool/localization';
import { CanadaProvince, Country, Market, MexicoProvince, PeruProvince, UsaProvince } from '@staycool/location';
import { KycStatus } from 'microservices/kyc';
import dayjs from 'services/dayjs';
import type { Filter } from 'types/complex-filter';
import { BoUser } from './auth-bo';
import { ObjectValues } from './util';

export type UserProvince = CanadaProvince | MexicoProvince | UsaProvince | PeruProvince;

export enum Gender {
    Male = 'M',
    Female = 'F',
    Other = 'O',
}

export enum UserPageTabs {
    DETAILS = 'details',
    EXTRA = 'extra',
    DETAILS_SUB = 'detailsSub',
    EXTRA_SUB = 'extraSub',
}

export enum LoginWarning {
    IP_COUNTRY_DONT_MATCH,
    FAILED_LOGIN,
    NO_WARNING,
}

export enum RiskAssessment {
    NOT_CHECKED = 'not_checked',
    LOW = 'low',
    MEDIUM = 'medium',
    HIGH = 'high',
    EXTREME = 'extreme',
}

export enum PopulationRegistryState {
    NOT_CHECKED = 'not_checked',
    FAILED = 'failed',
    PASSED = 'passed',
}

export enum EVerified {
    NOT_CHECKED = 'not_checked',
    PENDING = 'pending',
    FAILED = 'failed',
    VERIFIED = 'verified',
}

export enum UserVerifiedStatus {
    EMAIL = 'email',
    SMS = 'sms',
    MANUAL = 'manual',
    NOT_VERIFIED = 'not verified',
}

export interface AccountCloseReason {
    id: number;
    reason: string;
}

export enum RegistrationSource {
    BANK_ID = 'BankID',
    EMAIL = 'email',
    FACEBOOK = 'facebook',
    FINNISH_BANK_ID = 'Finnish Bank ID',
    FINNISH_MOBILE_ID = 'Finnish Mobile ID',
    GOOGLE = 'google',
    PAY_N_PLAY = 'Pay N Play',
    SMART_ID = 'SmartID',
}

export type Countries = {
    iso2: string;
    title: string;
}[];

export enum UserHistoryFieldName {
    BONUS = 'bonus',
    CLOSE_REASON = 'close_reason',
    CLOSE_REASON_ID = 'close_reason_id',
    INDUSTRY_ID = 'industry_id',
    IS_IN_DANGER_ZONE = 'is_in_danger_zone',
    RG_CALL_MADE = 'rg_call_made',
    RG_MAIL_SENT = 'rg_mail_sent',
    RISK_ASSESSMENT = 'risk_assessment',
    RG_ONSITE_MAIL_SENT = 'rg_onsite_mail_sent',
}

export enum UserCommentType {
    AML = 'aml',
    RISK = 'risk',
    COMMENT = 'comment',
    SPORTSBOOK = 'sportsbook',
    SYSTEM_EMAIL = 'system_email',
    RG = 'rg',
}

export enum UserGroupProduct {
    SPORTSBOOK = 'SPORTSBOOK',
    CASINO = 'CASINO',
    AUTOPAYOUT = 'AUTOPAYOUT',
}

export enum UserGroupType {
    RISK = 'RISK',
    STATUS = 'STATUS',
}

export enum SourceOfWealth {
    NOT_CHECKED = 'NOT_CHECKED',
    PENDING = 'PENDING',
    REQUESTED = 'REQUESTED',
    DECLINED = 'DECLINED',
    APPROVED = 'APPROVED',
}

export enum KycMasterMailGeneralSection {
    CLOSED_ACCOUNT = 'section_closed_account',
    PROOF_OF_ID = 'section_id',
    PROOF_OF_ADDRESS = 'section_address',
    PROOF_OF_PAYMENT = 'section_payment_method',
    FREE_SPIN = 'section_free_spin_method',
}

export enum KycMasterMailProofOfPaymentSection {
    GENERAL_BANK = 'section_general_bank_method',
    SPECIFIC_BANK = 'section_specific_bank_method',
    CREDIT_CARD = 'section_credit_card_method',
    ASTROPAY = 'section_astropay_method',
    RAPID = 'section_rapid_method',
    SKRILL = 'section_skrill_method',
    NETELLER = 'section_neteller_method',
    ECOPAYZ = 'section_ecopayz_method',
    JETON_WALLET = 'section_jeton_wallet_method',
    CRYPTO_WALLET = 'section_crypto_wallet_method',
}

export interface EditUserDetailsBasicForm {
    alias: string;
    id: string;
    createdAt: string;
    lastActive: string;
    isTest: boolean;
    isClosed: boolean;
    inPerson?: boolean;
    closeReasonId: number | '' | null;
    fathersName?: string;
    firstName: string;
    middleName: string;
    mothersName?: string;
    lastName: string;
    lastWagerDate: Date;
    birthDate: dayjs.Dayjs;
    gender: Gender;
    personalId: string;
    language: LegacyLanguage;
    email: string;
    phonePrefix?: string;
    phoneNumber?: string;
    facebook: string | null;
    google: string | null;
    country: Country;
    province: UserProvince | '' | null;
    city: string;
    zip: string;
    address: string;
    isFamilyName?: boolean;
    isSmsVerified: boolean;
    isEmailVerified: boolean;
    isManualVerified: boolean;
    isVerified?: boolean;
    signupProperty?: string;
    loyaltyId?: string;
    salutation?: string;
}

export interface CreateUserForm {
    email: string;
}

export interface PrintProfileUserDetailBasicForm {
    title?: string;
    description?: string;
}

export interface EditUserDetailsSettingsForm {
    isSportsBonusAbuser: boolean;
    isCasinoBonusAbuser: boolean;
    isSportsbookClosed: boolean;
    isCasinoClosed: boolean;
    isPokerClosed: boolean;
    racebookClosed: boolean;
    is_2faEnabled: boolean;
    is_2faEnabledOnce: boolean;
    lossLimitAmountUc: number;
    isLossLimitEnabled: boolean;
    oddsFormat: OddsFormat;
    registrationSource: string | null;
}

export interface EditUserMarketingForm {
    subscribeMailList: boolean;
    subscribeSms: boolean;
    subscribeDirectMail: boolean;
    subscribePhoneCalls: boolean;
    showInLeaderboard: boolean;
    showFullAliasInLeaderboard: boolean;
    userSbStatusId: number;
    userCasinoStatusId: number;
}

export interface EditUserAssignBonusForm {
    bonus: number;
}

export interface FormSubmission {
    submissionId: number;
    submissionState: SourceOfWealth;
    submissionUpdated: string;
    rows?: any[];
}

export interface UserIndustry {
    id: number;
    title: string;
}

export interface UserHistoryParams {
    where: Filter['where'];
    page?: number;
    whereIn?: Filter['whereIn'];
    distinctOn?: string;
    orderBy?: Filter['orderBy'];
    pageSize?: number;
}

export interface UserCommentsParams {
    userId: string;
    type: string;
    page?: number;
    pageSize?: number;
    orderBy?: Filter['orderBy'];
    where?: Filter['where'];
}

export interface UserLogsResponse {
    total: number;
    items: UserHistory[];
}

export interface UserHistory {
    id: number;
    fieldName: UserHistoryFieldName;
    prevVal?: string;
    currentVal?: string;
    created: Date;
    changedTime?: Date;
    changedUserId: string;
    changedBy: string;
    comments: string;
}

export interface UserLastLogin {
    loginTime: string;
    ipAddress: string;
    ipCountry: string;
    isSuccess: boolean;
}

export interface UserCloseReason {
    id: number;
    reason: string;
}

export interface UserGroup {
    id: number;
    name: string;
    product: UserGroupProduct;
    type: UserGroupType;
}

export interface UserSettings {
    userId: string;
    userSbStatusId: number;
    userCasinoStatusId: number;
}

export interface UserComment {
    id: number;
    userId: string;
    boUserId?: number;
    parentId?: number;
    comment: string;
    replies?: Pick<UserComment, 'id' | 'boUserId' | 'comment' | 'created'>[];
    created: Date;
    type: UserCommentType;
    highlighted: boolean;
    updated?: Date;
}

export interface UsersLoginHistory {
    app: string;
    appId: string;
    boUserId: string;
    browser: string;
    city: string;
    cookie: string;
    created: string;
    device: string;
    deviceId: string;
    id: number;
    ipAddress: string;
    ipCountry: string;
    isSuccess: boolean;
    latitude: number;
    loginSessionId: string;
    loginTime: string;
    logoutTime: string;
    longitude: number;
    masterSession: string;
    message: string;
    os: string;
    reason: string;
    regionName: string;
    socialLoginService: string;
    timeZone: string;
    userAgent: string;
    userId: string;
    zip: string;
}

export interface UserLoyalty {
    id: string;
}

export interface LoyaltyUserData {
    loyaltyId: string;
    email: string;
    alias: string;
}

export interface UserProductStatus {
    userId: string;
    casinoBonusAbuser: boolean;
    casinoClosed: boolean;
    pokerClosed: boolean;
    racebookClosed: boolean;
    sportsbookBonusAbuser: boolean;
    sportsbookClosed: boolean;
    virtualSportsClosed: boolean;
    createdAt: string;
    updatedAt: string;
}

export interface UserMetaData {
    userId: string;
    salutation?: string;
}

export interface ReopenRequest {
    userId: string;
    reopenAt: string;
}

export interface UserLoyaltyData {
    userId: string;
    loyaltyId?: string;
}

export interface UsersCountries {
    id: string;
    country: Country;
}

export const SALUTATION = {
    MR: 'Mr',
    MS: 'Ms',
    MRS: 'Mrs',
} as const;
export type Salutation = ObjectValues<typeof SALUTATION>;

export interface LimitedUserData {
    id: string;
    lastName: string;
    firstName: string;
    alias: string;
    country: string;
    showInLeaderboard: boolean;
}

export interface TermsAndConditionsHistory {
    market: Market;
    id: number;
    reverted: boolean;
    revertedBy?: number;
    triggeredAt: string;
    triggeredBy: number | string;
    totalUsersAffected: number;
    isRevertible: boolean;
}

export interface UsersSourceOfWealth {
    submissionId: number;
    submissionUpdated: string;
    submissionState: string;
    submissionStateUpdated: string;
    submissionStateUpdatedBy: number;
    userCountry: string;
    userClosed: boolean;
    userCreated: string;
    kycStatus: KycStatus;
    userId: string;
    rows: [
        {
            fieldId: number;
            value: string;
        },
    ];
    lastActivity: string;
    totalDeposit: number;
    totalWithdrawal: number;
    userCurrency: string;
    boUser: BoUser;
}

export type UserUpdateRequest = Partial<UserUpdateResponse> & { fathersName?: string; mothersName?: string };

export interface UserUpdateResponse {
    address: string;
    alias: string;
    avatar?: string;
    birthDate: string;
    birthPlace: string | null;
    casinoBonusAbuser: boolean;
    casinoClosed: boolean;
    city: string;
    closeReasonId: number | null;
    closed: boolean;
    country: Country;
    eVerified: EVerified;
    email: string;
    firstName: string;
    middleName: string;
    gender: Gender;
    id: string;
    industryId: number | null;
    inPerson?: boolean;
    isTest: boolean;
    jobTitle: string | null;
    language: LegacyLanguage;
    lastName: string;
    lastWagerDate?: Date;
    manualVerified: boolean;
    nationality: string | null;
    oddsFormat: OddsFormat;
    personalId: string;
    phoneNumber: string;
    phonePrefix: string;
    pokerClosed: boolean;
    populationRegistryState: PopulationRegistryState;
    province: UserProvince | null;
    showInLeaderboard: boolean;
    sportsBonusAbuser: boolean;
    sportsbookClosed: boolean;
    verified: boolean;
    zip: string;
    sourceOfFunds?: string | null;
}

export interface SignupProperty {
    id: number;
    name: string;
}

export interface ClosureAppeal {
    id: string;
    createdAt: string;
    updatedAt: string;
    updatedBy?: string;
    comments: {
        author: string;
        createdAt: string;
        id: number;
        message: string;
    }[];
    market: Market;
    form: ClosureAppealForm;
    state: ClosureAppealState;
    submittedAt?: string;
    userId: string;
}

export type ClosureAppealForm = { question: string; answer: string }[];

export enum ClosureAppealState {
    APPROVED = 'approved',
    PENDING = 'pending',
    REJECTED = 'rejected',
}

export interface ClosureAppealQuestion {
    id: number;
    closeReasonId: number;
    createdAt: string;
    order: number;
    translationKey: string;
    updatedAt: string;
    updatedBy: string;
}

export interface ClosureAppealSetting {
    id: number;
    config: ClosureAppealConfig;
}

export interface ClosureAppealConfig {
    cooldown?: {
        message: string;
        periodAmount: number;
        periodUnit: ClosureAppealCooldownUnit;
    };
}

export type ClosureAppealCooldownUnit = 'hours' | 'days' | 'weeks' | 'months';
