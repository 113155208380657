import produce from 'immer';
import isEqual from 'lodash/isEqual';
import { DependencyList, useEffect, useState } from 'react';

let reduxDevTools;

if (window.location.host.includes('localhost') && window.__REDUX_DEVTOOLS_EXTENSION__) {
    reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__.connect();
    reduxDevTools.init();
}

type StoreType<T> = [T] extends [{ _store: boolean }]
    ? T extends Store<T>
        ? Store<T>
        : T & { _store: boolean }
    : Store<T>;

function isStore(item: any): item is Store {
    return item && item._store;
}

export function createGlobalStore<T>(initialState: T, storeName: string) {
    const subscriptions = new Set<stateSubscriber<T>>();

    const store = {
        _store: true,
    } as Store<T>;

    store.state = initialState;

    store.set = (updateState: T | ((draft: T) => void)) => {
        const newState = updateState instanceof Function ? produce(store.state, updateState) : updateState;
        store.state = newState as T;
        if (window.traceStore && window.traceStore === store) {
            // eslint-disable-next-line no-console
            console.trace(storeName, newState);
        }
        if (window.watchStore && window.watchStore === store) {
            // eslint-disable-next-line no-console
            console.info(storeName, newState);
        }
        if (reduxDevTools) {
            reduxDevTools.send(storeName, newState);
        }
        subscriptions.forEach(setState => setState(newState as T));
    };
    store.reset = () => store.set(initialState);

    store.subscribe = setState => subscriptions.add(setState);
    store.unsubscribe = setState => subscriptions.delete(setState);
    return store;
}

export function createStores<T extends object>(object: T) {
    return {
        ...Object.keys(object).reduce((stores, key) => {
            stores[key] = isStore(object[key]) ? object[key] : createGlobalStore(object[key], key);
            return stores;
        }, {} as { [K in keyof T]: StoreType<T[K]> }),
        _store: true,
    };
}

export function useStore<T>(store: Store<T>): [T, StoreSetter<T>] {
    const [state, setState] = useState<T>(store.state);
    store.subscribe(setState);

    useEffect(() => () => store.unsubscribe(setState), []);

    return [state, store.set];
}

export function useStoreWithSelector<T, X>(
    store: Store<T>,
    selector: (state: T) => X,
    refresherValues: DependencyList = [],
    ensurer: (state: T) => boolean = () => true,
) {
    const [state, setState] = useState(selector(store.state));

    useEffect(() => {
        let previousState = state;
        setState(selector(store.state)); // re-evaluate necessary when the refresherValues change

        const stateSetter = state => {
            const newState = selector(state);
            const isSelectedStateChanged = !isEqual(previousState, newState);
            const isRenderAllowed = ensurer(state);

            if (isSelectedStateChanged && isRenderAllowed) {
                previousState = newState;
                setState(newState);
            }
        };

        store.subscribe(stateSetter);
        return () => store.unsubscribe(stateSetter);
    }, refresherValues);

    return [state, store.set] as const;
}

export function getStoreValue<T>(store: Store<T>) {
    return store.state;
}

type stateSubscriber<T = unknown> = (state: T) => void;

export interface Store<T = unknown> {
    _store: true;
    state: T;
    set: (updateState: T | ((draft: T) => void)) => void;
    reset: () => void;
    subscribe: (setState: stateSubscriber<T>) => Set<stateSubscriber<T>>;
    unsubscribe: (setState: stateSubscriber<T>) => void;
}

export interface StoreSetter<T> {
    (updateState: T): void;

    (updateState: (storeDraft: T) => void): void;
}

declare global {
    interface Window {
        traceStore: Store;
        watchStore: Store;
        __REDUX_DEVTOOLS_EXTENSION__?: any;
    }
}
