import React from 'react';
import { getStoreValue, useStore } from '../../../stores/utils';
import { store } from '../../../stores/store';
import { ClientName } from '../../../types/client';
import Wrapper from './styles';

export function HeaderLogo() {
    const [isCategoryTreeOpen] = useStore(store.sportsbook.isCategoryTreeOpen);
    const { CLIENT_NAME } = getStoreValue(store.environment);
    const wynnBetLogo = { logoName: 'wynnbet', className: 'wynnbet-logo' };
    const logos = {
        [ClientName.COOLBET]: { logoName: 'coolbet', className: 'coolbet-logo' },
        [ClientName.DEMO]: { logoName: 'gan', className: 'gan-logo' },
        [ClientName.SPORTSBOOKCORE]: { logoName: 'gan', className: 'gan-logo' },
        [ClientName.IVC]: { logoName: 'ivc', className: 'ivc-logo' },
        [ClientName.WYNNBET]: wynnBetLogo,
        [ClientName.WYNNBET_CORE]: wynnBetLogo,
        [ClientName.WYNNBET_MA]: { logoName: 'wynnbet-ma', className: 'wynnbet-logo' },
        [ClientName.WYNNBET_WV]: { logoName: 'wynnbet-wv', className: 'wynnbet-logo' },
        [ClientName.WYNNBET_NV]: { logoName: 'wynnbet-nv', className: 'wynnbet-logo' },
    };

    if (!logos[CLIENT_NAME]) {
        return <Wrapper />;
    }

    const { logoName, className } = logos[CLIENT_NAME];

    return (
        <Wrapper isCategoryTreeOpen={isCategoryTreeOpen}>
            <img
                className={`img-logo ${className}`}
                src={`/assets/images/header-logo/${logoName}.png`}
                alt="header-logo"
            />
        </Wrapper>
    );
}
