import { Slider, Typography } from 'antd';
import { useDebounce } from 'hooks/useDebounce';
import React from 'react';
import useLocalStorage from '../../../../hooks/useLocalStorage';

const { Text } = Typography;

interface Props {
    label: string;
    localStorageKey: string;
}

export function HeaderUserSettingsVolume({ label, localStorageKey }: Props) {
    const [soundVolume, setSoundVolume] = useLocalStorage(localStorageKey, 50);

    const onSoundVolumeChange = useDebounce((value: number) => {
        setSoundVolume(value);
    }, 300);

    if (soundVolume === undefined) {
        return null;
    }

    return (
        <div>
            <Text strong>{label}</Text>
            <Slider defaultValue={soundVolume} onChange={onSoundVolumeChange} />
        </div>
    );
}
