import { logger } from '@sentry/utils';
import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { getWarehouseDeliveryValidationErrors } from '../../../microservices/warehouse-management';
import { ValidationErrorsFilter, WarehouseValidationErrors } from '../../../types/warehouse-management';
import { useTableFilter } from '../../table/useTableFilter';

const defaultFilter = { page: 0, limit: 100, schema: '', table_name: '', error: '' };

export function useWarehouseValidationErrors() {
    const [validationErrors, setValidationErrors] = useState<WarehouseValidationErrors>();
    const { filterState, changeFilterState } = useTableFilter<ValidationErrorsFilter>(defaultFilter);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        loadWarehouseValidationErrors(filterState);
    }, [filterState]);

    async function loadWarehouseValidationErrors(filter: ValidationErrorsFilter) {
        setIsLoading(true);

        try {
            const warehouseValidationErrors = await getWarehouseDeliveryValidationErrors(filter);
            setValidationErrors(warehouseValidationErrors);
        } catch (error) {
            logger.log('useWarehouseValidationErrors', 'loadWarehouseValidationErrors', error);
            notification.error({ message: `Error loading delivery validation errors` });
        }

        setIsLoading(false);
    }

    return { validationErrors, filterState, changeFilterState, isLoading };
}
