import { notification } from 'antd';
import isObject from 'lodash/isObject';
import { DependencyList, useEffect, useState } from 'react';

type Awaited<T> = T extends PromiseLike<infer U> ? Awaited<U> : T;

interface Options {
    onError?: (errorMessage: any) => any;
    reactDepsList?: DependencyList;
    autoRun?: boolean;
    errorText?: string;
}

export const useFetch = <Fn extends (...args) => any>(
    fn: Fn,
    ...args: [] | [Options] | [Options, ...Parameters<Fn>] | Parameters<Fn>
) => {
    type FnReturn = Awaited<ReturnType<Fn>>;
    const maybeOpts = args[0];
    const defaultErrorHandler = (e: any) =>
        notification.error({
            message: errorText || (e?.name ? `${e.name} (${e.code || '?'}): ${e.meta || e.message}` : e.message),
        });
    const {
        autoRun = true,
        reactDepsList = [],
        onError = defaultErrorHandler,
        errorText = '',
    } = isObject(maybeOpts) ? (maybeOpts as Options) : ({} as Options);
    const [response, setResponse] = useState<FnReturn>();
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const defaultParams = isObject(maybeOpts) ? args.slice(1) : args;

    const fetchData = async (...args: Parameters<Fn>) => {
        try {
            setIsLoading(true);
            const res = await fn(...defaultParams.concat(args));
            setResponse(res);
            setIsLoading(false);
            return res;
        } catch (error) {
            setError(error);
            onError(error);
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (autoRun) {
            fetchData(...([] as unknown as Parameters<Fn>));
        }
    }, reactDepsList);

    return { response, setResponse, error, fetchData, isLoading };
};
