import React from 'react';
import { Collapse, Form, Input, Space, Tooltip } from 'antd';
import { useStore } from '../../../../stores/utils';
import { store } from '../../../../stores/store';
import { UiButton } from '../../../ui/button/UiButton';
import { UiNumberInput } from '../../../ui/number-input/UiNumberInput';
import { TICKET_LIST_BACKGROUND_COLORS, TicketListBackgroundColorSettings } from '../../../../services/ticket/ticket';
import { QuestionCircleOutlined } from '@ant-design/icons';

export function HeaderUserSettingsTicketListSettings() {
    const [backgroundColorRanges, setBackgroundColorRanges] = useStore(
        store.sportsbook.ticketListBackgroundColorRanges,
    );

    const { Panel } = Collapse;
    const { Item } = Form;
    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };

    function saveBackgroundSettings(values: TicketListBackgroundColorSettings) {
        localStorage.setItem(TICKET_LIST_BACKGROUND_COLORS, JSON.stringify(values));
        setBackgroundColorRanges(values);
    }

    return (
        <Collapse>
            <Panel
                header={
                    <Space>
                        <>Ticket list settings background colors</>
                        <Tooltip title="Range upper bound is not inclusive">
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </Space>
                }
                key="ticket_list_settings"
            >
                <Form {...layout} initialValues={backgroundColorRanges} onFinish={saveBackgroundSettings}>
                    <Item label="Green">
                        <Input.Group compact>
                            <Item noStyle className="success" name={['success', 'from']}>
                                <UiNumberInput placeholder="From" />
                            </Item>
                            <Item noStyle className="success" name={['success', 'to']}>
                                <UiNumberInput placeholder="To" />
                            </Item>
                        </Input.Group>
                    </Item>
                    <Item label="Yellow">
                        <Input.Group compact>
                            <Item noStyle className="success" name={['warning', 'from']}>
                                <UiNumberInput placeholder="From" />
                            </Item>
                            <Item noStyle className="success" name={['warning', 'to']}>
                                <UiNumberInput placeholder="To" />
                            </Item>
                        </Input.Group>
                    </Item>
                    <Item label="Red">
                        <Input.Group compact>
                            <Item noStyle className="success" name={['danger', 'from']}>
                                <UiNumberInput placeholder="From" />
                            </Item>
                            <Item noStyle className="success" name={['danger', 'to']}>
                                <UiNumberInput placeholder="To" />
                            </Item>
                        </Input.Group>
                    </Item>
                    <Item noStyle>
                        <UiButton btnName="Save" htmlType="submit" className="full-width" size="middle" />
                    </Item>
                </Form>
            </Panel>
        </Collapse>
    );
}
