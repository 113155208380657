import { ClientName, FaviconTypes } from 'types/client';
import { updateFavicon } from 'services/util';
import { store } from 'stores/store';
import { getStoreValue } from 'stores/utils';
import { useEffect } from 'react';

export const useFavicon = () => {
    const favicons = {
        [ClientName.COOLBET]: FaviconTypes.COOLBET,
        [ClientName.DEMO]: FaviconTypes.GAN,
        [ClientName.IVC]: FaviconTypes.IVC,
    };

    useEffect(() => {
        const { CLIENT_NAME } = getStoreValue(store.environment);
        if (favicons[CLIENT_NAME]) {
            updateFavicon(favicons[CLIENT_NAME]);
        }
    }, []);
};
