import { Navigator } from '../../../components/navigator/Navigator';
import { WarehouseDeliveryDetails } from '../../../components/warehouse/delivery/details/WarehouseDeliveryDetails';
import { WarehouseDeliveryList } from '../../../components/warehouse/delivery/list/WarehouseDeliveryList';
import { ROUTES } from '../../../routes';
import { getRoute, useRoutes } from '../../../services/router';

export function WarehouseDeliveryPage() {
    const routes = useRoutes([
        {
            path: getRoute(ROUTES.warehouse.delivery),
            component: WarehouseDeliveryList,
            exact: true,
        },
        {
            path: `${getRoute(ROUTES.warehouse.delivery)}/:detailsId`,
            component: WarehouseDeliveryDetails,
            exact: true,
        },
    ]);

    return <Navigator routes={routes} />;
}
