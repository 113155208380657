import { DEFAULT_DEBOUNCE_TIMEOUT } from 'services/util';
import { UiNumberInputDebounce } from '../../../ui/number-input/debounce/UiNumberInputDebounce';

interface Props {
    placeholder: string;
    isLoading: boolean;
    onChange: (value: number) => void;
}

export function WarehouseDebounceInputNumber({ placeholder, isLoading, onChange }: Props) {
    return (
        <UiNumberInputDebounce
            placeholder={placeholder}
            min={0}
            addonAfter="s"
            size="middle"
            disabled={isLoading}
            debounceTimeout={DEFAULT_DEBOUNCE_TIMEOUT}
            onChange={onChange}
        />
    );
}
