import { User } from '@sentry/browser';
import { Country } from '@staycool/location';
import {
    getServiceUrl,
    httpDelete,
    httpGet,
    httpPatch,
    httpPost,
    httpPostFile,
    httpPut,
    httpPutFile,
} from 'services/api';
import { UserProvince } from 'types/users';
import { GroupedPaymentMethod } from './payments2';

const getUrl = url => getServiceUrl('kyc', url);

export function getKycUser(id: string) {
    const url = getUrl(`user/${id}`);
    return httpGet<KycUser>(url);
}

export function updateKycUser(id: string, update: KycUserUpdate) {
    const url = getUrl(`user/${id}`);
    return httpPatch<KycUser & { message?: string }>(url, update);
}

export function getDocuments(userId: string, filters?: Partial<KycDocument>) {
    const url = getUrl('docs');
    return httpGet<KycDocumentsResponse>(url, {
        where: JSON.stringify({ user_id: userId, ...filters }),
        limit: 0,
    });
}

export function getDocumentRequests(filter: { type: string; userId: string }) {
    const url = getUrl('document-requests');
    return httpGet<DocumentRequest[]>(url, filter);
}

export function createDocumentRequest(body: { transactionId: string; type: string; userId: string }) {
    const url = getUrl('document-requests');
    return httpPost<DocumentRequest>(url, body);
}

export function deleteDocumentRequest(id: string) {
    const url = getUrl(`document-requests/${id}`);
    return httpDelete(url);
}

export function updateKycDocument(id: string, update: KycDocumentUpdate) {
    const url = getUrl(`docs/${id}`);
    return httpPut<KycDocument>(url, update);
}

export function deleteKycDocument(id: string) {
    const url = getUrl(`docs/${id}`);
    return httpDelete<void>(url);
}

export function getDocumentUrl(document: KycDocument) {
    const { filename, updated } = document;
    const url = getUrl(`download/${encodeURIComponent(filename)}?timestamp=${updated}`);
    return httpGet<Blob>(url, {}, { isBlob: true });
}

export function getAudioFile(document: KycDocument) {
    const url = getUrl(`full/${encodeURIComponent(document.filename)}`);
    return httpGet<string>(url);
}

export async function uploadDocumentFile(data: DocumentUploadPayload) {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => formData.append(key, value));
    const url = getUrl('upload');
    return httpPostFile<KycUploadResponse>(url, formData);
}

export async function updateDocumentFile(documentId: string, data: DocumentUpdatePayload) {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => formData.append(key, value));
    const url = getUrl(`docs/${documentId}/file`);
    return httpPutFile<void>(url, formData);
}

export function getKycDocumentsUploadLimits() {
    const url = getUrl('documents-upload-limits');
    return httpGet<DocumentsUploadLimitsResponse>(url);
}

export function updateKycDocumentsUploadLimits(limits: DocumentsUploadLimitsRequest) {
    const url = getUrl('documents-upload-limits');
    return httpPost<DocumentsUploadLimitsResponse>(url, limits);
}

export function getKycSettings() {
    const url = getUrl('settings');
    return httpGet<KycSetting[]>(url);
}

export function updateKycSettings(setting: Pick<KycSetting, 'settingName' | 'setting'>) {
    const url = getUrl('settings');
    return httpPost<KycSetting[]>(url, setting);
}

export function getGovernmentIds(userId: string) {
    const url = getUrl(`government-id/user/${userId}`);
    return httpGet<GovernmentId[]>(url);
}

export function getGroupedDocuments(filters: Record<string, unknown>) {
    const url = getUrl('docs/grouped');
    return httpGet<GroupedDocsResponse>(url, filters);
}

export function getGovernmentId(userId: string) {
    const url = getUrl(`government-id/user/${userId}`);
    return httpGet<GovernmentId[]>(url);
}

export async function updateGovernmentId(userId: string, update: Partial<GovernmentId>) {
    const url = getUrl(`government-id/user/${userId}`);
    return httpPut<GovernmentId>(url, update);
}

export async function createGovernmentId(data: Partial<GovernmentId>) {
    const url = getUrl('government-id');
    return httpPost<GovernmentId>(url, data);
}

export interface GovernmentId {
    id: number;
    userId: User['id'];
    documentType: string;
    documentValue: string;
    issuerState?: UserProvince;
    issuerCountry: Country;
    expirationDate: Date;
}

export enum FileType {
    PDF = 'application/pdf',
    JPEG = 'image/jpeg',
    PNG = 'image/png',
    MP3 = 'audio/mpeg',
}

export enum KycDocumentType {
    ADDRESS = 'address',
    AUDIO = 'audio',
    ID = 'id',
    INCOME = 'income',
    PAYMENT = 'payment',
    EDD = 'edd',
}

export enum HighLevelResult {
    APPROVED = 'Passed',
    EXPIRED = 'Expired',
    FAILED = 'Failed',
    NOT_ACCEPTED = 'NotAccepted',
    NOT_SUPPORTED = 'Notsupported',
    PENDING = 'Refer',
}

export enum KycDocumentStatus {
    PENDING = 'Pending',
    APPROVED = 'Approved',
    DECLINED = 'Declined',
    DELETED = 'Deleted',
}

export enum UserSelectedOption {
    BANK_SCREENSHOT = 'bank-screenshot',
    BANK_STATEMENT = 'bank-statement',
    CARD_FRONT_AND_BACK = 'card-front-and-back',
    CARD_LOST = 'card-lost',
    CARD_NO_NAME_OR_NUMBER = 'card-no-name-or-number',
    E_WALLET_SCREENSHOT = 'e-wallet-screenshot',
}

export enum KycDocumentSide {
    FRONT = 'front',
    BACK = 'back',
}

export enum DocumentsUploadLimitsField {
    ADDRESS_COUNT = 'address_count',
    ID_COUNT = 'id_count',
    INCOME_COUNT = 'income_count',
    PAYMENTS_COUNT = 'payment_count',
}

export interface DocumentRequest {
    created: Date;
    expired: Date;
    extra: {
        transactionId: string;
    };
    id: string;
    type: string;
    user_id: string;
}
export interface KycDocumentsResponse {
    count: number;
    items: KycDocument[];
    nextOffset: number;
}

export interface KycDocument {
    bucket: string;
    created: Date;
    deleted?: Date;
    document_type: KycDocumentType;
    expiry_date: string | null;
    file_type: FileType;
    filename: string;
    id: string;
    size: number;
    status: KycDocumentStatus;
    updated: Date;
    user_alias: string;
    user_id: string;
    extra: DocumentExtra;
    change_history_updated: string | null;
    updated_by: number | null;
    updated_from: KycDocumentStatus | null;
    user_pep_check_status?: string;
    showThumb?: boolean;
    paymentMethod?: GroupedPaymentMethod;
}

export interface DocumentExtra {
    isUploadLimitIgnored?: boolean;
    transactionId?: string;
    paymentMethodId?: string;
    declineReason?: string;
    side?: string;
    selectedOption?: string;
    JourneyId?: string;
    JourneySteps?: CheckSteps[];
    userDataMatchResult?: CheckSteps[];
    documentType?: string;
    documentNumber?: string;
    countryOfIssuing?: string;
    provinceOfIssuing?: string;
    encryptedEncryptionInfo?: string;
    idenfy?: KycDocumentExtraIdenfy;
}

interface KycDocumentExtraIdenfy {
    scanRef: string;
    manualAddress?: string;
    manualAddressMatch?: boolean;
    status?: {
        mismatchTags: string[];
        fraudTags: string[];
        denyReasons: string[];
        suspicionReasons: string[];
        overall: string;
        autoDocument: string;
        autoFace: string;
        manualDocument: string;
        manualFace: string;
    };
    data?: {
        docFirstName: string;
        docLastName: string;
        docNumber: string;
        docPersonalCode: string;
        docExpiry: string;
        docDateOfIssue: string;
        docType: string;
        docNationality: string;
        docIssuingCountry: string;
        selectedCountry: string;
        ageEstimate: string;
        docSex: string;
    };
    additionalData?: {
        UTILITY_BILL?: { address: { status: string; value: string } };
    };
}

interface CheckSteps {
    Type: string;
    HighLevelResult: HighLevelResult;
    ValidationChecks?: string[];
    FailureReason: string;
    TripleScanAttempts?: string;
}

export interface KycDocumentUpdate {
    status?: KycDocumentStatus;
    declineReason?: string;
    paymentMethodId?: string;
    side?: KycDocumentSide | string;
    documentType?: KycDocumentType;
    selectedOption?: string;
    documentNumber?: string;
    extraDocumentType?: string;
    countryOfIssuing?: string;
    provinceOfIssuing?: string;
}

export interface KycUploadResponse {
    insertedFiles: number;
}

export interface DocumentUploadPayload {
    [key: string]: string | File;
}

export interface DocumentUpdatePayload {
    [key: string]: string | File;
}

export type DocumentsUploadLimit = Partial<Record<Country, number>>;

export type DocumentsUploadLimitsRequest = Record<DocumentsUploadLimitsField, DocumentsUploadLimit>;

export interface DocumentsUploadLimitsResponse extends DocumentsUploadLimitsRequest {
    id: number;
    updated: Date;
}

export type KycSettingValue = boolean | string;

export interface KycSetting {
    settingName: string;
    setting: Record<Country, KycSettingValue>;
    description: string;
    header: string;
    values?: KycSettingValue[];
}

export interface GroupedDocsResponse {
    count: number;
    items: GroupedDocumentsByUserId[];
    nextOffset: number;
}

export interface GroupedDocumentsByUserId {
    docs: KycDocument[];
    oldest_document_from: string;
    user_alias: string;
    user_id: string;
    user_pep_check_status: string;
}

export enum KycTabs {
    INCOME = 'INCOME',
    NON_INCOME = 'NON_INCOME',
    SECURITY = 'SECURITY',
    PEP = 'PEP',
    SOURCE_OF_WEALTH = 'SOW',
    VIP = 'VIP',
}

export interface KycUserUpdate extends Partial<KycUser> {
    comments?: string;
    shouldSendKycApprovedEmail?: boolean;
}

export interface KycUser {
    addressDeclineReason: string | null;
    createdAt: string;
    governmentIdExpiryCheckDate: string | null;
    id: string;
    idDeclineReason: string | null;
    incomeDeclineReason: string | null;
    kycStatus: KycStatus;
    paymentDeclineReason: string | null;
    proofOfAddressStatus: KycProofStatus;
    proofOfIdExpiryDate: string | null;
    proofOfIdStatus: KycProofStatus;
    proofOfIncomeStatus: KycProofStatus;
    proofOfPaymentStatus: KycProofStatus;
    updatedAt: string;
}

export enum KycStatus {
    APPROVED = 'approved',
    NONE = 'none',
    SECURITY = 'security',
    WAITING = 'waiting',
}

export enum KycProofStatus {
    APPROVED = 'approved',
    DECLINED = 'declined',
    FAILED = 'failed',
    NOT_CHECKED = 'not_checked',
    PENDING = 'pending',
    REQUESTED = 'requested',
}
